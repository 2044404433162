import api from "apis";
import { Agent, GetAgentsParams } from "apis/types/agents";
import { PaginatedResponse } from "apis/types/general";

export const agentsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAgents: build.query<PaginatedResponse<Agent>, GetAgentsParams>({
      query: (params) => ({
        url: "/agents/",
        params: { ordering: "-id", ...params },
      }),
      forceRefetch: () => true,
    }),

    getAgent: build.query<Agent, string>({
      query: (id) => ({
        url: `/agents/${id}/`,
      }),
      forceRefetch: () => true,
    }),

    addAgent: build.mutation<any, Omit<Agent, "id">>({
      query: (body) => ({
        url: "/agents/",
        method: "POST",
        body,
      }),
    }),

    updateAgent: build.mutation<Agent, [id: string, body: Agent]>({
      query: ([id, body]) => ({
        url: `/agents/${id}/`,
        method: "PATCH",
        body,
      }),
    }),
    deleteAgent: build.mutation<Agent, string>({
      query: (id) => ({
        url: `/agents/${id}/`,
        method: "DELETE",
      }),
    }),

    deleteAgentsBulk: build.mutation<any, string>({
      query: (ids) => ({
        url: `/agents/delete_bulk/?ids=${ids}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAgentQuery,
  useGetAgentsQuery,
  useAddAgentMutation,
  useUpdateAgentMutation,
  useDeleteAgentMutation,
  useDeleteAgentsBulkMutation,
} = agentsApi;
