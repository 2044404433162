import api from "apis";
import {
  Benefit,
  CompanyMembership,
  Condition,
  GeneralBenefit,
  GeneralCondition,
  GetCompanyMembershipsParams,
  GetMembershipsParams,
  Membership,
} from "apis/types/memberships";
import { PaginatedResponse } from "apis/types/general";

export const membershipsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMemberships: build.query<
      PaginatedResponse<Membership>,
      GetMembershipsParams
    >({
      query: (params) => ({
        url: "/membership/",
        params: { ordering: "-id", ...params },
      }),
      forceRefetch: () => true,
    }),

    addCompanyMemberships: build.mutation<
      any,
      Omit<CompanyMembership, "membership_name" | "company_name" | "id">[]
    >({
      query: (body) => ({
        url: "/company-membership/",
        method: "POST",
        body,
      }),
    }),

    updateCompanyMemberships: build.mutation<
      any,
      Omit<
        CompanyMembership,
        "membership" | "company" | "membership_name" | "company_name"
      >[]
    >({
      query: (body) => ({
        url: "/company-membership/update_bulk/",
        method: "PATCH",
        body,
      }),
    }),

    getMembership: build.query<Membership, string>({
      query: (id) => ({
        url: `/membership/${id}/`,
      }),
      forceRefetch: () => true,
    }),

    addMembership: build.mutation<Omit<Membership, "benefits">, FormData>({
      query: (body) => ({
        url: "/membership/",
        method: "POST",
        body,
      }),
    }),

    deleteMembership: build.mutation<any, string>({
      query: (id) => ({
        url: `/membership/${id}/`,
        method: "DELETE",
      }),
    }),

    deleteMembershipsBulk: build.mutation<any, string>({
      query: (ids) => ({
        url: `/membership/delete_bulk/?ids=${ids}`,
        method: "DELETE",
      }),
    }),

    addBenefits: build.mutation<Benefit, Omit<Benefit, "id">[]>({
      query: (body) => ({
        url: "/membership-benefit/",
        method: "POST",
        body,
      }),
    }),

    addConditions: build.mutation<Condition, Omit<Condition, "id">[]>({
      query: (body) => ({
        url: "/membership-benefit-condition/",
        method: "POST",
        body,
      }),
    }),

    updateMembership: build.mutation<Membership, [id: string, body: FormData]>({
      query: ([id, body]) => ({
        url: `/membership/${id}/`,
        method: "PATCH",
        body,
      }),
    }),

    updateBenefitsBulk: build.mutation<any, Benefit[]>({
      query: (body) => ({
        url: `/membership-benefit/update_bulk/`,
        method: "PATCH",
        body,
      }),
    }),

    getCompanyMemberships: build.query<
      PaginatedResponse<CompanyMembership>,
      GetCompanyMembershipsParams
    >({
      query: (params) => ({
        url: "/company-membership/",
        params: { ordering: "-id", ...params },
      }),
    }),

    getGeneralBenefits: build.query<GeneralBenefit[], void>({
      query: () => ({
        url: "/general-benefit/",
      }),
      forceRefetch: () => true,
      providesTags: ["generalBenefits"],
    }),

    getGeneralBenefitConditions: build.query<GeneralCondition[], number>({
      query: (id) => ({
        url: `/general-condition/?general_benefit_id=${id}`,
      }),
    }),

    addGeneralBenefit: build.mutation<void, GeneralBenefit>({
      query: (body) => ({
        url: "/general-benefit/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["generalBenefits"],
    }),

    editGeneralBenefit: build.mutation<void, GeneralBenefit>({
      query: (body) => ({
        url: `/general-benefit/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["generalBenefits"],
    }),

    toggleGeneralBenefitVisibility: build.mutation<
      void,
      { id: number; is_visible: boolean }
    >({
      query: (body) => ({
        url: `/general-benefit/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["generalBenefits"],
    }),

    addGeneralCondition: build.mutation<void, GeneralCondition>({
      query: (body) => ({
        url: "/general-condition/",
        method: "POST",
        body,
      }),
      invalidatesTags: ["generalBenefits"],
    }),

    editGeneralCondition: build.mutation<void, GeneralCondition>({
      query: (body) => ({
        url: `/general-condition/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["generalBenefits"],
    }),

    toggleGeneralConditionVisibility: build.mutation<
      void,
      { id: number; is_visible: boolean }
    >({
      query: (body) => ({
        url: `/general-condition/${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["generalBenefits"],
    }),
  }),
});

export const {
  useGetMembershipsQuery,
  useAddCompanyMembershipsMutation,
  useUpdateCompanyMembershipsMutation,
  useGetMembershipQuery,
  useAddMembershipMutation,
  useAddBenefitsMutation,
  useAddConditionsMutation,
  useDeleteMembershipMutation,
  useDeleteMembershipsBulkMutation,
  useUpdateMembershipMutation,
  useGetCompanyMembershipsQuery,
  useLazyGetMembershipQuery,
  useUpdateBenefitsBulkMutation,
  useGetGeneralBenefitsQuery,
  useGetGeneralBenefitConditionsQuery,
  useAddGeneralBenefitMutation,
  useEditGeneralBenefitMutation,
  useToggleGeneralBenefitVisibilityMutation,
  useAddGeneralConditionMutation,
  useEditGeneralConditionMutation,
  useToggleGeneralConditionVisibilityMutation,
} = membershipsApi;
