/* eslint-disable no-nested-ternary */
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { stringifyParam } from "utils/queryParamService";
import { TableItemProps } from "components/general/Table/types";
import { Membership } from "apis/types/memberships";
import {
  useDeleteMembershipMutation,
  useDeleteMembershipsBulkMutation,
  useGetMembershipsQuery,
} from "apis/services/memberships";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import SelectionInput from "components/inputs/SelectionInput";
import Spinner from "components/general/Spinner";
import Table from "components/general/Table";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import ModalWrapper from "components/modals/ModalWrapper";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import CustomCridetCardLetters from "components/general/CustomCridetCardLetters";
import styles from "./styles.module.scss";

export default function Memberships() {
  const { t } = useAutoCompleteTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [isCardModalVisible, setIsCardModalVisible] = useState(false);
  const [targetCardMembership, setTargetCardMembership] =
    useState<null | Membership>(null);
  const statusTypeOptions = [
    { value: "true", label: t("Active") },
    { value: "false", label: t("Inactive") },
  ];
  const { data: memberships, isLoading: isMembershipLoading } =
    useGetMembershipsQuery({
      page_size: localStorage.getItem("MembershipsTable")
        ? Number(localStorage.getItem("MembershipsTable"))
        : 15,
      page: searchParams.get("page") ?? 1,
      search: searchParams.get("search") ?? "",
      status: searchParams.get("status") ?? "",
    });

  const [deleteMembership, { isLoading: isDeleteLoading }] =
    useDeleteMembershipMutation();

  const [deleteMembershipsBulk, { isLoading: isDeleteBulkLoading }] =
    useDeleteMembershipsBulkMutation();

  const tableHeaders = [
    t("Card_Name"),
    t("Card_Code"),
    t("Annual_Fees"),
    t("Card"),
    t("Status"),
    "", // Make the last one empty string to let the menu btn appear here
  ];

  const arrangeCompanyData = (item: Membership): TableItemProps => ({
    id: String(item.id),
    rowData: [
      { type: "text", dataItem: item.name },
      { type: "text", dataItem: item.card_code },
      {
        type: "text",
        dataItem: `${item.annual_fees} ${item.currency.toUpperCase()}`,
      },
      {
        type: "link",
        dataItem: [
          "View Card",
          () => {
            setTargetCardMembership(item);
            setIsCardModalVisible(true);
          },
        ],
      },
      {
        type: "status",
        dataItem: item.status ? t("Active") : t("Inactive"),
      },
      { type: "more", dataItem: [item.name, "/memberships/edit-membership/"] },
    ],
  });

  const handleDeleteMembership = (
    membershipName: string,
    membershipId: string
  ) => {
    if (membershipId) {
      deleteMembership(membershipId)
        .unwrap()
        .then(() => {
          setIsDeleteModalVisible(false);
          showToast({
            toastType: "success",
            title: `${membershipName} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };

  const handleDeleteBulk = (membershipsIds: string) => {
    deleteMembershipsBulk(membershipsIds)
      .unwrap()
      .then(() => {
        setIsDeleteModalVisible(false);
        showToast({
          toastType: "success",
          title: `Selected Memberships have been deleted successfully!`,
          showCloseIcon: true,
        });
      })
      .catch(handleErrors);
  };

  return (
    <section>
      <div className={styles.filtersContainer}>
        <div className={styles.filtersGroup}>
          <span className={`Headline300Heavy ${styles.headline}`}>
            {t("Memberships")}
          </span>
          <DebouncedSearch
            size="small"
            onSearchChange={(val) => {
              searchParams.set("search", val);
              setSearchParams(searchParams);
            }}
            defaultValue={String(
              searchParams
                ?.get("search")
                ?.split(",")
                .filter((filterd) => filterd !== "") ?? ""
            )}
          />
          <span className={styles.divider} />
          <SelectionInput
            size="small"
            placeholder={t("Status")}
            options={statusTypeOptions}
            mode="tags"
            onChange={(val) => {
              searchParams.set("status", stringifyParam(val as string[]));
              setSearchParams(searchParams);
            }}
            value={
              searchParams
                ?.get("status")
                ?.split(",")
                ?.filter((filterd) => filterd !== "") ?? []
            }
            containerStyle={styles.tagsScroll}
          />
        </div>
        <div className={styles.btnGroup}>
          <Button
            btnClassName={styles.importExportBtns}
            size="small"
            styleType="Secondary"
            prefix={<Icon name="import" size={16} color="Neutral1000" />}
          >
            <span className="Paragraph200Light"> {t("import")} </span>
          </Button>
          <Button
            btnClassName={styles.importExportBtns}
            size="small"
            styleType="Secondary"
            prefix={<Icon name="export" size={16} color="Neutral1000" />}
          >
            <span className="Paragraph200Light">{t("export")}</span>
          </Button>
          <span className={styles.divider} />
          <Button
            btnClassName={styles.addNewCompanyBtn}
            size="small"
            styleType="Primary"
            prefix={
              <Icon name="Add-Outline" size={12} color="TextPrimaryWhite" />
            }
            onClick={() => navigate("add-new-membership")}
          >
            <span className="Button200 text-nowrap">
              {" "}
              {t("Add_New_Membership")}{" "}
            </span>
          </Button>
        </div>
      </div>
      {isMembershipLoading ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : memberships?.results?.length ? (
        <Table
          tableName="MembershipsTable"
          headers={tableHeaders}
          data={memberships?.results?.map((item: Membership) =>
            arrangeCompanyData(item)
          )}
          count={memberships?.count}
          pagesCount={memberships?.pages_number}
          deleteOneRow={handleDeleteMembership}
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          isDeleteLoading={isDeleteLoading || isDeleteBulkLoading}
          deleteMultipleSelectedRows={handleDeleteBulk}
        />
      ) : (
        <div>{t("No_Data_Found")}!</div>
      )}

      <ModalWrapper
        headerTitle=""
        isVisible={isCardModalVisible}
        setIsVisible={setIsCardModalVisible}
        size="large"
        isFooterHidden
        onClose={() => setTargetCardMembership(null)}
      >
        <div className="d-flex justify-content-center">
          {targetCardMembership && (
            <CustomCridetCardLetters
              letterType={targetCardMembership.letters_type}
              isZoom
              bgColor={targetCardMembership.template_color}
              bgImage={targetCardMembership.template_image}
              textColor1={targetCardMembership.template_text_color_1}
              textColor2={targetCardMembership.template_text_color_2}
              shadowColor={targetCardMembership.template_shadow_color}
              shadowWidth={Number(targetCardMembership.template_shadow_width)}
            />
          )}
        </div>
      </ModalWrapper>
    </section>
  );
}
