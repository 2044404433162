import { useEffect, useRef, useState } from "react";
import CARD_LETTERS from "constants/CardLetters";
import FormLogo from "../FormLogo";
import { CustomCridetCardLettersProps } from "./types";
import Image from "../Image";
import styles from "./styles.module.scss";

export default function CustomCridetCardLetters({
  containerStyle,
  isZoom = false,
  bgImage,
  isExternalImg = true,
  bgColor = "rgb(4, 13, 21)",
  textColor1 = "rgb(255, 220, 96)",
  textColor2 = "rgb(255, 255, 255)",
  shadowColor = "rgba(0, 0, 0, 0.769)",
  shadowWidth = 0,
  firstFourNums = "0000",
  secondFourNums = "0000",
  thirdFourNums = "0000",
  fourthFourNums = "0000",
  expiaryDate = "00/00",
  cardHolder = "Card Holder",
  letterType = "gold",
}: CustomCridetCardLettersProps) {
  const sourceRef = useRef<HTMLDivElement>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({ left: 0, top: 0 });

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const handleMouseMove = (e: any) => {
    const targetRect = targetRef?.current?.getBoundingClientRect();
    const sourceRect = sourceRef?.current?.getBoundingClientRect();
    const containerRect = containerRef?.current?.getBoundingClientRect();
    if (targetRect && containerRect && sourceRect) {
      const xRatio =
        (targetRect.width - containerRect.width) / sourceRect.width;
      const yRatio =
        (targetRect.height - containerRect.height) / sourceRect.height;

      const left = Math.max(
        Math.min(e.pageX - sourceRect.left, sourceRect.width),
        0
      );
      const top = Math.max(
        Math.min(e.pageY - sourceRect.top, sourceRect.height),
        0
      );

      setOffset({
        left: left * -xRatio,
        top: top * -yRatio,
      });
    }
  };

  useEffect(() => {
    if (!isExternalImg) {
      if (bgImage) {
        setImgSrc(URL.createObjectURL(bgImage));
      } else {
        setImgSrc(null);
      }
    }
  }, [bgImage]);

  return (
    <div
      className={`${styles.container} ${containerStyle}`}
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <div
        id="card-demo"
        className={styles.card}
        ref={sourceRef}
        style={{
          backgroundColor: bgColor,
        }}
      >
        <div className={styles.logo}>
          <FormLogo />
        </div>

        {(imgSrc || bgImage) && (
          <Image
            src={isExternalImg ? bgImage : imgSrc}
            style={{ position: "absolute", width: "100%", height: "100%" }}
          />
        )}

        <div className={styles.cardNameWrapper}>
          <div className={`${styles.cardNumber} ${styles.first}`}>
            {firstFourNums.split("").map((letter: string) => (
              <span
                style={{
                  display: "inline-block",
                  width: "17px",
                  height: "25px",
                  backgroundImage: `url('${CARD_LETTERS?.[letterType]?.[letter]}')`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            ))}{" "}
            {secondFourNums.split("").map((letter: string) => (
              <span
                style={{
                  display: "inline-block",
                  width: "17px",
                  height: "25px",
                  backgroundImage: `url('${CARD_LETTERS?.[letterType]?.[letter]}')`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            ))}{" "}
            {thirdFourNums.split("").map((letter: string) => (
              <span
                style={{
                  display: "inline-block",
                  width: "17px",
                  height: "25px",
                  backgroundImage: `url('${CARD_LETTERS?.[letterType]?.[letter]}')`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            ))}{" "}
            {fourthFourNums.split("").map((letter: string) => (
              <span
                style={{
                  display: "inline-block",
                  width: "17px",
                  height: "25px",
                  backgroundImage: `url('${CARD_LETTERS?.[letterType]?.[letter]}')`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
            ))}
          </div>
        </div>

        <div className={styles.cardNameWrapper}>
          <div
            className={`${styles.cardName} ${styles.second}`}
            style={{
              textShadow: `${-1 + +shadowWidth}px ${
                2 + +shadowWidth
              }px 2.2px ${shadowColor}`,
              background: `linear-gradient(90deg, ${textColor1} -4.66%, ${textColor2} 220.29%)`,
            }}
          >
            {`EXPIRES END ${expiaryDate}`
              .toLowerCase()
              .split("")
              .map((letter: string) => {
                if (letter === " ") return " ";
                return (
                  <Image
                    key={`EXPIRES END${letter}${Math.random()}`}
                    style={{
                      display: "inline-block",
                      width: "auto",
                      maxHeight: "12px",
                      // marginLeft: "-10px",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                    src={CARD_LETTERS?.[letterType]?.[letter]}
                    alt={`EXPIRES END${letter}`}
                  />
                );
              })}
          </div>
        </div>

        <div className={styles.cardNameWrapper}>
          <div
            className={`${styles.cardName} ${styles.third}`}
            style={{
              textShadow: `${-1 + +shadowWidth}px ${
                2 + +shadowWidth
              }px 2.2px ${shadowColor}`,
              background: `linear-gradient(90deg, ${textColor1} -4.66%, ${textColor2} 220.29%)`,
            }}
          >
            {cardHolder
              .toLowerCase()
              .split("")
              .map((letter: string) => {
                if (letter === " ") return " ";
                return (
                  <Image
                    key={`cardholder${letter}${Math.random()}`}
                    style={{
                      display: "inline-block",
                      width: "auto",
                      maxHeight: "18px",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                    src={CARD_LETTERS?.[letterType]?.[letter]}
                    alt={`cardholder${letter}`}
                  />
                );
              })}
          </div>
        </div>
      </div>
      {isZoom && (
        <div
          ref={targetRef}
          style={{
            position: "absolute",
            left: `${offset.left}px`,
            top: `${offset.top}px`,
            opacity: `${opacity}`,
            width: "calc(343px * 2)",
            height: "calc(215px * 2)",
          }}
        >
          <div
            className={styles.card}
            style={{ width: "calc(343px * 2)", height: "calc(215px * 2)" }}
          >
            <div
              className={styles.logo}
              style={{ transform: "scale(2)", top: "12%", left: "14%" }}
            >
              <FormLogo />
            </div>

            {(imgSrc || bgImage) && (
              <Image
                src={isExternalImg ? bgImage : imgSrc}
                style={{
                  position: "absolute",
                  top: "25%",
                  left: "25%",
                  transform: "scale(2)",
                }}
              />
            )}

            <div className={styles.cardNameWrapper}>
              <div
                className={`${styles.cardNumber} ${styles.first}`}
                style={{
                  transform: "scale(2)",
                  top: "260px",
                  left: "174px",
                  textShadow: `${-1 + +shadowWidth}px ${
                    2 + +shadowWidth
                  }px 2.2px ${shadowColor}`,
                  background: `linear-gradient(90deg, ${textColor1} -4.66%, ${textColor2} 220.29%)`,
                }}
              >
                {firstFourNums} {secondFourNums} {thirdFourNums}{" "}
                {fourthFourNums}
              </div>
            </div>

            <div className={styles.cardNameWrapper}>
              <div
                className={`${styles.cardName} ${styles.second}`}
                style={{
                  transform: "scale(2)",
                  top: "322px",
                  left: "84px",
                  textShadow: `${-1 + +shadowWidth}px ${
                    2 + +shadowWidth
                  }px 2.2px ${shadowColor}`,
                  background: `linear-gradient(90deg, ${textColor1} -4.66%, ${textColor2} 220.29%)`,
                }}
              >
                EXPIRES END {expiaryDate}
              </div>
            </div>

            <div className={styles.cardNameWrapper}>
              <div
                className={`${styles.cardName} ${styles.third}`}
                style={{
                  transform: "scale(2)",
                  top: "360px",
                  left: "92px",
                  textShadow: `${-1 + +shadowWidth}px ${
                    2 + +shadowWidth
                  }px 2.2px ${shadowColor}`,
                  background: `linear-gradient(90deg, ${textColor1} -4.66%, ${textColor2} 220.29%)`,
                }}
              >
                {cardHolder.toUpperCase()}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
