/* eslint-disable jsx-a11y/anchor-is-valid */
import { Text, View, StyleSheet, Link } from "@react-pdf/renderer";
import { AccommodationQuotation } from "apis/types/accommodationQuotation";
import COLORS from "constants/Colors";
import dayjs from "dayjs";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";

export default function AccommodationDetailsPDF({
  currentLanguage,
  data,
  isConfirmation = false,
}: {
  currentLanguage: string;
  data: AccommodationQuotation;
  isConfirmation?: boolean;
}) {
  const CurrencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: data?.currency ?? "GBP",
  });
  const { t } = useAutoCompleteTranslation();
  const documentStyle = StyleSheet.create({
    Label100: {
      fontSize: "14px",
      textTransform: "capitalize",
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text",
      fontWeight: 700,
    },
    Paragraph100Heavy: {
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text-Heavy",
      fontSize: "14px",
    },
    Paragraph200Light: {
      fontFamily:
        currentLanguage === "ar"
          ? "IBMPlexSansArabicLight"
          : "SF-Pro-Text-Light",
      fontSize: "12px",
      fontWeight: 400,
      color: COLORS.TextSecondaryBlack,
    },
    Paragraph200Heavy: {
      color: COLORS.TextSecondaryBlack,
      fontSize: "12px",
      textTransform: "capitalize",
      fontFamily:
        currentLanguage === "ar"
          ? "IBMPlexSansArabicLight"
          : "SF-Pro-Text-Light",
      fontWeight: 400,
    },
    pageContainer: {
      backgroundColor: COLORS.Neutral0,
    },
    quotation_container: {
      display: "flex",
      flexDirection: "column",
      marginTop: "6px",
    },
    nonRefundableText: {
      fontSize: "12px",
      fontWeight: 700,
    },
    roomText: {
      fontSize: "12px",
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    navbar: {
      backgroundColor: COLORS.Neutral1000,
      display: "flex",
      justifyContent: "space-between",
      paddingHorizontal: "20",
      height: "90px",
      alignItems: "center",
      flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
    },
    logoImg: {
      objectFit: "contain",
      height: "80%",
      marginVertical: "auto",
    },
    flightQuotation: {
      borderRadius: "10px",
      border: `1px solid ${COLORS.Neutral300}`,
      padding: "10px",
      marginTop: "10px",
    },
  });

  return (
    <View>
      <View
        key="quotation"
        style={{
          width: "85%",
          marginHorizontal: "auto",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "12px",
          }}
        >
          <View wrap={false}>
            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
              }}
            >
              <Text
                style={{
                  ...documentStyle.Paragraph100Heavy,
                  textDecoration: "underline",
                  color: "#1581E2",
                  textTransform: "capitalize",
                  marginTop: "8px",
                  marginBottom: "4px",
                }}
              >
                {data?.name}
              </Text>
              <Text style={documentStyle.Paragraph200Heavy}> </Text>
            </View>

            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
                marginTop: "8px",
              }}
            >
              <Text style={documentStyle.Paragraph200Heavy}>{t("city")}</Text>
              <Text style={documentStyle.Paragraph200Light}>
                {data?.city_name}
                {data.country_name && ` , ${data.country_name}`}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
                marginTop: "8px",
              }}
            >
              <Text style={documentStyle.Paragraph200Heavy}>
                {t("Check_In_date")}
              </Text>
              <Text style={documentStyle.Paragraph200Light}>
                {dayjs(data?.check_in_date).format("DD/MM/YYYY")}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
                marginTop: "8px",
              }}
            >
              <Text style={documentStyle.Paragraph200Heavy}>
                {t("Check_Out_date")}
              </Text>
              <Text style={documentStyle.Paragraph200Light}>
                {dayjs(data?.check_out_date).format("DD/MM/YYYY")}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
                marginTop: "8px",
              }}
            >
              <Text style={documentStyle.Paragraph200Heavy}>{t("rating")}</Text>
              <Text style={documentStyle.Paragraph200Light}>
                {data?.rating} {t("Stars")}
              </Text>
            </View>

            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
                marginTop: "8px",
              }}
            >
              <Text style={documentStyle.Paragraph200Heavy}>
                {t("address")}
              </Text>
              <Text
                style={{
                  ...documentStyle.Paragraph200Light,
                  maxWidth: "55%",
                  textAlign: currentLanguage === "en" ? "right" : "left",
                }}
              >
                {data?.address}
              </Text>
            </View>

            {data.parameters &&
              data.parameters.length > 0 &&
              // eslint-disable-next-line no-unsafe-optional-chaining
              [...data?.parameters]
                .sort((a: any, b: any) => a.order - b.order)
                .map((item) => (
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                      marginTop: "8px",
                    }}
                  >
                    <Text style={documentStyle.Paragraph200Heavy}>
                      {item.name}
                    </Text>
                    <Text
                      style={{
                        ...documentStyle.Paragraph200Light,
                        maxWidth: "55%",
                        textAlign: currentLanguage === "en" ? "right" : "left",
                      }}
                    >
                      {item.value}
                    </Text>
                  </View>
                ))}

            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
                marginTop: "8px",
              }}
            >
              <Text
                style={{
                  ...documentStyle.Paragraph100Heavy,
                  color: data?.cancelation_before
                    ? COLORS.Success900
                    : COLORS.Danger700,
                }}
              >
                {data?.cancelation_before
                  ? t("Refundable")
                  : t("Non_Refundable")}
              </Text>
              {data?.cancelation_before && (
                <Text style={documentStyle.Paragraph200Light}>
                  {dayjs(data?.cancelation_before_date).format("DD/MM/YYYY")}
                </Text>
              )}
            </View>
          </View>

          {isConfirmation && (
            <View
              style={{
                border: `1px solid ${COLORS.Neutral300}`,
                borderRadius: "10px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
              }}
            >
              <View>
                <Text
                  style={{
                    ...documentStyle.Paragraph100Heavy,
                    fontSize: "16px",
                    marginBottom: "8px",
                  }}
                >
                  {t("guest_list")}
                </Text>
              </View>
              <View>
                {data?.guest_list?.split(/\r\n|\n|\r/).map((guestName) => (
                  <Text
                    key={guestName}
                    style={{
                      ...documentStyle.Paragraph200Light,
                      fontSize: "12px",
                      marginBottom: "8px",
                    }}
                  >
                    {guestName}
                  </Text>
                ))}
              </View>
            </View>
          )}

          <View
            style={{
              marginBottom: "12px",
              marginTop: "8px",
            }}
          >
            <View
              style={{
                backgroundColor: "#f0f7ff",
                padding: "10px",
                borderRadius: "10px",
                border: "1px solid #E1E6EF",
                color: "#1581E2",
                display: "flex",
                flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
              }}
            >
              <Text
                style={{
                  ...documentStyle.Paragraph100Heavy,
                  color: "#1581E2",
                }}
              >
                {data?.rooms?.length} {t("Room")}
                {(data?.rooms?.length ?? 0) > 1 && t("all")}
              </Text>
            </View>
            <View style={{ paddingLeft: "15px" }}>
              {data?.rooms?.map((room, idx) => {
                return (
                  <View
                    style={{ marginTop: idx > 0 ? 12 : 0 }}
                    key={`room${+idx}`}
                  >
                    <View
                      style={{
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        display: "flex",
                      }}
                    >
                      <Text
                        style={{
                          ...documentStyle.Paragraph200Heavy,
                          marginTop: "12px",
                        }}
                        key={room?.id}
                      >
                        {room?.type}
                      </Text>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        paddingLeft: "10px",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                          marginTop: "8px",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {t("Board_type")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Light}>
                          {data?.board_type}
                        </Text>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                          marginTop: "12px",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {t("Adults")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Light}>
                          {room?.adults}
                        </Text>
                      </View>
                      {room?.children !== undefined &&
                        room?.children !== null &&
                        room?.children !== "0" && (
                          <View
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection:
                                currentLanguage === "en"
                                  ? "row"
                                  : "row-reverse",
                              marginTop: "8px",
                            }}
                          >
                            <Text style={documentStyle.Paragraph200Heavy}>
                              {t("Children")}
                            </Text>
                            <Text style={documentStyle.Paragraph200Light}>
                              {room?.children}
                            </Text>
                          </View>
                        )}
                      {room?.children_age !== undefined &&
                        room?.children_age !== null &&
                        room?.children_age !== "0" &&
                        room?.children_age !== "" && (
                          <View
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection:
                                currentLanguage === "en"
                                  ? "row"
                                  : "row-reverse",
                              marginTop: "8px",
                            }}
                          >
                            <Text style={documentStyle.Paragraph200Heavy}>
                              {t("Age")}
                            </Text>
                            <Text style={documentStyle.Paragraph200Light}>
                              {room?.children_age}
                            </Text>
                          </View>
                        )}

                      {!isConfirmation && (
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection:
                              currentLanguage === "en" ? "row" : "row-reverse",
                            marginTop: "8px",
                          }}
                        >
                          <Text style={documentStyle.Paragraph200Heavy}>
                            {t("Room_price")}
                          </Text>
                          <Text style={documentStyle.Paragraph200Light}>
                            {CurrencyFormatter.format(Number(room?.price))}
                          </Text>
                        </View>
                      )}
                      {!isConfirmation &&
                        room?.discount !== undefined &&
                        room?.discount !== null &&
                        Number(room?.discount) > 0 && (
                          <View
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection:
                                currentLanguage === "en"
                                  ? "row"
                                  : "row-reverse",
                              marginTop: "8px",
                            }}
                          >
                            <Text style={documentStyle.Paragraph200Heavy}>
                              {t("Room_Discount")}
                            </Text>
                            <Text style={documentStyle.Paragraph200Light}>
                              {Number(room.discount)} %
                            </Text>
                          </View>
                        )}

                      {!isConfirmation &&
                        room?.discount_credit !== undefined &&
                        room?.discount_credit !== null &&
                        Number(room?.discount_credit) > 0 && (
                          <View
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection:
                                currentLanguage === "en"
                                  ? "row"
                                  : "row-reverse",
                              marginTop: "8px",
                            }}
                          >
                            <Text
                              style={{
                                ...documentStyle.Paragraph200Heavy,
                                maxWidth: "50%",
                              }}
                            >
                              {t("discout_credit_accommodation")}
                            </Text>
                            <Text style={documentStyle.Paragraph200Light}>
                              {Number(room.discount_credit)} %{" "}
                              {`(${t(
                                "equivalent_to"
                              )} ${CurrencyFormatter.format(
                                (Number(room.discount_credit) / 100) *
                                  Number(room.price)
                              )})`}
                            </Text>
                          </View>
                        )}

                      {!isConfirmation &&
                        room?.taxes_fee !== undefined &&
                        room?.taxes_fee !== null &&
                        room?.taxes_fee !== "0.00" && (
                          <View
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection:
                                currentLanguage === "en"
                                  ? "row"
                                  : "row-reverse",
                              marginTop: "8px",
                            }}
                          >
                            <Text style={documentStyle.Paragraph200Heavy}>
                              {t("Taxes_Fee")}
                            </Text>
                            <Text style={documentStyle.Paragraph200Light}>
                              {CurrencyFormatter.format(
                                Number(room?.taxes_fee)
                              )}
                            </Text>
                          </View>
                        )}
                    </View>
                  </View>
                );
              })}
              {!isConfirmation && (
                <View wrap={false}>
                  <View
                    style={{
                      marginTop: "12px",
                      border: `1px solid ${COLORS.Neutral300}`,
                      borderRadius: "10px",
                      padding: "10px",
                      marginLeft: "-15px",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                    }}
                  >
                    <Text
                      style={{
                        ...documentStyle.Paragraph100Heavy,
                        color: "#1581E2",
                      }}
                    >
                      {t("Price")}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    wrap={false}
                  >
                    {data?.city_tax !== undefined &&
                      data?.city_tax !== null &&
                      String(data?.city_tax) !== "0.00" && (
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection:
                              currentLanguage === "en" ? "row" : "row-reverse",
                            marginTop: "12px",
                          }}
                        >
                          <Text style={documentStyle.Paragraph200Heavy}>
                            {t("City_Tax")}
                          </Text>
                          <Text style={documentStyle.Paragraph200Light}>
                            {t("Approx")}{" "}
                            {CurrencyFormatter.format(Number(data.city_tax))}
                          </Text>
                        </View>
                      )}
                    {data?.tourism_fee !== undefined &&
                      data?.tourism_fee !== null &&
                      String(data?.tourism_fee) !== "0.00" && (
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection:
                              currentLanguage === "en" ? "row" : "row-reverse",
                            marginTop: "12px",
                          }}
                        >
                          <Text style={documentStyle.Paragraph200Heavy}>
                            {t("Tourism_Fee")}
                          </Text>
                          <Text style={documentStyle.Paragraph200Light}>
                            {t("Approx")}{" "}
                            {CurrencyFormatter.format(Number(data.tourism_fee))}
                          </Text>
                        </View>
                      )}

                    {data?.damage_deposit !== undefined &&
                      data?.damage_deposit !== null &&
                      data?.damage_deposit > 0 && (
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection:
                              currentLanguage === "en" ? "row" : "row-reverse",
                            marginTop: "12px",
                          }}
                        >
                          <Text
                            style={{
                              ...documentStyle.Paragraph200Heavy,
                              maxWidth: "50%",
                            }}
                          >
                            {t("Damage_Deposit")}
                          </Text>
                          <Text style={documentStyle.Paragraph200Light}>
                            {t("Approx")}{" "}
                            {CurrencyFormatter.format(
                              Number(data.damage_deposit ?? 0)
                            )}
                          </Text>
                        </View>
                      )}
                    {Number(data?.total_price_before_discount) &&
                      Number(data?.discount_credit_total_amount) && (
                        <View
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection:
                              currentLanguage === "en" ? "row" : "row-reverse",
                            marginTop: "8px",
                          }}
                        >
                          <Text style={documentStyle.Paragraph200Heavy}>
                            {t("Total_Price_Before_Discount")}
                          </Text>
                          <Text style={documentStyle.Paragraph200Light}>
                            {CurrencyFormatter.format(
                              Number(data.total_price_before_discount)
                            )}
                          </Text>
                        </View>
                      )}

                    {Number(data.total_discount_amount) !== 0 && (
                      <View
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                          marginTop: "8px",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {t("Total_Discount_Amount")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Light}>
                          {CurrencyFormatter.format(
                            Number(data.total_discount_amount)
                          )}
                        </Text>
                      </View>
                    )}
                    {Number(data.amount_deducted_from_wallet) !== 0 && (
                      <View
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                          marginTop: "8px",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {t("Amount_Deducted_From_Wallet")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Light}>
                          {CurrencyFormatter.format(
                            Number(data.amount_deducted_from_wallet)
                          )}
                        </Text>
                      </View>
                    )}

                    <View
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        marginTop: "8px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {t("Total")}
                      </Text>
                      <Text
                        style={{
                          ...documentStyle.Paragraph100Heavy,
                          color: COLORS.Success900,
                          fontSize: "12px",
                        }}
                      >
                        {CurrencyFormatter.format(Number(data.total))}{" "}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
            </View>
            {!isConfirmation && data?.payment_link && (
              <View
                wrap={false}
                style={{
                  border: `1px solid ${COLORS.Neutral300}`,
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection:
                      currentLanguage === "en" ? "row" : "row-reverse",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      ...documentStyle.Paragraph100Heavy,
                      fontSize: "12px",
                    }}
                  >
                    {t("payment_link")}
                  </Text>

                  <Link
                    src={data?.payment_link}
                    style={{
                      ...documentStyle.Paragraph100Heavy,
                      textDecoration: "underline",
                      color: "#1581E2",
                      textTransform: "capitalize",
                    }}
                  >
                    {t("open_link")}
                  </Link>
                </View>
              </View>
            )}

            {isConfirmation && (
              <View
                style={{
                  border: `1px solid ${COLORS.Neutral300}`,
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                }}
              >
                <View>
                  <Text
                    style={{
                      ...documentStyle.Paragraph100Heavy,
                      fontSize: "16px",
                      marginBottom: "8px",
                    }}
                  >
                    {t("Hotel_Rules")}
                  </Text>
                </View>
                <View>
                  {data?.hotel_rules?.split(/\r\n|\n|\r/).map((ruleText) => (
                    <Text
                      key={ruleText}
                      style={{
                        ...documentStyle.Paragraph200Light,
                        fontSize: "12px",
                        marginBottom: "8px",
                      }}
                    >
                      {ruleText}
                    </Text>
                  ))}
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
}
