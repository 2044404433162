import api from "apis";
import { GetSuppliersParams, Supplier } from "apis/types/suppliers";
import { PaginatedResponse } from "apis/types/general";

export const suppliersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSuppliers: build.query<PaginatedResponse<Supplier>, GetSuppliersParams>({
      query: (params) => ({
        url: "/suppliers/",
        params: { ordering: "-id", ...params },
      }),
      forceRefetch: () => true,
    }),

    getSupplier: build.query<Supplier, string>({
      query: (id) => ({
        url: `/suppliers/${id}/`,
      }),
      forceRefetch: () => true,
    }),

    addSupplier: build.mutation<any, Omit<Supplier, "id">>({
      query: (body) => ({
        url: "/suppliers/",
        method: "POST",
        body,
      }),
    }),

    updateSupplier: build.mutation<
      Supplier,
      [id: string, body: Omit<Supplier, "id">]
    >({
      query: ([id, body]) => ({
        url: `/suppliers/${id}/`,
        method: "PATCH",
        body,
      }),
    }),
    deleteSupplier: build.mutation<Supplier, string>({
      query: (id) => ({
        url: `/suppliers/${id}/`,
        method: "DELETE",
      }),
    }),

    deleteSuppliersBulk: build.mutation<any, string>({
      query: (ids) => ({
        url: `/suppliers/delete_bulk/?ids=${ids}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetSuppliersQuery,
  useGetSupplierQuery,
  useAddSupplierMutation,
  useUpdateSupplierMutation,
  useDeleteSupplierMutation,
  useDeleteSuppliersBulkMutation,
} = suppliersApi;
