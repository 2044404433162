import api from "apis";
import {
  Accommodations,
  GetAccommodationsParams,
} from "apis/types/accommodations";
import { PaginatedResponse } from "apis/types/general";

export const accommodationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccommodations: build.query<
      PaginatedResponse<Accommodations>,
      GetAccommodationsParams
    >({
      query: (params) => ({
        url: "/services/",
        params: { ordering: "-id", ...params },
      }),
      forceRefetch: () => true,
    }),

    getAccommodation: build.query<Accommodations, string>({
      query: (id) => ({
        url: `/services/${id}/`,
      }),
      forceRefetch: () => true,
    }),

    addAccommodation: build.mutation<
      any,
      Omit<Accommodations, "id" | "created_on" | "modified_on">
    >({
      query: (body) => ({
        url: "/services/",
        method: "POST",
        body,
      }),
    }),

    updateAccommodation: build.mutation<
      Accommodations,
      [id: string, body: Omit<Accommodations, "created_on" | "modified_on">]
    >({
      query: ([id, body]) => ({
        url: `/services/${id}/`,
        method: "PATCH",
        body,
      }),
    }),
    deleteAccommodation: build.mutation<Accommodations, string>({
      query: (id) => ({
        url: `/services/${id}/`,
        method: "DELETE",
      }),
    }),

    deleteAccommodationsBulk: build.mutation<any, string>({
      query: (ids) => ({
        url: `/services/delete_bulk/?ids=${ids}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAccommodationsQuery,
  useGetAccommodationQuery,
  useLazyGetAccommodationQuery,
  useAddAccommodationMutation,
  useUpdateAccommodationMutation,
  useDeleteAccommodationMutation,
  useDeleteAccommodationsBulkMutation,
} = accommodationsApi;
