import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import loginHandler from "utils/loginHandler";
import FormLogo from "components/general/FormLogo";
import COLORS from "constants/Colors";
import { useLazyGetUserInfoQuery, useLoginMutation } from "apis/services/auth";
import handleErrors from "utils/handleErrors";
import { setUserInfo } from "reducers/authReducer";
import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

type FormData = yup.InferType<typeof schema>;

export default function Login() {
  const { t } = useAutoCompleteTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [login, { isLoading }] = useLoginMutation();
  const [getUserInfo, { isLoading: getUserInfoLoading }] =
    useLazyGetUserInfoQuery();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "all" /**
     *react-hook-form	validation strategy before submitting behaviour: onChange | onBlur | onSubmit | onTouched | all.
     * I Choose all because the submit btn is disabled as per design it gets enabled when the two fields are validated, so the errors must show to guide user to enable the submit btn
     */,
  });
  const onLoginHandler = (data: FormData) => {
    login(data)
      .unwrap()
      .then((tokens) => {
        loginHandler({
          token: tokens.access_token,
          refreshToken: tokens.refresh_token,
        });
        getUserInfo()
          .unwrap()
          .then((res) => dispatch(setUserInfo(res)));
      })
      .finally(() => {
        if (location.state?.from && location.state?.from?.pathname !== "/") {
          navigate(location.state?.from);
        } else {
          navigate("/quotations");
        }
      })
      .catch(handleErrors);
  };

  return (
    <>
      <FormLogo />
      <h3 className="Headline300Heavy"> {t("Log_In_To_Your_Account")} </h3>
      <span className="Paragraph200Light">{t("Enter_Your_Email")}</span>
      <form
        onSubmit={handleSubmit(onLoginHandler)}
        className={styles.loginForm}
      >
        <div className={styles.inputsContainer}>
          <div className="d-flex flex-column gap-1">
            <TextInput
              status={errors.email?.message ? "error" : "default"}
              placeholder={t("Email")}
              i18nLabelKey="EMAIL"
              errorMsg={errors?.email?.message}
              reactHookFormProps={register("email")}
            />
          </div>
          <div className="d-flex flex-column gap-1">
            <TextInput
              status={errors.password?.message ? "error" : "default"}
              placeholder={t("PASSWORD")}
              i18nLabelKey="PASSWORD"
              type="password"
              errorMsg={errors?.password?.message}
              reactHookFormProps={register("password")}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <Link
            to="/forgot-password"
            className={`Button100 ${styles.forgotPassword}`}
          >
            {t("Forget_Password")}?
          </Link>
        </div>

        <Button
          btnClassName={styles.submitBtn}
          styleType="Primary"
          type="submit"
          disabled={!isValid}
          isLoading={isLoading || getUserInfoLoading}
        >
          <span
            className="Button100"
            style={{ color: COLORS.TextPrimaryWhite }}
          >
            {t("LOGIN")}
          </span>
        </Button>
      </form>
    </>
  );
}
