import { NavLink } from "react-router-dom";
import NavLinkItem from "components/general/NavLinkItem";
import LOGO from "assets/images/navLogo.svg";
import Navbar from "components/general/Navbar";
import Image from "components/general/Image";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import logoutHandler from "utils/logoutHandler";
import { useState } from "react";
import useScreenSize from "hooks/useScreenSize";
import { MainNavigationListItem, NavigationListItem } from "./types";
import styles from "./styles.module.scss";

export default function SidebarLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useAutoCompleteTranslation();
  const upperNavigationList: MainNavigationListItem[] = [
    {
      title: t("Quotations"),
      iconName: "Money",
      href: "/quotations",
    },
    {
      title: t("Customers"),
      iconName: "Person",
      href: "/customers",
      subList: [
        {
          title: t("Add_New_Customer"),
          iconName: "dot",
          href: "/customers/create-customer-profile",
        },
      ],
    },
    {
      title: t("Memberships"),
      iconName: "credit_card",
      href: "/memberships",
      subList: [
        {
          title: t("Add_New_Membership"),
          iconName: "dot",
          href: "/memberships/add-new-membership",
        },
        {
          title: t("Membership_Benefits_Settings"),
          iconName: "dot",
          href: "/memberships/membership-benefits-settings",
        },
      ],
    },
    {
      title: t("Companies"),
      iconName: "Business",
      href: "/companies",
      subList: [
        {
          title: t("Add_New_Company"),
          iconName: "dot",
          href: "/companies/add-new-company",
        },
      ],
    },
    {
      title: t("Services"),
      iconName: "grid_view",
      href: "/services",
      subList: [
        {
          title: t("Accommodations"),
          iconName: "dot",
          href: "/services/accommodation",
        },
      ],
    },
    {
      title: t("Suppliers"),
      iconName: "supervisor_account",
      href: "/suppliers",
    },
    {
      title: t("Chat"),
      iconName: "chat",
      href: "/chat",
    },
    // {
    //   title: t("Statistics"),
    //   iconName: "bar_chart",
    //   href: "/statistics",
    // },
  ];

  const lowerNavigationList: NavigationListItem[] = [
    {
      title: t("User_Management"),
      iconName: "supervisor_account",
      href: "/user-management",
    },
    // {
    //   title: t("Settings"),
    //   iconName: "Settings",
    //   href: "/settings",
    // },
  ];

  const { width } = useScreenSize();

  const [isOpen, setIsOpen] = useState(width > 700);

  return (
    <div className="h-100 d-flex">
      <aside
        className={`${styles.sidenav} ${isOpen ? styles.open : styles.close}`}
      >
        <div className={styles.sideBarLogoContainer}>
          <div
            className={styles.toggleSideBar}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Icon name="ChevronDown" size={21} />
          </div>
          {isOpen && (
            <NavLink to="/quotations">
              <div className={styles.logo}>
                <Image src={LOGO} alt="Logo" />
              </div>
            </NavLink>
          )}
        </div>

        <div className={`w-100 ${styles.navigationContent}`}>
          {upperNavigationList?.map((item) => (
            <NavLinkItem key={item?.title} item={item} isSideBarOpen={isOpen} />
          ))}
        </div>
        <div className={`mt-auto w-100 ${styles.navigationContent}`}>
          {lowerNavigationList?.map((item) => (
            <NavLinkItem key={item?.title} item={item} isSideBarOpen={isOpen} />
          ))}
          <Button
            styleType="NoStyle"
            onClick={logoutHandler}
            btnClassName={styles.navigationListItem}
          >
            <div className="d-flex align-items-center">
              <Icon name="logout" size={20} color="TextPrimaryWhite" />
            </div>
            {isOpen && (
              <span className="Paragraph200Heavy"> {t("Logout")} </span>
            )}
          </Button>
        </div>
      </aside>

      <div className="h-100 w-100">
        <Navbar isSideBarOpen={isOpen} />

        <main
          className={`${styles.sidelayoutContent} ${
            isOpen ? "" : styles.widthWithSidebarClosed
          }`}
        >
          {children}
        </main>
      </div>
    </div>
  );
}
