import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Image from "components/general/Image";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import StatusBadge from "components/general/StatusBadge";
import ModalWrapper from "components/modals/ModalWrapper";
import showToast from "utils/showToast";
import PageTitle from "components/general/PageTitle";
import {
  useDeleteCompanyMutation,
  useGetCompanyQuery,
} from "apis/services/companies";
import Spinner from "components/general/Spinner";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import dayjs from "dayjs";
import styles from "./styles.module.scss";

export default function CompanyDetails() {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { companyId } = useParams();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { data: company, isLoading } = useGetCompanyQuery(companyId || "");

  const [deleteCompny, { isLoading: isDeleteLoading }] =
    useDeleteCompanyMutation();

  const handleDeleteCompany = () => {
    if (companyId) {
      deleteCompny(companyId)
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/companies");
          showToast({
            toastType: "success",
            title: `${company?.name} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };
  useEffect(() => {
    if (company) {
      const updatedUrl = `${pathname}?idName=${company?.name}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [company]);

  return (
    <section>
      <PageTitle> {t("Company_Details")} </PageTitle>

      <div className={styles.mainContentContainer}>
        {isLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <>
            <div className={styles.logoAndBtns}>
              {company?.logo !== null ? (
                typeof company?.logo === "string" && (
                  <Image
                    src={company?.logo}
                    alt="company-logo"
                    className={styles.logo}
                  />
                )
              ) : (
                <Icon name="Image" size={168} />
              )}
              <div className={styles.btns}>
                <Button
                  size="small"
                  prefix={<Icon name="Edit" size={16} />}
                  btnClassName={styles.edit}
                  onClick={() =>
                    navigate(`/companies/edit-company/${companyId}`)
                  }
                >
                  <span className="Button100"> {t("Edit")} </span>
                </Button>
                <Button
                  size="small"
                  styleType="Secondary"
                  prefix={<Icon name="delete_outline" size={16} />}
                  btnClassName={styles.delete}
                  onClick={() => setIsDeleteModalVisible(true)}
                >
                  <span className="Button100">{t("Delete")}</span>
                </Button>
              </div>
            </div>
            <div className={styles.innerContainer}>
              <div className="w-100 d-flex">
                <div className="d-flex flex-column gap-2 col-6">
                  <span className="Label100"> {t("Company_Name")} </span>
                  <span className={`Paragraph200Light ${styles.dimmed}`}>
                    {company?.name}
                  </span>
                </div>
                <div className="d-flex flex-column gap-1">
                  <span className="Label100"> {t("Company_Code")} </span>
                  <span className={`Paragraph200Light ${styles.dimmed}`}>
                    {company?.code}
                  </span>
                </div>
              </div>
              <div className="w-100 d-flex">
                <div className="d-flex flex-column gap-2 col-6">
                  <span className="Label100"> {t("Company_Login_Email")} </span>
                  <span className={`Paragraph200Light ${styles.dimmed}`}>
                    {company?.company_login_email}
                  </span>
                </div>
                <div className="d-flex flex-column gap-1">
                  <span className="Label100"> {t("Date_Created")} </span>
                  <span className={`Paragraph200Light ${styles.dimmed}`}>
                    {dayjs(company?.created_on).format("DD/MM/YYYY")}
                  </span>
                </div>
              </div>
              <div className="w-100 d-flex flex-column gap-2">
                <span className="Label100">{t("Memberships_Limitation")}</span>
                <div className={styles.badgesAndCount}>
                  {company?.company_memberships?.map((item) => (
                    <div className="d-flex align-items-center justify-content-between">
                      <StatusBadge
                        type="complete"
                        showDot={false}
                        contrast="high"
                      >
                        <span className="Label200">{item.membership}</span>
                      </StatusBadge>
                      <Button
                        styleType="Secondary"
                        btnClassName={styles.badgeBtn}
                      >
                        <span className="Label200">
                          {item.number_of_subscribers_limit}
                        </span>
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-100 d-flex flex-column gap-2">
                <span className="Label100"> {t("Status")} </span>
                <StatusBadge type={company?.status ? "success" : "error"}>
                  <span className="Label200">
                    {company?.status ? t("Active") : t("Inactive")}
                  </span>
                </StatusBadge>
              </div>
            </div>
          </>
        )}
      </div>

      <ModalWrapper
        size="large"
        headerTitle={t("Delete")}
        modalType="error"
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        isFooterHidden
      >
        {isDeleteLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Medium" />
          </div>
        ) : (
          <>
            <span className="Paragraph100Light">
              {t("Delete_Modal")} ({company?.name})?
            </span>
            <div className={styles.deleteModalBtns}>
              <Button
                styleType="Secondary"
                btnClassName={styles.cancelBtn}
                onClick={() => setIsDeleteModalVisible(false)}
              >
                <span className="Button100">{t("Cancel")}</span>
              </Button>
              <Button
                btnClassName={styles.deleteBtn}
                onClick={handleDeleteCompany}
              >
                <span className="Button100">{t("Delete")}</span>
              </Button>
            </div>
          </>
        )}
      </ModalWrapper>
    </section>
  );
}
