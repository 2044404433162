import { useEffect } from "react";
import { Control, FieldErrors } from "react-hook-form";
import useToggleState from "hooks/useToggleState";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Collapse from "components/wrappers/Collapse";
import * as yup from "yup";
import AccommodationDetails from "./AccommodationDetails";
import PriceDetails from "./PriceDetails";
import CheckInOut from "./CheckInOut";
import Supplier from "./SupplierQuotationDetails";
import AccommodationPayment from "./AccommodationPayment";
import { generalQuotationTypes } from "../..";
import HotelRules from "./HotelRules";
import ParameterDetails from "../General/ParameterDetails";

export const accommodationSchema = yup.object().shape(
  {
    parameters: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        value: yup.string().when("name", {
          is: (val: string) => !!val,
          then: (schema) => schema.required("Value is required"),
        }),
        order: yup.string().when("name", {
          is: (val: string) => !!val,
          then: (schema) => schema.required("Order is required"),
        }),
      })
    ),
    id: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    quotation_type: yup
      .string()
      .required("Quotation type is required")
      .equals(["accommodation"], "Invalid quotation type"),
    rooms: yup.array().of(
      yup.object().shape({
        id: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
        price: yup
          .number()
          .moreThan(0, "Room Price is required")
          .required("Room Price is required")
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
        discount: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .max(100, "Discount must be less than or equal to 100"),
        room_type: yup.string().required("Room Type is required"),
        adults: yup
          .number()
          .moreThan(0, "Number of adults is required")
          .lessThan(101, "Max number of Adults is 100")
          .required("Number of adults is required")
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
        children: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
        children_age: yup.string().when("children", {
          is: (val: string) => Number(val) > 0,
          then: (schema) => schema.required("Age of children is required"),
        }),
        discount_credit: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .max(100, "Credit percentage can't exceed 100"),
        taxes_fee: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
      })
    ),
    accommodation_parameters: yup.array().of(
      yup.object().shape({
        name: yup.string().required("Parameter Name is required"),
        value: yup.string().required("Parameter Value is required"),
        order: yup
          .number()
          .required("Parameter Order is required")
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
      })
    ),
    accommodation_type: yup
      .string()
      .oneOf(
        [
          "hotel",
          "resort",
          "apartment",
          "aparthotel",
          "serviced_apartment",
          "",
        ],
        "Accommodation Type is required"
      )
      .required("Accommodation Type is required"),
    accommodation_service_id: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    hotel_rules: yup.string(),
    isAccomodationNew: yup.boolean(),
    name: yup.string().required("Accommodation Name is required"),
    rating: yup
      .number()
      .notOneOf([0], "Rating is required")
      .required("Rating is required")
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    address: yup.string().required("Address is required"),
    board_type: yup.string().required("Board type is required"),
    currency: yup
      .string()
      .required("Currency is required. Please choose currency from above!"),
    city_tax: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    damage_deposit: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable(),
    tourism_fee: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    apply_discount_on_taxes_and_basic_price: yup.boolean(),
    check_in_date: yup.string().required("Check In date is required"),
    check_out_date: yup.string().required("Check Out date is required"),
    cancelation_before: yup.boolean(),
    cancelation_before_date: yup
      .string()
      .nullable()
      .when("cancelation_before", {
        is: true,
        then: (schema) =>
          schema.required("Cancelation Before Date is required"),
      }),
    supplier: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    supplier_currency: yup.string(),
    supplier_price: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    payment_date_for_supplier: yup.string().nullable(),
    general_quotation: yup
      .number()
      .required("General Quotation is required")
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    city: yup
      .number()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? undefined : value)),
    city_name_new: yup.string().when("city", {
      is: (val: number) => val === 0 || val === null,
      then: (schema) => schema.required("City is required"),
    }),
    wallets: yup
      .array()
      .of(
        yup
          .number()
          .required()
          .transform((value) => (Number.isNaN(value) ? undefined : value))
      )
      .nullable(),
    payment_gateway: yup
      .string()
      .oneOf(["stripe", "paytabs", "kashier"], "Payment gateway is required!")
      .required("Payment gateway is required!"),
    guest_list: yup.string(),
  },
  [["discount", "discount_credit"]]
);

export type accommodationTypes = yup.InferType<typeof accommodationSchema>;
export type customAccommodationErrorsType = Omit<
  generalQuotationTypes,
  "quotations"
> & {
  quotations: yup.InferType<typeof accommodationSchema>[];
};

export default function AccomodationFields({
  control,
  errors,
  index,
  setValue,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customAccommodationErrorsType>;
  index: number;
  setValue: any;
}) {
  const { t } = useAutoCompleteTranslation();
  const {
    isSelected: isCollapseSelected,
    addToSelected: addToSelectedCollapse,
    replaceState,
  } = useToggleState<string>([]);

  useEffect(() => {
    if (errors.quotations?.[index]) {
      const temp: string[] = [];

      temp.push("accommodation");

      if (
        errors.quotations?.[index]?.city ||
        errors.quotations?.[index]?.type ||
        errors.quotations?.[index]?.name ||
        errors.quotations?.[index]?.board_type ||
        errors.quotations?.[index]?.rooms
      ) {
        temp.push("accommodationDetails");
      }

      if (
        errors.quotations?.[index]?.city_tax ||
        errors.quotations?.[index]?.currency ||
        errors.quotations?.[index]?.tourism_fee
      ) {
        temp.push("priceDetails");
      }

      if (
        errors.quotations?.[index]?.check_in_date ||
        errors.quotations?.[index]?.check_out_date
      ) {
        temp.push("checkInOut");
      }

      if (errors.quotations?.[index]?.payment_gateway) {
        temp.push("Payment");
      }

      if (errors.quotations?.[index]?.parameters) {
        temp.push("Parameter");
      }

      if (temp.length > 0) replaceState(temp);
    }
  }, [errors.quotations]);

  return (
    <Collapse
      isGeneral
      title={t("Accommodation")}
      isOpen={isCollapseSelected("accommodation")}
      setIsOpen={() => addToSelectedCollapse("accommodation")}
    >
      <Collapse
        order={1}
        title={t("Accommodation_Details")}
        isOpen={isCollapseSelected("accommodationDetails")}
        setIsOpen={() => addToSelectedCollapse("accommodationDetails")}
      >
        <AccommodationDetails
          control={control}
          errors={errors}
          index={index}
          setValue={setValue}
        />
      </Collapse>

      <Collapse
        order={2}
        title={t("Price_Details")}
        isOpen={isCollapseSelected("priceDetails")}
        setIsOpen={() => addToSelectedCollapse("priceDetails")}
      >
        <PriceDetails
          control={control}
          errors={errors}
          index={index}
          setValue={setValue}
        />
      </Collapse>

      <Collapse
        order={3}
        title={t("CheckInOut")}
        isOpen={isCollapseSelected("checkInOut")}
        setIsOpen={() => addToSelectedCollapse("checkInOut")}
      >
        <CheckInOut control={control} errors={errors} index={index} />
      </Collapse>

      <Collapse
        order={4}
        title={t("Payment")}
        isOpen={isCollapseSelected("Payment")}
        setIsOpen={() => addToSelectedCollapse("Payment")}
      >
        <AccommodationPayment
          control={control}
          errors={errors}
          index={index}
          // setValue={setValue}
        />
      </Collapse>

      <Collapse
        order={5}
        title={t("Hotel_Rules")}
        isOpen={isCollapseSelected("Hotel_Rules")}
        setIsOpen={() => addToSelectedCollapse("Hotel_Rules")}
      >
        <HotelRules control={control} errors={errors} index={index} />
      </Collapse>

      <Collapse
        order={6}
        title={t("Supplier")}
        isOpen={isCollapseSelected("supplier")}
        setIsOpen={() => addToSelectedCollapse("supplier")}
      >
        <Supplier control={control} errors={errors} index={index} />
      </Collapse>
      <Collapse
        order={7}
        title={t("Parameter")}
        isOpen={isCollapseSelected("Parameter")}
        setIsOpen={() => addToSelectedCollapse("Parameter")}
      >
        <ParameterDetails control={control} errors={errors} index={index} />
      </Collapse>
    </Collapse>
  );
}
