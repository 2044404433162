/* eslint-disable no-nested-ternary */
import { useState } from "react";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import SelectionInput from "components/inputs/SelectionInput";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import Table from "components/general/Table";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useDeleteCompaniesBulkMutation,
  useDeleteCompanyMutation,
  useGetCompaniesQuery,
} from "apis/services/companies";
import { Company } from "apis/types/companies";
import { TableItemProps } from "components/general/Table/types";
import Spinner from "components/general/Spinner";
import { stringifyParam } from "utils/queryParamService";
import { useGetMembershipsQuery } from "apis/services/memberships";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "./styles.module.scss";

export default function Companies() {
  const { t } = useAutoCompleteTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    data: companies,
    isLoading,
    isFetching,
  } = useGetCompaniesQuery({
    page_size: localStorage.getItem("CompaniesTable")
      ? Number(localStorage.getItem("CompaniesTable"))
      : 15,
    page: searchParams.get("page") ?? 1,
    search: searchParams.get("search") ?? "",
    membership: searchParams.get("membership") ?? "",
    status: searchParams.get("status") ?? "",
  });

  const { data: memberships, isLoading: isMembershipLoading } =
    useGetMembershipsQuery({ page_size: 100 });
  const statusTypeOptions = [
    { value: "true", label: t("Active") },
    { value: "false", label: t("Inactive") },
  ];
  const tableHeaders = [
    "Logo",
    "Name",
    "Code",
    "login Email",
    "Memberships",
    "Status",
    "", // Make the last one empty string to let the menu btn appear here
  ];

  const arrangeCompanyData = (item: Company): TableItemProps => ({
    id: String(item.id),
    rowData: [
      { type: "logo", dataItem: `${item?.logo}` },
      { type: "text", dataItem: item.name },
      { type: "text", dataItem: item.code },
      { type: "text", dataItem: item.company_login_email },
      { type: "membership", dataItem: item.company_memberships },
      {
        type: "status",
        dataItem: item.status ? t("Active") : t("Inactive"),
      },
      { type: "more", dataItem: [item.name, "/companies/edit-company/"] },
    ],
  });

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteCompny, { isLoading: isDeleteLoading }] =
    useDeleteCompanyMutation();

  const [deleteCompaniesBulk, { isLoading: isDeleteBulkLoading }] =
    useDeleteCompaniesBulkMutation();

  const handleDeleteCompany = (companyName: string, companyId: string) => {
    if (companyId) {
      deleteCompny(companyId)
        .unwrap()
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/companies");
          showToast({
            toastType: "success",
            title: `${companyName} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };

  const handleDeleteBulk = (companiesIds: string) => {
    deleteCompaniesBulk(companiesIds)
      .unwrap()
      .then(() => {
        setIsDeleteModalVisible(false);
        showToast({
          toastType: "success",
          title: `Selected Companies have been deleted successfully!`,
          showCloseIcon: true,
        });
      })
      .catch(handleErrors);
  };

  return (
    <section>
      <div className={styles.filtersContainer}>
        <div className={styles.filtersGroup}>
          <span className={`Headline300Heavy ${styles.headline}`}>
            {t("Companies")}
          </span>
          <DebouncedSearch
            size="small"
            onSearchChange={(val) => {
              searchParams.set("search", val);
              setSearchParams(searchParams);
            }}
            defaultValue={String(
              searchParams
                ?.get("search")
                ?.split(",")
                .filter((filterd) => filterd !== "") ?? []
            )}
          />
          <span className={styles.divider} />
          <SelectionInput
            size="small"
            placeholder={t("Membership_Type")}
            options={
              memberships?.results?.map((item: any) => ({
                value: item.name,
                label: item.name,
              })) || []
            }
            mode="tags"
            onChange={(val) => {
              searchParams.set("membership", stringifyParam(val as string[]));
              setSearchParams(searchParams);
            }}
            value={
              searchParams
                ?.get("membership")
                ?.split(",")
                ?.filter((filterd) => filterd !== "") ?? []
            }
            containerStyle={styles.tagsScroll}
          />
          <SelectionInput
            size="small"
            placeholder={t("Status")}
            options={statusTypeOptions}
            mode="tags"
            onChange={(val) => {
              searchParams.set("status", stringifyParam(val as string[]));
              setSearchParams(searchParams);
            }}
            value={
              searchParams
                ?.get("status")
                ?.split(",")
                ?.filter((filterd) => filterd !== "") ?? []
            }
            containerStyle={styles.tagsScroll}
          />
        </div>
        <div className={styles.btnGroup}>
          <Button
            btnClassName={styles.importExportBtns}
            size="small"
            styleType="Secondary"
            prefix={<Icon name="import" size={16} color="Neutral1000" />}
          >
            <span className="Paragraph200Light"> {t("import")} </span>
          </Button>
          <Button
            btnClassName={styles.importExportBtns}
            size="small"
            styleType="Secondary"
            prefix={<Icon name="export" size={16} color="Neutral1000" />}
          >
            <span className="Paragraph200Light">{t("export")}</span>
          </Button>
          <span className={styles.divider} />
          <Button
            btnClassName={styles.addNewCompanyBtn}
            size="small"
            styleType="Primary"
            prefix={
              <Icon name="Add-Outline" size={12} color="TextPrimaryWhite" />
            }
            onClick={() => navigate("add-new-company")}
          >
            <span className="Button200 text-nowrap">
              {" "}
              {t("Add_New_Company")}{" "}
            </span>
          </Button>
        </div>
      </div>
      {isFetching || isLoading || isMembershipLoading ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : companies?.results?.length ? (
        <Table
          tableName="CompaniesTable"
          headers={tableHeaders}
          data={companies?.results?.map((item: Company) =>
            arrangeCompanyData(item)
          )}
          count={companies?.count}
          pagesCount={companies?.pages_number}
          deleteOneRow={handleDeleteCompany}
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          isDeleteLoading={isDeleteLoading || isDeleteBulkLoading}
          deleteMultipleSelectedRows={handleDeleteBulk}
        />
      ) : (
        <div>No Data found!</div>
      )}
    </section>
  );
}
