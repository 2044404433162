import { useEffect } from "react";
import Collapse from "components/wrappers/Collapse";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import useToggleState from "hooks/useToggleState";
import * as yup from "yup";
import { Control, FieldErrors } from "react-hook-form";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import { generalQuotationSchema, generalQuotationTypes } from "../..";
import ParameterDetails from "./ParameterDetails";
import GeneralPrice from "./GeneralPrice";
import GeneralPayment from "./GeneralPayment";
import SupplierGeneralQuotationDetails from "./SupplierGeneralQuotationDetails";
import styles from "../../styles.module.scss";

export const generalSchema = yup.object().shape({
  quotation_type_name: yup.string().required("َQuotation type is required"),
  parameters: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Name is required"),
      value: yup.string().required("Value is required"),
      order: yup.string().required("Order is required"),
    })
  ),
  quotation_type: yup
    .string()
    .required("Quotation type is required")
    .equals(["general"], "Invalid quotation type"),
  price: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .required("Price is required"),
  taxes_fee: yup.string(),
  discount: yup
    .string()
    .test(
      "maxDiscount",
      "Discount must be less than or equal to 100",
      (value) => {
        if (!value) return true; // Allow empty string
        const discountValue = parseFloat(value);
        return discountValue <= 100;
      }
    ),
  discount_credit: yup
    .string()
    .test("maxCredit", "Credit percentage can't exceed 100", (value) => {
      if (!value) return true; // Allow empty string
      const creditValue = parseFloat(value);
      return creditValue <= 100;
    }),
  payment_gateway: yup
    .string()
    .oneOf(["stripe", "paytabs", "kashier"], "Payment gateway is required!")
    .required("Payment gateway is required!"),
  cancelation_before: yup.boolean(),
  cancelation_before_date: yup.string().when("cancelation_before", {
    is: true,
    then: (schema) => schema.required("Cancelation Before Date is required"),
  }),
  supplier: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  supplier_currency: yup.string(),
  supplier_price: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  payment_date_for_supplier: yup.string(),
  general_quotation: yup
    .number()
    .required("General Quotation is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  wallets: yup
    .array()
    .of(yup.number().required())
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  wallet_activation_date: yup
    .string()
    .required("Wallet activation date is required"),
});

export type generalTypes = yup.InferType<typeof generalSchema>;

export type customgeneralErrorTypes = Omit<
  yup.InferType<typeof generalQuotationSchema>,
  "quotations"
> & {
  quotations: yup.InferType<typeof generalSchema>[];
};

export default function GeneralFields({
  control,
  errors,
  index,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customgeneralErrorTypes>;
  index: number;
}) {
  const { t } = useAutoCompleteTranslation();
  const {
    isSelected: isCollapseSelected,
    addToSelected: addToSelectedCollapse,
    replaceState,
  } = useToggleState<string>([]);

  useEffect(() => {
    if (errors.quotations?.[index]) {
      const temp: string[] = [];

      temp.push("General");

      if (errors.quotations?.[index]?.parameters) {
        temp.push("Parameter");
      }
      if (
        errors.quotations?.[index]?.price ||
        errors.quotations?.[index]?.wallet_activation_date
      ) {
        temp.push("Price");
      }
      if (errors.quotations?.[index]?.payment_gateway) {
        temp.push("Payment");
      }

      if (temp.length > 0) replaceState(temp);
    }
  }, [errors.quotations]);

  return (
    <Collapse
      isGeneral
      title={t("General")}
      isOpen={isCollapseSelected("General")}
      setIsOpen={() => addToSelectedCollapse("General")}
    >
      <div className="d-flex w-100 flex-column gap-1">
        <ControlledTextInput
          control={control}
          name={`quotations.${index}.quotation_type_name`}
          inputStyle={styles.inputBg}
          i18nLabelKey="Quotation_Type"
          i18nPlaceholder="Quotation_Type"
          errorMsg={errors?.quotations?.[index]?.quotation_type_name?.message}
        />
      </div>
      <Collapse
        order={1}
        title={t("Parameter")}
        isOpen={isCollapseSelected("Parameter")}
        setIsOpen={() => addToSelectedCollapse("Parameter")}
      >
        <ParameterDetails control={control} errors={errors} index={index} />
      </Collapse>
      <Collapse
        order={2}
        title={t("Price")}
        isOpen={isCollapseSelected("Price")}
        setIsOpen={() => addToSelectedCollapse("Price")}
      >
        <GeneralPrice control={control} errors={errors} index={index} />
      </Collapse>

      <Collapse
        order={3}
        title={t("Payment")}
        isOpen={isCollapseSelected("Payment")}
        setIsOpen={() => addToSelectedCollapse("Payment")}
      >
        <GeneralPayment control={control} errors={errors} index={index} />
      </Collapse>

      <Collapse
        order={4}
        title={t("Supplier")}
        isOpen={isCollapseSelected("supplier")}
        setIsOpen={() => addToSelectedCollapse("supplier")}
      >
        <SupplierGeneralQuotationDetails
          control={control}
          errors={errors}
          index={index}
        />
      </Collapse>
    </Collapse>
  );
}
