import { useEffect, useState } from "react";
import PageTitle from "components/general/PageTitle";
import StatusBadge from "components/general/StatusBadge";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import {
  useGetCustomerQuery,
  useDeleteCustomerMutation,
} from "apis/services/customers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomCridetCardLetters from "components/general/CustomCridetCardLetters";
import handleErrors from "utils/handleErrors";
import ModalWrapper from "components/modals/ModalWrapper";
import showToast from "utils/showToast";
import Spinner from "components/general/Spinner";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Tabs from "components/general/Tabs";
import dayjs from "dayjs";
import { TabItem } from "components/general/Tabs/types";
import WalletTable from "./WalletTable";
import styles from "./styles.module.scss";

export default function CustomerDetails() {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const { pathname } = useLocation();
  const { data: customer, isLoading } = useGetCustomerQuery(customerId || "");

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteCustomer, { isLoading: isDeleteLoading }] =
    useDeleteCustomerMutation();
  const handleDeleteCustomer = () => {
    if (customerId) {
      deleteCustomer(customerId)
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/customers");
          showToast({
            toastType: "success",
            title: `${customer?.first_name} ${customer?.last_name} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };

  const TabsContent: TabItem[] = [
    {
      label: t("Wallet"),
      children: (
        <WalletTable
          id={Number(customerId)}
          wallet_total_active={customer?.wallet_total_active ?? 0}
          wallet_total_inactive={customer?.wallet_total_inactive ?? 0}
        />
      ),
    },
    {
      label: "Notes",
      children: <span className="Paragraph200Light">{customer?.notes}</span>,
    },
  ];

  useEffect(() => {
    if (customer) {
      const updatedUrl = `${pathname}?idName=${customer?.first_name} ${customer?.last_name}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [customer]);
  return (
    <section>
      <PageTitle> {t("Customer_Profiles")} </PageTitle>
      <div className={styles.customerInfoContainer}>
        {isLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <>
            <div className="w-100 d-flex justify-content-between">
              {/* Info Container */}
              <div className={styles.customerDataContainer}>
                <div className={styles.infoContainer}>
                  <div className="d-flex flex-column gap-2">
                    <span className="Paragraph100Heavy">
                      {" "}
                      {t("Customer_Name")}{" "}
                    </span>
                    <span className="Paragraph200Light">
                      {customer?.first_name} {customer?.last_name}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="Paragraph100Heavy">
                      {t("Membership_Price")}
                    </span>
                    <span className="Paragraph200Light">
                      {customer?.cards?.[0]?.membership?.annual_fees}{" "}
                      {customer?.cards?.[0]?.currency}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="Paragraph100Heavy">
                      {" "}
                      {t("Card_Type")}{" "}
                    </span>
                    <span className="Paragraph200Light">
                      {customer?.cards?.[0]?.membership?.name}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="Paragraph100Heavy">{t("Status")}</span>
                    <span className="Paragraph200Light">
                      <StatusBadge
                        type={customer?.status ? "success" : "error"}
                      >
                        <span className="Label200">
                          {customer?.status ? t("Active") : t("Inactive")}
                        </span>
                      </StatusBadge>
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="Paragraph100Heavy">
                      {t("Invitation_Code")}
                    </span>
                    <span className="Paragraph200Light">
                      {customer?.invitation_code}
                    </span>
                  </div>
                </div>
                <div className={styles.infoContainer}>
                  <div className="d-flex flex-column gap-2">
                    <span className="Paragraph100Heavy">
                      {t("PHONE_NUMBER")}
                    </span>
                    <span className="Paragraph200Light">
                      {customer?.mobile_code}{" "}
                      {customer?.mobile?.split(customer.mobile_code)[1]}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="Paragraph100Heavy">
                      {t("Membership_After_Discount")}
                    </span>
                    <span className="Paragraph200Light">
                      {customer?.cards?.[0]?.price_after_discount}{" "}
                      {customer?.cards?.[0]?.currency}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="Paragraph100Heavy">
                      {t("Renewal_Price")}
                    </span>
                    <span className="Paragraph200Light">
                      {customer?.cards?.[0]?.membership?.annual_fees}{" "}
                      {customer?.cards?.[0]?.currency}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="Paragraph100Heavy">
                      {" "}
                      {t("Card_Number")}{" "}
                    </span>
                    <span className="Paragraph200Light">
                      {customer?.cards?.[0]?.card_number}
                    </span>
                  </div>
                  <div className="d-flex flex-column gap-2">
                    <span className="Paragraph100Heavy">
                      {" "}
                      {t("Date_Creation")}{" "}
                    </span>
                    <span className="Paragraph200Light">
                      {dayjs(customer?.created_on).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
              </div>
              {/* Card Container */}
              <div className="d-flex flex-column align-items-end">
                <div className={styles.btns}>
                  <Button
                    size="small"
                    prefix={<Icon name="Edit" size={16} />}
                    btnClassName={styles.edit}
                    onClick={() => {
                      navigate(`/customers/edit-customer/${customerId}`);
                    }}
                  >
                    <span className="Button100 text-white">{t("Edit")}</span>
                  </Button>
                  <Button
                    size="small"
                    styleType="Secondary"
                    prefix={<Icon name="delete_outline" size={16} />}
                    btnClassName={styles.delete}
                    onClick={() => setIsDeleteModalVisible(true)}
                  >
                    <span className="Button100"> {t("Delete")} </span>
                  </Button>
                </div>
                <div className="mt-4">
                  <CustomCridetCardLetters
                    isZoom
                    letterType={customer?.cards?.[0]?.membership?.letters_type}
                    bgColor={customer?.cards?.[0]?.membership?.template_color}
                    bgImage={customer?.cards?.[0]?.membership?.template_image}
                    textColor1={
                      customer?.cards?.[0]?.membership?.template_text_color_1
                    }
                    textColor2={
                      customer?.cards?.[0]?.membership?.template_text_color_2
                    }
                    shadowColor={
                      customer?.cards?.[0]?.membership?.template_shadow_color
                    }
                    shadowWidth={Number(
                      customer?.cards?.[0]?.membership?.template_shadow_width
                    )}
                    cardHolder={`${customer?.first_name} ${customer?.last_name}`}
                    expiaryDate={dayjs(
                      customer?.cards?.[0]?.expiry_date
                    ).format("MM/YY")}
                    firstFourNums={customer?.cards?.[0]?.card_number.slice(
                      0,
                      4
                    )}
                    secondFourNums={customer?.cards?.[0]?.card_number.slice(
                      4,
                      -8
                    )}
                    thirdFourNums={customer?.cards?.[0]?.card_number.slice(
                      -8,
                      -4
                    )}
                    fourthFourNums={customer?.cards?.[0]?.card_number.slice(-4)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4">
              <Tabs tabs={TabsContent} />
            </div>
          </>
        )}
      </div>

      <ModalWrapper
        size="large"
        headerTitle={t("Delete")}
        modalType="error"
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        isFooterHidden
      >
        {isDeleteLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Medium" />
          </div>
        ) : (
          <>
            <span className="Paragraph100Light">
              {t("Delete_Modal")} ({customer?.first_name} {customer?.last_name}
              )?
            </span>
            <div className={styles.deleteModalBtns}>
              <Button
                styleType="Secondary"
                btnClassName={styles.cancelBtn}
                onClick={() => setIsDeleteModalVisible(false)}
              >
                <span className="Button100">{t("Cancel")}</span>
              </Button>
              <Button
                btnClassName={styles.deleteBtn}
                onClick={handleDeleteCustomer}
              >
                <span className="Button100">{t("Delete")}</span>
              </Button>
            </div>
          </>
        )}
      </ModalWrapper>
    </section>
  );
}
