import { useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import SelectionInput from "components/inputs/SelectionInput";
import { City } from "apis/types/cities";
import { useGetCitesQuery } from "apis/services/cities";
import InstructionNote from "components/general/InstructionNote";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import Button from "components/general/Button";
import {
  useGetAccommodationsQuery,
  useLazyGetAccommodationQuery,
} from "apis/services/accommodations";
import { debounce } from "lodash-es";
import SelectionSearchInput from "components/inputs/SelectionSearchInput";
import { Accommodations } from "apis/types/accommodations";
import CheckBox from "components/general/CheckBox";
import { generalQuotationTypes } from "../..";
import { customAccommodationErrorsType } from ".";
import styles from "../../styles.module.scss";

const accommodationTypesOptions = [
  { value: "hotel", label: "Hotel" },
  { value: "resort", label: "Resort" },
  { value: "apartment", label: "Apartment" },
  { value: "aparthotel", label: "Aparthotel" },
  { value: "serviced_apartment", label: "Serviced Apartment" },
];

const boardOptions = [
  {
    id: 1,
    value: "Room Only",
  },
  {
    id: 2,
    value: "Breakfast Included",
  },
  { value: "Half Board", id: 3 },
  { value: "Full Board", id: 4 },
  { value: "All Inclusive", id: 5 },
];

export default function AccommodationDetails({
  control,
  errors,
  index,
  setValue,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customAccommodationErrorsType>;
  index: number;
  setValue: any;
}) {
  const { t } = useAutoCompleteTranslation();
  const {
    fields: rooms,
    append,
    remove: removeRoom,
  } = useFieldArray({
    control,
    name: `quotations.${index}.rooms`,
  });
  const [accommodationSearch, setAccommodationSearch] = useState("");
  const [citiesSearch, setCitiesSearch] = useState("");

  const accommodationSearchHandler = useMemo(
    () => debounce((val: string) => setAccommodationSearch(val), 500),
    []
  );

  const citiesSearchHandler = useMemo(
    () => debounce((val: string) => setCitiesSearch(val), 500),
    []
  );
  const isAccommodationNew = useWatch({
    control,
    name: `quotations.${index}.isAccomodationNew`,
  });
  const AccomodationWatcher = useWatch({
    control,
    name: `quotations.${index}.accommodation_service_id`,
  });

  const CityWatcher = useWatch({
    control,
    name: `quotations.${index}.city`,
  });

  const GeneralQuotationCurrency = useWatch({ control, name: "currency" });

  const { data: cities, isLoading: isCitiesLoading } = useGetCitesQuery({
    search: citiesSearch,
  });

  const { data: accommodations, isLoading: isAccommodationsLoading } =
    useGetAccommodationsQuery(
      {
        page_size: 5,
        city_id: String(CityWatcher) ?? "",
        service_type: "accommodations",
        search: accommodationSearch,
      },
      {
        skip: CityWatcher === 0,
      }
    );
  const [getAccommodationTarget] = useLazyGetAccommodationQuery();

  useEffect(() => {
    // if (AccomodationWatcher === 0 || AccomodationWatcher === "") {
    //   setValue(`quotations.${index}.rating`, 0);
    //   setValue(`quotations.${index}.address`, "");
    // } else {
    getAccommodationTarget(String(AccomodationWatcher))
      .unwrap()
      .then((item) => {
        setValue(
          `quotations.${index}.accommodation_type`,
          item.accomodation_type
        );
        setValue(`quotations.${index}.rating`, item.rating);
        setValue(`quotations.${index}.address`, item.address);
      })
      .catch((err) => console.log(err));
    // }
  }, [AccomodationWatcher]);

  return (
    <>
      <div className={styles.formGroup}>
        <div className="d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.city_name_new`}
            render={({ field }) => {
              return (
                <SelectionSearchInput
                  isLoading={isCitiesLoading}
                  size="large"
                  options={
                    cities?.results?.map((item: City) => ({
                      id: item.id,
                      value: `${item.name}${item.country ? "," : ""} ${
                        item.country ? item?.country?.name : ""
                      }`,
                    })) ?? []
                  }
                  onSearch={(value) => {
                    citiesSearchHandler(value);
                    field.onChange(value);
                    setValue(`quotations.${index}.city`, null);
                  }}
                  onSelect={(value, option) => {
                    setValue(`quotations.${index}.city`, option.id);
                    field.onChange(value);
                  }}
                  i18nLabelKey="City"
                  i18nPlaceholder="city"
                  onChange={field.onChange}
                  status={
                    errors.quotations?.[index]?.city_name_new ? "error" : ""
                  }
                  value={field.value}
                />
              );
            }}
          />
          {errors.quotations &&
            errors.quotations?.[index]?.city_name_new?.message && (
              <div className="w-100">
                <InstructionNote instructionType="danger">
                  <span className="Paragraph300Light">
                    {errors.quotations?.[index]?.city_name_new?.message}
                  </span>
                </InstructionNote>
              </div>
            )}
        </div>
        <div className="d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.name`}
            render={({ field }) => {
              return (
                <SelectionSearchInput
                  isLoading={isAccommodationsLoading}
                  size="large"
                  i18nPlaceholder="Accommodation_name"
                  i18nLabelKey="Accommodation_Name"
                  options={
                    accommodations?.results.map((item: Accommodations) => ({
                      id: item.id!,
                      value: item.name,
                    })) ?? []
                  }
                  onSelect={(_, option) => {
                    field.onChange(option.value);
                    setValue(
                      `quotations.${index}.accommodation_service_id`,
                      Number(option.id)
                    );
                    setValue(`quotations.${index}.isAccomodationNew`, false);
                  }}
                  status={errors.quotations?.[index]?.name ? "error" : ""}
                  onSearch={(val) => {
                    field.onChange(val);
                    setValue(`quotations.${index}.accommodation_service_id`, 0);
                    setValue(`quotations.${index}.isAccomodationNew`, true);
                    accommodationSearchHandler(val);
                  }}
                  value={field.value}
                />
              );
            }}
          />
          {errors.quotations && errors.quotations?.[index]?.name?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.quotations?.[index]?.name?.message}
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
        <div className="d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.accommodation_type`}
            render={({ field }) => {
              return (
                <SelectionInput
                  size="large"
                  i18nPlaceholder="Type"
                  options={accommodationTypesOptions}
                  i18nLabelKey="Accommodation_Type"
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                  value={field.value === "" ? undefined : field.value}
                  status={
                    errors.quotations?.[index]?.accommodation_type
                      ? "error"
                      : ""
                  }
                  isDisabled={!isAccommodationNew}
                />
              );
            }}
          />
          {errors.quotations &&
            errors.quotations?.[index]?.accommodation_type?.message && (
              <div className="w-100">
                <InstructionNote instructionType="danger">
                  <span className="Paragraph300Light">
                    {errors.quotations?.[index]?.accommodation_type?.message}
                  </span>
                </InstructionNote>
              </div>
            )}
        </div>
        <div className="d-flex justify-content-between gap-4">
          <div className="d-flex w-100 flex-column gap-1">
            <Controller
              control={control}
              name={`quotations.${index}.rating`}
              render={({ field }) => (
                <TextInput
                  type="number"
                  onChange={field.onChange}
                  inputStyle={styles.inputBg}
                  i18nLabelKey="Rating"
                  i18nPlaceholder="Rating"
                  status={isAccommodationNew ? "default" : "disabled"}
                  value={String(field.value)}
                  errorMsg={errors?.quotations?.[index]?.rating?.message}
                />
              )}
            />
          </div>
          <div className="d-flex w-100 flex-column gap-1">
            <ControlledTextInput
              control={control}
              name={`quotations.${index}.address`}
              inputStyle={styles.inputBg}
              i18nLabelKey="Address"
              i18nPlaceholder="address"
              status={isAccommodationNew ? "default" : "disabled"}
              errorMsg={errors?.quotations?.[index]?.address?.message}
            />
          </div>
        </div>
        <div className="d-flex w-100 flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.board_type`}
            render={({ field }) => (
              <SelectionSearchInput
                size="large"
                i18nPlaceholder="Board_type"
                i18nLabelKey="Board_Type"
                options={boardOptions}
                onSearch={(val) => {
                  field.onChange(val);
                }}
                onSelect={(val) => {
                  field.onChange(val);
                }}
                value={field.value === "" ? undefined : field.value}
                status={errors.quotations?.[index]?.board_type ? "error" : ""}
              />
            )}
          />
          {errors.quotations &&
            errors.quotations?.[index]?.board_type?.message && (
              <div className="w-100">
                <InstructionNote instructionType="danger">
                  <span className="Paragraph300Light">
                    {errors.quotations?.[index]?.board_type?.message}
                  </span>
                </InstructionNote>
              </div>
            )}
        </div>
        <div className="d-flex w-100 flex-column gap-1">
          <ControlledTextInput
            type="textarea"
            control={control}
            name={`quotations.${index}.guest_list`}
            containerStyle={styles.textareaContainer}
            i18nLabelKey="guest_list"
            i18nPlaceholder="guest_list"
            errorMsg={errors?.quotations?.[index]?.guest_list?.message}
          />
        </div>
      </div>
      {rooms.map((room, roomIndex) => (
        <div className={styles.formGroup} key={room.id}>
          <div>
            <span className="Paragraph100Heavy d-block mb-1">
              {" "}
              {t("Rooms")}
            </span>
            <div className="d-flex justify-content-between mb-2">
              <span className="Label100">{t("Room_Type")} *</span>
              {roomIndex === rooms.length - 1 && (
                <Button
                  styleType="NoStyle"
                  btnClassName={styles.addAnotherRoomBtn}
                  onClick={() =>
                    append({
                      room_type: "",
                      price: 0,
                      discount: 0,
                      adults: 0,
                      children: 0,
                      children_age: "",
                      discount_credit: 0,
                      taxes_fee: 0,
                    })
                  }
                >
                  <span className="Paragraph300Light">
                    + {t("Add_Another_Room")}
                  </span>
                </Button>
              )}
              {rooms.length > 1 && (
                <Button
                  styleType="NoStyle"
                  btnClassName={styles.addAnotherRoomBtn}
                  onClick={() => removeRoom(roomIndex)}
                >
                  <span className="Paragraph300Light">
                    - {t("Remove_Room")}
                  </span>
                </Button>
              )}
            </div>
            <div className="d-flex w-100 flex-column gap-1">
              <ControlledTextInput
                control={control}
                name={`quotations.${index}.rooms.${roomIndex}.room_type`}
                inputStyle={styles.inputBg}
                size="large"
                i18nPlaceholder="Room"
                errorMsg={
                  errors.quotations?.[index]?.rooms?.[roomIndex]?.room_type
                    ?.message
                }
              />
            </div>
          </div>
          <div className="d-flex w-100 flex-column gap-1">
            <Controller
              control={control}
              name={`quotations.${index}.rooms.${roomIndex}.adults`}
              render={({ field }) => (
                <TextInput
                  type="number"
                  onChange={field.onChange}
                  inputStyle={styles.inputBg}
                  i18nLabelKey="Adults"
                  i18nPlaceholder="Adults"
                  value={String(field.value)}
                  status={
                    errors.quotations?.[index]?.rooms?.[roomIndex]?.adults
                      ? "error"
                      : "default"
                  }
                  errorMsg={
                    errors?.quotations?.[index]?.rooms?.[roomIndex]?.adults
                      ?.message
                  }
                />
              )}
            />
          </div>
          <div className="d-flex justify-content-between gap-4">
            <div className="d-flex w-100 flex-column gap-1">
              <Controller
                control={control}
                name={`quotations.${index}.rooms.${roomIndex}.children`}
                render={({ field }) => (
                  <TextInput
                    type="number"
                    onChange={field.onChange}
                    inputStyle={styles.inputBg}
                    i18nLabelKey="Children"
                    i18nPlaceholder="Children"
                    value={String(field.value)}
                    status={
                      errors.quotations?.[index]?.rooms?.[roomIndex]?.children
                        ? "error"
                        : "default"
                    }
                    errorMsg={
                      errors?.quotations?.[index]?.rooms?.[roomIndex]?.children
                        ?.message
                    }
                  />
                )}
              />
            </div>
            <div className="d-flex w-100 flex-column gap-1">
              <Controller
                control={control}
                name={`quotations.${index}.rooms.${roomIndex}.children_age`}
                render={({ field }) => (
                  <TextInput
                    onChange={field.onChange}
                    inputStyle={styles.inputBg}
                    i18nLabelKey="Children_Age"
                    i18nPlaceholder="Children_Age"
                    value={String(field.value)}
                    status={
                      errors.quotations?.[index]?.rooms?.[roomIndex]
                        ?.children_age
                        ? "error"
                        : "default"
                    }
                    errorMsg={
                      errors?.quotations?.[index]?.rooms?.[roomIndex]
                        ?.children_age?.message
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between gap-4">
            <div className="d-flex w-100 flex-column gap-1">
              <Controller
                control={control}
                name={`quotations.${index}.rooms.${roomIndex}.price`}
                render={({ field }) => (
                  <TextInput
                    type="number"
                    onChange={field.onChange}
                    inputStyle={styles.inputBg}
                    i18nLabelKey="Basic_Price"
                    i18nPlaceholder="Basic_Price"
                    value={String(field.value)}
                    status={
                      errors.quotations?.[index]?.rooms?.[roomIndex]?.price
                        ? "error"
                        : "default"
                    }
                    errorMsg={
                      errors?.quotations?.[index]?.rooms?.[roomIndex]?.price
                        ?.message
                    }
                  />
                )}
              />
            </div>
            <div className="w-100 d-flex flex-column gap-1">
              <Controller
                control={control}
                name={`quotations.${index}.rooms.${roomIndex}.taxes_fee`}
                render={({ field }) => (
                  <TextInput
                    type="number"
                    onChange={field.onChange}
                    containerStyle="w-100"
                    inputStyle={styles.inputBg}
                    i18nLabelKey="Taxes_Fee"
                    suffix={GeneralQuotationCurrency}
                    placeholder="00.00"
                    value={String(field.value)}
                    status={
                      errors.quotations?.[index]?.rooms?.[roomIndex]?.taxes_fee
                        ?.message
                        ? "error"
                        : "default"
                    }
                    errorMsg={
                      errors?.quotations?.[index]?.rooms?.[roomIndex]?.taxes_fee
                        ?.message
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between gap-4">
            <div className="d-flex w-100 flex-column gap-1">
              <Controller
                control={control}
                name={`quotations.${index}.rooms.${roomIndex}.discount_credit`}
                render={({ field }) => (
                  <TextInput
                    type="number"
                    onChange={field.onChange}
                    inputStyle={styles.inputBg}
                    i18nLabelKey="Kudos_Point"
                    i18nPlaceholder="Kudos_Point"
                    value={String(field.value)}
                    status={
                      errors.quotations?.[index]?.rooms?.[roomIndex]
                        ?.discount_credit
                        ? "error"
                        : "default"
                    }
                    errorMsg={
                      errors?.quotations?.[index]?.rooms?.[roomIndex]
                        ?.discount_credit?.message
                    }
                  />
                )}
              />
            </div>

            <div className="d-flex w-100 flex-column gap-1">
              <Controller
                control={control}
                name={`quotations.${index}.rooms.${roomIndex}.discount`}
                render={({ field }) => (
                  <TextInput
                    type="number"
                    onChange={field.onChange}
                    inputStyle={styles.inputBg}
                    i18nLabelKey="Discount"
                    i18nPlaceholder="Discount"
                    value={String(field.value)}
                    status={
                      errors.quotations?.[index]?.rooms?.[roomIndex]?.discount
                        ? "error"
                        : "default"
                    }
                    errorMsg={
                      errors?.quotations?.[index]?.rooms?.[roomIndex]?.discount
                        ?.message
                    }
                  />
                )}
              />
            </div>
          </div>
        </div>
      ))}
      <div className={styles.checkbox_container}>
        <Controller
          control={control}
          name={`quotations.${index}.apply_discount_on_taxes_and_basic_price`}
          render={({ field }) => (
            <CheckBox
              value={field.value}
              checked={field.value}
              onCheckChange={field.onChange}
              text={t("Apply_Discount")}
            />
          )}
        />
      </div>
    </>
  );
}
