import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import FormLogo from "components/general/FormLogo";
import TextInput from "components/inputs/TextInput";
import CriteriaTitle from "components/general/CriteriaTitle";
import Button from "components/general/Button";
import COLORS from "constants/Colors";
import ModalWrapper from "components/modals/ModalWrapper";
import { useFirstLoginPasswordMutation } from "apis/services/auth";
import handleErrors from "utils/handleErrors";
import styles from "./styles.module.scss";

const schema = yup
  .object({
    new_password: yup
      .string()
      .required("Password field is required")
      .test("isValidPass", "Password criteria error", (value) => {
        const hasUpperCase = /[A-Z]/.test(value!);
        const hasLowerCase = /[a-z]/.test(value!);
        const hasNumber = /[0-9]/.test(value!);
        const hasSymbole = /[~!@#%^&*()+_=/-]/.test(value!);
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole];
        return conditions.find((item) => item === false) === undefined;
      }),
    new_password2: yup
      .string()
      .required("Confirm password field is required")
      .oneOf([yup.ref("new_password")], "Passwords must match"),
  })
  .required();

type formDataTyps = yup.InferType<typeof schema>;

export default function FristLogin() {
  const [firstLoginPassword, { isLoading }] = useFirstLoginPasswordMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<formDataTyps>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const passwordWatch = watch("new_password");

  const [isVisible, setIsVisible] = useState(false);

  const submitForm = (data: formDataTyps) => {
    firstLoginPassword(data)
      .unwrap()
      .then(() => setIsVisible(true))
      .catch(handleErrors);
  };
  return (
    <>
      <FormLogo />
      <h3 className="Headline300Heavy">Change password</h3>
      <span className="Paragraph200Light">
        This was a one-time password that must be updated with these conditions.
      </span>
      <form className={styles.form} onSubmit={handleSubmit(submitForm)}>
        <div className={styles.inputsContainer}>
          <TextInput
            type="password"
            status={errors.new_password?.message ? "error" : "default"}
            placeholder="Password"
            i18nLabelKey="FIRST_TIME_NEW_PASSWORD"
            reactHookFormProps={register("new_password")}
          />
          <TextInput
            type="password"
            status={errors.new_password2?.message ? "error" : "default"}
            placeholder="Password"
            i18nLabelKey="FIRST_TIME_CONFIRM_PASSWORD"
            reactHookFormProps={register("new_password2")}
          />
          <CriteriaTitle value={passwordWatch} title="Capital letter" />
          <CriteriaTitle value={passwordWatch} title="Small letter" />
          <CriteriaTitle value={passwordWatch} title="Number" />
          <CriteriaTitle value={passwordWatch} title="Special symbol" />
        </div>

        <Button
          btnClassName={styles.updatePassword}
          styleType="Primary"
          type="submit"
          isLoading={isLoading}
        >
          <span
            className="Button100"
            style={{ color: COLORS.TextPrimaryWhite }}
          >
            Update password
          </span>
        </Button>
      </form>

      <ModalWrapper
        headerTitle="Congratulations!"
        size="large"
        isFooterHidden
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        modalType="success"
        onClose={() => navigate("/login")}
      >
        <span className="Paragraph100Light">
          Your password has been successfully updated
        </span>
      </ModalWrapper>
    </>
  );
}
