import { SpinnerProps } from "./types";
import Icon from "../Icon";
import styles from "./styles.module.scss";

export default function Spinner({ containerStyle, size }: SpinnerProps) {
  const sizes = {
    Small: 24,
    Medium: 64,
    Large: 112,
  };

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <Icon name="Spinner" size={sizes[size]} color="Primary800" />
    </div>
  );
}
