import { Switch } from "antd";
import DateInput from "components/inputs/DateInput";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { Control, Controller, FieldErrors, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import InstructionNote from "components/general/InstructionNote";
import { customAccommodationErrorsType } from ".";
import { generalQuotationTypes } from "../..";
import styles from "../../styles.module.scss";

export default function CheckInOut({
  control,
  errors,
  index,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customAccommodationErrorsType>;
  index: number;
}) {
  const { t } = useAutoCompleteTranslation();
  const CancelBeforeWatcher = useWatch({
    control,
    name: `quotations.${index}.cancelation_before`,
  });

  const CheckInDate = useWatch({
    control,
    name: `quotations.${index}.check_in_date`,
  });

  return (
    <div className={styles.formGroup}>
      <div className="d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.check_in_date`}
          render={({ field }) => (
            <DateInput
              defaultValue={field.value}
              onChange={(e) =>
                e
                  ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                  : field.onChange("")
              }
              i18nLabelKey="Check_In"
              status={errors.quotations?.[index]?.check_in_date ? "error" : ""}
              disabledDate={(current) =>
                current && current < dayjs().startOf("day")
              }
            />
          )}
        />
        {errors.quotations?.[index]?.check_in_date &&
          errors.quotations?.[index]?.check_in_date?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.quotations?.[index]?.check_in_date?.message}
                </span>
              </InstructionNote>
            </div>
          )}
      </div>
      <div className="d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.check_out_date`}
          render={({ field }) => (
            <DateInput
              defaultValue={field.value}
              onChange={(e) =>
                e
                  ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                  : field.onChange("")
              }
              i18nLabelKey="Check_out"
              status={errors.quotations?.[index]?.check_out_date ? "error" : ""}
              disabledDate={(current) =>
                CheckInDate
                  ? dayjs(current, "DD/MM/YYYY").isBefore(
                      dayjs(CheckInDate, "DD/MM/YYYY"),
                      "day"
                    ) ||
                    dayjs(current, "DD/MM/YYYY").isSame(
                      dayjs(CheckInDate, "DD/MM/YYYY"),
                      "day"
                    )
                  : current && current < dayjs().startOf("day")
              }
            />
          )}
        />
        {errors.quotations?.[index]?.check_out_date &&
          errors.quotations?.[index]?.check_out_date?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.quotations?.[index]?.check_out_date?.message}
                </span>
              </InstructionNote>
            </div>
          )}
      </div>
      <div className={styles.switchContainer}>
        <span className="Paragraph100Light">{t("Cancelation_Before")}</span>
        <Controller
          control={control}
          name={`quotations.${index}.cancelation_before`}
          render={({ field }) => (
            <Switch
              className={styles.switch}
              defaultChecked={field.value}
              checked={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
      <div className="d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.cancelation_before_date`}
          render={({ field }) => (
            <DateInput
              isDisabled={!CancelBeforeWatcher}
              defaultValue={field.value ?? ""}
              onChange={(e) =>
                e
                  ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                  : field.onChange("")
              }
              i18nLabelKey="Cancelation_Before_Date"
              status={
                errors.quotations?.[index]?.cancelation_before_date
                  ? "error"
                  : ""
              }
            />
          )}
        />
        {errors.quotations?.[index]?.cancelation_before_date &&
          errors.quotations?.[index]?.cancelation_before_date?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.quotations?.[index]?.cancelation_before_date?.message}
                </span>
              </InstructionNote>
            </div>
          )}
      </div>
    </div>
  );
}
