/* eslint-disable no-nested-ternary */
import { useState } from "react";
import PageTitle from "components/general/PageTitle";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import SelectionInput from "components/inputs/SelectionInput";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import { useNavigate, useSearchParams } from "react-router-dom";
import Table from "components/general/Table";
import {
  useDeleteGeneralQuotationMutation,
  useDeleteGeneralQuotationsBulkMutation,
  useGetGeneralQuotationsQuery,
} from "apis/services/generalQuotations";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import Spinner from "components/general/Spinner";
import { GeneralQuotationGetReq } from "apis/types/generalQuotations";
import { TableItemProps } from "components/general/Table/types";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import dayjs from "dayjs";
import styles from "./styles.module.scss";

export default function Quotations() {
  const { t } = useAutoCompleteTranslation();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const quotationTypeOptions = [
    { value: "has_accommodation_quotations", label: t("Accommodation") },
    { value: "has_flight_quotations", label: t("Flight") },
    { value: "has_general_quotations", label: t("General") },
  ];

  const tableHeaders = [
    t("Quotation_Id"),
    t("date"),
    t("Customer_Name"),
    t("Membership"),
    t("Customer_Mobile"),
    t("country"),
    t("Quotation_types"),
    "", // Make the last one empty string to let the menu btn appear here
  ];
  const arrangeCompanyData = (
    item: GeneralQuotationGetReq
  ): TableItemProps => ({
    id: String(item?.id),
    rowData: [
      { type: "text", dataItem: item?.quotation_id },
      {
        type: "text",
        dataItem:
          (item?.created_on && dayjs(item?.created_on).format("DD/MM/YYYY")) ??
          "",
      },
      {
        type: "text",
        dataItem: `${item?.customer?.first_name} ${item?.customer?.last_name}`,
      },
      {
        type: "text",
        dataItem: item?.membership_name,
      },
      {
        type: "text",
        dataItem: item?.customer?.mobile?.split(item?.customer?.mobile_code)[1]
          ? `${item?.customer?.mobile_code} ${item?.customer?.mobile?.split(
              item?.customer?.mobile_code
            )[1]}`
          : "",
      },
      { type: "text", dataItem: item?.country?.name },
      {
        type: "membership",
        dataItem: [
          item.accomodation_quotations.length > 0
            ? { membership: "Accommodation" }
            : undefined,
          item.flight_quotations.length > 0
            ? { membership: "Flight" }
            : undefined,
          item.quotations.length > 0 ? { membership: "General" } : undefined,
        ].filter((filter) => typeof filter !== "undefined"),
      },
      {
        type: "more",
        dataItem: [item?.quotation_id, "/quotations/edit-quotation/"],
      },
    ],
  });

  const {
    data: quotations,
    isLoading,
    isFetching,
  } = useGetGeneralQuotationsQuery({
    page_size: localStorage.getItem("AccommodationQuotationTable")
      ? Number(localStorage.getItem("AccommodationQuotationTable"))
      : 15,
    page: Number(searchParams.get("page")) || 1,
    search: searchParams.get("search") || "",
    has_accommodation_quotations: searchParams
      .get("type")
      ?.split(",")
      .includes("has_accommodation_quotations")
      ? true
      : undefined,
    has_flight_quotations: searchParams
      .get("type")
      ?.split(",")
      .includes("has_flight_quotations")
      ? true
      : undefined,
    has_general_quotations: searchParams
      .get("type")
      ?.split(",")
      .includes("has_general_quotations")
      ? true
      : undefined,
  });

  const [deleteQuotation, { isLoading: isDeleteLoading }] =
    useDeleteGeneralQuotationMutation();
  const [deleteQuotationsBulk, { isLoading: isDeleteBulkLoading }] =
    useDeleteGeneralQuotationsBulkMutation();

  const handleDeleteQuotation = (
    quotationName: string,
    quotationId: string
  ) => {
    if (quotationId) {
      deleteQuotation(quotationId)
        .unwrap()
        .then(() => {
          setIsDeleteModalVisible(false);
          showToast({
            toastType: "success",
            title: `${quotationName} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };
  const handleDeleteBulk = (quotationsIds: string) => {
    deleteQuotationsBulk(quotationsIds)
      .unwrap()
      .then(() => {
        setIsDeleteModalVisible(false);
        showToast({
          toastType: "success",
          title: `Selected Quotations have been deleted successfully!`,
          showCloseIcon: true,
        });
      })
      .catch(handleErrors);
  };

  return (
    <section>
      <div className="d-flex justify-content-between ">
        <PageTitle> {t("Quotations")} </PageTitle>
        <div className={styles.btnGroup}>
          <DebouncedSearch
            size="small"
            onSearchChange={(val) => {
              searchParams.set("search", val);
              setSearchParams(searchParams);
            }}
            defaultValue={String(
              searchParams
                ?.get("search")
                ?.split(",")
                .filter((filterd) => filterd !== "") ?? ""
            )}
          />
          <span className={styles.divider} />

          <SelectionInput
            size="small"
            i18nPlaceholder="Quotation_types"
            options={quotationTypeOptions}
            mode="tags"
            onChange={(val) => {
              searchParams.set("type", val as string);
              setSearchParams(searchParams);
            }}
            value={
              searchParams
                ?.get("type")
                ?.split(",")
                ?.filter((filterd) => filterd !== "") ?? []
            }
            containerStyle={styles.tagsScroll}
          />
          <span className={styles.divider} />

          <Button
            btnClassName={styles.addNewCompanyBtn}
            size="small"
            styleType="Primary"
            prefix={
              <Icon name="Add-Outline" size={12} color="TextPrimaryWhite" />
            }
            onClick={() => navigate("add-new-quotation")}
          >
            <span className="Button200 text-nowrap">
              {" "}
              {t("Add_New_Quotation")}{" "}
            </span>
          </Button>
        </div>
      </div>

      {isFetching || isLoading ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : quotations?.results?.length ? (
        <Table
          tableName="QuotationsTable"
          headers={tableHeaders}
          data={quotations?.results?.map((item: GeneralQuotationGetReq) =>
            arrangeCompanyData(item)
          )}
          count={quotations?.count}
          pagesCount={quotations?.pages_number}
          deleteOneRow={handleDeleteQuotation}
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          isDeleteLoading={isDeleteLoading || isDeleteBulkLoading}
          deleteMultipleSelectedRows={handleDeleteBulk}
        />
      ) : (
        <div>No Data found!</div>
      )}
    </section>
  );
}
