/* eslint-disable jsx-a11y/anchor-is-valid */
import { Text, View, StyleSheet, Link } from "@react-pdf/renderer";
import COLORS from "constants/Colors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { GeneralQuotationGetReq } from "apis/types/generalQuotations";
import { GeneralTypeOfQuotations } from "apis/types/generalTypeOfQuotaions";
import dayjs from "dayjs";

export default function GeneralDetailsPDF({
  generalQuotation,
  currentLanguage,
  generalData,
  isConfirmation = false,
}: {
  generalQuotation?: GeneralQuotationGetReq;
  currentLanguage: string;
  generalData: GeneralTypeOfQuotations;
  isConfirmation?: boolean;
}) {
  const { t } = useAutoCompleteTranslation();
  const CurrencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: generalQuotation?.currency ?? "GBP",
  });
  const documentStyle = StyleSheet.create({
    Label100: {
      fontSize: "14px",
      textTransform: "capitalize",
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text",
      fontWeight: 700,
    },
    Paragraph100Heavy: {
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text-Heavy",
      fontSize: "14px",
    },
    Paragraph200Light: {
      fontFamily:
        currentLanguage === "ar"
          ? "IBMPlexSansArabicLight"
          : "SF-Pro-Text-Light",
      fontSize: "12px",
      fontWeight: 400,
      color: COLORS.TextSecondaryBlack,
    },
    Paragraph200Heavy: {
      color: COLORS.TextSecondaryBlack,
      fontSize: "12px",
      textTransform: "capitalize",
      fontFamily:
        currentLanguage === "ar"
          ? "IBMPlexSansArabicLight"
          : "SF-Pro-Text-Light",
      fontWeight: 400,
    },
    pageContainer: {
      backgroundColor: COLORS.Neutral0,
    },
    quotation_container: {
      display: "flex",
      flexDirection: "column",
      marginTop: "6px",
    },
    nonRefundableText: {
      fontSize: "12px",
      fontWeight: 700,
    },
    roomText: {
      fontSize: "12px",
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    navbar: {
      backgroundColor: COLORS.Neutral1000,
      display: "flex",
      justifyContent: "space-between",
      paddingHorizontal: "20",
      height: "90px",
      alignItems: "center",
      flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
    },
    logoImg: {
      objectFit: "contain",
      height: "80%",
      marginVertical: "auto",
    },
    flightQuotation: {
      borderRadius: "10px",
      border: `1px solid ${COLORS.Neutral300}`,
      padding: "10px",
      marginTop: "10px",
    },
  });
  return (
    <View>
      <View
        style={{
          ...documentStyle.flightQuotation,
          width: "85%",
          marginHorizontal: "auto",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
          }}
        >
          <Text
            style={{
              ...documentStyle.Paragraph100Heavy,
              textDecoration: "underline",
              color: "#1581E2",
              textTransform: "capitalize",
            }}
          >
            {generalData?.quotation_type_name}
          </Text>
        </View>

        <View style={{ marginTop: "12px" }}>
          {generalData?.parameters
            ? generalData?.parameters?.map((param) => (
                <View
                  style={{
                    display: "flex",
                    flexDirection:
                      currentLanguage === "en" ? "row" : "row-reverse",
                    justifyContent: "space-between",
                    marginTop: "12px",
                  }}
                >
                  <Text
                    style={{
                      ...documentStyle.Paragraph200Heavy,
                      maxWidth: "50%",
                    }}
                  >
                    {param?.name}
                  </Text>
                  <Text
                    style={{
                      ...documentStyle.Paragraph200Heavy,
                      maxWidth: "50%",
                      textAlign: currentLanguage === "en" ? "right" : "left",
                    }}
                  >
                    {param?.value}
                  </Text>
                </View>
              ))
            : null}
          <View
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
              marginTop: "8px",
            }}
          >
            <Text
              style={{
                ...documentStyle.Paragraph100Heavy,
                color: generalData?.cancelation_before
                  ? COLORS.Success900
                  : COLORS.Danger700,
              }}
            >
              {generalData?.cancelation_before
                ? t("Refundable")
                : t("Non_Refundable")}
            </Text>
            {generalData?.cancelation_before && (
              <Text style={documentStyle.Paragraph200Light}>
                {dayjs(generalData?.cancelation_before_date).format(
                  "DD/MM/YYYY"
                )}
              </Text>
            )}
          </View>
          {!isConfirmation && (
            <View wrap={false}>
              <View
                style={{
                  border: `1px solid ${COLORS.Neutral300}`,
                  borderRadius: "10px",
                  padding: "10px",
                  marginTop: "12px",
                  display: "flex",
                  flexDirection:
                    currentLanguage === "en" ? "row" : "row-reverse",
                }}
              >
                <Text
                  style={{
                    ...documentStyle.Paragraph100Heavy,
                    color: "#1581E2",
                  }}
                >
                  {t("Price")}
                </Text>
              </View>
              <View>
                <View
                  style={{
                    display: "flex",
                    flexDirection:
                      currentLanguage === "en" ? "row" : "row-reverse",
                    justifyContent: "space-between",
                    marginTop: "12px",
                  }}
                >
                  <Text style={documentStyle.Paragraph200Heavy}>
                    {t("Price")}{" "}
                  </Text>
                  <Text style={documentStyle.Paragraph200Heavy}>
                    {CurrencyFormatter.format(Number(generalData?.price))}
                  </Text>
                </View>
                {generalData?.taxes_fee !== null &&
                  generalData?.taxes_fee !== undefined &&
                  generalData?.taxes_fee !== "0.00" && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {t("Taxes_Fee")}{" "}
                      </Text>
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {CurrencyFormatter.format(
                          Number(generalData.taxes_fee)
                        )}
                      </Text>
                    </View>
                  )}
                {generalData?.discount_credit !== null &&
                  generalData?.discount_credit !== undefined &&
                  Number(generalData?.discount_credit) > 0 && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text
                        style={{
                          ...documentStyle.Paragraph200Heavy,
                          maxWidth: "50%",
                        }}
                      >
                        {t("discout_credit_accommodation")}{" "}
                      </Text>
                      <Text style={documentStyle.Paragraph200Light}>
                        {Number(generalData?.discount_credit)} %{" "}
                        {`(${t("equivalent_to")} ${CurrencyFormatter.format(
                          (Number(generalData?.discount_credit) / 100) *
                            Number(generalData?.price)
                        )})`}
                      </Text>
                    </View>
                  )}
                {generalData.discount !== null &&
                  generalData.discount !== undefined &&
                  String(generalData.discount) !== "0.00" && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {t("discount")}{" "}
                      </Text>
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {generalData.discount} %
                      </Text>
                    </View>
                  )}
                {Number(generalData.total_price_before_discount) &&
                  (Number(generalData.discount) ||
                    Number(generalData.discount_credit)) && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {t("Total_Price_Before_Discount")}{" "}
                      </Text>
                      <Text style={documentStyle.Paragraph200Light}>
                        {CurrencyFormatter.format(
                          Number(generalData.total_price_before_discount)
                        )}
                      </Text>
                    </View>
                  )}
                {Number(generalData.total_discount_amount) !== 0 && (
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                      marginTop: "8px",
                    }}
                  >
                    <Text style={documentStyle.Paragraph200Heavy}>
                      {t("Total_Discount_Amount")}
                    </Text>
                    <Text style={documentStyle.Paragraph200Light}>
                      {CurrencyFormatter.format(
                        Number(generalData.total_discount_amount)
                      )}
                    </Text>
                  </View>
                )}
                {Number(generalData.amount_deducted_from_wallet) !== 0 && (
                  <View
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                      marginTop: "8px",
                    }}
                  >
                    <Text style={documentStyle.Paragraph200Heavy}>
                      {t("Amount_Deducted_From_Wallet")}
                    </Text>
                    <Text style={documentStyle.Paragraph200Light}>
                      {CurrencyFormatter.format(
                        Number(generalData.amount_deducted_from_wallet)
                      )}
                    </Text>
                  </View>
                )}
                <View
                  style={{
                    display: "flex",
                    flexDirection:
                      currentLanguage === "en" ? "row" : "row-reverse",
                    justifyContent: "space-between",
                    marginTop: "12px",
                  }}
                >
                  <Text style={documentStyle.Paragraph200Heavy}>
                    {t("Total")}{" "}
                  </Text>
                  <Text
                    style={{
                      ...documentStyle.Paragraph200Light,
                      color: COLORS.Success900,
                      fontFamily: "SF-Pro-Text-Heavy",
                    }}
                  >
                    {CurrencyFormatter.format(Number(generalData.total))}
                  </Text>
                </View>
              </View>
            </View>
          )}
          {!isConfirmation && generalData?.payment_link && (
            <View
              wrap={false}
              style={{
                border: `1px solid ${COLORS.Neutral300}`,
                borderRadius: "10px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection:
                    currentLanguage === "en" ? "row" : "row-reverse",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    ...documentStyle.Paragraph100Heavy,
                    fontSize: "12px",
                  }}
                >
                  {t("payment_link")}
                </Text>
                <Link
                  src={generalData.payment_link}
                  style={{
                    ...documentStyle.Paragraph100Heavy,
                    textDecoration: "underline",
                    color: "#1581E2",
                    textTransform: "capitalize",
                  }}
                >
                  {t("open_link")}
                </Link>
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}
