import { useEffect } from "react";
import { Control, Controller, FieldErrors, useWatch } from "react-hook-form";
import TextInput from "components/inputs/TextInput";
import InstructionNote from "components/general/InstructionNote";
import { generalQuotationTypes } from "../..";
import { customAccommodationErrorsType } from ".";
import styles from "../../styles.module.scss";

export default function PriceDetails({
  control,
  errors,
  index,
  setValue,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customAccommodationErrorsType>;
  index: number;
  setValue: any;
}) {
  const GeneralQuotationCurrency = useWatch({ control, name: "currency" });

  useEffect(() => {
    setValue(`quotations.${index}.currency`, GeneralQuotationCurrency);
  }, [GeneralQuotationCurrency]);

  return (
    <div className={styles.formGroup}>
      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.city_tax`}
            render={({ field }) => (
              <TextInput
                type="number"
                onChange={field.onChange}
                containerStyle="w-100"
                inputStyle={styles.inputBg}
                i18nLabelKey="City_Tax"
                suffix={GeneralQuotationCurrency}
                placeholder="00.00"
                value={String(field.value)}
                status={
                  errors?.quotations?.[index]?.city_tax?.message
                    ? "error"
                    : "default"
                }
                errorMsg={errors?.quotations?.[index]?.city_tax?.message}
              />
            )}
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.tourism_fee`}
            render={({ field }) => (
              <TextInput
                type="number"
                onChange={field.onChange}
                containerStyle="w-100"
                inputStyle={styles.inputBg}
                i18nLabelKey="Tourism_Fee"
                suffix={GeneralQuotationCurrency}
                placeholder="00.00"
                value={String(field.value)}
                status={
                  errors?.quotations?.[index]?.tourism_fee?.message
                    ? "error"
                    : "default"
                }
                errorMsg={errors?.quotations?.[index]?.tourism_fee?.message}
              />
            )}
          />
        </div>
      </div>

      <div className="w-100 d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.damage_deposit`}
          render={({ field }) => (
            <TextInput
              type="number"
              onChange={field.onChange}
              containerStyle="w-100"
              inputStyle={styles.inputBg}
              i18nLabelKey="Damage_Deposit"
              suffix={GeneralQuotationCurrency}
              placeholder="00.00"
              value={String(field.value)}
              status={
                errors.quotations?.[index]?.damage_deposit &&
                errors.quotations?.[index]?.damage_deposit?.message
                  ? "error"
                  : "default"
              }
              errorMsg={errors?.quotations?.[index]?.damage_deposit?.message}
            />
          )}
        />
      </div>
      {errors?.quotations?.[index]?.currency &&
        errors?.quotations?.[index]?.currency?.message && (
          <div className="w-100">
            <InstructionNote instructionType="danger">
              <span className="Paragraph300Light">
                {errors?.quotations?.[index]?.currency?.message}
              </span>
            </InstructionNote>
          </div>
        )}
    </div>
  );
}
