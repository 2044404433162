import { useEffect } from "react";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { Control, FieldErrors, useFieldArray } from "react-hook-form";
import useToggleState from "hooks/useToggleState";
import Collapse from "components/wrappers/Collapse";
import { customgeneralErrorTypes } from ".";
import { generalQuotationTypes } from "../..";
import styles from "../../styles.module.scss";
import ParameterItem from "./ParameterItem";

export default function ParameterDetails({
  control,
  index,
  errors,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customgeneralErrorTypes>;
  index: number;
  // setValue: any;
}) {
  const { t } = useAutoCompleteTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `quotations.${index}.parameters`,
  });

  const {
    isSelected: isCollapseSelected,
    addToSelected: addToSelectedCollapse,
    replaceState,
  } = useToggleState<string>([]);

  useEffect(() => {
    if (errors.quotations?.[index]) {
      const temp: string[] = [];

      fields.forEach((_, nestedIndex) => {
        if (errors.quotations?.[index]?.parameters?.[nestedIndex]) {
          temp.push(`Parameter ${nestedIndex + 1}`);
        }
      });

      if (temp.length > 0) replaceState(temp);
    }
  }, [errors.quotations]);

  return (
    <div>
      {fields?.map((general, nestedIndex) => (
        <Collapse
          key={`General${general.id}`}
          isGeneral
          title={`${t("Parameter")} ${nestedIndex + 1}`}
          isOpen={isCollapseSelected(`Parameter ${nestedIndex + 1}`)}
          setIsOpen={() =>
            addToSelectedCollapse(`Parameter ${nestedIndex + 1}`)
          }
          innerCollapesContainerStyle={styles.passengerContainer_collapse}
          showRemoveBtn={nestedIndex !== 0}
          removeCollapse={() => remove(nestedIndex)}
          containerStyle={styles.collapseCustom}
        >
          <ParameterItem
            errors={errors}
            index={index}
            control={control}
            nestedIndex={nestedIndex}
            append={append}
          />
        </Collapse>
      ))}
    </div>
  );
}
