import { Upload } from "antd";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Icon from "../Icon";
import { UploadDraggerProps } from "./types";
import styles from "./styles.module.scss";

const { Dragger } = Upload;

export default function UploadDragger({
  containerStyle,
  onChange,
}: UploadDraggerProps) {
  const { t } = useAutoCompleteTranslation();
  return (
    <Dragger
      showUploadList={false}
      className={`${styles.container} ${containerStyle}`}
      onChange={onChange}
      beforeUpload={() => false}
      name="upload"
      multiple={false}
    >
      <div>
        <Icon name="Upload" size={32} color="TextSecondaryBlack" />
      </div>
      <span className="Paragraph300Light">{t("Upload_Text")}</span>
    </Dragger>
  );
}
