import { Switch } from "antd";
import { Control, Controller, FieldErrors, useWatch } from "react-hook-form";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import CheckBox from "components/general/CheckBox";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import DateInput from "components/inputs/DateInput";
import InstructionNote from "components/general/InstructionNote";
import dayjs from "dayjs";
import { generalQuotationTypes } from "../..";
import { customgeneralErrorTypes } from ".";
import styles from "../../styles.module.scss";

export default function GeneralPrice({
  control,
  errors,
  index,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customgeneralErrorTypes>;
  index: number;
}) {
  const { t } = useAutoCompleteTranslation();

  const CancelBeforeWatcher = useWatch({
    control,
    name: `quotations.${index}.cancelation_before`,
  });

  return (
    <div className={styles.spaceTop}>
      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.price`}
            type="number"
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="price"
            i18nPlaceholder="price"
            errorMsg={errors?.quotations?.[index]?.price?.message}
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.taxes_fee`}
            type="number"
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Taxes_Fees"
            i18nPlaceholder="Taxes_Fees"
            errorMsg={errors?.quotations?.[index]?.taxes_fee?.message}
          />
        </div>
      </div>

      <div className={styles.checkbox_container}>
        <Controller
          control={control}
          name={`quotations.${index}.apply_discount_on_taxes_and_basic_price`}
          render={({ field }) => (
            <CheckBox
              value={field.value}
              checked={field.value}
              onCheckChange={field.onChange}
              text={t("Apply_Discount")}
            />
          )}
        />
      </div>

      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.discount_credit`}
            type="number"
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Kudos_Point"
            i18nPlaceholder="Kudos_Point"
            errorMsg={errors?.quotations?.[index]?.discount_credit?.message}
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.discount`}
            type="number"
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Discount"
            i18nPlaceholder="Discount"
            errorMsg={errors?.quotations?.[index]?.discount?.message}
          />
        </div>
      </div>
      <div className="d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.wallet_activation_date`}
          render={({ field }) => (
            <DateInput
              defaultValue={field.value}
              onChange={(e) =>
                e
                  ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                  : field.onChange("")
              }
              i18nLabelKey="wallet_activation_date"
              status={
                errors.quotations?.[index]?.wallet_activation_date
                  ? "error"
                  : ""
              }
              disabledDate={(current) =>
                current && current <= dayjs().endOf("day")
              }
            />
          )}
        />
        {errors.quotations?.[index]?.wallet_activation_date?.message && (
          <div className="w-100">
            <InstructionNote instructionType="danger">
              <span className="Paragraph300Light">
                {errors.quotations?.[index]?.wallet_activation_date?.message}
              </span>
            </InstructionNote>
          </div>
        )}
      </div>
      <div className={styles.switchContainer}>
        <span className="Paragraph100Light">{t("Cancelation_Before")}</span>
        <Controller
          control={control}
          name={`quotations.${index}.cancelation_before`}
          render={({ field }) => (
            <Switch
              className={styles.switch}
              defaultChecked={field.value}
              checked={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
      <div className="d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.cancelation_before_date`}
          render={({ field }) => (
            <DateInput
              isDisabled={!CancelBeforeWatcher}
              defaultValue={field.value ?? ""}
              onChange={(e) =>
                e
                  ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                  : field.onChange("")
              }
              i18nLabelKey="Cancelation_Before_Date"
              status={
                errors.quotations?.[index]?.cancelation_before_date
                  ? "error"
                  : ""
              }
            />
          )}
        />
        {errors.quotations?.[index]?.cancelation_before_date &&
          errors.quotations?.[index]?.cancelation_before_date?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.quotations?.[index]?.cancelation_before_date?.message}
                </span>
              </InstructionNote>
            </div>
          )}
      </div>
    </div>
  );
}
