import Icon from "components/general/Icon";
import { ChangeEventHandler, useMemo, useState } from "react";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { debounce } from "lodash-es";
import { DebouncedSearchProps } from "./types";
import TextInput from "../TextInput";
import styles from "./styles.module.scss";

export default function DebouncedSearch({
  defaultValue = "",
  onSearchChange,
  size = "large",
}: DebouncedSearchProps) {
  const [searchValue, setsearchValue] = useState(defaultValue);
  const { t } = useAutoCompleteTranslation();

  const debouncedSearchHandler = useMemo(
    () => debounce(onSearchChange, 500),
    [onSearchChange]
  );

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setsearchValue(e.target.value);
    debouncedSearchHandler(e.target.value);
  };

  return (
    <TextInput
      size={size}
      containerStyle={styles.container}
      placeholder={t("search")}
      value={searchValue}
      prefix={<Icon name="Search" size={16} color="TextSecondaryBlack" />}
      onChange={onChangeHandler}
    />
  );
}
