import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { useNavigate } from "react-router-dom";
import {
  Controller,
  ResolverOptions,
  useForm,
  useWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PageTitle from "components/general/PageTitle";
import Button from "components/general/Button";
import {
  useGetCompanyMembershipsQuery,
  useLazyGetMembershipQuery,
} from "apis/services/memberships";
import handleErrors from "utils/handleErrors";
import { useLazyGetCountryQuery } from "apis/services/countries";
import { WalletItem } from "apis/types/wallet";
import { Membership } from "apis/types/memberships";
import { useAddCustomerMutation } from "apis/services/customers";
import { CustomerPostRerquest } from "apis/types/customers";
import Spinner from "components/general/Spinner";
import dayjs from "dayjs";
import showToast from "utils/showToast";
import { Switch } from "antd";
import Tabs from "components/general/Tabs";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { TabItem } from "components/general/Tabs/types";
import { useAddWalletTransactionsMutation } from "apis/services/wallet";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import CustomCridetCardLetters from "components/general/CustomCridetCardLetters";
import CustomerInfoInputs from "./CustomerInfoInputs";
import MembershipCardDetails from "./MembershipCardDetails";
import Wallet from "./Wallet";
import styles from "./styles.module.scss";

const schema = yup.object({
  firstName: yup.string().required("First name is required!"),
  lastName: yup.string().required("Last name is required!"),
  email: yup.string().email().trim().required("Email address is required!"),
  company: yup
    .number()
    .moreThan(0, "Company is required!")
    .required()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  countryShortName: yup.string(),
  mobileCode: yup.string().required(),
  mobile_code_char: yup.string().required(),
  mobile: yup.string().required("Mobile number is required!"),
  membership: yup
    .number()
    .moreThan(0, "Memebership is required!")
    .required()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  companyMembership: yup
    .number()
    .moreThan(0, "Memebership is required!")
    .required()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  country: yup
    .number()
    .moreThan(0, "Country is required!")
    .required()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  currency: yup.string().required(),
  membershipPrice: yup.string().required("Memberhsip price is required!"),
  afterDiscount: yup
    .number()
    .required("After discount is required!")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  cardCode: yup.string().length(4, "Card code is required!"),
  countryCardCode: yup.string().required("Country code is required!"),
  cardNumber: yup.string().length(8, "Card Number is required!"),
  expiryDate: yup.string().required("Expiray Date is required!"),
  renewalPrice: yup.string().required("Renewal Price is required!"),
  status: yup.boolean().required(),
  notes: yup.string(),
  wallet: yup
    .array()
    .of(
      yup.object({
        amount: yup
          .number()
          .moreThan(0, "Amount is required!")
          .required("Amount is required!")
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
        currency: yup.string().required("Currency is required!"),
        amount_type: yup
          .string()
          .oneOf(["refund", "travel_pound", "kudos", ""])
          .required("Amount Type is required!"),
        date: yup
          .string()
          .nullable()
          .when("is_active", {
            is: false,
            then: (dateSchema) => dateSchema.required("Date is required"),
          }),
        is_active: yup.boolean().required("Status is required!"),
      })
    )
    .required(),
});

export type FormTypes = yup.InferType<typeof schema>;

export default function CreateCustomerProfile() {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.userData);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormTypes>({
    resolver:
      user?.role !== "admin"
        ? yupResolver(schema.omit(["wallet"]))
        : yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      company: 0,
      mobileCode: "+44",
      mobile_code_char: "",
      countryShortName: "GB",
      mobile: "",
      membership: 0,
      companyMembership: 0,
      country: 0,
      currency: "GBP",
      membershipPrice: "",
      afterDiscount: 0,
      cardCode: "",
      countryCardCode: "",
      cardNumber: "",
      expiryDate: "",
      renewalPrice: "",
      status: true,
      wallet:
        user?.role !== "admin"
          ? undefined
          : [
              {
                amount: 0,
                currency: "GBP",
                amount_type: "",
                date: "",
                is_active: false,
              },
            ],
    },
    mode: "onSubmit",
  } as unknown as ResolverOptions<FormTypes>);

  const TabsData: TabItem[] = [
    {
      label: t("Wallet"),
      children: <Wallet control={control} errors={errors} />,
    },
    {
      label: t("Notes"),
      children: (
        <ControlledTextInput
          control={control}
          name="notes"
          type="textarea"
          containerStyle="mt-2"
          inputStyle={styles.formTextArea}
          i18nLabelKey="Notes"
          i18nPlaceholder="Notes"
        />
      ),
    },
  ];

  const [targetMembershipObj, setTargetMembershipObj] =
    useState<Membership | null>(null);

  const companyId = useWatch({ control, name: "company" });

  const { data: comapnyMemberhsips } = useGetCompanyMembershipsQuery({
    company_id: companyId ?? "",
  });

  const companyMembershipIdWatcher = useWatch({
    control,
    name: "companyMembership",
  });

  const [getMemberhsip] = useLazyGetMembershipQuery();

  useEffect(() => {
    if (companyMembershipIdWatcher === 0) return;

    const targetMembershipId = comapnyMemberhsips?.results.find(
      (item) => item.id === Number(companyMembershipIdWatcher)
    );

    if (!targetMembershipId) return;

    getMemberhsip(String(targetMembershipId.membership))
      .unwrap()
      .then((res) => {
        setTargetMembershipObj(res);
        if (res.price) {
          setValue("membership", res.id);
          setValue("currency", res.currency);
          setValue("membershipPrice", String(res.price));
          setValue("afterDiscount", res.price);
        }
        if (res.annual_fees) setValue("renewalPrice", String(res.annual_fees));
        setValue("cardCode", res.card_code);
      })
      .catch(handleErrors);
  }, [companyMembershipIdWatcher, comapnyMemberhsips]);

  const [getCountry] = useLazyGetCountryQuery();

  const countryWatcher = useWatch({ control, name: "country" });

  useEffect(() => {
    if (!countryWatcher) return;

    getCountry(String(countryWatcher))
      .unwrap()
      .then((res) => {
        if (!res) return;

        setValue("countryCardCode", res.country_card_code);
      });
  }, [countryWatcher]);

  const countryCodeWatcher = useWatch({ control, name: "cardCode" });
  const countryCardCodeWatcher = useWatch({ control, name: "countryCardCode" });
  const cardNumberWatcher = useWatch({ control, name: "cardNumber" });
  const nameWatcher = useWatch({ control, name: ["firstName", "lastName"] });
  const expiryDateWatcher = useWatch({ control, name: "expiryDate" });
  const statusWatcher = useWatch({ control, name: "status" });

  const [addCustomer, { isLoading: isAddCustomerLoading }] =
    useAddCustomerMutation();

  const [addWalletTransactions] = useAddWalletTransactionsMutation();

  const onSubmit = (data: FormTypes) => {
    const customerData: CustomerPostRerquest = {
      cards: [
        {
          card_number: `${data.cardCode}${data.countryCardCode}${data.cardNumber}`,
          currency: data.currency,
          price_after_discount:
            data.afterDiscount === 0 ? null : data.afterDiscount,
          expiry_date: dayjs(data.expiryDate, "MM/YY").format("YYYY-MM-01"),
          membership: data.membership,
          company_membership: data.companyMembership,
        },
      ],
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      mobile: data.mobile,
      mobile_code: data.mobileCode,
      mobile_code_char: data.mobile_code_char,
      status: data.status,
      company: data.company,
      country: data.country,
      notes: data.notes,
    };

    addCustomer(customerData)
      .unwrap()
      .then((res) => {
        if (user && user.role === "admin") {
          const transactions: Omit<WalletItem, "id">[] = data.wallet?.map(
            (item) => ({
              ...item,
              date:
                item.date === "" || item.date === null
                  ? undefined
                  : dayjs(item.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
              customer: res.id,
            })
          );
          addWalletTransactions(transactions)
            .unwrap()
            .then(() => {
              navigate("/customers");
              showToast({
                toastType: "success",
                title: `${data.firstName} ${data.lastName} has been added successfully!`,
                showCloseIcon: true,
              });
            });
        } else {
          navigate("/customers");
          showToast({
            toastType: "success",
            title: `${data.firstName} ${data.lastName} has been added successfully!`,
            showCloseIcon: true,
          });
        }
      })
      .catch(handleErrors);
  };

  return (
    <section>
      <PageTitle> {t("Create_Customer_Profile")} </PageTitle>
      <div className={styles.container}>
        {isAddCustomerLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <div className={styles.innerContainer}>
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              <CustomerInfoInputs
                control={control}
                errors={errors}
                setValue={setValue}
              />

              <div className={styles.cardContainer}>
                <div className={styles.card}>
                  <CustomCridetCardLetters
                    letterType={targetMembershipObj?.letters_type}
                    bgColor={targetMembershipObj?.template_color}
                    bgImage={targetMembershipObj?.template_image}
                    textColor1={targetMembershipObj?.template_text_color_1}
                    textColor2={targetMembershipObj?.template_text_color_2}
                    shadowColor={targetMembershipObj?.template_shadow_color}
                    shadowWidth={Number(
                      targetMembershipObj?.template_shadow_width
                    )}
                    firstFourNums={
                      countryCodeWatcher === "" ? undefined : countryCodeWatcher
                    }
                    secondFourNums={
                      countryCardCodeWatcher === ""
                        ? undefined
                        : countryCardCodeWatcher
                    }
                    thirdFourNums={
                      cardNumberWatcher === ""
                        ? undefined
                        : cardNumberWatcher?.slice(0, 4)
                    }
                    fourthFourNums={
                      cardNumberWatcher === ""
                        ? undefined
                        : cardNumberWatcher?.slice(4)
                    }
                    cardHolder={
                      nameWatcher[0] === ""
                        ? undefined
                        : `${nameWatcher[0]} ${nameWatcher[1]}`
                    }
                    expiaryDate={
                      expiryDateWatcher === "" ? undefined : expiryDateWatcher
                    }
                  />
                </div>
              </div>

              <MembershipCardDetails
                control={control}
                errors={errors}
                setValue={setValue}
              />

              <Tabs tabs={TabsData} />

              <div className={styles.switchContainer}>
                <span className="Paragraph100Light">
                  {statusWatcher ? t("Active") : t("Inactive")}
                </span>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Switch
                      defaultChecked={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </div>

              <Button btnClassName={styles.saveBtn} type="submit">
                <span> {t("Save")} </span>
              </Button>
            </form>
          </div>
        )}
      </div>
    </section>
  );
}
