import styles from "./styles.module.scss";
import { PageTitleProps } from "./types";

export default function PageTitle({
  containerStyle,
  children,
}: PageTitleProps) {
  return (
    <span className={`Headline300Heavy ${styles.header} ${containerStyle}`}>
      {children}
    </span>
  );
}
