import Icon from "../Icon";
import { CustomToastProps } from "./types";
import styles from "./styles.module.scss";

export default function CustomToast({
  toastType,
  title,
  msg,
  showCloseIcon = false,
}: CustomToastProps) {
  const icons = {
    warning: "Warning",
    danger: "Error",
    info: "Info",
    success: "Done",
  };
  return (
    <div className={styles.container}>
      <div className="d-flex align-items-center gap-2">
        <div>
          <Icon name={icons[toastType]} size={24} />
        </div>
        {title && (
          <p className={`Paragraph100Heavy m-0 ${styles.title}`}>{title}</p>
        )}
        {showCloseIcon && (
          <div className={styles.closeBtn}>
            <Icon name="Close" size={20} />
          </div>
        )}
      </div>

      {msg && (
        <div>
          <p className={`Paragraph200Light m-0 ${styles.msg}`}>{msg}</p>
        </div>
      )}
    </div>
  );
}
