import bottomRings from "assets/images/bottom-rings.svg";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Image from "components/general/Image";
import WAVEING_HAND from "assets/images/waving-hand.svg";
import { AuthLayoutProps } from "./types";
import styles from "./styles.module.scss";

export default function AuthLayout({ children }: AuthLayoutProps) {
  const { t } = useAutoCompleteTranslation();
  return (
    <section className="vh-100 d-flex relative">
      <div className={styles.bottomRingsContainer}>
        <Image className="w-100 h-100" src={bottomRings} alt="bottom rings" />
      </div>
      <div className={styles.leftSection}>
        <div className={`${styles.leftContent} d-flex row row-gap-3`}>
          <div className="d-flex align-center">
            <h1 className="Headline100Heavy">{t("Welcome_Back")}&nbsp;</h1>
            <Image
              className={styles.wavingHand}
              src={WAVEING_HAND}
              alt="waving hand"
            />
          </div>

          <p className="Paragraph100Light">
            {t("DISCOUNT_AND_CREDIT_ON_THE_LOWEST_PRICES")} <br />{" "}
            {t("YOU_CAN_FIND_ONLINE")}
          </p>
        </div>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.rightContent}>{children}</div>
      </div>
    </section>
  );
}
