import { useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import SelectionInput from "components/inputs/SelectionInput";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import Button from "components/general/Button";
import {
  useGetCompanyMembershipsQuery,
  useGetMembershipQuery,
} from "apis/services/memberships";
import InstructionNote from "components/general/InstructionNote";
import {
  useGetCountriesQuery,
  useLazyGetCountryQuery,
} from "apis/services/countries";
import DateInput from "components/inputs/DateInput";
import dayjs from "dayjs";
import { useGetCurrenciesQuery } from "apis/services/currency";
import { debounce } from "lodash";
import { FormTypes } from ".";
import styles from "./styles.module.scss";

export default function EditMembershipCardDetails({
  control,
  errors,
  setValue,
}: {
  control: Control<FormTypes>;
  errors: FieldErrors<FormTypes>;
  setValue: UseFormSetValue<FormTypes>;
}) {
  const { t } = useAutoCompleteTranslation();
  const companyId = useWatch({ control, name: "company" });

  const [countriesSearch, setCountriesSearch] = useState("");

  const { data: comapnyMemberhsips, isLoading: isCompanyMembershipsLoading } =
    useGetCompanyMembershipsQuery({ company_id: companyId ?? "" });

  const {
    data: countries,
    isLoading: isCountriesLoading,
    isFetching: isCountriesFetching,
  } = useGetCountriesQuery({ search: countriesSearch });

  const [getCountry] = useLazyGetCountryQuery();

  const { data: currenciesData, isLoading: isCurrenciesLoading } =
    useGetCurrenciesQuery();

  const generateRandomEightNumbers = () => {
    const randomNumbers = [];

    for (let i = 0; i < 8; i += 1) {
      const randomNumber = Math.floor(Math.random() * 10);
      randomNumbers.push(randomNumber);
    }

    setValue("cardNumber", randomNumbers.join(""));
  };

  const CurrencyWatcher = useWatch({
    control,
    name: "currency",
  });

  const CountryWatcher = useWatch({
    control,
    name: "country",
  });

  const companyMembershipIdWatcher = useWatch({
    control,
    name: "companyMembership",
  });

  const targetMembershipId = comapnyMemberhsips?.results.find(
    (item) => item.id === Number(companyMembershipIdWatcher)
  );

  const { data: targetMembershipData } = useGetMembershipQuery(
    String(targetMembershipId?.membership) ?? ""
  );

  useEffect(() => {
    if (CurrencyWatcher && currenciesData && targetMembershipData) {
      setValue(
        "membershipPrice",
        String(
          (
            Number(targetMembershipData.price) *
            // @ts-ignore
            currenciesData[targetMembershipData.currency][CurrencyWatcher]
          ).toFixed(2)
        )
      );
      setValue(
        "afterDiscount",
        Number(
          (
            targetMembershipData.price *
            // @ts-ignore
            currenciesData[targetMembershipData.currency][CurrencyWatcher]
          ).toFixed(2)
        )
      );
      setValue(
        "renewalPrice",
        String(
          (
            Number(targetMembershipData.annual_fees) *
            // @ts-ignore
            currenciesData[targetMembershipData.currency][CurrencyWatcher]
          ).toFixed(2)
        )
      );
    }
  }, [CurrencyWatcher, targetMembershipData]);

  useEffect(() => {
    if (CountryWatcher && CountryWatcher !== 0) {
      getCountry(String(CountryWatcher))
        .unwrap()
        .then((res) => setCountriesSearch(res.name));
    }
  }, [CountryWatcher]);

  return (
    <>
      <div className="d-flex gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name="companyMembership"
            render={({ field }) => (
              <SelectionInput
                value={field.value === 0 ? undefined : String(field.value)}
                onChange={field.onChange}
                isLoading={isCompanyMembershipsLoading}
                options={
                  comapnyMemberhsips?.results?.map((item) => ({
                    value: String(item.id),
                    label: item.membership_name,
                  })) ?? []
                }
                containerStyle="w-100"
                label={`${t("Card_Type")} (${t("Membership")}) *`}
                placeholder={t("Card_Type")}
                status={
                  errors.companyMembership && errors.companyMembership.message
                    ? "error"
                    : ""
                }
              />
            )}
          />
          {errors.companyMembership && errors.companyMembership.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.companyMembership.message}
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <SelectionInput
                isLoading={isCountriesLoading || isCountriesFetching}
                value={field.value === 0 ? "" : String(field.value)}
                onChange={field.onChange}
                options={
                  countries?.results?.map((item) => ({
                    value: String(item.id),
                    label: item.name,
                  })) ?? []
                }
                showSearch
                onSearch={debounce((e) => setCountriesSearch(e), 500)}
                onFilter={false}
                containerStyle="w-100"
                label={t("Country")}
                placeholder={t("country")}
                status={errors.country && errors.country.message ? "error" : ""}
              />
            )}
          />
          {errors.country && errors.country.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.country.message}
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex gap-2">
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name="currency"
            render={({ field }) => (
              <SelectionInput
                isLoading={isCurrenciesLoading}
                value={field.value}
                onChange={field.onChange}
                options={
                  currenciesData
                    ? Object.keys(currenciesData).map((item) => ({
                        label: item,
                        value: item,
                      }))
                    : []
                }
                containerStyle="w-100"
                label={t("Currency")}
                placeholder={t("Currency")}
                status={
                  errors.currency && errors.currency.message ? "error" : ""
                }
              />
            )}
          />
          {errors.currency && errors.currency.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.currency.message}
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name="membershipPrice"
            render={({ field }) => (
              <TextInput
                value={Number(field.value).toFixed(2)}
                containerStyle={`w-100 ${styles.dimmed}`}
                label={t("Membership_Price")}
                placeholder="00.00"
                suffix={
                  <span className={styles.dimmed}>{CurrencyWatcher}</span>
                }
                status="disabled"
                errorMsg={errors?.membershipPrice?.message}
              />
            )}
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name="afterDiscount"
            render={({ field }) => (
              <TextInput
                type="number"
                value={String(field.value)}
                onChange={field.onChange}
                containerStyle="w-100"
                label={t("After_Discount")}
                placeholder="00.00"
                suffix={
                  <span className={styles.dimmed}>{CurrencyWatcher}</span>
                }
                status={errors?.afterDiscount?.message ? "error" : "default"}
                errorMsg={errors?.afterDiscount?.message}
              />
            )}
          />
        </div>
      </div>

      <div className="d-flex align-items-start gap-2">
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name="cardCode"
            containerStyle="w-100"
            label={`${t("Card_Code")} *`}
            i18nPlaceholder="Code_With_Four_digits"
            status="disabled"
            errorMsg={errors?.cardCode?.message}
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name="countryCardCode"
            containerStyle={`w-100 ${styles.dimmed}`}
            label={`${t("Country_code")} *`}
            i18nPlaceholder="Code_With_Four_digits"
            status="disabled"
            errorMsg={errors?.countryCardCode?.message}
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name="cardNumber"
            render={({ field }) => (
              <TextInput
                value={field.value}
                onChange={(e) => {
                  if (/^(|\d{0,8})$/.test(e.target.value)) field.onChange(e);
                }}
                containerStyle="w-100"
                label={`${t("Card_Number")} *`}
                placeholder={`${t("Card_Number")} ${t("Eight_Digits")}`}
                status={errors?.cardNumber?.message ? "error" : "default"}
                errorMsg={errors?.cardNumber?.message}
              />
            )}
          />
        </div>
      </div>

      <Button styleType="Secondary" onClick={generateRandomEightNumbers}>
        <span>
          {t("Generate_Automatically")} {t("Last_Eight_Digits")}
        </span>
      </Button>

      <div className="d-flex gap-3">
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name="expiryDate"
            render={({ field }) => (
              <DateInput
                picker="month"
                defaultValue={field.value}
                onChange={(e) =>
                  e
                    ? field.onChange(dayjs(e).format("MM/YY"))
                    : field.onChange("")
                }
                label={`${t("Expiry_Date")} *`}
                placeholder="MM/YY"
                dateFormat="MM/YY"
                status={
                  errors.expiryDate && errors.expiryDate.message ? "error" : ""
                }
              />
            )}
          />
          {errors.expiryDate && errors.expiryDate.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.expiryDate.message}
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name="renewalPrice"
            render={({ field }) => (
              <TextInput
                value={Number(field.value).toFixed(2)}
                containerStyle="w-100"
                label={`${t("Renewal_Price")} *`}
                placeholder="00.00"
                suffix={
                  <span className={styles.dimmed}>{CurrencyWatcher}</span>
                }
                status="disabled"
                errorMsg={errors?.renewalPrice?.message}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}
