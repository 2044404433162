import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UploadDragger from "components/general/UploadDragger";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import InstructionNote from "components/general/InstructionNote";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import { Switch } from "antd";
import SelectionInput from "components/inputs/SelectionInput";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import showToast from "utils/showToast";
import PageTitle from "components/general/PageTitle";
import Spinner from "components/general/Spinner";
import {
  useAddCompanyMembershipsMutation,
  useGetMembershipsQuery,
} from "apis/services/memberships";
import { useAddCompanyMutation } from "apis/services/companies";
import { CompanyMembership } from "apis/types/memberships";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "./styles.module.scss";

const schema = yup.object({
  name: yup.string().required("Company name is required!"),
  code: yup.string().required("Company code is required!").min(4).max(4),
  company_login_email: yup
    .string()
    .email()
    .required("Company email is required!"),
  company_memberships: yup
    .array()
    .of(
      yup.object({
        id: yup.string(),
        membership: yup.string().required("Membership is required!"),
        number_of_subscribers_limit: yup
          .string()
          .required("Limitaions number is required!"),
      })
    )
    .required(),
  notes: yup.string(),
  status: yup.boolean(),
});

type FormData = yup.InferType<typeof schema>;

export default function AddNewCompany() {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();

  const [addCompany, { isLoading: isAddCompanyLoading }] =
    useAddCompanyMutation();
  const [addCompanyMemberships, { isLoading: isAddCompanyMembershipsLoading }] =
    useAddCompanyMembershipsMutation();

  const { data: memberships, isLoading: isMembershipLoading } =
    useGetMembershipsQuery({ page_size: 100 });

  const [uploadedLogo, setUploadedLogo] = useState<string | Blob>("");
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isSwitchOpen, setIsSwitchOpen] = useState(true);

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      code: "",
      company_login_email: "",
      company_memberships: [
        {
          id: "",
          membership: "",
          number_of_subscribers_limit: "",
        },
      ],
      notes: "",
      status: true,
    },
    mode: "onSubmit",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "company_memberships",
  });

  const addMembership = () => {
    append({
      id: "",
      membership: "",
      number_of_subscribers_limit: "",
    });
  };

  const deleteMembership = (index: number) => {
    remove(index);
  };

  const onSwitchChange = () => {
    setIsSwitchOpen(!isSwitchOpen);
  };

  const onSubmit = (data: FormData) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("code", data.code);
    if (uploadedLogo) formData.append("logo", uploadedLogo);
    formData.append("company_login_email", data.company_login_email);
    if (data.notes) formData.append("notes", data.notes);
    if (data.status) formData.append("status", String(data.status));

    addCompany(formData)
      .unwrap()
      .then((res) => {
        const companyMemberships: Omit<
          CompanyMembership,
          "membership_name" | "company_name" | "id"
        >[] = data.company_memberships.map((item) => ({
          number_of_subscribers_limit: Number(item.number_of_subscribers_limit),
          membership: Number(item.membership),
          company: Number(res.id),
        }));
        addCompanyMemberships(companyMemberships)
          .unwrap()
          .then(() => {
            navigate("/companies");
            showToast({
              toastType: "success",
              title: `${data.name} company has been added successfully!`,
              msg: "Congratulations! ",
              showCloseIcon: true,
            });
          });
      })
      .catch(handleErrors);
  };

  return (
    <section>
      <PageTitle> {t("Add_New_Company")} </PageTitle>

      <div className={styles.mainContentContainer}>
        {isMembershipLoading ||
        isAddCompanyLoading ||
        isAddCompanyMembershipsLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <div className={styles.innerContainer}>
            <div className={styles.uploadContainer}>
              <span className="Label100"> {t("Company_Logo")} </span>
              {uploadedLogo && uploadedFile ? (
                <div className={styles.uploadedFile}>
                  <div>
                    <Icon name="Image" color="TextSecondaryBlack" size={20} />
                  </div>
                  <span className="Paragraph200Light">{uploadedFile.name}</span>
                  <span className="Paragraph300Light">
                    {(uploadedFile.size / 1024).toFixed(2)} KB
                  </span>
                  <Button
                    btnClassName={styles.uploadedFileRemoveBtn}
                    styleType="NoStyle"
                    onClick={() => {
                      setUploadedFile(null);
                      setUploadedLogo("");
                    }}
                  >
                    <Icon name="Close" size={16} color="TextSecondaryBlack" />
                  </Button>
                </div>
              ) : (
                <UploadDragger
                  containerStyle={styles.uploadDragger}
                  onChange={(e) => {
                    if (
                      !(
                        e.file.type === "image/png" ||
                        e.file.type === "image/jpg" ||
                        e.file.type === "image/jpeg"
                      )
                    ) {
                      showToast({
                        toastType: "danger",
                        title: "Invalid File Type",
                        msg: "Please upload a JPG, JPEG, or PNG image.",
                        showCloseIcon: true,
                      });
                    } else if (e.file.size <= 1024000) {
                      setUploadedFile(e.file);
                      setUploadedLogo(e.file);
                    } else {
                      showToast({
                        toastType: "danger",
                        title: "File Size Exceeded",
                        msg: "Please upload an image smaller than 1MB.",
                        showCloseIcon: true,
                      });
                    }
                  }}
                />
              )}
            </div>

            <form
              className={styles.companyDetailsForm}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={styles.formRow}>
                <div className="w-100 d-flex flex-column gap-1">
                  <TextInput
                    containerStyle="w-100"
                    label={`${t("Company_Name")} *`}
                    placeholder={t("Company_Name")}
                    reactHookFormProps={register("name")}
                    status={errors?.name?.message ? "error" : "default"}
                    errorMsg={errors?.name?.message}
                  />
                </div>
                <div className="w-100 d-flex flex-column gap-1">
                  <Controller
                    control={control}
                    name="code"
                    render={({ field }) => (
                      <TextInput
                        containerStyle="w-100"
                        label={`${t("Company_Code")} *`}
                        placeholder={t("Company_Code")}
                        status={
                          errors.code && errors.code.message
                            ? "error"
                            : "default"
                        }
                        onChange={({ target: { value } }) => {
                          const sanitizedValue = value
                            .replace(/\D/g, "")
                            .slice(0, 4);
                          field.onChange(sanitizedValue);
                        }}
                        value={field.value}
                      />
                    )}
                  />
                  {errors.code && errors.code.message && (
                    <div className="w-100">
                      <InstructionNote instructionType="danger">
                        <span className="Paragraph300Light">
                          {errors.code.message}
                        </span>
                      </InstructionNote>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.formRow} flex-column gap-1`}>
                <TextInput
                  label={`${t("Company_Login_Email")} *`}
                  placeholder={t("Company_Login_Email")}
                  reactHookFormProps={register("company_login_email")}
                  status={
                    errors?.company_login_email?.message ? "error" : "default"
                  }
                  errorMsg={errors?.company_login_email?.message}
                />
                <InstructionNote instructionType="info">
                  <span className="Paragraph300Light">
                    {t("Email_Info_Text")}
                  </span>
                </InstructionNote>
              </div>
              {fields.map((row, index) => {
                const order = index + 1;
                const isFirstIndex = index === 0;
                return (
                  <div className={styles.formRow} key={row.id}>
                    <div className="w-100 d-flex flex-column gap-1">
                      <Controller
                        control={control}
                        name={`company_memberships.${index}.membership`}
                        render={({ field }) => (
                          <SelectionInput
                            size="large"
                            onChange={(value) => field.onChange(value)}
                            containerStyle="w-100"
                            label={`${t("Memberships")}(${order}) ${
                              isFirstIndex ? "*" : ""
                            }`}
                            placeholder={t("Select")}
                            options={
                              memberships?.results?.map((item) => ({
                                value: String(item.id),
                                label: item.name,
                              })) || []
                            }
                            status={
                              errors?.company_memberships?.[index]?.membership
                                ?.message
                                ? "error"
                                : ""
                            }
                          />
                        )}
                      />
                      {errors.company_memberships &&
                        errors.company_memberships[index]?.membership
                          ?.message && (
                          <div className="w-100">
                            <InstructionNote instructionType="danger">
                              <span className="Paragraph300Light">
                                {
                                  errors.company_memberships[index]?.membership
                                    ?.message
                                }
                              </span>
                            </InstructionNote>
                          </div>
                        )}
                    </div>
                    <div className="w-100 d-flex gap-3">
                      <div className="w-100 d-flex flex-column gap-1">
                        <ControlledTextInput
                          type="number"
                          control={control}
                          name={`company_memberships.${index}.number_of_subscribers_limit`}
                          containerStyle="w-100"
                          label={`${t("Limitation_On_Membership")}(${order}) ${
                            isFirstIndex ? "*" : ""
                          }`}
                          placeholder={t("Limitation_On_Membership")}
                          errorMsg={
                            errors?.company_memberships?.[index]
                              ?.number_of_subscribers_limit?.message
                          }
                        />
                      </div>

                      {!isFirstIndex && (
                        <div className="pt-4">
                          <Button
                            onClick={() => {
                              unregister(`company_memberships.${index}`);
                              deleteMembership(index);
                            }}
                            btnClassName={styles.deleteRowBtn}
                            styleType="Secondary"
                          >
                            <div>
                              <Icon
                                name="Close"
                                size={40}
                                color="TextSecondaryBlack"
                              />
                            </div>
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

              <div className={styles.formRow}>
                <Button
                  onClick={() => navigate("/memberships/add-new-membership")}
                  styleType="Secondary"
                >
                  <span>+ {t("Create_New_Membership")}</span>
                </Button>
                <Button onClick={addMembership} styleType="Secondary">
                  <span>+ {t("Add_Another_Membership")}</span>
                </Button>
              </div>

              <TextInput
                type="textarea"
                inputStyle={styles.formTextArea}
                label={t("Additional_Notes")}
                placeholder={t("Notes")}
                reactHookFormProps={register("notes")}
              />

              <div className={styles.switchContainer}>
                <span className="Paragraph100Light">
                  {isSwitchOpen ? t("Active") : t("Inactive")}
                </span>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Switch
                      className={styles.switch}
                      defaultChecked={isSwitchOpen}
                      onChange={(e) => {
                        field.onChange(e);
                        onSwitchChange();
                      }}
                    />
                  )}
                />
              </div>

              <Button btnClassName={styles.saveBtn} type="submit">
                <span> {t("Save")} </span>
              </Button>
            </form>
          </div>
        )}
      </div>
    </section>
  );
}
