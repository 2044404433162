/* eslint-disable no-nested-ternary */
import { useState } from "react";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import SelectionInput from "components/inputs/SelectionInput";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import { useNavigate, useSearchParams } from "react-router-dom";
import Table from "components/general/Table";
import { TableItemProps } from "components/general/Table/types";
import Spinner from "components/general/Spinner";
import {
  useGetAgentsQuery,
  useDeleteAgentMutation,
  useDeleteAgentsBulkMutation,
} from "apis/services/agents";
import { Agent } from "apis/types/agents";
import { stringifyParam } from "utils/queryParamService";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "./styles.module.scss";

export default function UserManagement() {
  const { t } = useAutoCompleteTranslation();
  const rolesTypeOptions = [
    { value: "admin", label: t("Admin") },
    { value: "booking_team", label: t("Booking_Team") },
    { value: "finance", label: t("Finance") },
    { value: "company_admin", label: t("Company_Admin") },
  ];
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: agents,
    isLoading,
    isFetching,
  } = useGetAgentsQuery({
    page_size: localStorage.getItem("AgentsTable")
      ? Number(localStorage.getItem("AgentsTable"))
      : 15,
    page: searchParams.get("page") ?? 1,
    search: searchParams.get("search") ?? "",
    role: searchParams.get("role") ?? "",
  });

  const arrangeCompanyData = (item: Agent): TableItemProps => ({
    id: String(item.id),
    rowData: [
      { type: "text", dataItem: `${item?.first_name} ${item.last_name}` },
      {
        type: "text",
        dataItem: rolesTypeOptions.find((role) => role.value === item.role)
          ?.label,
      },
      { type: "text", dataItem: item.email },
      { type: "more", dataItem: [item.email, "/user-management/edit-user/"] },
    ],
  });

  const tableHeaders = [
    t("NAME"),
    t("Role"),
    t("Login_Email"),
    "", // Make the last one empty string to let the menu btn appear here
  ];
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteAgent, { isLoading: isDeleteLoading }] =
    useDeleteAgentMutation();
  const [deleteAgentsBulk, { isLoading: isDeleteBulkLoading }] =
    useDeleteAgentsBulkMutation();
  const handleDeleteAgent = (agentName: string, agentId: string) => {
    if (agentId) {
      deleteAgent(agentId)
        .unwrap()
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/user-management");
          showToast({
            toastType: "success",
            title: `${agentName} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };
  const handleDeleteBulk = (agentsIds: string) => {
    deleteAgentsBulk(agentsIds)
      .unwrap()
      .then(() => {
        setIsDeleteModalVisible(false);
        showToast({
          toastType: "success",
          title: `Selected Agents have been deleted successfully!`,
          showCloseIcon: true,
        });
      })
      .catch(handleErrors);
  };

  return (
    <section>
      <div className={styles.filtersContainer}>
        <div className={styles.filtersGroup}>
          <span className={`Headline300Heavy ${styles.headline}`}>
            {t("User_Management")}
          </span>
        </div>
        <div className={styles.btnGroup}>
          <DebouncedSearch
            size="small"
            onSearchChange={(val) => {
              searchParams.set("search", val);
              setSearchParams(searchParams);
            }}
            defaultValue={String(
              searchParams
                ?.get("search")
                ?.split(",")
                .filter((filterd) => filterd !== "") ?? []
            )}
          />

          <SelectionInput
            size="small"
            placeholder={t("Roles")}
            options={rolesTypeOptions}
            mode="tags"
            onChange={(val) => {
              searchParams.set("role", stringifyParam(val as string[]));
              setSearchParams(searchParams);
            }}
            value={
              searchParams
                ?.get("role")
                ?.split(",")
                ?.filter((filterd) => filterd !== "") ?? []
            }
            containerStyle={styles.rolesFilter}
          />
          <span className={styles.divider} />

          <Button
            btnClassName={styles.importExportBtns}
            size="small"
            styleType="Secondary"
            prefix={<Icon name="import" size={16} color="Neutral1000" />}
          >
            <span className="Paragraph200Light">Import</span>
          </Button>
          <Button
            btnClassName={styles.importExportBtns}
            size="small"
            styleType="Secondary"
            prefix={<Icon name="export" size={16} color="Neutral1000" />}
          >
            <span className="Paragraph200Light">Export</span>
          </Button>
          <span className={styles.divider} />
          <Button
            btnClassName={styles.addNewCompanyBtn}
            size="small"
            styleType="Primary"
            prefix={
              <Icon name="Add-Outline" size={12} color="TextPrimaryWhite" />
            }
            onClick={() => navigate("add-new-agent")}
          >
            <span className="Button200 text-nowrap">{t("Add_New_Agent")}</span>
          </Button>
        </div>
      </div>
      {isFetching || isLoading ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : agents?.results?.length ? (
        <Table
          tableName="AgentsTable"
          headers={tableHeaders}
          data={agents?.results?.map((item: Agent) => arrangeCompanyData(item))}
          count={agents?.count}
          pagesCount={agents?.pages_number}
          deleteOneRow={handleDeleteAgent}
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          isDeleteLoading={isDeleteLoading || isDeleteBulkLoading}
          deleteMultipleSelectedRows={handleDeleteBulk}
        />
      ) : (
        <div> {t("No_Data_Found")} </div>
      )}
    </section>
  );
}
