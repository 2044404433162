/* eslint-disable no-nested-ternary */
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { Select } from "antd";
import Icon from "components/general/Icon";
import CheckBox from "components/general/CheckBox";
import Spinner from "components/general/Spinner";
import { SelectionInputProps } from "./types";
import styles from "./styles.module.scss";

export default function SelectionInput({
  containerStyle,
  onChange,
  options,
  value,
  placeholder = "",
  size = "large",
  label,
  i18nPlaceholder,
  i18nLabelKey,
  status = "",
  mode = "",
  isDisabled = false,
  defaultValue = undefined,
  isLoading = false,
  onScroll = undefined,
  showSearch = false,
  onSearch = undefined,
  onFilter = undefined,
  onInputKeyDown = undefined,
}: SelectionInputProps) {
  const { t } = useAutoCompleteTranslation();
  const id = `selectInput${Math.random()}`;

  const tagOption = (option: any) => {
    return (
      <div className={styles.tagOption}>
        {mode === "tags" && (
          <CheckBox
            checked={value === option.value || value?.includes(option.value)}
            value={option.value}
          />
        )}
        <span>{option?.label}</span>
      </div>
    );
  };

  return (
    <div className={`${containerStyle}`} id={id}>
      {(!!label || !!i18nLabelKey) && (
        <span className="d-block mb-2 Label100">
          {i18nLabelKey ? t(i18nLabelKey) : label}
        </span>
      )}
      <Select
        showSearch={showSearch}
        mode={mode || undefined}
        className={`${styles.selectInput} ${styles[size]} ${styles[mode]} w-100`}
        getPopupContainer={(trigger: HTMLElement | null) => {
          return (
            (trigger?.parentNode as HTMLElement) ?? document.getElementById(id)
          );
        }}
        suffixIcon={
          isLoading ? (
            <Spinner containerStyle={styles.loadingSpinner} size="Small" />
          ) : (
            <Icon name="ChevronDown" size={16} color="TextSecondaryBlack" />
          )
        }
        placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
        onChange={onChange}
        value={value}
        options={options}
        status={status}
        optionRender={tagOption}
        disabled={isDisabled}
        defaultValue={defaultValue}
        onPopupScroll={onScroll}
        onSearch={onSearch}
        filterOption={onFilter}
        notFoundContent={null}
        onInputKeyDown={onInputKeyDown}
        defaultActiveFirstOption={!showSearch}
      />
    </div>
  );
}
