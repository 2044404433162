/* eslint-disable jsx-a11y/anchor-is-valid */
import { Text, View, StyleSheet, Link } from "@react-pdf/renderer";
import COLORS from "constants/Colors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { GeneralQuotationGetReq } from "apis/types/generalQuotations";
import { FlightQuotation } from "apis/types/flightQuotation";
import dayjs from "dayjs";

export default function FlightDetailsPDF({
  flight,
  currentLanguage,
  generalQuotation,
  isConfirmation = false,
}: {
  flight?: FlightQuotation;
  currentLanguage: string;
  generalQuotation: GeneralQuotationGetReq;
  isConfirmation?: boolean;
}) {
  const { t } = useAutoCompleteTranslation();
  const CurrencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: generalQuotation?.currency ?? "GBP",
  });

  const documentStyle = StyleSheet.create({
    Label100: {
      fontSize: "14px",
      textTransform: "capitalize",
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text",
      fontWeight: 700,
    },
    Paragraph100Heavy: {
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text-Heavy",
      fontSize: "14px",
    },
    Paragraph200Light: {
      fontFamily:
        currentLanguage === "ar"
          ? "IBMPlexSansArabicLight"
          : "SF-Pro-Text-Light",
      fontSize: "12px",
      fontWeight: 400,
      color: COLORS.TextSecondaryBlack,
    },
    Paragraph200Heavy: {
      color: COLORS.TextSecondaryBlack,
      fontSize: "12px",
      textTransform: "capitalize",
      fontFamily:
        currentLanguage === "ar"
          ? "IBMPlexSansArabicLight"
          : "SF-Pro-Text-Light",
      fontWeight: 400,
    },
    pageContainer: {
      backgroundColor: COLORS.Neutral0,
    },
    quotation_container: {
      display: "flex",
      flexDirection: "column",
      marginTop: "6px",
    },
    nonRefundableText: {
      fontSize: "12px",
      fontWeight: 700,
    },
    roomText: {
      fontSize: "12px",
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    navbar: {
      backgroundColor: COLORS.Neutral1000,
      display: "flex",
      justifyContent: "space-between",
      paddingHorizontal: "20",
      height: "90px",
      alignItems: "center",
      flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
    },
    logoImg: {
      objectFit: "contain",
      height: "80%",
      marginVertical: "auto",
    },
    flightQuotation: {
      borderRadius: "10px",
      border: `1px solid ${COLORS.Neutral300}`,
      padding: "10px",
      marginTop: "10px",
    },
  });
  return (
    <View>
      {flight?.parameters &&
        flight?.parameters?.length > 0 &&
        // eslint-disable-next-line no-unsafe-optional-chaining
        [...flight?.parameters]
          .sort((a: any, b: any) => a.order - b.order)
          .map((item) => (
            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
                marginTop: "8px",
                width: "85%",
                marginHorizontal: "auto",
              }}
            >
              <Text style={documentStyle.Paragraph200Heavy}>{item.name}</Text>
              <Text
                style={{
                  ...documentStyle.Paragraph200Light,
                  maxWidth: "55%",
                  textAlign: currentLanguage === "en" ? "right" : "left",
                }}
              >
                {item.value}
              </Text>
            </View>
          ))}
      <View
        style={{
          width: "85%",
          marginHorizontal: "auto",
        }}
      >
        <View
          style={{
            width: "100%",
            marginHorizontal: "auto",
            border: `1px solid ${COLORS.Neutral300}`,
            borderRadius: "10px",
            marginTop: "15px",
            flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              ...documentStyle.Label100,
              padding: "15px",
              color: COLORS.TextPrimaryBlack,
            }}
          >
            {t("Confirmation_Number")}
          </Text>
          <Text
            style={{
              ...documentStyle.Paragraph200Light,
              padding: "15px",
              color: COLORS.TextPrimaryBlack,
            }}
          >
            {flight?.confirmation_number}
          </Text>
        </View>
        <View style={documentStyle.flightQuotation}>
          {/* First Passenger */}
          {flight?.passengers && flight?.passengers?.length > 0 && (
            <View wrap={false}>
              <View
                style={{
                  display: "flex",
                  flexDirection:
                    currentLanguage === "en" ? "row" : "row-reverse",
                }}
              >
                <Text
                  style={{
                    ...documentStyle.Paragraph100Heavy,
                    color: COLORS.TextPrimaryBlack,
                    fontWeight: 400,
                  }}
                >
                  {t("Passenger")}
                </Text>
              </View>
              <View
                style={{
                  marginTop: "12px",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection:
                      currentLanguage === "en" ? "row" : "row-reverse",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={documentStyle.Paragraph200Heavy}>
                    {flight.passengers[0].client_name}
                  </Text>
                  <Text style={documentStyle.Paragraph200Heavy}>
                    {flight.passengers[0].is_child ? t("Child") : t("Adult")}
                  </Text>
                </View>{" "}
                {flight.passengers[0].no_of_adults !== null &&
                  flight.passengers[0].no_of_adults !== undefined &&
                  flight.passengers[0].no_of_adults > 0 && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {t("Number_Of_Adults")}
                      </Text>
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {flight.passengers[0].no_of_adults}
                      </Text>
                    </View>
                  )}
                {flight.passengers[0].no_of_teens !== null &&
                  flight.passengers[0].no_of_teens !== undefined &&
                  flight.passengers[0].no_of_teens > 0 && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {t("Number_Of_Teens")}
                      </Text>
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {flight.passengers[0].no_of_teens}
                      </Text>
                    </View>
                  )}
                {flight.passengers[0].no_of_children !== null &&
                  flight.passengers[0].no_of_children !== undefined &&
                  flight.passengers[0].no_of_children > 0 && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {t("Number_Of_Childern")}
                      </Text>
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {flight.passengers[0].no_of_children}
                      </Text>
                    </View>
                  )}
                {flight.passengers[0].no_of_infants !== null &&
                  flight.passengers[0].no_of_infants !== undefined &&
                  flight.passengers[0].no_of_infants > 0 && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {t("Number_Of_Infants")}
                      </Text>
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {flight.passengers[0].no_of_infants}
                      </Text>
                    </View>
                  )}
                <View
                  style={{
                    display: "flex",
                    flexDirection:
                      currentLanguage === "en" ? "row" : "row-reverse",
                    justifyContent: "space-between",
                    marginTop: "12px",
                  }}
                >
                  <Text style={documentStyle.Paragraph200Heavy}>
                    {t("Price")}
                  </Text>
                  <Text style={documentStyle.Paragraph200Heavy}>
                    {CurrencyFormatter.format(
                      Number(flight?.passengers[0]?.price)
                    )}
                  </Text>
                </View>
                {flight.passengers[0].taxes_fee !== null &&
                  flight.passengers[0].taxes_fee !== undefined &&
                  String(flight.passengers[0].taxes_fee) !== "0.00" && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {t("Taxes_Fee")}
                      </Text>
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {CurrencyFormatter.format(
                          Number(flight?.passengers[0]?.taxes_fee)
                        )}
                      </Text>
                    </View>
                  )}
                {flight.passengers[0].discount_credit !== null &&
                  flight.passengers[0].discount_credit !== undefined &&
                  flight.passengers[0].discount_credit > 0 && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text
                        style={{
                          ...documentStyle.Paragraph200Heavy,
                          maxWidth: "50%",
                        }}
                      >
                        {t("discout_credit_accommodation")}{" "}
                      </Text>
                      <Text style={documentStyle.Paragraph200Light}>
                        {Number(flight.passengers[0].discount_credit)} %{" "}
                        {`(${t("equivalent_to")} ${CurrencyFormatter.format(
                          (Number(flight.passengers[0].discount_credit) / 100) *
                            Number(flight.passengers[0].price)
                        )})`}
                      </Text>
                    </View>
                  )}
                {flight.passengers[0].discount !== null &&
                  flight.passengers[0].discount !== undefined &&
                  flight.passengers[0].discount !== "0.00" && (
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        justifyContent: "space-between",
                        marginTop: "12px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {t("discount")}{" "}
                      </Text>
                      <Text style={documentStyle.Paragraph200Heavy}>
                        {flight.passengers[0].discount} %
                      </Text>
                    </View>
                  )}
              </View>
            </View>
          )}

          {/* End First Passenger */}

          {/* Second Passenger */}
          {flight?.passengers &&
            flight?.passengers?.length > 1 &&
            flight?.passengers?.slice(1).map((passenger, idx) => (
              <View
                wrap={false}
                style={documentStyle.flightQuotation}
                key={passenger.id}
              >
                <Text
                  style={{
                    ...documentStyle.Paragraph100Heavy,
                    color: COLORS.TextPrimaryBlack,
                    fontWeight: 400,
                    display: "flex",
                    flexDirection:
                      currentLanguage === "en" ? "row" : "row-reverse",
                  }}
                >
                  {t("Passenger")} {idx + 2}
                </Text>
                <View style={{ marginTop: "12px" }}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={documentStyle.Paragraph200Heavy}>
                      {passenger.client_name}
                    </Text>
                    <Text style={documentStyle.Paragraph200Heavy}>
                      {passenger.is_child ? t("Child") : t("Adult")}
                    </Text>
                  </View>{" "}
                  {passenger.age !== null &&
                    passenger.age !== undefined &&
                    Number(passenger.age) > 0 && (
                      <View
                        style={{
                          display: "flex",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                          justifyContent: "space-between",
                          marginTop: "12px",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {t("Age")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {passenger.age ? passenger.age : 0}
                        </Text>
                      </View>
                    )}
                  <View
                    style={{
                      display: "flex",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Text style={documentStyle.Paragraph200Heavy}>
                      {t("Price")}
                    </Text>
                    <Text style={documentStyle.Paragraph200Heavy}>
                      {CurrencyFormatter.format(Number(passenger?.price))}
                    </Text>
                  </View>
                  {passenger.taxes_fee !== null &&
                    passenger.taxes_fee !== undefined &&
                    String(passenger.taxes_fee) !== "0.00" && (
                      <View
                        style={{
                          display: "flex",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                          justifyContent: "space-between",
                          marginTop: "12px",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {t("Taxes_Fee")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {CurrencyFormatter.format(
                            Number(passenger?.taxes_fee)
                          )}
                        </Text>
                      </View>
                    )}
                  {passenger.discount_credit !== null &&
                    passenger.discount_credit !== undefined &&
                    passenger.discount_credit > 0 && (
                      <View
                        style={{
                          display: "flex",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                          justifyContent: "space-between",
                          marginTop: "12px",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {t("Kudos_Point")}{" "}
                        </Text>
                        <Text style={documentStyle.Paragraph200Light}>
                          {Number(passenger.discount_credit)} %{" "}
                          {`(${t("equivalent_to")} ${CurrencyFormatter.format(
                            (Number(passenger.discount_credit) / 100) *
                              Number(passenger.price)
                          )})`}
                        </Text>
                      </View>
                    )}
                  {passenger.discount !== null &&
                    passenger.discount !== undefined &&
                    passenger.discount !== "0.00" && (
                      <View
                        style={{
                          display: "flex",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                          justifyContent: "space-between",
                          marginTop: "12px",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {t("discount")}{" "}
                        </Text>
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {passenger.discount} %
                        </Text>
                      </View>
                    )}
                </View>
              </View>
            ))}
        </View>

        <View style={documentStyle.flightQuotation}>
          {flight?.routes.map((data, index) => {
            const isOdd = index % 2 !== 0;
            const backgroundColor = isOdd ? COLORS.Neutral300 : "transparent";

            return (
              <View key={data.id}>
                <View
                  wrap={false}
                  style={{
                    backgroundColor,
                    borderRadius: "10px",
                    border: `1px solid ${COLORS.Neutral300}`,
                    marginTop: "12px",
                    padding: "10px",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                    }}
                  >
                    <Text
                      style={{
                        ...documentStyle.Paragraph100Heavy,
                        textDecoration: "underline",
                        color: "#1581E2",
                        textTransform: "capitalize",
                      }}
                    >
                      {data.is_transit
                        ? t("Transit_Route")
                        : t("Flight_Details")}
                    </Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                    }}
                  >
                    <Text
                      style={{
                        ...documentStyle.Paragraph100Heavy,
                        color: COLORS.TextSecondaryBlack,
                        fontWeight: 200,
                        marginTop: "12px",
                        fontSize: "12px",
                      }}
                    >
                      {data.departure_city_name}({data.departure_airport}){" "}
                      {t("To")} {data.arrival_city_name} ({data.arrival_airport}
                      )
                    </Text>
                  </View>

                  <View
                    style={{
                      display: "flex",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        gap: "3px",
                      }}
                    >
                      <View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection:
                              currentLanguage === "en" ? "row" : "row-reverse",
                          }}
                        >
                          <Text
                            style={{
                              ...documentStyle.Paragraph100Heavy,
                              color: COLORS.TextSecondaryBlack,
                              fontWeight: 200,
                              marginTop: "12px",
                              fontSize: "12px",
                            }}
                          >
                            {dayjs(data?.departure_datetime).format("HH:mm")}{" "}
                            {data?.departure_airport_code}
                            ______
                          </Text>
                        </View>
                        <Text
                          style={{
                            ...documentStyle.Paragraph200Heavy,
                            fontSize: "10px",
                          }}
                        >
                          {dayjs(data.departure_datetime).format("DD/MM/YYYY")}
                        </Text>
                        {data.departure_terminal !== null &&
                          data.departure_terminal !== undefined &&
                          data.departure_terminal !== "" && (
                            <Text
                              style={{
                                ...documentStyle.Paragraph200Heavy,
                                fontSize: "10px",
                              }}
                            >
                              Dep. Terminal: {data.departure_terminal}
                            </Text>
                          )}
                      </View>

                      <View>
                        <View
                          style={{
                            display: "flex",
                            flexDirection:
                              currentLanguage === "en" ? "row" : "row-reverse",
                          }}
                        >
                          <Text
                            style={{
                              ...documentStyle.Paragraph100Heavy,
                              color: COLORS.TextSecondaryBlack,
                              fontWeight: 200,
                              marginTop: "12px",
                              fontSize: "12px",
                            }}
                          >
                            {dayjs(data.arrival_datetime).format("HH:mm")}{" "}
                            {data.arrival_airport_code}
                          </Text>
                        </View>
                        <Text
                          style={{
                            ...documentStyle.Paragraph200Heavy,
                            fontSize: "10px",
                          }}
                        >
                          {dayjs(data.arrival_datetime).format("DD/MM/YYYY")}
                        </Text>
                        {data.arrival_terminal !== null &&
                          data.arrival_terminal !== undefined &&
                          data.arrival_terminal !== "" && (
                            <Text
                              style={{
                                ...documentStyle.Paragraph200Heavy,
                                fontSize: "10px",
                              }}
                            >
                              Arr. Terminal: {data.arrival_terminal}
                            </Text>
                          )}
                      </View>
                    </View>
                  </View>
                  {data.is_transit && (
                    <View style={{ marginTop: "12px" }}>
                      <Text
                        style={{
                          ...documentStyle.Paragraph100Heavy,
                          color: COLORS.TextSecondaryBlack,
                          fontWeight: 200,
                          fontSize: "12px",
                        }}
                      >
                        {t("Transit_Duration")}
                      </Text>
                      <Text
                        style={{
                          ...documentStyle.Paragraph200Heavy,
                          color: "#1581E2",
                          fontSize: "10px",
                        }}
                      >
                        {" "}
                        {data.transit_duration_days} {t("Days")}{" "}
                        {data.transit_duration_hours} {t("Hours")}{" "}
                        {data.transit_duration_minutes} {t("Min")}
                      </Text>
                    </View>
                  )}

                  <View
                    style={{
                      marginTop: "12px",
                    }}
                  >
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {t("Flight_Number")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {` ${currentLanguage === "en" ? ":" : ""} ${
                            data.flight_number
                          } ${currentLanguage === "ar" ? ":" : ""} `}
                          {`(${data.airline_code})`}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {t("Duration")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Heavy}>
                          {` ${currentLanguage === "en" ? ":" : ""} ${
                            data.duration
                          } ${currentLanguage === "ar" ? ":" : ""} `}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Light}>
                          {t("Cabin")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Light}>
                          {` ${currentLanguage === "en" ? ":" : ""} ${
                            data.cabin
                          } ${currentLanguage === "ar" ? ":" : ""} `}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Light}>
                          {t("Baggage")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Light}>
                          {` ${currentLanguage === "en" ? ":" : ""} ${
                            data.baggage_allowance
                          } ${currentLanguage === "ar" ? ":" : ""} `}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                      }}
                    >
                      <View
                        style={{
                          display: "flex",
                          flexDirection:
                            currentLanguage === "en" ? "row" : "row-reverse",
                        }}
                      >
                        <Text style={documentStyle.Paragraph200Light}>
                          {t("Operated_By")}
                        </Text>
                        <Text style={documentStyle.Paragraph200Light}>
                          {` ${currentLanguage === "en" ? ":" : ""} ${
                            data.airline
                          } ${currentLanguage === "ar" ? ":" : ""} `}
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      marginTop: "16px",
                      display: "flex",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        ...documentStyle.Paragraph100Heavy,
                        color: data.cancelation_before
                          ? COLORS.Success900
                          : COLORS.Danger700,
                      }}
                    >
                      {data.cancelation_before
                        ? t("Refundable")
                        : t("Non_Refundable")}
                    </Text>

                    {data.cancelation_before && (
                      <Text style={documentStyle.Paragraph200Light}>
                        {dayjs(data.cancelation_before_datetime).format(
                          "DD/MM/YYYY"
                        )}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
            );
          })}
        </View>
        {!isConfirmation && (
          <View style={documentStyle.flightQuotation}>
            <View wrap={false}>
              <View
                style={{
                  border: `1px solid ${COLORS.Neutral300}`,
                  borderRadius: "10px",
                  padding: "10px",
                  display: "flex",
                  flexDirection:
                    currentLanguage === "en" ? "row" : "row-reverse",
                }}
              >
                <Text
                  style={{
                    ...documentStyle.Paragraph100Heavy,
                    color: "#1581E2",
                  }}
                >
                  {t("Price")}
                </Text>
              </View>
              {Number(flight?.total_price_before_discount) &&
                Number(flight?.discount_credit_total_amount) && (
                  <View
                    style={{
                      display: "flex",
                      flexDirection:
                        currentLanguage === "en" ? "row" : "row-reverse",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <Text
                      style={{
                        ...documentStyle.Paragraph200Light,
                      }}
                    >
                      {t("Total_Price_Before_Discount")}
                    </Text>
                    <View
                      style={{
                        display: "flex",
                        flexDirection:
                          currentLanguage === "en" ? "row" : "row-reverse",
                        gap: "3px",
                      }}
                    >
                      <Text style={documentStyle.Paragraph200Light}>
                        {CurrencyFormatter.format(
                          Number(flight?.total_price_before_discount)
                        )}
                      </Text>
                    </View>
                  </View>
                )}

              {Number(flight?.total_discount_amount) !== 0 && (
                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection:
                      currentLanguage === "en" ? "row" : "row-reverse",
                    marginTop: "8px",
                  }}
                >
                  <Text style={documentStyle.Paragraph200Heavy}>
                    {t("Total_Discount_Amount")}
                  </Text>
                  <Text style={documentStyle.Paragraph200Light}>
                    {CurrencyFormatter.format(
                      Number(flight?.total_discount_amount)
                    )}
                  </Text>
                </View>
              )}
              {Number(flight?.amount_deducted_from_wallet) !== 0 && (
                <View
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection:
                      currentLanguage === "en" ? "row" : "row-reverse",
                    marginTop: "8px",
                  }}
                >
                  <Text style={documentStyle.Paragraph200Heavy}>
                    {t("Amount_Deducted_From_Wallet")}
                  </Text>
                  <Text style={documentStyle.Paragraph200Light}>
                    {CurrencyFormatter.format(
                      Number(flight?.amount_deducted_from_wallet)
                    )}
                  </Text>
                </View>
              )}

              <View
                style={{
                  display: "flex",
                  flexDirection:
                    currentLanguage === "en" ? "row" : "row-reverse",
                  justifyContent: "space-between",
                  marginTop: "12px",
                }}
              >
                <Text
                  style={{
                    ...documentStyle.Paragraph200Heavy,
                  }}
                >
                  {t("Total")}
                </Text>
                <View
                  style={{
                    display: "flex",
                    flexDirection:
                      currentLanguage === "en" ? "row" : "row-reverse",
                    gap: "3px",
                  }}
                >
                  <Text
                    style={{
                      ...documentStyle.Paragraph200Light,
                      color: COLORS.Success900,
                      fontFamily: "SF-Pro-Text-Heavy",
                    }}
                  >
                    {CurrencyFormatter.format(Number(flight?.total))}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}

        {!isConfirmation && flight?.payment_link && (
          <View
            wrap={false}
            style={{
              border: `1px solid ${COLORS.Neutral300}`,
              borderRadius: "10px",
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              marginTop: "8px",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: currentLanguage === "en" ? "row" : "row-reverse",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  ...documentStyle.Paragraph100Heavy,
                  fontSize: "12px",
                }}
              >
                {t("payment_link")}
              </Text>
              <Link
                src={flight.payment_link}
                style={{
                  ...documentStyle.Paragraph100Heavy,
                  textDecoration: "underline",
                  color: "#1581E2",
                  textTransform: "capitalize",
                }}
              >
                {t("open_link")}
              </Link>
            </View>
          </View>
        )}
      </View>
    </View>
  );
}
