import Button from "components/general/Button";
import InstructionNote from "components/general/InstructionNote";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import { Rate } from "antd";
import SelectionInput from "components/inputs/SelectionInput";
import PageTitle from "components/general/PageTitle";
import Spinner from "components/general/Spinner";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useGetAccommodationQuery,
  useUpdateAccommodationMutation,
} from "apis/services/accommodations";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import showToast from "utils/showToast";
import { useEffect, useState } from "react";
import { Country, City } from "apis/types/accommodations";
import { useGetCountriesQuery } from "apis/services/countries";
import { debounce } from "lodash";
import { useGetCitesQuery } from "apis/services/cities";
import styles from "./styles.module.scss";

const serviceType = [
  { value: "airlines", label: "Airlines" },
  { value: "airports", label: "Airports" },
  { value: "cabins", label: "Cabins" },
  { value: "airport_lounges", label: "Airport Lounges" },
  { value: "car_rental", label: "Car Rental" },
  { value: "train", label: "Train" },
  { value: "accommodations", label: "Accommodations" },
];

const accommodationType = [
  { value: "hotel", label: "Hotel" },
  { value: "resort", label: "Resort" },
  { value: "apartment", label: "Apartment" },
  { value: "aparthotel", label: "Aparthotel" },
  { value: "serviced_apartment", label: "Serviced Apartment" },
];

const schema = yup.object({
  service_type: yup.string().required("Accommodation field is required"),
  accomodation_type: yup
    .string()
    .required("Accomodation type field is required"),
  country: yup
    .number()
    .moreThan(0, "Country field is required")
    .required("Country field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  city: yup
    .number()
    .moreThan(0, "City field is required")
    .required("City field is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  address: yup.string().min(1).max(250).required("Address field is required"),
  name: yup
    .string()
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be at most 50 characters")
    .required("Name is required!"),
  rating: yup
    .number()
    .min(1, "Rating must be between 1 and 5")
    .max(5, "Rating must be between 1 and 5")
    .required("Rating is required!")
    .typeError("Rating must be between 1 and 5")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  place_policies: yup
    .string()
    .min(1)
    .max(250)
    .required("Policies field is required"),
});
export default function EditAccomodation() {
  const { t } = useAutoCompleteTranslation();
  // const [targetCountry, setTargetCountry] = useState(0);
  // const [targetCity, setTargetCity] = useState(0);

  const { accomodationId } = useParams();
  const { pathname } = useLocation();
  const [countriesSearch, setCountriesSearch] = useState("");
  const [CitiesSearch, setCitiesSearch] = useState("");

  type formData = yup.InferType<typeof schema>;
  const navigate = useNavigate();
  const { data: accommodation, isLoading: isAccommodationLoading } =
    useGetAccommodationQuery(accomodationId || "");

  const [updateAccommodation, { isLoading: isUpdateLoading }] =
    useUpdateAccommodationMutation();

  const {
    data: countries,
    isLoading: isCountriesLoading,
    isFetching: isCountriesFetching,
  } = useGetCountriesQuery({
    search: countriesSearch,
  });
  const {
    data: cities,
    isLoading: isCitiesLoading,
    isFetching: isCitiesFetching,
  } = useGetCitesQuery({
    search: CitiesSearch,
  });

  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm<formData>({
    resolver: yupResolver(schema),
    defaultValues: {
      service_type: "",
      accomodation_type: "",
      country: 0,
      city: 0,
      address: "",
      name: "",
      rating: 1,
      place_policies: "",
    },
    mode: "onSubmit",
  });

  const onSubmit = (data: formData) => {
    if (accomodationId) {
      updateAccommodation([accomodationId, data])
        .unwrap()
        .then(() => {
          navigate("/services");
          showToast({
            toastType: "success",
            title: `Changes have been saved successfully!`,
            showCloseIcon: true,
          });
        });
    }
  };

  useEffect(() => {
    if (accommodation) {
      setValue("service_type", accommodation.service_type);
      setValue("accomodation_type", accommodation.accomodation_type);
      setValue("country", (accommodation.country as Country).id);
      setValue("city", (accommodation.city as City).id);
      setCitiesSearch((accommodation.city as City).name);
      setCountriesSearch((accommodation.country as Country).name);
      setValue("address", accommodation.address);
      setValue("name", accommodation.name);
      setValue("rating", accommodation.rating);
      setValue("place_policies", accommodation.place_policies);
      const updatedUrl = `${pathname}?idName=${accommodation?.name}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [accommodation]);
  return (
    <section>
      <PageTitle> {t("Edit_Accommodation")} </PageTitle>
      <div className={styles.addNewAccommodation}>
        {isAccommodationLoading || isUpdateLoading ? (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.customWidth}
          >
            {/* Service Type */}
            <div className="d-flex flex-column gap-1">
              <Controller
                control={control}
                name="service_type"
                render={({ field }) => {
                  return (
                    <SelectionInput
                      size="large"
                      label={`${t("Service_Type")}`}
                      placeholder={`${t("Accommodations")}`}
                      options={serviceType}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      value={field.value === "" ? undefined : field.value}
                      status={errors.service_type ? "error" : ""}
                    />
                  );
                }}
              />
              {errors.service_type && errors.service_type.message && (
                <div className="w-100">
                  <InstructionNote instructionType="danger">
                    <span className="Paragraph300Light">
                      {errors.service_type.message}
                    </span>
                  </InstructionNote>
                </div>
              )}
            </div>

            {/* Accommodation Type */}
            <div className="d-flex flex-column gap-1">
              <Controller
                control={control}
                name="accomodation_type"
                render={({ field }) => {
                  return (
                    <SelectionInput
                      size="large"
                      label={`${t("Accommodation_Type")}`}
                      placeholder={`${t("Accommodation_type")}`}
                      options={accommodationType}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      value={field.value === "" ? undefined : field.value}
                      status={errors.accomodation_type ? "error" : ""}
                    />
                  );
                }}
              />
              {errors.accomodation_type && errors.accomodation_type.message && (
                <div className="w-100">
                  <InstructionNote instructionType="danger">
                    <span className="Paragraph300Light">
                      {errors.accomodation_type.message}
                    </span>
                  </InstructionNote>
                </div>
              )}
            </div>

            {/* Country */}
            <div className="d-flex flex-column gap-1">
              <Controller
                control={control}
                name="country"
                render={({ field }) => {
                  return (
                    <SelectionInput
                      isLoading={isCountriesLoading || isCountriesFetching}
                      size="large"
                      label={`${t("Country")}`}
                      placeholder={`${t("country")}`}
                      options={
                        countries?.results?.map((data: any) => ({
                          value: String(data.id),
                          label: data.name,
                        })) ?? []
                      }
                      showSearch
                      onSearch={debounce((e) => setCountriesSearch(e), 500)}
                      onFilter={false}
                      onChange={field.onChange}
                      value={
                        field.value === 0 ? undefined : String(field.value)
                      }
                      status={errors.country ? "error" : ""}
                    />
                  );
                }}
              />
              {errors.country && errors.country.message && (
                <div className="w-100">
                  <InstructionNote instructionType="danger">
                    <span className="Paragraph300Light">
                      {errors.country.message}
                    </span>
                  </InstructionNote>
                </div>
              )}
            </div>

            {/* City */}
            <div className="d-flex flex-column gap-1">
              <Controller
                control={control}
                name="city"
                render={({ field }) => {
                  return (
                    <SelectionInput
                      isLoading={isCitiesLoading || isCitiesFetching}
                      size="large"
                      label={`${t("City")}`}
                      placeholder={`${t("City")}`}
                      options={
                        cities?.results?.map((data: any) => ({
                          value: String(data.id),
                          label: data.name,
                        })) ?? []
                      }
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      showSearch
                      onFilter={false}
                      onSearch={debounce((e) => setCitiesSearch(e), 500)}
                      value={
                        field.value === 0 ? undefined : String(field.value)
                      }
                      status={errors.city ? "error" : ""}
                    />
                  );
                }}
              />
              {errors.city && errors.city.message && (
                <div className="w-100">
                  <InstructionNote instructionType="danger">
                    <span className="Paragraph300Light">
                      {errors.city.message}
                    </span>
                  </InstructionNote>
                </div>
              )}
            </div>

            {/* Name */}
            <div className="d-flex flex-column gap-1">
              <ControlledTextInput
                control={control}
                name="name"
                size="large"
                label={`${t("NAME")}`}
                placeholder={`${t("name")}`}
                errorMsg={errors?.name?.message}
              />
            </div>

            {/* Address */}
            <div className="d-flex flex-column gap-1">
              <ControlledTextInput
                control={control}
                name="address"
                size="large"
                label={`${t("Address")}`}
                placeholder={`${t("address")}`}
                errorMsg={errors?.address?.message}
              />
            </div>

            {/* Rate */}
            <div className="d-flex flex-column gap-1">
              <Controller
                control={control}
                name="rating"
                render={({ field }) => {
                  return (
                    <div>
                      <span className="d-block mb-2 Label100">
                        {t("Rating")}
                      </span>
                      <div className={styles.rateContainer}>
                        <span className="Paragraph200Light">{field.value}</span>
                        <Rate
                          defaultValue={field.value}
                          value={field.value}
                          onChange={field.onChange}
                          className="w-100"
                        />
                      </div>
                    </div>
                  );
                }}
              />
              {errors.rating && errors.rating.message && (
                <div className="w-100">
                  <InstructionNote instructionType="danger">
                    <span className="Paragraph300Light">
                      {errors.rating.message}
                    </span>
                  </InstructionNote>
                </div>
              )}
            </div>

            {/* Place policies */}
            <div className="d-flex flex-column gap-1">
              <ControlledTextInput
                control={control}
                name="place_policies"
                type="textarea"
                label={t("Place_Policies")}
                placeholder={`${t("Place_Policies")}`}
                inputStyle={styles.formTextArea}
                errorMsg={errors?.place_policies?.message}
              />
            </div>

            <Button
              styleType="Primary"
              btnClassName={`${styles.saveContainer}  Button100`}
              type="submit"
              size="large"
            >
              {t("Save")}
            </Button>
          </form>
        )}
      </div>
    </section>
  );
}
