import { useEffect, useState } from "react";
import PageTitle from "components/general/PageTitle";
import Button from "components/general/Button";
import SelectionInput from "components/inputs/SelectionInput";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InstructionNote from "components/general/InstructionNote";
import dayjs from "dayjs";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetCountriesQuery } from "apis/services/countries";
import { Country } from "apis/types/countries";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Spinner from "components/general/Spinner";
import {
  useAddOrEditQuotationsMutation,
  useGetGeneralQuotationQuery,
  useUpdateGeneralQuotationMutation,
} from "apis/services/generalQuotations";
import { useGetCurrenciesQuery } from "apis/services/currency";
import { debounce } from "lodash";
import {
  ACCOMODATION_SAMPLE,
  FLIGHT_SAMPLE,
  GENERAL_SAMPLE,
} from "constants/QuotationsSamples";
import {
  useGetCustomerQuery,
  useUpdateCustomerMutation,
} from "apis/services/customers";
import AccomodationFields, {
  accommodationSchema,
} from "./editFields/Accomodation";
import FlightFields, { flightSchema } from "./editFields/Flight";
import GeneralFields, { generalSchema } from "./editFields/General";
import styles from "./styles.module.scss";

const quotationOptionsTypes = [
  { value: "accomodation", label: "Accomodation" },
  { value: "flight", label: "Flight" },
  { value: "general", label: "General" },
];

const quotationTypeSampleTarget: any = {
  accomodation: ACCOMODATION_SAMPLE,
  flight: FLIGHT_SAMPLE,
  general: GENERAL_SAMPLE,
};

export const generalQuotationSchema = yup.object({
  agentName: yup.string().required("Agent name is rquired!"),
  quotationId: yup.string().required("Quotation id is rquired!"),
  customerId: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .not([0], "Customer id is rquired!")
    .required("Customer id is rquired!"),
  country: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .not([0], "Country is rquired!")
    .required("Country is rquired!"),
  currency: yup.string().required("Currency is required"),
  customerNotes: yup.string(),
  quotations: yup
    .array()
    .of(
      yup.lazy((item) => {
        if (item?.quotation_type === "accommodation")
          return accommodationSchema;
        if (item?.quotation_type === "general") return generalSchema;
        return flightSchema;
      })
    )
    .min(1, "At least one quotation type is required")
    .required("Quotation is required"),
});

export type generalQuotationTypes = yup.InferType<
  typeof generalQuotationSchema
>;

export default function EditQuotation() {
  const { t } = useAutoCompleteTranslation();
  const { quotationId } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [countriesSearch, setCountriesSearch] = useState("");

  const { data: quotationData, isLoading: isQuotationLoading } =
    useGetGeneralQuotationQuery(quotationId ?? "");

  const [isQuotationTypeListVisible, setIsQuotationTypeListVisible] =
    useState(false);

  const [customersData, setCustomersData] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    if (quotationData) {
      setCustomersData([
        {
          value: String(quotationData.customer.id),
          label: `${quotationData.customer.mobile_code} ${
            quotationData.customer.mobile.split(
              quotationData.customer.mobile_code
            )[1]
          }`,
        },
      ]);
    }
  }, [quotationData]);

  const { data: currenciesData, isLoading: isCurrenciesLoading } =
    useGetCurrenciesQuery();

  const {
    data: countries,
    isLoading: isCountriesLoading,
    isFetching: isCountriesFetching,
  } = useGetCountriesQuery({ search: countriesSearch });

  const [updateGeneralQuotation, { isLoading: isAddGeneralLoading }] =
    useUpdateGeneralQuotationMutation();
  const [addQuotations, { isLoading: isAddQuotationsLoading }] =
    useAddOrEditQuotationsMutation();

  const { data: customerData } = useGetCustomerQuery(
    String(quotationData?.customer.id),
    {
      skip: !quotationData,
    }
  );

  const [updateCustomer, { isLoading: isUpdateCustomerLoading }] =
    useUpdateCustomerMutation();

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    setValue,
  } = useForm<generalQuotationTypes>({
    resolver: yupResolver(generalQuotationSchema),
    defaultValues: {
      agentName:
        (quotationData?.agent?.first_name &&
          `${quotationData?.agent?.first_name} ${quotationData?.agent?.last_name}`) ||
        quotationData?.agent?.username,
      quotationId: String(quotationData?.id),
      currency: quotationData?.currency,
      customerId: quotationData?.customer.id,
      country: quotationData?.country.id,
      customerNotes: "",
      quotations: [],
    },
    mode: "onSubmit",
  });

  const {
    fields: quotationFields,
    append,
    replace,
  } = useFieldArray({
    control,
    name: "quotations",
  });

  useEffect(() => {
    if (customerData?.notes) {
      setValue("customerNotes", customerData?.notes);
    }
  }, [customerData]);

  useEffect(() => {
    if (quotationData) {
      setValue(
        "agentName",
        (quotationData?.agent?.first_name &&
          `${quotationData?.agent?.first_name} ${quotationData?.agent?.last_name}`) ||
          quotationData?.agent?.username
      );
      setValue("quotationId", quotationData.quotation_id);
      setValue("currency", quotationData.currency);
      setValue("customerId", quotationData.customer.id);
      setCountriesSearch(quotationData?.country?.name);
      setValue("country", quotationData.country.id);
      append([
        ...quotationData.accomodation_quotations.map((item) => ({
          ...item,
          parameters:
            item?.parameters?.length > 0
              ? item?.parameters
              : [{ name: "", value: "", order: "" }],
          accommodation_type: item.type,
          rooms: item.rooms
            ? [
                ...item.rooms.map((room) => ({
                  ...room,
                  price: Number(room.price),
                  discount: Number(room.discount),
                  adults: Number(room.adults),
                  children: Number(room.children),
                  children_age: room.children_age,
                  room_type: room.type ?? "",
                  taxes_fee: Number(room.taxes_fee),
                })),
              ]
            : [],
          cancelation_before_date: item.cancelation_before_date
            ? dayjs(item.cancelation_before_date).format("DD/MM/YYYY")
            : "",
          supplier: item.supplier ?? 0,
          supplier_name: item.supplier_name ?? "",
          payment_date_for_supplier: item?.payment_date_for_supplier
            ? dayjs(item?.payment_date_for_supplier).format("DD/MM/YYYY")
            : item?.payment_date_for_supplier,
          quotation_type: "accommodation",
          city_name_new: item?.city_name ? item?.city_name : "",
          check_in_date: dayjs(item?.check_in_date).format("DD/MM/YYYY"),
          check_out_date: dayjs(item?.check_out_date).format("DD/MM/YYYY"),
        })),
      ]);
      append([
        ...quotationData.flight_quotations.map((item) => ({
          ...item,
          parameters:
            item?.parameters?.length > 0
              ? item?.parameters
              : [{ name: "", value: "", order: "" }],
          quotation_type: "flight",
          supplier_price: Number(item?.supplier_price),
          payment_date_for_supplier: item?.payment_date_for_supplier
            ? dayjs(item?.payment_date_for_supplier).format("DD/MM/YYYY")
            : item?.payment_date_for_supplier,
          passengers: item.passengers.map((passenger) => ({
            ...passenger,
          })),
          routes: item.routes.map((route) => ({
            ...route,
            departure_date: dayjs(route?.departure_datetime).format(
              "DD/MM/YYYY"
            ),
            departure_time: dayjs(route?.departure_datetime).format("HH:mm"),
            arrival_date: dayjs(route?.arrival_datetime).format("DD/MM/YYYY"),
            arrival_time: dayjs(route?.arrival_datetime).format("HH:mm"),
            cancelation_before_date: route?.cancelation_before_datetime
              ? dayjs(route?.cancelation_before_datetime).format("DD/MM/YYYY")
              : "",
            cancelation_before_time: route?.cancelation_before_datetime
              ? dayjs(route?.cancelation_before_datetime).format("HH:mm")
              : "",
          })),
        })),
      ]);
      append([
        ...quotationData.quotations.map((item) => ({
          ...item,
          quotation_type: "general",
          wallet_activation_date: dayjs(item?.wallet_activation_date).format(
            "DD/MM/YYYY"
          ),
          cancelation_before_date: item.cancelation_before_date
            ? dayjs(item.cancelation_before_date).format("DD/MM/YYYY")
            : "",
          payment_date_for_supplier: item?.payment_date_for_supplier
            ? dayjs(item?.payment_date_for_supplier).format("DD/MM/YYYY")
            : item?.payment_date_for_supplier,
          supplier_price: Number(item?.supplier_price),
        })),
      ]);
      const updatedUrl = `${pathname}?idName=${quotationData?.agent?.username}`;
      navigate(updatedUrl, { replace: true });
    }

    return () => replace([]);
  }, [quotationData]);

  const onSubmit = (data: generalQuotationTypes) => {
    if (quotationId && quotationData) {
      const generalQuotationData = {
        id: quotationId,
        quotation_id: data.quotationId,
        agent: quotationData.agent.id!,
        customer: data.customerId,
        country: data.country,
        currency: data.currency,
      };

      updateGeneralQuotation([quotationId, generalQuotationData])
        .unwrap()
        .then((res: any) => {
          const quotationsWithId = data.quotations.map((item: any) => {
            if (item?.quotation_type === "accommodation")
              return {
                ...item,
                parameters: item?.parameters.filter((param: any) => param.name),
                isAccomodationNew: undefined,
                general_quotation: res.id,
                type: item.accommodation_type,
                rooms: item?.rooms?.map((room: any) => ({
                  type: room.room_type,
                  price: room.price,
                  discount: room.discount ?? 0,
                  adults: String(room.adults),
                  children: String(room.children),
                  children_age: String(room.children_age),
                  discount_credit: room.discount_credit ?? 0,
                  taxes_fee: String(room.taxes_fee) ?? 0,
                })),

                name:
                  item.accommodation_service_id === 0 ? item.name : undefined,

                city_name_new:
                  item.city === null ? item.city_name_new : undefined,

                check_in_date: dayjs(item?.check_in_date, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ),

                check_out_date: dayjs(
                  item?.check_out_date,
                  "DD/MM/YYYY"
                ).format("YYYY-MM-DD"),

                cancelation_before_date: item?.cancelation_before_date
                  ? dayjs(item?.cancelation_before_date, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                  : undefined,

                supplier: item?.supplier === 0 ? undefined : item?.supplier,

                payment_date_for_supplier: item?.payment_date_for_supplier
                  ? dayjs(item?.payment_date_for_supplier, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                  : undefined,

                payment_link: undefined,
                no_of_payment_links_generated: undefined,
                total: undefined,
              };

            if (item?.quotation_type === "flight")
              return {
                ...item,
                parameters: item?.parameters.filter((param: any) => param.name),
                general_quotation: res.id,
                routes: item.routes.map((route: any) => ({
                  ...route,
                  departure_datetime: `${dayjs(
                    route?.departure_date,
                    "DD/MM/YYYY"
                  ).format("YYYY-MM-DD")}T${route?.departure_time}`,
                  arrival_datetime: `${dayjs(
                    route?.arrival_date,
                    "DD/MM/YYYY"
                  ).format("YYYY-MM-DD")}T${route?.arrival_time}`,
                  cancelation_before_datetime: route?.cancelation_before
                    ? `${dayjs(
                        route?.cancelation_before_date,
                        "DD/MM/YYYY"
                      ).format("YYYY-MM-DD")}T${route?.cancelation_before_time}`
                    : undefined,
                  arrival_city:
                    route.arrival_city === 0 || route.arrival_city === null
                      ? null
                      : route.arrival_city,
                  departure_city:
                    route.departure_city === 0 || route.departure_city
                      ? null
                      : route.departure_city,
                  departure_date: undefined,
                  departure_time: undefined,
                  arrival_date: undefined,
                  arrival_time: undefined,
                  cancelation_before_date: undefined,
                  cancelation_before_time: undefined,
                })),
                passengers: item.passengers.map((passenger: any) => ({
                  ...passenger,
                })),

                supplier: item?.supplier === 0 ? undefined : item?.supplier,

                payment_date_for_supplier: item?.payment_date_for_supplier
                  ? dayjs(item?.payment_date_for_supplier, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                  : undefined,

                payment_link: undefined,
                no_of_payment_links_generated: undefined,
                total: undefined,
              };

            if (item?.quotation_type === "general")
              return {
                ...item,
                general_quotation: res.id,

                wallet_activation_date: dayjs(
                  item?.wallet_activation_date,
                  "DD/MM/YYYY"
                ).format("YYYY-MM-DD"),

                parameters: item.parameters.map((param: any) => ({
                  ...param,
                  quotation: undefined,
                })),

                cancelation_before_date: item?.cancelation_before_date
                  ? dayjs(item?.cancelation_before_date, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                  : undefined,

                supplier: item?.supplier === 0 ? undefined : item?.supplier,

                payment_date_for_supplier: item?.payment_date_for_supplier
                  ? dayjs(item?.payment_date_for_supplier, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                  : undefined,

                payment_link: undefined,
                no_of_payment_links_generated: undefined,
                total: undefined,
              };

            return item;
          });

          addQuotations(quotationsWithId)
            .unwrap()
            .then(() => {
              if (
                dirtyFields.customerNotes &&
                customerData &&
                customerData.id
              ) {
                updateCustomer([
                  String(customerData.id),
                  { notes: data.customerNotes },
                ])
                  .then(() => {
                    navigate("/quotations");
                    showToast({
                      toastType: "success",
                      title: `New quotation has been added successfully!`,
                      showCloseIcon: true,
                    });
                  })
                  .catch(() => {
                    showToast({
                      toastType: "danger",
                      title: "Updating customer notes failed!",
                      showCloseIcon: true,
                    });
                    showToast({
                      toastType: "success",
                      title: `New quotation has been added successfully!`,
                      showCloseIcon: true,
                    });
                    navigate("/quotations");
                  });
              } else {
                navigate("/quotations");
                showToast({
                  toastType: "success",
                  title: `New quotation has been added successfully!`,
                  showCloseIcon: true,
                });
              }
            })
            .catch(handleErrors);
        })
        .catch(handleErrors);
    }
  };

  return (
    <section>
      <PageTitle>{t("Edit_New_Quotation")}</PageTitle>
      <div className={styles.container}>
        {isQuotationLoading ||
        isAddGeneralLoading ||
        isAddQuotationsLoading ||
        isUpdateCustomerLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.general}>
              <ControlledTextInput
                control={control}
                name="agentName"
                containerStyle="w-100"
                i18nLabelKey="Agent_Name"
                status="disabled"
              />

              <div className="d-flex flex-column gap-1">
                <ControlledTextInput
                  control={control}
                  name="quotationId"
                  i18nLabelKey="Quotation_ID"
                  i18nPlaceholder="Quotation_ID"
                  status="disabled"
                  errorMsg={errors?.quotationId?.message}
                />
              </div>
              <div className="d-flex gap-4">
                <div className="w-100 d-flex flex-column gap-1">
                  <Controller
                    control={control}
                    name="customerId"
                    render={({ field }) => (
                      <SelectionInput
                        isDisabled
                        value={String(field.value)}
                        options={customersData}
                        i18nLabelKey="Customer_ID"
                      />
                    )}
                  />
                  {errors?.customerId?.message && (
                    <div className="w-100">
                      <InstructionNote instructionType="danger">
                        <span className="Paragraph300Light">
                          {/* @ts-ignore */}
                          {errors?.customerId?.message}
                        </span>
                      </InstructionNote>
                    </div>
                  )}
                </div>
                <div className="w-100 d-flex flex-column gap-1">
                  <Controller
                    control={control}
                    name="country"
                    render={({ field }) => (
                      <SelectionInput
                        isLoading={isCountriesLoading || isCountriesFetching}
                        value={
                          field.value === 0 ? undefined : String(field.value)
                        }
                        onChange={field.onChange}
                        options={
                          countries?.results?.map((item: Country) => ({
                            value: String(item.id),
                            label: item.name,
                          })) ?? []
                        }
                        showSearch
                        onSearch={debounce((e) => setCountriesSearch(e), 500)}
                        onFilter={false}
                        i18nLabelKey="Country"
                        i18nPlaceholder="country"
                        status={
                          errors.country && errors.country.message
                            ? "error"
                            : ""
                        }
                      />
                    )}
                  />
                  {errors.country && errors.country.message && (
                    <div className="w-100">
                      <InstructionNote instructionType="danger">
                        <span className="Paragraph300Light">
                          {/* @ts-ignore */}
                          {errors.country.message}
                        </span>
                      </InstructionNote>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100 d-flex flex-column gap-1">
                <TextInput
                  i18nLabelKey="Membership"
                  i18nPlaceholder="Membership"
                  value={quotationData?.membership_name}
                  status="disabled"
                />
              </div>
              <div className="w-100 d-flex flex-column gap-1">
                <ControlledTextInput
                  control={control}
                  name="customerNotes"
                  type="textarea"
                  containerStyle="mt-2"
                  inputStyle={styles.formTextArea}
                  i18nLabelKey="Customer Notes"
                  i18nPlaceholder="Notes"
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <Controller
                  control={control}
                  name="currency"
                  render={({ field }) => {
                    return (
                      <SelectionInput
                        isLoading={isCurrenciesLoading}
                        i18nPlaceholder="Currency"
                        options={
                          currenciesData
                            ? Object.keys(currenciesData).map((item) => ({
                                label: item,
                                value: item,
                              }))
                            : []
                        }
                        i18nLabelKey="currency"
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        value={field.value === "" ? undefined : field.value}
                        status={errors.currency ? "error" : ""}
                      />
                    );
                  }}
                />
                {errors.currency && errors.currency.message && (
                  <div className="w-100">
                    <InstructionNote instructionType="danger">
                      <span className="Paragraph300Light">
                        {errors.currency.message}
                      </span>
                    </InstructionNote>
                  </div>
                )}
              </div>
            </div>

            {quotationFields.map((field, index) => {
              if (field.quotation_type === "accommodation") {
                return (
                  <AccomodationFields
                    key={`field${field.id}`}
                    errors={errors}
                    control={control}
                    index={index}
                    setValue={setValue}
                  />
                );
              }
              if (field.quotation_type === "flight") {
                return (
                  <FlightFields
                    key={`field${field.id}`}
                    errors={errors}
                    control={control}
                    index={index}
                    setValue={setValue}
                  />
                );
              }
              if (field.quotation_type === "general") {
                return (
                  <GeneralFields
                    key={`field${field.id}`}
                    errors={errors}
                    control={control}
                    index={index}
                  />
                );
              }
              return null;
            })}

            {isQuotationTypeListVisible && (
              <div className="d-flex flex-column gap-1">
                <SelectionInput
                  i18nLabelKey="Quotation_Types"
                  i18nPlaceholder="Choose_Quotation_Type"
                  options={quotationOptionsTypes}
                  containerStyle="mt-4"
                  onChange={(e) => {
                    append(quotationTypeSampleTarget[e as string]);
                    setIsQuotationTypeListVisible(false);
                  }}
                />
                {errors.quotations && errors.quotations.message && (
                  <div className="w-100">
                    <InstructionNote instructionType="danger">
                      <span className="Paragraph300Light">
                        {errors.quotations.message}
                      </span>
                    </InstructionNote>
                  </div>
                )}
              </div>
            )}

            <div className="w-100 mt-4 d-flex justify-content-between">
              {quotationFields.length > 0 && (
                <Button
                  btnClassName={styles.addAnotherBtn}
                  size="large"
                  styleType="Secondary"
                  onClick={() => setIsQuotationTypeListVisible(true)}
                >
                  <span className="Button100">
                    {" "}
                    {t("Add_Another_Quotation")}
                  </span>
                </Button>
              )}
              <Button btnClassName={styles.saveBtn} size="large" type="submit">
                <span className="Button100">{t("NEXT")}</span>
              </Button>
            </div>
          </form>
        )}
      </div>
    </section>
  );
}
