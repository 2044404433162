const COLORS = {
  Neutral0: "#ffffff",
  Neutral100: "#f8f9fc",
  Neutral200: "#f1f3f9",
  Neutral300: "#e1e6ef",
  Neutral700: "#3f444d",
  Neutral800: "#23272f",
  Neutral900: "#1b1f27",
  Neutral1000: "#0a0d14",
  Primary100: "#f8f2e1",
  Primary700: "#866f26",
  Primary800: "#cbb670",
  Primary900: "#705e25",
  Success100: "#edfdf8",
  Success700: "#1dc071",
  Success800: "#04724d",
  Success900: "#066042",
  Warning100: "#fff8eb",
  Warning700: "#b25e09",
  Warning800: "#96530f",
  Warning900: "#80460d",
  Danger100: "#fef1f2",
  Danger700: "#e02d3c",
  Danger800: "#ba2532",
  Danger900: "#981b25",
  TextPrimaryBlack: "#1d2433",
  TextSecondaryBlack: "#1d2433cc",
  TextDisabledBlack: "#1d2433a6",
  TextPrimaryWhite: "#ffffff",
  TextSecondaryWhite: "#ffffffbf",
  TextDisabledWhite: "#ffffff99",
  AgentColor: "#251D11",
};

export default COLORS;
