import { Membership } from "apis/types/memberships";
import ZeroGold from "../assets/images/gold-numbers/0.png";
import OneGold from "../assets/images/gold-numbers/1.png";
import TwoGold from "../assets/images/gold-numbers/2.png";
import ThreeGold from "../assets/images/gold-numbers/3.png";
import FourGold from "../assets/images/gold-numbers/4.png";
import FiveGold from "../assets/images/gold-numbers/5.png";
import SixGold from "../assets/images/gold-numbers/6.png";
import SevenGold from "../assets/images/gold-numbers/7.png";
import EightGold from "../assets/images/gold-numbers/8.png";
import NineGold from "../assets/images/gold-numbers/9.png";
import slashGold from "../assets/images/gold-numbers/slash.png";
import dashGold from "../assets/images/gold-numbers/dash.png";
import apostrophGold from "../assets/images/gold-numbers/apostroph.png";
import dotGold from "../assets/images/gold-numbers/dot.png";
import aGold from "../assets/images/gold-numbers/a.png";
import bGold from "../assets/images/gold-numbers/b.png";
import cGold from "../assets/images/gold-numbers/c.png";
import dGold from "../assets/images/gold-numbers/d.png";
import eGold from "../assets/images/gold-numbers/e.png";
import fGold from "../assets/images/gold-numbers/f.png";
import gGold from "../assets/images/gold-numbers/g.png";
import hGold from "../assets/images/gold-numbers/h.png";
import iGold from "../assets/images/gold-numbers/i.png";
import jGold from "../assets/images/gold-numbers/j.png";
import kGold from "../assets/images/gold-numbers/k.png";
import lGold from "../assets/images/gold-numbers/l.png";
import mGold from "../assets/images/gold-numbers/m.png";
import nGold from "../assets/images/gold-numbers/n.png";
import oGold from "../assets/images/gold-numbers/o.png";
import pGold from "../assets/images/gold-numbers/p.png";
import qGold from "../assets/images/gold-numbers/q.png";
import rGold from "../assets/images/gold-numbers/r.png";
import sGold from "../assets/images/gold-numbers/s.png";
import tGold from "../assets/images/gold-numbers/t.png";
import uGold from "../assets/images/gold-numbers/u.png";
import vGold from "../assets/images/gold-numbers/v.png";
import wGold from "../assets/images/gold-numbers/w.png";
import xGold from "../assets/images/gold-numbers/x.png";
import yGold from "../assets/images/gold-numbers/y.png";
import zGold from "../assets/images/gold-numbers/z.png";

import ZeroSilver from "../assets/images/silver-numbers/0.png";
import OneSilver from "../assets/images/silver-numbers/1.png";
import TwoSilver from "../assets/images/silver-numbers/2.png";
import ThreeSilver from "../assets/images/silver-numbers/3.png";
import FourSilver from "../assets/images/silver-numbers/4.png";
import FiveSilver from "../assets/images/silver-numbers/5.png";
import SixSilver from "../assets/images/silver-numbers/6.png";
import SevenSilver from "../assets/images/silver-numbers/7.png";
import EightSilver from "../assets/images/silver-numbers/8.png";
import NineSilver from "../assets/images/silver-numbers/9.png";
import slashSilver from "../assets/images/silver-numbers/slash.png";
import dashSilver from "../assets/images/silver-numbers/dash.png";
import apostrophSilver from "../assets/images/silver-numbers/apostroph.png";
import dotSilver from "../assets/images/silver-numbers/dot.png";
import aSilver from "../assets/images/silver-numbers/a.png";
import bSilver from "../assets/images/silver-numbers/b.png";
import cSilver from "../assets/images/silver-numbers/c.png";
import dSilver from "../assets/images/silver-numbers/d.png";
import eSilver from "../assets/images/silver-numbers/e.png";
import fSilver from "../assets/images/silver-numbers/f.png";
import gSilver from "../assets/images/silver-numbers/g.png";
import hSilver from "../assets/images/silver-numbers/h.png";
import iSilver from "../assets/images/silver-numbers/i.png";
import jSilver from "../assets/images/silver-numbers/j.png";
import kSilver from "../assets/images/silver-numbers/k.png";
import lSilver from "../assets/images/silver-numbers/l.png";
import mSilver from "../assets/images/silver-numbers/m.png";
import nSilver from "../assets/images/silver-numbers/n.png";
import oSilver from "../assets/images/silver-numbers/o.png";
import pSilver from "../assets/images/silver-numbers/p.png";
import qSilver from "../assets/images/silver-numbers/q.png";
import rSilver from "../assets/images/silver-numbers/r.png";
import sSilver from "../assets/images/silver-numbers/s.png";
import tSilver from "../assets/images/silver-numbers/t.png";
import uSilver from "../assets/images/silver-numbers/u.png";
import vSilver from "../assets/images/silver-numbers/v.png";
import wSilver from "../assets/images/silver-numbers/w.png";
import xSilver from "../assets/images/silver-numbers/x.png";
import ySilver from "../assets/images/silver-numbers/y.png";
import zSilver from "../assets/images/silver-numbers/z.png";

export const CARD_LETTERS_TYPES: Membership["letters_type"][] = [
  "gold",
  "silver",
];

const CARD_LETTERS: Record<
  Membership["letters_type"],
  Record<string, string>
> = {
  gold: {
    "0": ZeroGold,
    "1": OneGold,
    "2": TwoGold,
    "3": ThreeGold,
    "4": FourGold,
    "5": FiveGold,
    "6": SixGold,
    "7": SevenGold,
    "8": EightGold,
    "9": NineGold,
    "/": slashGold,
    "-": dashGold,
    "'": apostrophGold,
    ".": dotGold,
    a: aGold,
    b: bGold,
    c: cGold,
    d: dGold,
    e: eGold,
    f: fGold,
    g: gGold,
    h: hGold,
    i: iGold,
    j: jGold,
    k: kGold,
    l: lGold,
    m: mGold,
    n: nGold,
    o: oGold,
    p: pGold,
    q: qGold,
    r: rGold,
    s: sGold,
    t: tGold,
    u: uGold,
    v: vGold,
    w: wGold,
    x: xGold,
    y: yGold,
    z: zGold,
  },
  silver: {
    "0": ZeroSilver,
    "1": OneSilver,
    "2": TwoSilver,
    "3": ThreeSilver,
    "4": FourSilver,
    "5": FiveSilver,
    "6": SixSilver,
    "7": SevenSilver,
    "8": EightSilver,
    "9": NineSilver,
    "/": slashSilver,
    "-": dashSilver,
    "'": apostrophSilver,
    ".": dotSilver,
    a: aSilver,
    b: bSilver,
    c: cSilver,
    d: dSilver,
    e: eSilver,
    f: fSilver,
    g: gSilver,
    h: hSilver,
    i: iSilver,
    j: jSilver,
    k: kSilver,
    l: lSilver,
    m: mSilver,
    n: nSilver,
    o: oSilver,
    p: pSilver,
    q: qSilver,
    r: rSilver,
    s: sSilver,
    t: tSilver,
    u: uSilver,
    v: vSilver,
    w: wSilver,
    x: xSilver,
    y: ySilver,
    z: zSilver,
  },
};

export default CARD_LETTERS;
