import LOGO from "assets/images/logo.svg";
import Image from "../Image";
import styles from "./styles.module.scss";

export default function FormLogo() {
  return (
    <div className={styles.logoContainer}>
      <Image src={LOGO} className="w-100 h-100" />
    </div>
  );
}
