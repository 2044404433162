import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { Checkbox } from "antd";
import { CheckBoxProps } from "./types";
import styles from "./styles.module.scss";

export default function CheckBox({
  containerStyle,
  text,
  boxStyle,
  onCheckChange,
  checked = false,
  value,
}: CheckBoxProps) {
  const onChange = (e: CheckboxChangeEvent) => {
    onCheckChange?.(e.target.checked);
  };

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <Checkbox
        className={`${boxStyle} ${styles.insideBox}`}
        checked={checked}
        value={value}
        onChange={onChange}
      >
        {text}
      </Checkbox>
    </div>
  );
}
