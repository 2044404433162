/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Image from "components/general/Image";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import { saveAs } from "file-saver";
import Menu from "components/Menu";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "./styles.module.scss";
import { MessageProps } from "./types";
// import ReplyMessageBox from "../ReplyMessageBox";

function makeLinksClickable(text: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part: string) =>
    urlRegex.test(part) ? (
      <a href={part} target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    ) : (
      part
    )
  );
}

export default function Message({
  isUser,
  iconLetters,
  messageName,
  messageDescription,
  messageImage,
  messageFile,
  messageTime,
  msgImageModal,
  msgReplyTarget,
  msgEditTarget,
  msgDeleteTarget,
}: MessageProps) {
  const { t } = useAutoCompleteTranslation();

  const handleSaveFile = (fileTarget: string) => {
    if (fileTarget)
      saveAs(fileTarget, fileTarget?.split("/")?.at(-1)?.split(".")?.[0]);
  };

  return (
    <div
      className={`d-flex gap-2 align-items-end mb-4 ${
        isUser && "flex-row-reverse"
      } `}
    >
      <div className={`${styles.agentIcon} ${isUser && styles.userIcon}`}>
        <span className="Paragraph200Heavy"> {iconLetters} </span>
      </div>

      <div className="d-flex flex-column gap-2">
        {/* <ReplyMessageBox
          isUser={isUser}
          messageName={messageName}
          messageDescription={messageDescription}
          messageImage={messageImage}
          messageFile={messageFile}
          msgImageModal={msgImageModal}
        /> */}
        <div
          className={`${styles.agentMessage} ${isUser && styles.userMessage}`}
        >
          <span className="d-flex justify-content-between Paragraph200Heavy">
            {messageName}
            <Menu
              items={[
                {
                  key: "reply",
                  label: (
                    <span className="d-flex gap-2 align-items-center">
                      <Icon name="undo" size={20} color="TextSecondaryBlack" />
                      {t("Reply")}
                    </span>
                  ),
                  onClick: msgReplyTarget,
                },
                isUser
                  ? {
                      key: "edit",
                      label: (
                        <span className="d-flex gap-2 align-items-center">
                          <Icon
                            name="chat"
                            size={20}
                            color="TextSecondaryBlack"
                          />
                          {t("Edit")}
                        </span>
                      ),
                      onClick: msgEditTarget,
                    }
                  : null,
                isUser
                  ? {
                      key: "delete",
                      label: (
                        <span className="d-flex gap-2 align-items-center">
                          <Icon
                            name="Delete"
                            size={20}
                            color="TextSecondaryBlack"
                          />
                          {t("Delete")}
                        </span>
                      ),
                      onClick: msgDeleteTarget,
                    }
                  : null,
              ]}
            >
              {" "}
              {/* <Icon name="More" size={20} color="Neutral0" /> */}
            </Menu>
          </span>
          {messageDescription
            ?.split(/\r\n|\n|\r/)
            .map((text) => (
              <span className="Paragraph200Light">
                {makeLinksClickable(text)}
              </span>
            ))}
          {messageImage && (
            <div className="d-flex align-items-center">
              <div
                className={styles.messageImgageContainer}
                onClick={() => messageImage && msgImageModal?.(messageImage)}
              >
                <div className={styles.messageImageOverlay}>
                  <Icon size={48} name="Search" color="Neutral0" />
                </div>
                <Image src={messageImage} className="w-100" />
              </div>
              <div className="p-2">
                <Button
                  onClick={() => handleSaveFile(messageImage)}
                  styleType="NoStyle"
                  prefix={<Icon name="download" size={24} color="Neutral0" />}
                />
              </div>
            </div>
          )}
          {messageFile && (
            <Button
              onClick={() => handleSaveFile(messageFile)}
              styleType="NoStyle"
              suffix={<Icon name="download" size={24} color="Neutral0" />}
              btnClassName={styles.downloadBtn}
            >
              {messageFile?.split("/")?.at(-1)}
            </Button>
          )}
        </div>

        <span
          className={`Paragraph300Light ${styles.messageTime} ${
            isUser && styles.userMessageTime
          }`}
        >
          {messageTime}
        </span>
      </div>
    </div>
  );
}
