import Icon from "../Icon";
import { InstructionNoteProps } from "./types";
import styles from "./styles.module.scss";

export default function InstructionNote({
  children,
  instructionType,
}: InstructionNoteProps) {
  const icons = {
    warning: "Warning",
    danger: "Error",
    info: "Info",
    success: "Done",
  };

  return (
    <div className={`${styles.contianer} ${styles[instructionType]}`}>
      <Icon name={icons[instructionType]} size={16} />
      {children}
    </div>
  );
}
