import Button from "components/general/Button";
import Icon from "components/general/Icon";
import SelectionInput from "components/inputs/SelectionInput";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import { useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import ModalWrapper from "components/modals/ModalWrapper";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import InstructionNote from "components/general/InstructionNote";
import { useGetGeneralBenefitConditionsQuery } from "apis/services/memberships";
import { optionType } from "components/inputs/SelectionInput/types";
import { GeneralCondition } from "apis/types/memberships";
import { EditMembershipType } from ".";
import styles from "./styles.module.scss";

export default function EditBenefitConditions({
  nestIndex,
  control,
  errors,
}: {
  nestIndex: number;
  control: Control<EditMembershipType>;
  errors: FieldErrors<EditMembershipType>;
}) {
  const { t } = useAutoCompleteTranslation();
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [newConditionVal, setNewConditionVal] = useState("");

  const {
    fields: conditions,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `benefits.${nestIndex}.conditions`,
  });

  const parentBenefit = useWatch({
    control,
    name: `benefits.${nestIndex}`,
  });

  const onModalClose = () => {
    setNewConditionVal("");
    setIsAddModalVisible(false);
  };

  const onModalAdd = () => {
    append({
      general_condition: null,
      condition: newConditionVal,
      discount: 0,
    });
    onModalClose();
  };

  const { data: conditionsOptions, isLoading: isConditionsLoading } =
    useGetGeneralBenefitConditionsQuery(parentBenefit.general_benefit ?? 0, {
      skip:
        parentBenefit.general_benefit === 0 || !parentBenefit.general_benefit,
    });

  return (
    <div className="d-flex flex-column gap-2">
      {conditions?.map((condition, index) => (
        <div key={condition.id}>
          <div className="d-flex justify-content-between">
            <ControlledTextInput
              control={control}
              name={`benefits.${nestIndex}.conditions.${index}.condition`}
              containerStyle="col-8"
              suffix={
                <Button styleType="NoStyle" onClick={() => remove(index)}>
                  <Icon name="Close" size={20} color="TextSecondaryBlack" />
                </Button>
              }
              status={
                errors?.benefits?.[nestIndex]?.conditions?.[index]
                  ?.general_condition?.message
                  ? "error"
                  : "default"
              }
            />
            <Controller
              control={control}
              name={`benefits.${nestIndex}.conditions.${index}.discount`}
              render={({ field }) => (
                <TextInput
                  type="number"
                  value={String(field.value)}
                  onChange={field.onChange}
                  containerStyle="col-3"
                  suffix="%"
                  status={
                    errors?.benefits?.[nestIndex]?.conditions?.[index]?.discount
                      ?.message
                      ? "error"
                      : "default"
                  }
                />
              )}
            />
          </div>
          {errors.benefits &&
            (errors.benefits[nestIndex]?.conditions?.[index]?.general_condition
              ?.message ||
              errors.benefits[nestIndex]?.conditions?.[index]?.discount
                ?.message) && (
              <div className="w-100">
                <InstructionNote instructionType="danger">
                  <span className="Paragraph300Light">
                    {errors.benefits[nestIndex]?.conditions?.[index]
                      ?.general_condition?.message ||
                      errors.benefits[nestIndex]?.conditions?.[index]?.discount
                        ?.message}
                  </span>
                </InstructionNote>
              </div>
            )}
        </div>
      ))}
      {conditionsOptions && conditionsOptions.length > 0 && (
        <SelectionInput
          onChange={(val, option) => {
            append({
              general_condition: Number(val as string),
              condition: (option as optionType)?.label,
              discount: 0,
            });
          }}
          isLoading={isConditionsLoading}
          size="large"
          containerStyle="w-100"
          options={
            conditionsOptions?.map((conditionItem: GeneralCondition) => ({
              value: String(conditionItem.id),
              label: conditionItem.name,
            })) ?? []
          }
          placeholder={t("Choose_Condition")}
        />
      )}
      {errors.benefits &&
        errors.benefits[nestIndex] &&
        errors.benefits[nestIndex]?.conditions?.message && (
          <div className="w-100">
            <InstructionNote instructionType="danger">
              <span className="Paragraph300Light">
                {errors.benefits[nestIndex]?.conditions?.message}
              </span>
            </InstructionNote>
          </div>
        )}
      {parentBenefit.general_benefit && (
        <Button
          styleType="NoStyle"
          btnClassName={styles.addCondtionBtn}
          onClick={() => setIsAddModalVisible(true)}
        >
          <span className="Button100">+ {t("Add_Condition")} </span>
        </Button>
      )}
      <ModalWrapper
        size="large"
        isVisible={isAddModalVisible}
        headerTitle={t("Add_Condition")}
        onClose={onModalClose}
        isFooterHidden
      >
        <TextInput
          value={newConditionVal}
          onChange={(e) => setNewConditionVal(e.target.value)}
        />

        <div className={styles.addModalBtns}>
          <Button
            styleType="Secondary"
            btnClassName={styles.cancelBtn}
            onClick={onModalClose}
          >
            <span className="Button100">{t("Cancel")}</span>
          </Button>
          <Button
            btnClassName={styles.addBtn}
            disabled={newConditionVal === ""}
            onClick={onModalAdd}
          >
            <span className="Button100">{t("Add")}</span>
          </Button>
        </div>
      </ModalWrapper>
    </div>
  );
}
