import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import PageTitle from "components/general/PageTitle";
import Button from "components/general/Button";
import SelectionInput from "components/inputs/SelectionInput";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import * as yup from "yup";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import InstructionNote from "components/general/InstructionNote";
import dayjs from "dayjs";
import { debounce } from "lodash-es";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import { useNavigate } from "react-router-dom";
import {
  useGetCountriesQuery,
  useLazyGetCountryQuery,
} from "apis/services/countries";
import DateInput from "components/inputs/DateInput";
import TimeInput from "components/inputs/TimeInput";
import { Country } from "apis/types/countries";
import Spinner from "components/general/Spinner";
import {
  useAddGeneralQuotationMutation,
  useAddOrEditQuotationsMutation,
} from "apis/services/generalQuotations";
import { Customer } from "apis/types/customers";
import {
  useGetCustomerQuery,
  useGetCustomersQuery,
  useUpdateCustomerMutation,
} from "apis/services/customers";
import {
  ACCOMODATION_SAMPLE,
  FLIGHT_SAMPLE,
  GENERAL_SAMPLE,
} from "constants/QuotationsSamples";
import { useGetCurrenciesQuery } from "apis/services/currency";
import AccomodationFields, { accommodationSchema } from "./fields/Accomodation";
import FlightFields, { flightSchema } from "./fields/Flight";
import GeneralFields, { generalSchema } from "./fields/General";
import styles from "./styles.module.scss";

const quotationOptionsTypes = [
  { value: "accomodation", label: "Accomodation" },
  { value: "flight", label: "Flight" },
  { value: "general", label: "General" },
];

const quotationTypeSampleTarget: any = {
  accomodation: ACCOMODATION_SAMPLE,
  flight: FLIGHT_SAMPLE,
  general: GENERAL_SAMPLE,
};

export const generalQuotationSchema = yup.object({
  agentName: yup.string().required("Agent name is rquired!"),
  quotationId: yup.string().required("Quotation id is rquired!"),
  customerId: yup
    .number()
    .not([0], "Customer id is rquired!")
    .required("Customer id is rquired!"),
  country: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value))
    .not([0], "Country is rquired!")
    .required("Country is rquired!"),
  currency: yup.string().required("Currency is required"),
  creationDate: yup.string(),
  creationTime: yup.string(),
  customerNotes: yup.string(),
  quotations: yup
    .array()
    .of(
      yup.lazy((item) => {
        if (item?.quotation_type === "accommodation")
          return accommodationSchema;
        if (item?.quotation_type === "general") return generalSchema;
        return flightSchema;
      })
    )
    .min(1, "At least one quotation type is required")
    .required("Quotation is required"),
});

export type generalQuotationTypes = yup.InferType<
  typeof generalQuotationSchema
>;

export default function AddNewQuotation() {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.auth.userData);

  const [isQuotationTypeListVisible, setIsQuotationTypeListVisible] =
    useState(true);

  const [customerIdSearch, setCustomerIdSearch] = useState("");

  const [countriesSearch, setCountriesSearch] = useState("");

  const { data: currenciesData, isLoading: isCurrenciesLoading } =
    useGetCurrenciesQuery();

  const [updateCustomer, { isLoading: isUpdateCustomerLoading }] =
    useUpdateCustomerMutation();

  const {
    data: customers,
    isLoading: isCustomersLoading,
    isFetching: isCustomerFetching,
  } = useGetCustomersQuery({
    page_size: 5,
    search: customerIdSearch,
  });

  const {
    data: countries,
    isLoading: isCountriesLoading,
    isFetching: isCountriesFetching,
  } = useGetCountriesQuery({ search: countriesSearch });

  const [addGeneralQuotation, { isLoading: isAddGeneralLoading }] =
    useAddGeneralQuotationMutation();
  const [addQuotations, { isLoading: isAddQuotationsLoading }] =
    useAddOrEditQuotationsMutation();

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    control,
    setValue,
  } = useForm<generalQuotationTypes>({
    resolver: yupResolver(generalQuotationSchema),
    defaultValues: {
      agentName:
        (user?.first_name && `${user?.first_name} ${user?.last_name}`) ||
        user?.username,
      quotationId: "",
      customerId: 0,
      country: 0,
      creationDate: "",
      creationTime: "",
      customerNotes: "",
      quotations: [],
    },
    mode: "onSubmit",
  });

  const CustomerWatcher = useWatch({ control, name: "customerId" });

  const { data: customerData } = useGetCustomerQuery(CustomerWatcher, {
    skip: CustomerWatcher === 0,
  });
  const { fields: quotationFields, append } = useFieldArray({
    control,
    name: "quotations",
  });

  useEffect(() => {
    if (user) {
      setValue(
        "agentName",
        (user?.first_name && `${user?.first_name} ${user?.last_name}`) ||
          user?.username
      );
    }
    if (user && CustomerWatcher)
      setValue(
        "quotationId",
        `U${user.id}C${CustomerWatcher}T${String(Date.now())}`
      );

    if (customerData) {
      setValue("country", customerData?.country);
      setValue("customerNotes", customerData?.notes);
    }
  }, [user, CustomerWatcher, customerData]);

  const [getCountry] = useLazyGetCountryQuery();

  useEffect(() => {
    if (customerData) {
      getCountry(String(customerData.country))
        .unwrap()
        .then((res) => {
          if (!res) return;
          setCountriesSearch(res.name);
        });
    }
  }, [customerData]);

  const onSubmit = (data: generalQuotationTypes) => {
    if (user && user.id) {
      const creationDateFormated = data.creationDate
        ? dayjs(data.creationDate, "DD/MM/YYYY").format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD");
      const creationTimeFormated = data.creationTime
        ? data.creationTime
        : dayjs().format("HH:mm");

      const creationDateTime = `${creationDateFormated}T${creationTimeFormated}`;

      const generalQuotationData = {
        quotation_id: data.quotationId,
        created_on: creationDateTime,
        agent: user.id,
        customer: data.customerId,
        country: data.country,
        currency: data.currency,
      };
      addGeneralQuotation(generalQuotationData)
        .unwrap()
        .then((res: any) => {
          const quotationsWithId = data.quotations.map((item: any) => {
            if (item?.quotation_type === "accommodation")
              return {
                ...item,
                parameters: item?.parameters.filter((param: any) => param.name),
                type: item.accommodation_type,
                isAccomodationNew: undefined,
                general_quotation: res.id,

                rooms: item?.rooms?.map((room: any) => ({
                  type: room.room_type,
                  price: room.price,
                  discount: room.discount ?? 0,
                  adults: String(room.adults),
                  children: String(room.children),
                  children_age: String(room.children_age),
                  discount_credit: room.discount_credit ?? 0,
                  taxes_fee: String(room.taxes_fee) ?? 0,
                })),

                name:
                  item.accommodation_service_id === 0 ? item.name : undefined,

                city_name_new:
                  item.city === null ? item.city_name_new : undefined,

                check_in_date: dayjs(item?.check_in_date, "DD/MM/YYYY").format(
                  "YYYY-MM-DD"
                ),

                check_out_date: dayjs(
                  item?.check_out_date,
                  "DD/MM/YYYY"
                ).format("YYYY-MM-DD"),

                cancelation_before_date: item?.cancelation_before_date
                  ? dayjs(item?.cancelation_before_date, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                  : undefined,

                supplier: item?.supplier === 0 ? undefined : item?.supplier,

                payment_date_for_supplier: item?.payment_date_for_supplier
                  ? dayjs(item?.payment_date_for_supplier, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                  : undefined,

                payment_link: undefined,
                no_of_payment_links_generated: undefined,
              };

            if (item?.quotation_type === "flight")
              return {
                ...item,
                parameters: item?.parameters.filter((param: any) => param.name),
                general_quotation: res.id,
                routes: item.routes.map((route: any) => ({
                  ...route,
                  departure_datetime: `${dayjs(
                    route?.departure_date,
                    "DD/MM/YYYY"
                  ).format("YYYY-MM-DD")}T${route?.departure_time}`,
                  arrival_datetime: `${dayjs(
                    route?.arrival_date,
                    "DD/MM/YYYY"
                  ).format("YYYY-MM-DD")}T${route?.arrival_time}`,
                  cancelation_before_datetime: route?.cancelation_before
                    ? `${dayjs(
                        route?.cancelation_before_date,
                        "DD/MM/YYYY"
                      ).format("YYYY-MM-DD")}T${route?.cancelation_before_time}`
                    : undefined,
                  arrival_city:
                    route.arrival_city === 0 ? null : route.arrival_city,
                  departure_city:
                    route.departure_city === 0 ? null : route.departure_city,
                  departure_date: undefined,
                  departure_time: undefined,
                  arrival_date: undefined,
                  arrival_time: undefined,
                  cancelation_before_date: undefined,
                  cancelation_before_time: undefined,
                })),
                passengers: item.passengers.map((passenger: any) => ({
                  ...passenger,
                  date_of_birth: passenger.date_of_birth
                    ? dayjs(passenger.date_of_birth, "DD/MM/YYYY").format(
                        "YYYY-MM-DD"
                      )
                    : undefined,
                })),

                supplier: item?.supplier === 0 ? undefined : item?.supplier,

                payment_date_for_supplier: item?.payment_date_for_supplier
                  ? dayjs(item?.payment_date_for_supplier, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                  : undefined,

                payment_link: undefined,
                no_of_payment_links_generated: undefined,
              };

            if (item?.quotation_type === "general")
              return {
                ...item,
                general_quotation: res.id,

                wallet_activation_date: dayjs(
                  item?.wallet_activation_date,
                  "DD/MM/YYYY"
                ).format("YYYY-MM-DD"),

                cancelation_before_date: item?.cancelation_before_date
                  ? dayjs(item?.cancelation_before_date, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                  : undefined,

                supplier: item?.supplier === 0 ? undefined : item?.supplier,

                payment_date_for_supplier: item?.payment_date_for_supplier
                  ? dayjs(item?.payment_date_for_supplier, "DD/MM/YYYY").format(
                      "YYYY-MM-DD"
                    )
                  : undefined,

                payment_link: undefined,
                no_of_payment_links_generated: undefined,
              };

            return item;
          });

          addQuotations(quotationsWithId)
            .unwrap()
            .then(() => {
              if (
                dirtyFields.customerNotes &&
                customerData &&
                customerData.id
              ) {
                updateCustomer([
                  String(customerData.id),
                  { notes: data.customerNotes },
                ])
                  .then(() => {
                    navigate("/quotations");
                    showToast({
                      toastType: "success",
                      title: `New quotation has been added successfully!`,
                      showCloseIcon: true,
                    });
                  })
                  .catch(() => {
                    showToast({
                      toastType: "danger",
                      title: "Updating customer notes failed!",
                      showCloseIcon: true,
                    });
                    showToast({
                      toastType: "success",
                      title: `New quotation has been added successfully!`,
                      showCloseIcon: true,
                    });
                    navigate("/quotations");
                  });
              } else {
                navigate("/quotations");
                showToast({
                  toastType: "success",
                  title: `New quotation has been added successfully!`,
                  showCloseIcon: true,
                });
              }
            })
            .catch(handleErrors);
        })
        .catch(handleErrors);
    }
  };

  return (
    <section>
      <PageTitle> {t("Add_New_Quotation")} </PageTitle>
      <div className={styles.container}>
        {isAddQuotationsLoading ||
        isAddGeneralLoading ||
        isUpdateCustomerLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <div className={styles.general}>
              <ControlledTextInput
                control={control}
                name="agentName"
                containerStyle="w-100"
                i18nLabelKey="Agent_Name"
                status="disabled"
              />

              <div className="d-flex flex-column gap-1">
                <ControlledTextInput
                  control={control}
                  name="quotationId"
                  i18nLabelKey="Quotation_ID"
                  i18nPlaceholder="Quotation_ID"
                  status="disabled"
                  errorMsg={errors?.quotationId?.message}
                />
              </div>
              <div className="d-flex gap-4">
                <div className="w-100 d-flex flex-column gap-1">
                  <Controller
                    control={control}
                    name="customerId"
                    render={({ field }) => (
                      <SelectionInput
                        showSearch
                        onSearch={debounce((e) => setCustomerIdSearch(e), 500)}
                        onFilter={false}
                        isLoading={isCustomersLoading || isCustomerFetching}
                        options={
                          customers?.results.map((item: Customer) => {
                            const phone = item.mobile.split(item.mobile_code)[1]
                              ? `${item.mobile_code} ${
                                  item.mobile.split(item.mobile_code)[1]
                                }`
                              : "";
                            return {
                              value: String(item.id),
                              label: `${item.first_name} ${item.last_name} ${phone}`,
                            };
                          }) ?? []
                        }
                        i18nLabelKey="Customer_ID"
                        i18nPlaceholder="Customer_Id"
                        onChange={field.onChange}
                        status={
                          errors.customerId && errors.customerId.message
                            ? "error"
                            : ""
                        }
                      />
                    )}
                  />
                  {errors.customerId && errors.customerId.message && (
                    <div className="w-100">
                      <InstructionNote instructionType="danger">
                        <span className="Paragraph300Light">
                          {/* @ts-ignore */}
                          {errors.customerId.message}
                        </span>
                      </InstructionNote>
                    </div>
                  )}
                </div>
                <div className="w-100 d-flex flex-column gap-1">
                  <Controller
                    control={control}
                    name="country"
                    render={({ field }) => (
                      <SelectionInput
                        isLoading={isCountriesLoading || isCountriesFetching}
                        value={
                          field.value === 0 ? undefined : String(field.value)
                        }
                        onChange={field.onChange}
                        options={
                          countries?.results?.map((item: Country) => ({
                            value: String(item.id),
                            label: item.name,
                          })) ?? []
                        }
                        showSearch
                        onSearch={debounce((e) => setCountriesSearch(e), 500)}
                        onFilter={false}
                        i18nLabelKey="Country"
                        i18nPlaceholder="country"
                        status={
                          errors.country && errors.country.message
                            ? "error"
                            : ""
                        }
                      />
                    )}
                  />
                  {errors.country && errors.country.message && (
                    <div className="w-100">
                      <InstructionNote instructionType="danger">
                        <span className="Paragraph300Light">
                          {/* @ts-ignore */}
                          {errors.country.message}
                        </span>
                      </InstructionNote>
                    </div>
                  )}
                </div>
              </div>
              <div className="w-100 d-flex flex-column gap-1">
                <TextInput
                  i18nLabelKey="Membership"
                  i18nPlaceholder="Membership"
                  value={customerData?.cards[0]?.membership?.name}
                  status="disabled"
                />
              </div>
              <div className="w-100 d-flex flex-column gap-1">
                <ControlledTextInput
                  control={control}
                  name="customerNotes"
                  type="textarea"
                  containerStyle="mt-2"
                  inputStyle={styles.formTextArea}
                  i18nLabelKey="Customer Notes"
                  i18nPlaceholder="Notes"
                />
              </div>
              <div className="d-flex flex-column gap-1">
                <Controller
                  control={control}
                  name="currency"
                  render={({ field }) => {
                    return (
                      <SelectionInput
                        isLoading={isCurrenciesLoading}
                        i18nPlaceholder="Currency"
                        options={
                          currenciesData
                            ? Object.keys(currenciesData).map((item) => ({
                                label: item,
                                value: item,
                              }))
                            : []
                        }
                        i18nLabelKey="currency"
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        value={field.value === "" ? undefined : field.value}
                        status={errors.currency ? "error" : ""}
                      />
                    );
                  }}
                />
                {errors.currency && errors.currency.message && (
                  <div className="w-100">
                    <InstructionNote instructionType="danger">
                      <span className="Paragraph300Light">
                        {errors.currency.message}
                      </span>
                    </InstructionNote>
                  </div>
                )}
              </div>
              <div className="w-100 d-flex flex-column gap-1">
                <Controller
                  control={control}
                  name="creationDate"
                  render={({ field }) => (
                    <DateInput
                      defaultValue={field.value}
                      onChange={(e) =>
                        e
                          ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                          : field.onChange("")
                      }
                      i18nLabelKey="Creation_Date"
                      status={
                        errors.creationDate && errors.creationDate.message
                          ? "error"
                          : ""
                      }
                    />
                  )}
                />
                {errors.creationDate && errors.creationDate.message && (
                  <div className="w-100">
                    <InstructionNote instructionType="danger">
                      <span className="Paragraph300Light">
                        {errors.creationDate.message}
                      </span>
                    </InstructionNote>
                  </div>
                )}
              </div>
              <div className="w-100 d-flex flex-column gap-1">
                <Controller
                  control={control}
                  name="creationTime"
                  render={({ field }) => (
                    <TimeInput
                      defaultValue={field.value}
                      onChange={(e, timeString) => field.onChange(timeString)}
                      i18nLabelKey="Creation_Time"
                      status={
                        errors.creationTime && errors.creationTime.message
                          ? "error"
                          : ""
                      }
                    />
                  )}
                />
                {errors.creationTime && errors.creationTime.message && (
                  <div className="w-100">
                    <InstructionNote instructionType="danger">
                      <span className="Paragraph300Light">
                        {errors.creationTime.message}
                      </span>
                    </InstructionNote>
                  </div>
                )}
              </div>
            </div>

            {quotationFields.map((field, index) => {
              if (field.quotation_type === "accommodation") {
                return (
                  <AccomodationFields
                    key={`field${field.id}`}
                    errors={errors}
                    control={control}
                    index={index}
                    setValue={setValue}
                  />
                );
              }
              if (field.quotation_type === "flight") {
                return (
                  <FlightFields
                    key={`field${field.id}`}
                    errors={errors}
                    control={control}
                    index={index}
                    setValue={setValue}
                  />
                );
              }
              if (field.quotation_type === "general") {
                return (
                  <GeneralFields
                    key={`field${field.id}`}
                    errors={errors}
                    control={control}
                    index={index}
                  />
                );
              }
              return null;
            })}

            {isQuotationTypeListVisible && (
              <div className="d-flex flex-column gap-1">
                <SelectionInput
                  i18nLabelKey="Quotation_Types"
                  i18nPlaceholder="Choose_Quotation_Type"
                  options={quotationOptionsTypes}
                  containerStyle="mt-4"
                  onChange={(e) => {
                    append(quotationTypeSampleTarget[e as string]);
                    setIsQuotationTypeListVisible(false);
                  }}
                />
                {errors.quotations && errors.quotations.message && (
                  <div className="w-100">
                    <InstructionNote instructionType="danger">
                      <span className="Paragraph300Light">
                        {errors.quotations.message}
                      </span>
                    </InstructionNote>
                  </div>
                )}
              </div>
            )}

            <div className="w-100 mt-4 d-flex justify-content-between">
              {quotationFields.length > 0 && (
                <Button
                  btnClassName={styles.addAnotherBtn}
                  size="large"
                  styleType="Secondary"
                  onClick={() => setIsQuotationTypeListVisible(true)}
                >
                  <span className="Button100">
                    {" "}
                    {t("Add_Another_Quotation")}{" "}
                  </span>
                </Button>
              )}
              <Button
                btnClassName={styles.saveBtn}
                size="large"
                type="submit"
                disabled={isAddGeneralLoading || isAddQuotationsLoading}
              >
                <span className="Button100"> {t("NEXT")} </span>
              </Button>
            </div>
          </form>
        )}
      </div>
    </section>
  );
}
