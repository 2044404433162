/* eslint-disable no-nested-ternary */
import { useEffect, useState } from "react";
import PageTitle from "components/general/PageTitle";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import SelectionInput from "components/inputs/SelectionInput";
import UploadDragger from "components/general/UploadDragger";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import { Switch } from "antd";
import InstructionNote from "components/general/InstructionNote";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useGetGeneralBenefitsQuery,
  useGetMembershipQuery,
  useUpdateBenefitsBulkMutation,
  useUpdateMembershipMutation,
} from "apis/services/memberships";
import showToast from "utils/showToast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "components/general/Spinner";
import handleErrors from "utils/handleErrors";
import Image from "components/general/Image";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { Benefit } from "apis/types/memberships";
import { optionType } from "components/inputs/SelectionInput/types";
import { useGetCurrenciesQuery } from "apis/services/currency";
import CustomCridetCardLetters from "components/general/CustomCridetCardLetters";
import { CARD_LETTERS_TYPES } from "constants/CardLetters";
import EditBenefitConditions from "./EditBenefitConditions";
import styles from "./styles.module.scss";

const schema = yup.object({
  name: yup.string().required("Membership name is required!"),
  card_code: yup.string().required("Membership card code is required!"),
  price: yup
    .number()
    .moreThan(0, "Membership price must be greater than 0")
    .required("Membership price is required!")
    .integer("A whole number is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  annual_fees: yup
    .number()
    .moreThan(0, "Annual fees must be greater than 0")
    .required("Annual fees code is required!")
    .integer("A whole number is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  currency: yup.string().required("Membership currency is required!"),
  status: yup.boolean(),
  notes: yup.string(),
  letters_type: yup
    .string()
    .oneOf([...CARD_LETTERS_TYPES])
    .required("Letters Type is required!"),
  benefits: yup
    .array()
    .of(
      yup.object({
        id: yup
          .number()
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
        general_benefit: yup.number(),
        benefit: yup.string().required("Choose benefit!"),
        general_benefit_name: yup.string().nullable(),
        membership: yup
          .number()
          .required()
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
        is_active: yup.boolean().required(),
        conditions: yup
          .array()
          .of(
            yup.object({
              id: yup
                .number()
                .transform((value) =>
                  Number.isNaN(value) ? undefined : value
                ),
              general_condition: yup.number().nullable(),
              condition: yup.string().required("Condition name is required!"),
              general_condition_name: yup.string().nullable(),
              discount: yup
                .number()
                .max(100, "Max discount number is 100")
                .required("Condition discount is required!")
                .transform((value) =>
                  Number.isNaN(value) ? undefined : value
                ),
            })
          )
          .min(1, "Benfit must have least 1 condition")
          .required(),
        isAlreadyRegistered: yup.boolean(),
      })
    )
    .min(1, "Benfits must have least 1 Benefit with at least 1 condition")
    .required(),
});

export type EditMembershipType = yup.InferType<typeof schema>;

export default function EditMembership() {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();
  const { membershipId } = useParams();
  const { pathname } = useLocation();
  const { data: currenciesData, isLoading: isCurrenciesLoading } =
    useGetCurrenciesQuery();

  const { data: membership, isLoading: isMembershipLoading } =
    useGetMembershipQuery(membershipId || "");
  const [updateMembership, { isLoading: isUpdateMembershipLoading }] =
    useUpdateMembershipMutation();
  const [updateBenefits, { isLoading: isUpadateBenefitsLoading }] =
    useUpdateBenefitsBulkMutation();

  const { data: ConditionOptions } = useGetGeneralBenefitsQuery();

  const [uploadedImage, setUploadedImage] = useState<string | Blob>("");
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [incomingImage, setIncomingImage] = useState(
    membership?.template_image ?? null
  );
  const [isSwitchOpen, setIsSwitchOpen] = useState(membership?.status);

  const defaultValues = {
    name: membership?.name ?? "",
    card_code: membership?.card_code ?? "0",
    price: membership?.price ?? 0,
    annual_fees: membership?.annual_fees ?? 0,
    currency: membership?.currency ?? "GBP",
    status: membership?.status ?? true,
    notes: membership?.notes ?? "",
    letters_type: membership?.letters_type,
    benefits: membership?.benefits
      ? [
          ...membership.benefits.map((item) => ({
            ...item,
            isAlreadyRegistered: true,
          })),
        ]
      : [],
  };

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    control,
  } = useForm<EditMembershipType>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onSubmit",
  });

  const { fields: benefits, append } = useFieldArray({
    control,
    name: "benefits",
  });

  const handleAddBenefit = () => {
    if (membership)
      append({
        general_benefit: 0,
        benefit: "",
        is_active: false,
        conditions: [],
        isAlreadyRegistered: false,
        membership: membership.id,
      });
  };

  const onSwitchChange = () => {
    setIsSwitchOpen(!isSwitchOpen);
  };

  const letters_type = useWatch({
    control,
    name: "letters_type",
  });

  const onSubmit = (data: EditMembershipType) => {
    const formData = new FormData();
    if (dirtyFields.name) formData.append("name", data.name);
    if (dirtyFields.card_code) formData.append("card_code", data.card_code);
    if (dirtyFields.price) formData.append("price", String(data.price));
    if (uploadedImage) formData.append("template_image", uploadedImage);
    if (dirtyFields.annual_fees)
      formData.append("annual_fees", String(data.annual_fees));
    if (dirtyFields.currency) formData.append("currency", data.currency);
    if (dirtyFields.status && data.status)
      formData.append("status", String(data.status));
    if (dirtyFields.notes && data.notes) formData.append("notes", data.notes);
    if (dirtyFields.letters_type && data.letters_type)
      formData.append("letters_type", data.letters_type);

    const updatedBenefits: Benefit[] = data.benefits.map((item) => ({
      id: item.id ?? undefined,
      general_benefit: item.general_benefit,
      benefit: item.benefit,
      conditions: item.conditions.map((condition) => ({
        id: condition.id ?? undefined,
        condition: condition.condition,
        general_condition: condition.general_condition,
        discount: condition.discount,
      })),
      is_active: item.is_active,
      membership: item.membership,
    }));

    if (membershipId) {
      updateMembership([membershipId, formData])
        .unwrap()
        .then(() => {
          if (dirtyFields.benefits) {
            updateBenefits(updatedBenefits)
              .unwrap()
              .then(() => {
                navigate("/memberships");
                showToast({
                  toastType: "success",
                  title: `${data.name} membership has been updated successfully!`,
                  msg: "Congratulations! ",
                  showCloseIcon: true,
                });
              });
          } else {
            navigate("/memberships");
            showToast({
              toastType: "success",
              title: `${data.name} membership has been updated successfully!`,
              msg: "Congratulations! ",
              showCloseIcon: true,
            });
          }
        })
        .catch(handleErrors);
    }
  };

  useEffect(() => {
    if (!membership) return;

    Object.keys(defaultValues)?.forEach((key) =>
      // @ts-ignore
      setValue(key as keyof typeof defaultValues, defaultValues[key])
    );
    setIncomingImage(membership.template_image);
    setIsSwitchOpen(membership.status);
    if (membership) {
      const updatedUrl = `${pathname}?idName=${membership?.name}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [membership]);

  return (
    <section>
      <PageTitle> {t("Edit_Membership")} </PageTitle>
      <div
        className={`${styles.container} d-flex flex-row-reverse justify-content-end gap-4`}
      >
        {isMembershipLoading ||
        isUpdateMembershipLoading ||
        isUpadateBenefitsLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <>
            <CustomCridetCardLetters
              isExternalImg={!uploadedImage}
              letterType={letters_type}
              bgImage={uploadedImage || incomingImage}
            />
            <div className={styles.innerContainer}>
              <form
                className={styles.companyDetailsForm}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="d-flex flex-nowrap gap-4">
                  <div className="w-100 d-flex flex-column gap-1">
                    <ControlledTextInput
                      control={control}
                      name="name"
                      containerStyle="w-100"
                      label={`${t("Membership_Name")} *`}
                      placeholder={t("Membership_Name")}
                      errorMsg={errors?.name?.message}
                    />
                  </div>
                  <div className="w-100 d-flex flex-column gap-1">
                    <Controller
                      control={control}
                      name="card_code"
                      render={({ field }) => (
                        <TextInput
                          value={field.value}
                          onChange={(e) => {
                            if (/^(|\d{0,4})$/.test(e.target.value))
                              field.onChange(e);
                          }}
                          containerStyle="w-100"
                          label={`${t("Card_Code")} *`}
                          placeholder={t("Code_With_Four_digits")}
                          status={
                            errors.card_code && errors.card_code.message
                              ? "error"
                              : "default"
                          }
                          errorMsg={errors?.card_code?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="d-flex gap-4">
                  <div className="w-100 d-flex flex-column gap-1">
                    <Controller
                      control={control}
                      name="price"
                      render={({ field }) => (
                        <TextInput
                          type="number"
                          onChange={field.onChange}
                          containerStyle="w-100"
                          label={`${t("Membership_Price")} *`}
                          placeholder={t("Membership_Price")}
                          value={String(field.value)}
                          status={
                            errors.name && errors.name.message
                              ? "error"
                              : "default"
                          }
                          suffix={
                            <Controller
                              control={control}
                              name="currency"
                              render={({ field: currencyField }) => (
                                <SelectionInput
                                  isLoading={isCurrenciesLoading}
                                  value={currencyField.value}
                                  onChange={currencyField.onChange}
                                  size="small"
                                  containerStyle={styles.textAndSelectDropdown}
                                  options={
                                    currenciesData
                                      ? Object.keys(currenciesData).map(
                                          (item) => ({
                                            label: item,
                                            value: item,
                                          })
                                        )
                                      : []
                                  }
                                  defaultValue="GBP"
                                />
                              )}
                            />
                          }
                        />
                      )}
                    />
                    {errors.price && errors.price.message && (
                      <div className="w-100">
                        <InstructionNote instructionType="danger">
                          <span className="Paragraph300Light">
                            {errors.price.message}
                          </span>
                        </InstructionNote>
                      </div>
                    )}
                    {errors.currency && errors.currency.message && (
                      <div className="w-100">
                        <InstructionNote instructionType="danger">
                          <span className="Paragraph300Light">
                            {errors.currency.message}
                          </span>
                        </InstructionNote>
                      </div>
                    )}
                  </div>
                  <div className="w-100" />
                </div>

                <div className={styles.uploadContainer}>
                  <span className="Label100">{t("Template_Image")}</span>
                  {incomingImage ? (
                    <div className={styles.incomingImage}>
                      {typeof membership?.template_image === "string" && (
                        <Image
                          src={membership?.template_image}
                          alt="uploaded-image"
                          className="w-100"
                        />
                      )}
                      <Button
                        btnClassName={styles.uploadedFileRemoveBtn}
                        styleType="NoStyle"
                        onClick={() => {
                          setIncomingImage(null);
                        }}
                      >
                        <Icon
                          name="Close"
                          size={16}
                          color="TextSecondaryBlack"
                        />
                      </Button>
                    </div>
                  ) : uploadedImage && uploadedFile ? (
                    <div className={styles.uploadedFile}>
                      <div>
                        <Icon
                          name="Image"
                          color="TextSecondaryBlack"
                          size={20}
                        />
                      </div>
                      <span className="Paragraph200Light">
                        {uploadedFile.name}
                      </span>
                      <span className="Paragraph300Light">
                        {(uploadedFile.size / 1024).toFixed(2)} KB
                      </span>
                      <Button
                        btnClassName={styles.uploadedFileRemoveBtn}
                        styleType="NoStyle"
                        onClick={() => {
                          setUploadedFile(null);
                          setUploadedImage("");
                        }}
                      >
                        <Icon
                          name="Close"
                          size={16}
                          color="TextSecondaryBlack"
                        />
                      </Button>
                    </div>
                  ) : (
                    <UploadDragger
                      containerStyle={styles.uploadDragger}
                      onChange={(e) => {
                        if (e.file.size <= 3145728) {
                          setUploadedFile(e.file);
                          setUploadedImage(e.file);
                        } else {
                          showToast({
                            toastType: "danger",
                            title: "File Size Exceeded",
                            msg: "Please upload an image smaller than 3MB.",
                            showCloseIcon: true,
                          });
                        }
                      }}
                    />
                  )}
                </div>

                <div className={styles.templateColors}>
                  <span className="Label100">{t("Card_Template")}</span>
                  <Controller
                    control={control}
                    name="letters_type"
                    render={({ field }) => (
                      <SelectionInput
                        i18nPlaceholder="Choose_Letters_Type"
                        isLoading={isCurrenciesLoading}
                        value={field.value}
                        onChange={field.onChange}
                        containerStyle={`w-100 ${styles.benefitsList}`}
                        options={
                          CARD_LETTERS_TYPES
                            ? CARD_LETTERS_TYPES.map((item) => ({
                                label: item,
                                value: item,
                              }))
                            : []
                        }
                      />
                    )}
                  />
                </div>

                <div className={styles.benefits}>
                  <span className="Label100">{t("Benefits")}</span>
                  <div className="d-flex justify-content-between flex-nowrap gap-3 gx-0">
                    <div
                      className={`${styles.benefitsDetailContainer} ${styles.dimmed} w-100 d-flex flex-column gap-2`}
                    >
                      {benefits.map((benefit: any, index) => (
                        <div
                          key={benefit.id}
                          className={`${styles.dimmed} w-100 d-flex flex-column gap-2`}
                        >
                          <div className="d-flex align-items-center gap-3">
                            {benefit.isAlreadyRegistered ? (
                              <span
                                className={`${styles.benefitTitle} Paragraph100Light`}
                              >
                                {benefit.general_benefit_name ||
                                  benefit.benefit}
                              </span>
                            ) : (
                              <Controller
                                name={`benefits.${index}.general_benefit`}
                                control={control}
                                render={({ field }) => (
                                  <SelectionInput
                                    size="small"
                                    value={
                                      field.value === 0
                                        ? undefined
                                        : String(field.value)
                                    }
                                    onChange={(value, option) => {
                                      field.onChange(value);
                                      setValue(
                                        `benefits.${index}.conditions`,
                                        []
                                      );
                                      setValue(
                                        `benefits.${index}.benefit`,
                                        (option as optionType)?.label
                                      );
                                    }}
                                    containerStyle={`w-100 ${styles.benefitsList}`}
                                    options={
                                      ConditionOptions?.map((condition) => ({
                                        value: String(condition.id),
                                        label: condition.name,
                                      })) ?? []
                                    }
                                    placeholder={t("Select_Benefit")}
                                    status={
                                      errors.benefits &&
                                      errors.benefits[index]?.general_benefit
                                        ?.message
                                        ? "error"
                                        : ""
                                    }
                                  />
                                )}
                              />
                            )}
                            <Controller
                              name={`benefits.${index}.is_active`}
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  defaultChecked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e);
                                  }}
                                />
                              )}
                            />
                          </div>
                          {errors.benefits &&
                            errors.benefits[index]?.general_benefit
                              ?.message && (
                              <div className="w-100">
                                <InstructionNote instructionType="danger">
                                  <span className="Paragraph300Light">
                                    {
                                      errors.benefits[index]?.general_benefit
                                        ?.message
                                    }
                                  </span>
                                </InstructionNote>
                              </div>
                            )}
                          <EditBenefitConditions
                            nestIndex={index}
                            control={control}
                            errors={errors}
                          />
                        </div>
                      ))}
                      <Button styleType="Secondary" onClick={handleAddBenefit}>
                        <span className="Button100">+ {t("Add_Benefit")}</span>
                      </Button>
                      {errors.benefits && errors.benefits.message && (
                        <div className="w-100">
                          <InstructionNote instructionType="danger">
                            <span className="Paragraph300Light">
                              {errors.benefits.message}
                            </span>
                          </InstructionNote>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <Controller
                    control={control}
                    name="annual_fees"
                    render={({ field }) => (
                      <TextInput
                        type="number"
                        onChange={field.onChange}
                        containerStyle="w-100 mb-1"
                        placeholder={t("Annual_Fees")}
                        label={`${t("Annual_Fees")} *`}
                        value={String(field.value)}
                        status={
                          errors.name && errors.name.message
                            ? "error"
                            : "default"
                        }
                      />
                    )}
                  />
                  {errors.annual_fees && errors.annual_fees.message && (
                    <div className="w-100 mb-1">
                      <InstructionNote instructionType="danger">
                        <span className="Paragraph300Light">
                          {errors.annual_fees.message}
                        </span>
                      </InstructionNote>
                    </div>
                  )}
                  <InstructionNote instructionType="info">
                    <span className="Paragraph300Light">
                      {" "}
                      {t("Disclaimer")}
                    </span>
                  </InstructionNote>
                </div>

                <ControlledTextInput
                  control={control}
                  name="notes"
                  type="textarea"
                  inputStyle={styles.formTextArea}
                  label={t("Additional_Notes")}
                  placeholder={t("Notes")}
                />

                <div className={styles.switchContainer}>
                  <span className="Paragraph100Light">
                    {isSwitchOpen ? t("Active") : t("Inactive")}
                  </span>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field }) => (
                      <Switch
                        className={styles.switch}
                        defaultChecked={isSwitchOpen}
                        onChange={(e) => {
                          field.onChange(e);
                          onSwitchChange();
                        }}
                      />
                    )}
                  />
                </div>

                <Button btnClassName={styles.updateBtn} type="submit">
                  <span>{t("Save")}</span>
                </Button>
              </form>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
