import Button from "components/general/Button";
import {
  Control,
  Controller,
  FieldErrors,
  UseFieldArrayAppend,
} from "react-hook-form";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import CheckBox from "components/general/CheckBox";
import { generalQuotationTypes } from "../..";
import { customFlightErrorTypes } from ".";
import styles from "../../styles.module.scss";

export default function PassengerItem({
  append,
  nestedIndex,
  index,
  errors,
  control,
}: {
  index: number;
  append: UseFieldArrayAppend<
    generalQuotationTypes,
    `quotations.${number}.passengers`
  >;
  nestedIndex: number;
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customFlightErrorTypes>;
}) {
  const { t } = useAutoCompleteTranslation();

  return (
    <div className={styles.spaceTop}>
      <div>
        {nestedIndex === 0 && (
          <div className="d-flex justify-content-between mb-2">
            <span className="Label100">{t("Client_Name")}</span>
            <Button
              styleType="NoStyle"
              btnClassName={styles.addAnotherRoomBtn}
              onClick={() =>
                append({
                  client_name: "",
                  price: "",
                  discount: "0",
                  discount_credit: 0,
                  is_child: false,
                  age: "",
                  no_of_adults: 0,
                  no_of_children: 0,
                  no_of_teens: 0,
                  no_of_infants: 0,
                  taxes_fee: 0,
                  apply_discount_on_taxes_and_basic_price: false,
                })
              }
            >
              <span className="Paragraph300Light">
                + {`${t("Add_One_More_Passenger")}`}
              </span>
            </Button>
          </div>
        )}
        <div className="d-flex w-100 flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.passengers.${nestedIndex}.client_name`}
            inputStyle={styles.inputBg}
            size="large"
            i18nPlaceholder="Client_Name"
            label={
              nestedIndex > 0
                ? `${t("Client_Name")} ${nestedIndex + 1}`
                : undefined
            }
            status={nestedIndex === 0 ? "disabled" : "default"}
            errorMsg={
              errors?.quotations?.[index]?.passengers?.[nestedIndex]
                ?.client_name?.message
            }
          />
        </div>
      </div>

      {nestedIndex === 0 && (
        <>
          <div className="d-flex justify-content-between gap-4">
            <div className="w-100 d-flex flex-column gap-1">
              <Controller
                control={control}
                name={`quotations.${index}.passengers.${nestedIndex}.no_of_adults`}
                render={({ field }) => (
                  <TextInput
                    type="number"
                    onChange={field.onChange}
                    containerStyle="w-100"
                    inputStyle={styles.inputBg}
                    i18nLabelKey="Adult"
                    i18nPlaceholder="Adult"
                    value={String(field.value)}
                    status="default"
                    errorMsg={
                      errors?.quotations?.[index]?.passengers?.[nestedIndex]
                        ?.no_of_adults?.message
                    }
                  />
                )}
              />
            </div>
            <div className="w-100 d-flex flex-column gap-1">
              <Controller
                control={control}
                name={`quotations.${index}.passengers.${nestedIndex}.no_of_teens`}
                render={({ field }) => (
                  <TextInput
                    type="number"
                    onChange={field.onChange}
                    containerStyle="w-100"
                    inputStyle={styles.inputBg}
                    i18nLabelKey="Teen"
                    i18nPlaceholder="Teen"
                    value={String(field.value)}
                    status="default"
                    errorMsg={
                      errors?.quotations?.[index]?.passengers?.[nestedIndex]
                        ?.no_of_teens?.message
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between gap-4">
            <div className="w-100 d-flex flex-column gap-1">
              <Controller
                control={control}
                name={`quotations.${index}.passengers.${nestedIndex}.no_of_children`}
                render={({ field }) => (
                  <TextInput
                    type="number"
                    onChange={field.onChange}
                    containerStyle="w-100"
                    inputStyle={styles.inputBg}
                    i18nLabelKey="Child"
                    i18nPlaceholder="Child"
                    value={String(field.value)}
                    status="default"
                    errorMsg={
                      errors?.quotations?.[index]?.passengers?.[nestedIndex]
                        ?.no_of_children?.message
                    }
                  />
                )}
              />
            </div>
            <div className="w-100 d-flex flex-column gap-1">
              <Controller
                control={control}
                name={`quotations.${index}.passengers.${nestedIndex}.no_of_infants`}
                render={({ field }) => (
                  <TextInput
                    type="number"
                    onChange={field.onChange}
                    containerStyle="w-100"
                    inputStyle={styles.inputBg}
                    i18nLabelKey="Infant"
                    i18nPlaceholder="Infant"
                    value={String(field.value)}
                    status="default"
                    errorMsg={
                      errors?.quotations?.[index]?.passengers?.[nestedIndex]
                        ?.no_of_infants?.message
                    }
                  />
                )}
              />
            </div>
          </div>
        </>
      )}

      {/* Adult & Child CheckBoxes */}
      {nestedIndex > 0 && (
        <div className="d-flex gap-4">
          <Controller
            control={control}
            name={`quotations.${index}.passengers.${nestedIndex}.is_child`}
            render={({ field }) => (
              <>
                <div className={styles.checkboxType_container}>
                  <CheckBox
                    value={!field.value}
                    checked={!field.value}
                    onCheckChange={() => field.onChange(!field.value)}
                    text={t("Adult")}
                  />
                </div>
                <div className={styles.checkboxType_container}>
                  <CheckBox
                    value={field.value}
                    checked={field.value}
                    onCheckChange={() => field.onChange(!field.value)}
                    text={t("Child")}
                  />
                </div>
              </>
            )}
          />
        </div>
      )}

      {nestedIndex > 0 && (
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.passengers.${nestedIndex}.age`}
            render={({ field }) => (
              <TextInput
                onChange={field.onChange}
                containerStyle="w-50 pe-2"
                inputStyle={styles.inputBg}
                i18nLabelKey="Age"
                placeholder="7 Years"
                value={String(field.value)}
                status={
                  errors?.quotations?.[index]?.passengers?.[nestedIndex]?.age
                    ?.message
                    ? "error"
                    : "default"
                }
                errorMsg={
                  errors?.quotations?.[index]?.passengers?.[nestedIndex]?.age
                    ?.message
                }
              />
            )}
          />
        </div>
      )}

      <div className="d-flex justify-content-between gap-4">
        <div className="d-flex w-100 flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.passengers.${nestedIndex}.price`}
            type="number"
            inputStyle={styles.inputBg}
            size="large"
            placeholder="00.00"
            i18nLabelKey="price"
            errorMsg={
              errors?.quotations?.[index]?.passengers?.[nestedIndex]?.price
                ?.message
            }
          />
        </div>
        <div className="d-flex w-100 flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.passengers.${nestedIndex}.taxes_fee`}
            render={({ field }) => (
              <TextInput
                type="number"
                inputStyle={styles.inputBg}
                size="large"
                placeholder="00.00"
                i18nLabelKey="Taxes_Fees"
                onChange={(val) => {
                  field.onChange(val);
                }}
                value={String(field.value)}
                status={
                  errors?.quotations?.[index]?.passengers?.[nestedIndex]
                    ?.taxes_fee?.message
                    ? "error"
                    : "default"
                }
                errorMsg={
                  errors?.quotations?.[index]?.passengers?.[nestedIndex]
                    ?.taxes_fee?.message
                }
              />
            )}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.passengers.${nestedIndex}.discount_credit`}
            render={({ field }) => (
              <TextInput
                type="number"
                onChange={field.onChange}
                containerStyle="w-100"
                inputStyle={styles.inputBg}
                i18nLabelKey="Kudos_Point"
                placeholder="20"
                value={String(field.value)}
                status={
                  errors?.quotations?.[index]?.passengers?.[nestedIndex]
                    ?.discount_credit?.message
                    ? "error"
                    : "default"
                }
                errorMsg={
                  errors?.quotations?.[index]?.passengers?.[nestedIndex]
                    ?.discount_credit?.message
                }
              />
            )}
          />
        </div>

        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.passengers.${nestedIndex}.discount`}
            type="number"
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Discount"
            placeholder="20"
            errorMsg={
              errors?.quotations?.[index]?.passengers?.[nestedIndex]?.discount
                ?.message
            }
          />
        </div>
      </div>
      <div className={styles.checkbox_container}>
        <Controller
          control={control}
          name={`quotations.${index}.passengers.${nestedIndex}.apply_discount_on_taxes_and_basic_price`}
          render={({ field }) => (
            <CheckBox
              value={field.value}
              checked={field.value}
              onCheckChange={field.onChange}
              text={t("Apply_Discount")}
            />
          )}
        />
      </div>
    </div>
  );
}
