import Button from "components/general/Button";
import Spinner from "components/general/Spinner";
import ModalWrapper from "components/modals/ModalWrapper";
import Icon from "components/general/Icon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PageTitle from "components/general/PageTitle";
import {
  useDeleteAccommodationMutation,
  useGetAccommodationQuery,
} from "apis/services/accommodations";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { City, Country } from "apis/types/accommodations";
import { useEffect, useState } from "react";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import { Rate } from "antd";
import styles from "./styles.module.scss";

export default function AccommodationDetails() {
  const { t } = useAutoCompleteTranslation();
  const { accommodationId } = useParams();
  const { pathname } = useLocation();
  const { data: accommodation, isLoading } = useGetAccommodationQuery(
    accommodationId || ""
  );
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deleteAccommodation, { isLoading: isDeleteLoading }] =
    useDeleteAccommodationMutation();
  const navigate = useNavigate();
  const handleEditBtn = () => {
    navigate(`/services/accommodation/edit-accommodation/${accommodationId}`);
  };

  const handleDeleteAgent = () => {
    if (accommodationId) {
      deleteAccommodation(accommodationId)
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/services");
          showToast({
            toastType: "success",
            title: `${accommodation?.name} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };

  useEffect(() => {
    if (accommodation) {
      const updatedUrl = `${pathname}?idName=${accommodation?.name}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [accommodation]);
  return (
    <section>
      <PageTitle>{accommodation?.name}</PageTitle>
      {isLoading ? (
        <div className="w-100 d-flex justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : (
        <div className={styles.agentDetails_container}>
          <div className={styles.agentInfo}>
            <div>
              <div className={styles.agentDetail}>
                <span className="Label100"> {t("Accommodation_name")} </span>
                <span className="Paragraph200Light">{accommodation?.name}</span>
              </div>
              <div className={styles.agentDetail}>
                <span className="Label100"> {t("Country")} </span>
                <span className="Paragraph200Light">
                  {(accommodation?.country as Country)?.name}
                </span>
              </div>
              <div className={styles.agentDetail}>
                <span className="Label100">{t("Address")}</span>
                {accommodation?.address}
              </div>
              <div className={styles.agentDetail}>
                <span className="Label100">{t("Policy")}</span>
                <span className="Paragraph200Light">
                  {accommodation?.place_policies}
                </span>
              </div>
            </div>
            <div>
              <div className={styles.agentDetail}>
                <span className="Label100"> {t("Accommodation_Type")} </span>
                {accommodation?.accomodation_type}
              </div>
              <div className={styles.agentDetail}>
                <span className="Label100">{t("City")}</span>
                <span className="Paragraph200Light">
                  {(accommodation?.city as City)?.name}
                </span>
              </div>
              <div className={styles.agentDetail}>
                <span className="Label100">{t("Rating")}</span>
                <div className={styles.rateContainer}>
                  <span className="Paragraph200Light">
                    {accommodation?.rating}
                  </span>
                  <Rate value={accommodation?.rating} disabled />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2">
            <Button
              styleType="Primary"
              prefix={<Icon name="Edit" size={16} color="TextPrimaryWhite" />}
              btnClassName={styles.editBtn}
              onClick={handleEditBtn}
            >
              <span className="Button100 text-white">{t("Edit")}</span>
            </Button>
            <Button
              styleType="Secondary"
              prefix={<Icon name="Delete" size={16} color="TextPrimaryBlack" />}
              btnClassName={styles.editBtn}
              onClick={() => setIsDeleteModalVisible(true)}
            >
              <span className="Button100">{t("Delete")}</span>
            </Button>
          </div>
        </div>
      )}
      <ModalWrapper
        size="large"
        headerTitle="Delete"
        modalType="error"
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        isFooterHidden
      >
        {isDeleteLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Medium" />
          </div>
        ) : (
          <>
            <span className="Paragraph100Light">
              {t("Delete_Modal")} ({accommodation?.name}
              )?
            </span>
            <div className={styles.deleteModalBtns}>
              <Button
                styleType="Secondary"
                btnClassName={styles.cancelBtn}
                onClick={() => setIsDeleteModalVisible(false)}
              >
                <span className="Button100">{t("Cancel")}</span>
              </Button>
              <Button
                btnClassName={styles.deleteBtn}
                onClick={handleDeleteAgent}
              >
                <span className="Button100">{t("Delete")}</span>
              </Button>
            </div>
          </>
        )}
      </ModalWrapper>
    </section>
  );
}
