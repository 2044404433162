/* eslint-disable no-nested-ternary */
import { useState } from "react";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import SelectionInput from "components/inputs/SelectionInput";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import { useNavigate, useSearchParams } from "react-router-dom";
import PageTitle from "components/general/PageTitle";
import Table from "components/general/Table";
import { Supplier } from "apis/types/suppliers";
import { TableItemProps } from "components/general/Table/types";
import {
  useDeleteSupplierMutation,
  useDeleteSuppliersBulkMutation,
  useGetSuppliersQuery,
} from "apis/services/suppliers";
import showToast from "utils/showToast";
import Spinner from "components/general/Spinner";
import handleErrors from "utils/handleErrors";
import { stringifyParam } from "utils/queryParamService";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "./styles.module.scss";

export default function Suppliers() {
  const { t } = useAutoCompleteTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const navigate = useNavigate();
  const serviceTypeOptions = [
    { value: "airlines", label: "Airlines" },
    { value: "airports", label: "Airports" },
    { value: "cabins", label: "Cabins" },
    { value: "airport_lounges", label: "Airport lounges access" },
    { value: "car_rental", label: "Car rental" },
    { value: "train", label: "Train ticket" },
    { value: "accommodations", label: "Accommodations" },
  ];

  // API's
  const {
    data: suppliers,
    isLoading,
    isFetching,
  } = useGetSuppliersQuery({
    page_size: localStorage.getItem("SuppliersTable")
      ? Number(localStorage.getItem("SuppliersTable"))
      : 15,
    page: searchParams.get("page") ?? 1,
    search: searchParams.get("search") ?? "",
    service: searchParams.get("service") ?? "",
    role: searchParams.get("role") ?? "",
  });

  const [deleteSupplier, { isLoading: isDeleteLoading }] =
    useDeleteSupplierMutation();
  const [deleteSuppliersBulk, { isLoading: isDeleteBulkLoading }] =
    useDeleteSuppliersBulkMutation();
  const handleDeleteSupplier = (supplierName: string, supplierId: string) => {
    if (supplierId) {
      deleteSupplier(supplierId)
        .unwrap()
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/suppliers");
          showToast({
            toastType: "success",
            title: `${supplierName} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };

  const handleDeleteBulk = (suppliersIds: string) => {
    deleteSuppliersBulk(suppliersIds)
      .unwrap()
      .then(() => {
        setIsDeleteModalVisible(false);
        showToast({
          toastType: "success",
          title: `Selected Suppliers have been deleted successfully!`,
          showCloseIcon: true,
        });
      })
      .catch(handleErrors);
  };

  const tableHeaders = [
    t("Supplier_Name"),
    t("Service_type"),
    t("Wallet_Amount"),
    t("Status"),
    "", // Make the last one empty string to let the menu btn appear here
  ];

  const arrangeSuppliersData = (item: Supplier): TableItemProps => ({
    id: String(item.id),
    rowData: [
      { type: "text", dataItem: `${item?.name} ` },
      { type: "text", dataItem: item.service },
      { type: "text", dataItem: `${item?.credit} ` },
      {
        type: "status",
        dataItem: item.status ? t("Active") : t("Inactive"),
      },
      { type: "more", dataItem: [item.name, "/suppliers/edit-supplier/"] },
    ],
  });

  return (
    <section>
      <div className={styles.filtersContainer}>
        <div className={styles.filtersGroup}>
          <PageTitle> {t("Suppliers")} </PageTitle>
        </div>
        <div className={styles.btnGroup}>
          <DebouncedSearch
            size="small"
            onSearchChange={(val) => {
              searchParams.set("search", val);
              setSearchParams(searchParams);
            }}
            defaultValue={String(
              searchParams
                ?.get("search")
                ?.split(",")
                .filter((filterd) => filterd !== "") ?? ""
            )}
          />
          <span className={styles.divider} />
          <SelectionInput
            size="small"
            placeholder={t("Service_type")}
            options={serviceTypeOptions}
            mode="tags"
            onChange={(val) => {
              searchParams.set("service", stringifyParam(val as string[]));
              setSearchParams(searchParams);
            }}
            value={
              searchParams
                ?.get("service")
                ?.split(",")
                ?.filter((filterd) => filterd !== "") ?? []
            }
            containerStyle={styles.tagsScroll}
          />

          <span className={styles.divider} />
          <Button
            btnClassName={styles.addNewCompanyBtn}
            size="small"
            styleType="Primary"
            prefix={
              <Icon name="Add-Outline" size={12} color="TextPrimaryWhite" />
            }
            onClick={() => navigate("add-new-supplier")}
          >
            <span className="Button200 text-nowrap">
              {" "}
              {t("Add_New_Supplier")}{" "}
            </span>
          </Button>
        </div>
      </div>

      {isFetching || isLoading ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : suppliers?.results?.length ? (
        <Table
          tableName="SuppliersTable"
          headers={tableHeaders}
          data={
            suppliers?.results?.map((item: Supplier) =>
              arrangeSuppliersData(item)
            ) ?? []
          }
          count={suppliers?.count}
          pagesCount={suppliers?.pages_number}
          deleteOneRow={handleDeleteSupplier}
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          isDeleteLoading={isDeleteLoading || isDeleteBulkLoading}
          deleteMultipleSelectedRows={handleDeleteBulk}
        />
      ) : (
        <div>No Data found!</div>
      )}
    </section>
  );
}
