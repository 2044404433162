import Button from "components/general/Button";
import { Control, FieldErrors, UseFieldArrayAppend } from "react-hook-form";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import { generalQuotationTypes } from "../..";
import { customgeneralErrorTypes } from ".";
import styles from "../../styles.module.scss";

export default function ParameterItem({
  append,
  nestedIndex,
  index,
  errors,
  control,
}: {
  index: number;
  append: UseFieldArrayAppend<
    generalQuotationTypes,
    `quotations.${number}.parameters`
  >;
  nestedIndex: number;
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customgeneralErrorTypes>;
}) {
  const { t } = useAutoCompleteTranslation();

  return (
    <div className={styles.spaceTop}>
      <div>
        {nestedIndex === 0 && (
          <div className="d-flex justify-content-between mb-2">
            <Button
              styleType="NoStyle"
              btnClassName={`${styles.addAnotherRoomBtn} ms-auto`}
              onClick={() =>
                append({
                  name: "",
                  order: "",
                  value: "",
                })
              }
            >
              <span className="Paragraph300Light">
                + {`${t("Add_One_More_Parameter")}`}
              </span>
            </Button>
          </div>
        )}
      </div>

      <div className="w-100 d-flex flex-column gap-1">
        <ControlledTextInput
          control={control}
          name={`quotations.${index}.parameters.${nestedIndex}.name`}
          containerStyle="w-100"
          inputStyle={styles.inputBg}
          i18nLabelKey="name"
          i18nPlaceholder="name"
          errorMsg={
            errors?.quotations?.[index]?.parameters?.[nestedIndex]?.name
              ?.message
          }
        />
      </div>
      <div className="w-100 d-flex flex-column gap-1">
        <ControlledTextInput
          control={control}
          name={`quotations.${index}.parameters.${nestedIndex}.value`}
          containerStyle="w-100"
          inputStyle={styles.inputBg}
          i18nLabelKey="value"
          i18nPlaceholder="value"
          errorMsg={
            errors?.quotations?.[index]?.parameters?.[nestedIndex]?.value
              ?.message
          }
        />
      </div>
      <div className="w-100 d-flex flex-column gap-1">
        <ControlledTextInput
          control={control}
          name={`quotations.${index}.parameters.${nestedIndex}.order`}
          type="number"
          containerStyle="w-100"
          inputStyle={styles.inputBg}
          i18nLabelKey="Order"
          i18nPlaceholder="Order"
          errorMsg={
            errors?.quotations?.[index]?.parameters?.[nestedIndex]?.order
              ?.message
          }
        />
      </div>
    </div>
  );
}
