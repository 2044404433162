import api from "apis";
import { PaginatedResponse } from "apis/types/general";
import { AccommodationQuotation } from "apis/types/accommodationQuotation";
import { FlightQuotation } from "apis/types/flightQuotation";
import {
  GeneralQuotation,
  GeneralQuotationGetReq,
  GetGeneralQuotationParams,
} from "../types/generalQuotations";

export const generalQuotationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getGeneralQuotations: build.query<
      PaginatedResponse<GeneralQuotationGetReq>,
      GetGeneralQuotationParams
    >({
      query: (params) => ({
        url: "/general-quotations/",
        params: { ordering: "-id", ...params },
      }),
      forceRefetch: () => true,
    }),

    getGeneralQuotation: build.query<GeneralQuotationGetReq, string>({
      query: (id) => ({
        url: `/general-quotations/${id}/`,
      }),
      forceRefetch: () => true,
      providesTags: ["quotations"],
    }),

    addGeneralQuotation: build.mutation<
      Pick<
        GeneralQuotationGetReq,
        "id" | "quotation_id" | "created_on" | "agent" | "customer" | "country"
      >,
      GeneralQuotation
    >({
      query: (body) => ({
        url: "/general-quotations/",
        method: "POST",
        body,
      }),
    }),

    updateGeneralQuotation: build.mutation<
      GeneralQuotation,
      [id: string, body: Omit<GeneralQuotation, "created_on">]
    >({
      query: ([id, body]) => ({
        url: `/general-quotations/${id}/`,
        method: "PATCH",
        body,
      }),
    }),

    deleteGeneralQuotation: build.mutation<GeneralQuotation, string>({
      query: (id) => ({
        url: `/general-quotations/${id}/`,
        method: "DELETE",
      }),
    }),

    deleteGeneralQuotationsBulk: build.mutation<any, string>({
      query: (ids) => ({
        url: `/general-quotations/delete_bulk/?ids=${ids}`,
        method: "DELETE",
      }),
    }),

    addOrEditQuotations: build.mutation<
      any,
      (
        | Partial<Omit<AccommodationQuotation, "id">>
        | Partial<Omit<FlightQuotation, "id">>
      )[]
    >({
      query: (body) => ({
        url: "/quotations/",
        method: "PATCH",
        body,
      }),
    }),

    confirmQuotation: build.mutation<
      any,
      [string, Required<Pick<GeneralQuotation, "is_confirmed">>]
    >({
      query: ([id, body]) => ({
        url: `/general-quotations/${id}/make_confirmation/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["quotations"],
    }),

    confirmGeneralQuotation: build.mutation<
      any,
      [string, Required<Pick<GeneralQuotation, "is_visible_in_website">>]
    >({
      query: ([id, body]) => ({
        url: `/general-quotations/${id}/show_quotation/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["quotations"],
    }),
  }),
});

export const {
  useGetGeneralQuotationsQuery,
  useGetGeneralQuotationQuery,
  useAddGeneralQuotationMutation,
  useUpdateGeneralQuotationMutation,
  useDeleteGeneralQuotationMutation,
  useDeleteGeneralQuotationsBulkMutation,
  useAddOrEditQuotationsMutation,
  useConfirmQuotationMutation,
  useConfirmGeneralQuotationMutation,
} = generalQuotationsApi;
