/* eslint-disable no-nested-ternary */
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { Select } from "antd";
import Icon from "components/general/Icon";
import CheckBox from "components/general/CheckBox";
import Spinner from "components/general/Spinner";
import { WalletSelectionInputProps } from "./types";
import styles from "./styles.module.scss";

export default function WalletSelectionInput({
  containerStyle,
  onChange,
  options,
  value,
  placeholder = "",
  size = "large",
  label,
  i18nPlaceholder,
  i18nLabelKey,
  status = "",
  isDisabled = false,
  defaultValue = undefined,
  isLoading = false,
  onScroll = undefined,
  onInputKeyDown = undefined,
}: WalletSelectionInputProps) {
  const { t } = useAutoCompleteTranslation();
  const id = `selectInput${Math.random()}`;

  const tagOption = (option: any) => {
    return (
      <div className={styles.tagOption}>
        <div className={styles.checkboxContainer}>
          {option?.data?.isActive && (
            <CheckBox
              checked={value?.includes(String(option?.data?.value))}
              value={option?.data?.value}
            />
          )}
        </div>
        <div className={styles.rowContainer}>
          <div className="Paragraph200Light">
            {option?.data?.label} {option?.data?.walletCurrency}
          </div>
          <div className="Paragraph200Light">
            {option?.data?.remainingAmount} {option?.data?.walletCurrency}
          </div>
          <div>
            {option?.data?.isActive ? (
              <div className={styles.activeContainer}>
                <div className={styles.greenCircle} />
                <span className="Label200">Active</span>
              </div>
            ) : (
              <div className={styles.inActiveContainer}>
                <div className={styles.redCircle} />
                <span className="Label200">Inactive</span>
              </div>
            )}
          </div>
          <div className="Paragraph200Light">{option?.data?.walletType}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${containerStyle}`} id={id}>
      {(!!label || !!i18nLabelKey) && (
        <span className="d-block mb-2 Label100">
          {i18nLabelKey ? t(i18nLabelKey) : label}
        </span>
      )}
      <Select
        mode="multiple"
        className={`${styles.selectInput} ${styles[size]} w-100`}
        getPopupContainer={(trigger: HTMLElement | null) => {
          return (
            (trigger?.parentNode as HTMLElement) ?? document.getElementById(id)
          );
        }}
        suffixIcon={
          isLoading ? (
            <Spinner containerStyle={styles.loadingSpinner} size="Small" />
          ) : (
            <Icon name="ChevronDown" size={16} color="TextSecondaryBlack" />
          )
        }
        placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
        onChange={onChange}
        value={value?.map((item) => String(item))}
        options={options}
        status={status}
        optionRender={tagOption}
        disabled={isDisabled}
        defaultValue={defaultValue}
        onPopupScroll={onScroll}
        notFoundContent={null}
        onInputKeyDown={onInputKeyDown}
        defaultActiveFirstOption={false}
        showSearch={false}
        filterOption={false}
      />
    </div>
  );
}
