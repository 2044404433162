import api from "apis";
import { Currencies } from "apis/types/currency";

export const currenciesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrencies: build.query<Currencies, void>({
      query: () => ({
        url: "/currency-converter/",
      }),
      forceRefetch: () => true,
    }),
  }),
});

export const { useGetCurrenciesQuery } = currenciesApi;
