/* eslint-disable jsx-a11y/control-has-associated-label */
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useToggleState from "hooks/useToggleState";
import Menu from "components/Menu";
import ModalWrapper from "components/modals/ModalWrapper";
import { stringifyParam } from "utils/queryParamService";
import { Rate } from "antd";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Icon from "../Icon";
import Image from "../Image";
import { TableProps } from "./types";
import Button from "../Button";
import StatusBadge from "../StatusBadge";
import CheckBox from "../CheckBox";
import Spinner from "../Spinner";
import styles from "./styles.module.scss";

export default function Table({
  tableName,
  containerStyle,
  headers,
  data,
  isCheckBoxVisible = true,
  count = 0,
  pagesCount = 0,
  deleteMultipleSelectedRows,
  deleteOneRow,
  isDeleteLoading = false,
  isDeleteModalVisible,
  setIsDeleteModalVisible,
}: TableProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useAutoCompleteTranslation();

  const [deleteTarget, setDeleteTarget] = useState({ name: "", id: "" });

  const currentPage = searchParams.get("page")
    ? Number(searchParams.get("page"))
    : 1;

  const tableRowsCount = localStorage.getItem(tableName)
    ? Number(localStorage.getItem(tableName))
    : 15;
  const startIdx = (currentPage - 1) * tableRowsCount;
  const endIdx = Math.min(currentPage * tableRowsCount, count);

  const handlePageChange = (page: number) => {
    searchParams.set("page", page.toString());
    setSearchParams(searchParams);
  };

  const [isAllCheked, setIsAllChecked] = useState(false);
  const { selectedValues, isSelected, addToSelected, replaceState } =
    useToggleState<string>([]);

  const checkAll = () => {
    replaceState([...selectedValues, ...data.map((row) => row.id)]);
  };

  const unCheckAll = () => {
    replaceState([
      ...selectedValues.filter((id) => !data.find((item) => item.id === id)),
    ]);
  };

  useEffect(() => {
    let temp = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const item of data) {
      if (isSelected(item.id)) {
        temp = true;
      } else {
        temp = false;
        break;
      }
    }

    setIsAllChecked(temp);
  }, [isSelected, data]);

  // Calculate itemsPerPage based on the remaining height of the screen starting from the table headers row to the end of the visible height minus table footer height
  useEffect(() => {
    if (!localStorage.getItem(tableName)) {
      const tableHeaderPositionBottom = document
        .getElementById("tableHeader")
        ?.getBoundingClientRect()?.bottom;

      const tableRowHeight =
        document.getElementById("tableRow")?.offsetHeight || 0;

      const tableFooterHeight =
        document.getElementById("tableFooter")?.offsetHeight;

      const documentHight = document.body.offsetHeight;

      const freeHeightDistance =
        (tableHeaderPositionBottom &&
          tableFooterHeight &&
          documentHight - tableHeaderPositionBottom - tableFooterHeight) ||
        0;

      const rowsCount = Math.floor(freeHeightDistance / tableRowHeight);

      localStorage.setItem(tableName, String(rowsCount));
      window.location.reload();
    }
  }, []);

  const handleDelete = () => {
    if (selectedValues.length > 1) {
      deleteMultipleSelectedRows?.(deleteTarget.id);
    } else {
      deleteOneRow?.(deleteTarget.name, deleteTarget.id);
    }
  };

  const TargetChildMap = new Map<string, (item: any, id: string) => any>([
    [
      "text",
      (item, id) => (
        <Button
          btnClassName={styles.tableBtn}
          styleType="NoStyle"
          onClick={() => navigate(`${id}`)}
        >
          <span className="Paragraph200Light text-capitalize">{item}</span>
        </Button>
      ),
    ],
    [
      "rate",
      (item) => (
        <div className="d-flex gap-2 align-items-center ">
          <span className="Paragraph200Light"> {item} </span>
          <Rate
            className={styles.antRateStar}
            disabled
            allowHalf
            defaultValue={item}
          />
        </div>
      ),
    ],
    [
      "status",
      (item) => (
        <StatusBadge type={item === t("Inactive") ? "error" : "success"}>
          <span className="Label200">{item}</span>
        </StatusBadge>
      ),
    ],
    [
      "logo",
      (item, id) => (
        <Button
          btnClassName={styles.tableBtn}
          styleType="NoStyle"
          onClick={() => navigate(`${id}`)}
        >
          {item !== "null" ? (
            <Image
              className={styles.companyLogo}
              src={item}
              alt="companyLogo"
            />
          ) : (
            <Icon name="Image" size={38} />
          )}
        </Button>
      ),
    ],
    [
      "more",
      (item, id) => (
        <Menu
          items={[
            {
              key: 1,
              label: t("Edit"),
              onClick: () => navigate(`${item[1]}${id}`),
            },
            {
              key: 2,
              label: t("Delete"),
              onClick: () => {
                setDeleteTarget({ name: item[0], id });
                setIsDeleteModalVisible?.(true);
              },
            },
          ]}
        >
          <Icon name="More" size={20} color="TextSecondaryBlack" />
        </Menu>
      ),
    ],
    [
      "membership",
      (arr) => {
        return (
          arr?.length > 0 && (
            <span className={`Paragraph200Light ${styles.memberships}`}>
              {arr.length > 1
                ? `${arr[0]?.membership}, ${arr[1]?.membership}`
                : `${arr[0]?.membership}`}

              {arr.length > 2 && (
                <Button
                  btnClassName={styles.membershipMoreBtn}
                  styleType="Secondary"
                >
                  <span className="Paragraph200Light">+{arr.length - 2}</span>
                </Button>
              )}
            </span>
          )
        );
      },
    ],
    [
      "link",
      (item) => (
        <Button
          styleType="NoStyle"
          btnClassName={styles.linkBtn}
          onClick={item[1]}
        >
          <span className="Button100">{item[0]}</span>
        </Button>
      ),
    ],
    [
      "pureText",
      (item, id) => <span className={`Paragraph200Light ${id}`}>{item}</span>,
    ],
  ]);

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <table>
        <thead id="tableHeader">
          <tr>
            {isCheckBoxVisible && (
              <th>
                <CheckBox
                  checked={isAllCheked}
                  onCheckChange={(e) => {
                    if (e) {
                      checkAll();
                    } else {
                      unCheckAll();
                    }
                    setIsAllChecked(e);
                  }}
                />
              </th>
            )}
            {headers.map((item, key) => {
              if (key === headers.length - 1 && selectedValues.length > 1)
                return (
                  <th key={`Key${+key}`} className="Label200">
                    <Menu
                      items={[
                        {
                          key: 1,
                          label: "Delete Selected",
                          onClick: () => {
                            setDeleteTarget({
                              name: "Selected Items",
                              id: stringifyParam(selectedValues),
                            });
                            setIsDeleteModalVisible?.(true);
                          },
                        },
                      ]}
                    >
                      <Icon name="More" size={20} color="TextSecondaryBlack" />
                    </Menu>
                  </th>
                );
              return (
                <th key={`Key${+key}`} className="Label200">
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => {
            return (
              <tr key={row.id} id="tableRow">
                {isCheckBoxVisible && (
                  <td>
                    <CheckBox
                      checked={isSelected(row.id)}
                      value={row.id}
                      onCheckChange={() => {
                        addToSelected(row.id);
                      }}
                    />
                  </td>
                )}
                {row.rowData.map((item, key) => (
                  <td key={`Key${+key}`}>
                    {TargetChildMap.get(item.type)?.(item.dataItem, row.id)}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {count > 0 && (
        <div className={styles.tableFooter} id="tableFooter">
          <span className="Paragraph300Light">
            {t("Showing")} {startIdx + 1}-{endIdx} {t("Of")} {count}{" "}
            {t("Items")}
          </span>
          <div className={styles.tableFooterBtnsContainer}>
            {Array.from({ length: pagesCount }, (_, index) => {
              const currentPageNumber = currentPage;
              const halfMaxPages = Math.floor(5 / 2);
              const startPage =
                currentPageNumber <= halfMaxPages
                  ? 1
                  : Math.min(
                      Math.max(currentPageNumber - halfMaxPages, 1),
                      pagesCount - 5 + 1
                    );
              const endPage = Math.min(startPage + 5 - 1, pagesCount);

              if (index + 1 >= startPage && index + 1 <= endPage) {
                return (
                  <Button
                    key={index}
                    btnClassName={`${styles.tableFooterBtn} ${
                      currentPage === index + 1
                        ? styles.tableFooterBtnActive
                        : ""
                    }`}
                    styleType="Secondary"
                    onClick={() => {
                      handlePageChange(index + 1);
                    }}
                  >
                    <span className="Paragraph200Light">{index + 1}</span>
                  </Button>
                );
              }
              if (
                (index === startPage - 2 && index !== 0) ||
                (index === endPage && index !== pagesCount - 1)
              ) {
                // Add ellipsis (...) for hidden pages
                return (
                  <span key={index} className="Paragraph200Light">
                    ...
                  </span>
                );
              }
              if (index === 0 || index === pagesCount - 1) {
                // Display the first and last page numbers
                return (
                  <Button
                    key={index}
                    btnClassName={`${styles.tableFooterBtn} ${
                      currentPage === index + 1
                        ? styles.tableFooterBtnActive
                        : ""
                    }`}
                    styleType="Secondary"
                    onClick={() => {
                      handlePageChange(index + 1);
                    }}
                  >
                    <span className="Paragraph200Light">{index + 1}</span>
                  </Button>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}
      <ModalWrapper
        size="large"
        headerTitle={t("Delete")}
        modalType="error"
        isVisible={isDeleteModalVisible ?? false}
        setIsVisible={setIsDeleteModalVisible}
        isFooterHidden
        onClose={() => setDeleteTarget({ name: "", id: "" })}
      >
        {isDeleteLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Medium" />
          </div>
        ) : (
          <>
            <span className="Paragraph100Light">
              {t("Delete_Modal")} ({deleteTarget.name})?
            </span>
            <div className={styles.deleteModalBtns}>
              <Button
                styleType="Secondary"
                btnClassName={styles.cancelBtn}
                onClick={() => {
                  setDeleteTarget({ name: "", id: "" });
                  setIsDeleteModalVisible?.(false);
                }}
              >
                <span className="Button100"> {t("Cancel")} </span>
              </Button>
              <Button btnClassName={styles.deleteBtn} onClick={handleDelete}>
                <span className="Button100">{t("Delete")}</span>
              </Button>
            </div>
          </>
        )}
      </ModalWrapper>
    </div>
  );
}
