import { useEffect, useState } from "react";
import { useDeleteAgentMutation, useGetAgentQuery } from "apis/services/agents";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import StatusBadge from "components/general/StatusBadge";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import Spinner from "components/general/Spinner";
import showToast from "utils/showToast";
import ModalWrapper from "components/modals/ModalWrapper";
import PageTitle from "components/general/PageTitle";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import dayjs from "dayjs";
import styles from "./styles.module.scss";

export default function AgentDetails() {
  const { t } = useAutoCompleteTranslation();
  const roleOptions = {
    admin: t("Admin"),
    booking_team: t("Booking_Team"),
    finance: t("Finance"),
    company_admin: t("Company_Admin"),
  };

  const { agentId } = useParams();
  const { pathname } = useLocation();
  const { data: agent, isLoading } = useGetAgentQuery(agentId || "");
  const [deleteAgent, { isLoading: isDeleteLoading }] =
    useDeleteAgentMutation();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const navigate = useNavigate();
  const handleEditBtn = () => {
    navigate(`/user-management/edit-user/${agentId}`);
  };
  const handleDeleteAgent = () => {
    if (agentId) {
      deleteAgent(agentId)
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/user-management");
          showToast({
            toastType: "success",
            title: `${agent?.first_name} ${agent?.last_name} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };

  useEffect(() => {
    if (agent) {
      const updatedUrl = `${pathname}?idName=${agent?.first_name} ${agent?.last_name}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [agent]);

  return (
    <section>
      <PageTitle>
        {agent?.first_name} {agent?.last_name}
      </PageTitle>
      {isLoading ? (
        <div className="w-100 d-flex justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : (
        <div className={styles.agentDetails_container}>
          <div className={styles.agentInfo}>
            <div>
              <div className={styles.agentDetail}>
                <span className="Label100">{t("First_Name")}</span>
                <span className="Paragraph200Light"> {agent?.first_name} </span>
              </div>
              <div className={styles.agentDetail}>
                <span className="Label100">{t("Email")}</span>
                <span className="Paragraph200Light"> {agent?.email} </span>
              </div>
              <div className={styles.agentDetail}>
                <span className="Label100">{t("Status")}</span>
                <StatusBadge type={agent?.is_active ? "success" : "error"}>
                  <span className="Label200">
                    {agent?.is_active ? t("Active") : t("Inactive")}
                  </span>
                </StatusBadge>
              </div>
              <div className={styles.agentDetail}>
                <span className="Label100">{t("Role")}</span>
                <span className="Paragraph200Light">
                  {/* @ts-ignore */}
                  {agent && agent.role && roleOptions[agent.role]}
                </span>
              </div>
            </div>
            <div>
              <div className={styles.agentDetail}>
                <span className="Label100">{t("Last_Name")}</span>
                <span className="Paragraph200Light"> {agent?.last_name} </span>
              </div>
              <div className={styles.agentDetail}>
                <span className="Label100"> {t("Date_Created")} </span>
                <span className="Paragraph200Light">
                  {dayjs(agent?.created_on).format("DD/MM/YYYY")}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2">
            <Button
              styleType="Primary"
              prefix={<Icon name="Edit" size={16} color="TextPrimaryWhite" />}
              btnClassName={styles.editBtn}
              onClick={handleEditBtn}
            >
              <span className="Button100 text-white"> {t("Edit")} </span>
            </Button>
            <Button
              styleType="Secondary"
              prefix={<Icon name="Delete" size={16} color="TextPrimaryBlack" />}
              btnClassName={styles.editBtn}
              onClick={() => setIsDeleteModalVisible(true)}
            >
              <span className="Button100"> {t("Delete")} </span>
            </Button>
          </div>
        </div>
      )}
      <ModalWrapper
        size="large"
        headerTitle={t("Delete")}
        modalType="error"
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        isFooterHidden
      >
        {isDeleteLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Medium" />
          </div>
        ) : (
          <>
            <span className="Paragraph100Light">
              {t("Delete_Modal")} ({agent?.first_name} {agent?.last_name})?
            </span>
            <div className={styles.deleteModalBtns}>
              <Button
                styleType="Secondary"
                btnClassName={styles.cancelBtn}
                onClick={() => setIsDeleteModalVisible(false)}
              >
                <span className="Button100"> {t("Cancel")} </span>
              </Button>
              <Button
                btnClassName={styles.deleteBtn}
                onClick={handleDeleteAgent}
              >
                <span className="Button100">{t("Delete")}</span>
              </Button>
            </div>
          </>
        )}
      </ModalWrapper>
    </section>
  );
}
