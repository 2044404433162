import PageTitle from "components/general/PageTitle";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { useCallback, useEffect, useState } from "react";
import {
  useGetConversationMessagesQuery,
  useGetConversationsQuery,
} from "apis/services/chat";
import { ChatMessage, Conversation } from "apis/types/chat";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import DomainUrl from "apis/Domain";
import { PaginatedResponse } from "apis/types/general";
import Button from "components/general/Button";
import useScreenSize from "hooks/useScreenSize";
import Sidebar from "./Sidebar";
import styles from "./styles.module.scss";
import ChatBody from "./ChatBody";

export default function ChatComponent() {
  const { t } = useAutoCompleteTranslation();
  const { width } = useScreenSize();

  const [isConversationsOpen, setIsConversationsOpen] = useState(width < 700);

  const token = useSelector((state: RootState) => state.auth.token);
  const [search, setSearch] = useState("");
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [conversationsSocket, setConversationsSocket] =
    useState<WebSocket | null>(null);

  const [messagesPage, setMessagesPage] = useState(1);
  const [conversationsPage, setConversationsPage] = useState(1);

  const [activeConversation, setActiveConversation] =
    useState<Conversation | null>(null);

  const [messages, setMessages] = useState<
    PaginatedResponse<ChatMessage> | undefined
  >({ count: 0, next: null, pages_number: 0, previous: null, results: [] });

  const [conversations, setConversations] = useState<
    PaginatedResponse<Conversation> | undefined
  >({ count: 0, next: null, pages_number: 0, previous: null, results: [] });

  const {
    data: oldConversations,
    isLoading: isOldConversationsLoading,
    refetch: refetchOldConversations,
  } = useGetConversationsQuery({
    page: conversationsPage,
    search,
  });

  const {
    data: oldMessages,
    isLoading: isMessagesLoading,
    refetch: refetchOldMessages,
  } = useGetConversationMessagesQuery(
    { id: activeConversation?.id ?? 0, page: messagesPage },
    {
      skip: activeConversation === null || socket === null,
    }
  );

  // Load old msgs and converstions
  useEffect(() => {
    if (!oldConversations) return;
    setConversations(oldConversations);
  }, [oldConversations]);

  useEffect(() => {
    if (!oldConversations) return;
    if (oldConversations.results.length > 0) {
      refetchOldConversations();
    }
  }, []);

  useEffect(() => {
    if (!oldMessages) return;
    setMessages(oldMessages);
  }, [oldMessages]);

  useEffect(() => {
    if (oldMessages && activeConversation) {
      refetchOldMessages();
    }
  }, [activeConversation]);

  // init sockets
  const initConversationsSocket = useCallback(() => {
    (async () => {
      const socketUrl = `wss://${
        DomainUrl.split("https://")[1]
      }/ws/notification-server/?token=${token}`;

      const url = new WebSocket(socketUrl);
      setConversationsSocket(url);
    })();
  }, []);

  const initSocket = useCallback(() => {
    (async () => {
      const socketUrl = `wss://${
        DomainUrl.split("https://")[1]
      }/ws/socket-server/${activeConversation?.id}/?token=${token}`;

      const url = new WebSocket(socketUrl);
      setSocket(url);
    })();
  }, [activeConversation?.id]);

  useEffect(() => {
    initConversationsSocket();
    // eslint-disable-next-line consistent-return
    return () => {
      conversationsSocket?.close();
      setConversationsSocket(null);
    };
  }, []);

  useEffect(() => {
    if (!activeConversation) return;
    initSocket();
    if (width < 800) setIsConversationsOpen(false);
    // eslint-disable-next-line consistent-return
    return () => {
      socket?.close();
      setSocket(null);
    };
  }, [activeConversation]);

  // Socket listeners
  useEffect(() => {
    if (!conversationsSocket) return;

    conversationsSocket.onopen = () =>
      console.log("conversationsSocket Connected to the server");

    conversationsSocket.onclose = () =>
      console.log(
        "conversationsSocket Disconnected. Check internet or server."
      );

    conversationsSocket.onerror = (e) => console.log(e);

    conversationsSocket.onmessage = (event: MessageEvent) => {
      const data: number = JSON.parse(event.data);

      if (activeConversation?.id === Number(data)) {
        return;
      }

      setConversations((prevState) => {
        let modifiedConversation = prevState?.results.find(
          (conversation) => conversation.id === Number(data)
        );

        if (modifiedConversation) {
          modifiedConversation = {
            ...modifiedConversation,
            unread_count: modifiedConversation.unread_count + 1,
          };

          return {
            ...prevState!,
            results: [
              modifiedConversation!,
              ...prevState!.results.filter(
                (conversation) => conversation.id !== Number(data)
              ),
            ],
          };
        }
        return prevState;
      });
    };
  }, [conversationsSocket, activeConversation]);

  useEffect(() => {
    if (!socket) return;

    socket.onopen = () => console.log("Connected to the server");

    socket.onclose = () =>
      console.log("Disconnected. Check internet or server.");

    socket.onerror = (e) => console.log(e);

    socket.onmessage = (event: MessageEvent) => {
      const data: ChatMessage = JSON.parse(event.data);
      if (activeConversation?.id !== Number(data.chat)) return;

      setMessages((prevState) => ({
        ...prevState!,
        results: [data, ...prevState!.results],
      }));
    };
  }, [socket, activeConversation]);

  return (
    <section>
      <PageTitle> {t("Chat_Rooms")} </PageTitle>
      <div className={styles.container}>
        {activeConversation && width < 800 && !isConversationsOpen && (
          <Button onClick={() => setIsConversationsOpen(true)}>
            Open Chats
          </Button>
        )}
        <div className="d-flex">
          <Sidebar
            conversationsPage={conversationsPage}
            setConversationsPage={setConversationsPage}
            setMessagesPage={setMessagesPage}
            conversations={conversations}
            activeConversation={activeConversation}
            setActiveConversation={setActiveConversation}
            setConversations={setConversations}
            isLoading={isOldConversationsLoading}
            setSearch={setSearch}
            isConversationsOpen={isConversationsOpen}
            closeConversations={() => setIsConversationsOpen(false)}
          />
          <ChatBody
            activeConversation={activeConversation}
            messages={messages}
            isLoading={isMessagesLoading}
            page={messagesPage}
            setPage={setMessagesPage}
            socket={socket}
            isConversationsOpen={isConversationsOpen}
          />
        </div>
      </div>
    </section>
  );
}
