import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import TextInput from "components/inputs/TextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InstructionNote from "components/general/InstructionNote";
import SelectionInput from "components/inputs/SelectionInput";
import Button from "components/general/Button";
import { useNavigate } from "react-router-dom";
import { useAddSupplierMutation } from "apis/services/suppliers";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import Spinner from "components/general/Spinner";
import { Switch } from "antd";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import { useGetCurrenciesQuery } from "apis/services/currency";
import styles from "./styles.module.scss";

const serviceTypeOptions = [
  { value: "airlines", label: "Airlines" },
  { value: "airports", label: "Airports" },
  { value: "cabins", label: "Cabins" },
  { value: "airport_lounges", label: "Airport lounges access" },
  { value: "car_rental", label: "Car rental" },
  { value: "train", label: "Train ticket" },
  { value: "accommodations", label: "Accommodations" },
];

const schema = yup.object({
  name: yup
    .string()
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name must be at most 50 characters")
    .required("First name is required!"),
  credit: yup
    .number()
    .moreThan(0, "Credit must be more than 0")
    .required("Credit is required!")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  service: yup.string().required("Service Type field is required"),
  currency: yup.string().required("Currency field is required"),
  status: yup.boolean().required(),
});

type formData = yup.InferType<typeof schema>;

export default function AddNewSupplier() {
  const { t } = useAutoCompleteTranslation();

  const navigate = useNavigate();
  const [addSupplier, { isLoading }] = useAddSupplierMutation();

  const { data: currenciesData, isLoading: isCurrenciesLoading } =
    useGetCurrenciesQuery();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<formData>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      credit: 0,
      service: "",
      currency: "",
      status: true,
    },
    mode: "onSubmit",
  });

  const [statusVal, setStatusVal] = useState<string | string[]>([]);
  const [currency, setCurrency] = useState<string | string[]>([]);
  const [isSwitchOpen, setIsSwitchOpen] = useState(true);
  const onSwitchChange = () => {
    setIsSwitchOpen(!isSwitchOpen);
  };
  const onSubmit = (data: formData) => {
    addSupplier(data)
      .unwrap()
      .then(() => {
        navigate("/suppliers");
        showToast({
          toastType: "success",
          title: `New supplier has been added successfully!`,
          msg: "Congratulations! ",
          showCloseIcon: true,
        });
      })
      .catch((err) => {
        handleErrors(err);
        navigate("/suppliers");
      });
  };
  return (
    <section>
      <span className="Headline300Heavy"> {t("Add_New_Supplier")} </span>
      <div className={styles.addNewAgent_container}>
        {isLoading ? (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.formContainer}
          >
            <div className="d-flex flex-column gap-1">
              <ControlledTextInput
                control={control}
                name="name"
                errorMsg={errors?.name?.message}
                label={t("NAME")}
                placeholder={t("name")}
              />
            </div>

            {/* Service Type */}
            <div className="d-flex flex-column gap-1">
              <Controller
                control={control}
                name="service"
                render={({ field }) => {
                  return (
                    <SelectionInput
                      i18nLabelKey="Service_Type"
                      size="large"
                      i18nPlaceholder="Type"
                      options={serviceTypeOptions}
                      onChange={(val) => {
                        setStatusVal(val);
                        field.onChange(val);
                      }}
                      value={statusVal}
                      status={errors.service ? "error" : ""}
                    />
                  );
                }}
              />
              {errors.service && errors.service.message && (
                <div className="w-100">
                  <InstructionNote instructionType="danger">
                    <span className="Paragraph300Light">
                      {errors.service.message}
                    </span>
                  </InstructionNote>
                </div>
              )}
            </div>
            <div className={`${styles.columnsContainer}`}>
              {/* Add Credit */}
              <div className="d-flex flex-column gap-1 w-100 ">
                <Controller
                  control={control}
                  name="credit"
                  render={({ field }) => (
                    <TextInput
                      type="number"
                      onChange={field.onChange}
                      value={String(field.value)}
                      containerStyle="w-100"
                      label={t("GTO_Current_Amount")}
                      placeholder="00.00"
                      status={errors?.credit?.message ? "error" : "default"}
                      errorMsg={errors?.credit?.message}
                    />
                  )}
                />
              </div>

              {/* Cuurency */}
              <div className="d-flex flex-column gap-1 w-100 ">
                <Controller
                  control={control}
                  name="currency"
                  render={({ field }) => {
                    return (
                      <SelectionInput
                        isLoading={isCurrenciesLoading}
                        size="large"
                        label={`${t("Currency")} *`}
                        placeholder={t("Currency")}
                        options={
                          currenciesData
                            ? Object.keys(currenciesData).map((item) => ({
                                label: item,
                                value: item,
                              }))
                            : []
                        }
                        onChange={(val) => {
                          setCurrency(val);
                          field.onChange(val);
                        }}
                        value={currency}
                        status={errors.currency ? "error" : ""}
                      />
                    );
                  }}
                />
                {errors.currency && errors.currency.message && (
                  <div className="w-100">
                    <InstructionNote instructionType="danger">
                      <span className="Paragraph300Light">
                        {errors.currency.message}
                      </span>
                    </InstructionNote>
                  </div>
                )}
              </div>
            </div>
            {/* Switch */}
            <div className={styles.switchContainer}>
              <span className="Paragraph100Light">
                {isSwitchOpen ? t("Active") : t("Inactive")}
              </span>
              <Controller
                control={control}
                name="status"
                render={({ field }) => (
                  <Switch
                    className={styles.switch}
                    defaultChecked={isSwitchOpen}
                    onChange={(e) => {
                      field.onChange(e);
                      onSwitchChange();
                    }}
                  />
                )}
              />
            </div>
            <Button
              styleType="Primary"
              type="submit"
              btnClassName={styles.submitBtn}
            >
              <span className="Button100">{t("Save")}</span>
            </Button>
          </form>
        )}
      </div>
    </section>
  );
}
