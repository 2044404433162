import { useEffect, useState } from "react";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import PageTitle from "components/general/PageTitle";
import InstructionNote from "components/general/InstructionNote";
import Button from "components/general/Button";
import { useForm, Controller } from "react-hook-form";
import { Switch } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SelectionInput from "components/inputs/SelectionInput";
import { useUpdateAgentMutation, useGetAgentQuery } from "apis/services/agents";
import showToast from "utils/showToast";
import Spinner from "components/general/Spinner";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "./styles.module.scss";

const schema = yup.object({
  first_name: yup
    .string()
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name must be at most 50 characters")
    .required("First name is required!"),
  last_name: yup
    .string()
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name must be at most 50 characters")
    .required("Last Name is required!"),
  email: yup
    .string()
    .email()
    .min(5, "Email name must be at least 5 characters")
    .max(100, "Email must be at most 100 characters")
    .required("Email is required!"),
  role: yup.string().required("Role field is required"),
  is_active: yup.boolean().required(),
  id: yup
    .number()
    .required()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
});
type formData = yup.InferType<typeof schema>;

export default function EditAgent() {
  const { t } = useAutoCompleteTranslation();
  const roleOptions = [
    { value: "admin", label: t("Admin") },
    { value: "booking_team", label: t("Booking_Team") },
    { value: "finance", label: t("Finance") },
    { value: "company_admin", label: t("Company_Admin") },
  ];
  const navigate = useNavigate();
  const { agentId } = useParams();
  const { pathname } = useLocation();
  const { data: agent, isLoading } = useGetAgentQuery(agentId || "");
  const [updateAgent, { isLoading: isUpdateLoading }] =
    useUpdateAgentMutation();
  const [isSwitchOpen, setIsSwitchOpen] = useState(agent?.is_active);

  const onSwitchChange = () => {
    setIsSwitchOpen(!isSwitchOpen);
  };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    control,
  } = useForm<formData>({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      id: agent?.id,
      role: "",
      is_active: true,
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    if (agent) {
      // Update the form's default values once the agent data is available
      setValue("first_name", agent.first_name);
      setValue("last_name", agent.last_name);
      setValue("email", agent.email);
      setValue("id", agent.id);
      setValue("role", agent.role);
      setValue("is_active", agent.is_active);
      setIsSwitchOpen(agent.is_active);
      const updatedUrl = `${pathname}?idName=${agent?.first_name} ${agent?.last_name}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [agent]);

  const onSubmit = (data: formData) => {
    if (agentId) {
      updateAgent([agentId, data])
        .unwrap()
        .then(() => {
          navigate("/user-management");
          showToast({
            toastType: "success",
            title: `Changes have been saved successfully!`,
            showCloseIcon: true,
          });
        });
    }
  };
  return (
    <section>
      <div className={styles.container}>
        <PageTitle>
          {agent?.first_name} {agent?.last_name}
        </PageTitle>
        <div className={styles.newAgent_container}>
          {isLoading || isUpdateLoading ? (
            <div className="w-100 d-flex align-items-center justify-content-center">
              <Spinner size="Large" />
            </div>
          ) : (
            <form
              className={styles.form_container}
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* First and Last Name Input */}
              <div className="d-flex gap-4">
                <div className="d-flex justify-content-between gap-2 flex-column ">
                  <ControlledTextInput
                    control={control}
                    name="first_name"
                    containerStyle="w-100"
                    label={`${t("First_Name")} *`}
                    placeholder={t("First_Name")}
                    errorMsg={errors?.first_name?.message}
                  />
                </div>
                <div className="d-flex justify-content-between gap-2 flex-column ">
                  <ControlledTextInput
                    control={control}
                    name="last_name"
                    containerStyle="w-100"
                    label={`${t("Last_Name")} *`}
                    placeholder={t("Last_Name")}
                    errorMsg={errors?.last_name?.message}
                  />
                </div>
              </div>
              {/* Email Input */}
              <div className="w-100 d-flex flex-column gap-2">
                <ControlledTextInput
                  control={control}
                  name="email"
                  containerStyle="w-100"
                  label={`${t("Email")} *`}
                  placeholder="example@gmail.com"
                  errorMsg={errors?.email?.message}
                />
                <InstructionNote instructionType="info">
                  <span className="Paragraph300Light">{t("Follow_OTP")} </span>
                </InstructionNote>
              </div>

              {/* Role Container */}
              <div className={styles.roleContainer}>
                <div className="d-flex justify-content-between ">
                  <span className="Label100">{t("Role")} *</span>
                </div>
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => {
                    return (
                      <SelectionInput
                        size="large"
                        placeholder={t("Select")}
                        options={roleOptions}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        value={field.value}
                        status={errors.role ? "error" : ""}
                      />
                    );
                  }}
                />
                {errors.role && errors.role.message && (
                  <div className="w-100">
                    <InstructionNote instructionType="danger">
                      <span className="Paragraph300Light">
                        {errors.role.message}
                      </span>
                    </InstructionNote>
                  </div>
                )}

                {/* Switch */}
                <div className={styles.switchContainer}>
                  <span className="Paragraph100Light">
                    {isSwitchOpen ? t("Active") : t("Inactive")}
                  </span>
                  <Controller
                    control={control}
                    {...register("is_active")}
                    render={({ field }) => (
                      <Switch
                        className={styles.switch}
                        checked={isSwitchOpen}
                        onChange={(e) => {
                          field.onChange(e);
                          onSwitchChange();
                        }}
                      />
                    )}
                  />
                </div>

                {/* Save Button */}

                <Button
                  styleType="Primary"
                  btnClassName={`${styles.saveContainer} Button100 mt-3`}
                  type="submit"
                  size="large"
                >
                  {t("Save_Changes")}
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
}
