import { useState } from "react";
import PageTitle from "components/general/PageTitle";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import SelectionInput from "components/inputs/SelectionInput";
import UploadDragger from "components/general/UploadDragger";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import { Switch } from "antd";
import InstructionNote from "components/general/InstructionNote";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  useAddBenefitsMutation,
  useAddMembershipMutation,
  useGetGeneralBenefitsQuery,
} from "apis/services/memberships";
import showToast from "utils/showToast";
import { useNavigate } from "react-router-dom";
import Spinner from "components/general/Spinner";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { optionType } from "components/inputs/SelectionInput/types";
import { useGetCurrenciesQuery } from "apis/services/currency";
import CustomCridetCardLetters from "components/general/CustomCridetCardLetters";
import { CARD_LETTERS_TYPES } from "constants/CardLetters";
import BenefitConditions from "./BenefitConditions";
import styles from "./styles.module.scss";

const schema = yup.object({
  name: yup.string().required("Membership name is required!"),
  card_code: yup
    .string()
    .min(4, "Card code must be 4 numbers")
    .max(4, "Card code must be 4 numbers")
    .required("Membership card code is required!"),
  price: yup
    .number()
    .moreThan(0, "Membership price must be greater than 0")
    .required("Membership price is required!")
    .integer("A whole number is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  annual_fees: yup
    .number()
    .moreThan(0, "Annual fees must be greater than 0")
    .required("Annual fees code is required!")
    .integer("A whole number is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  currency: yup.string().required("Membership currency is required!"),
  status: yup.boolean(),
  notes: yup.string(),
  letters_type: yup
    .string()
    .oneOf([...CARD_LETTERS_TYPES])
    .required("Letters Type is required!"),
  benefits: yup
    .array()
    .of(
      yup.object({
        general_benefit: yup.number(),
        benefit: yup.string().required("Choose benefit!"),
        is_active: yup.boolean().required(),
        conditions: yup
          .array()
          .of(
            yup.object({
              general_condition: yup.number().nullable(),
              condition: yup.string().required("Condition is required!"),
              discount: yup
                .number()
                .max(100, "Max discount number is 100")
                .required("Condition discount is required!")
                .transform((value) =>
                  Number.isNaN(value) ? undefined : value
                ),
            })
          )
          .min(1, "Benfit must have least 1 condition")
          .required(),
      })
    )
    .min(1, "Benfits must have least 1 Benefit with at least 1 condition")
    .required(),
});

export type AddNewMembershipFormType = yup.InferType<typeof schema>;

export default function AddNewMembership() {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();

  const { data: currenciesData, isLoading: isCurrenciesLoading } =
    useGetCurrenciesQuery();

  const [addMembership, { isLoading: isMembershipLoading }] =
    useAddMembershipMutation();
  const [addBenefits, { isLoading: isBenefitsLoading }] =
    useAddBenefitsMutation();

  const { data: ConditionOptions } = useGetGeneralBenefitsQuery();

  const [uploadedImage, setUploadedImage] = useState<string | Blob>("");
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isSwitchOpen, setIsSwitchOpen] = useState(true);

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<AddNewMembershipFormType>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      card_code: "",
      price: 0,
      annual_fees: 0,
      currency: "GBP",
      status: true,
      notes: "",
      letters_type: "gold",
      benefits: [
        {
          general_benefit: 0,
          is_active: false,
          conditions: [],
        },
      ],
    },
    mode: "onSubmit",
  });

  const { fields: benefits, append } = useFieldArray({
    control,
    name: "benefits",
  });

  const handleAddBenefit = () => {
    append({
      general_benefit: 0,
      benefit: "",
      is_active: false,
      conditions: [],
    });
  };

  const onSwitchChange = () => {
    setIsSwitchOpen(!isSwitchOpen);
  };

  const letters_type = useWatch({
    control,
    name: "letters_type",
  });

  const onSubmit = (data: AddNewMembershipFormType) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("card_code", data.card_code);
    formData.append("price", String(data.price));
    if (uploadedImage) formData.append("template_image", uploadedImage);
    formData.append("annual_fees", String(data.annual_fees));
    formData.append("currency", data.currency);
    if (data.status) formData.append("status", String(data.status));
    if (data.notes) formData.append("notes", data.notes);
    if (data.letters_type) formData.append("letters_type", data.letters_type);

    addMembership(formData)
      .unwrap()
      .then((membershipRes) => {
        const benefitsData = data.benefits.map((benefit) => ({
          membership: membershipRes.id,
          ...benefit,
        }));
        addBenefits(benefitsData)
          .unwrap()
          .then(() => {
            navigate("/memberships");
            showToast({
              toastType: "success",
              title: `${data.name} membership has been added successfully!`,
              msg: "Congratulations! ",
              showCloseIcon: true,
            });
          });
      })
      .catch(handleErrors);
  };

  return (
    <section>
      <PageTitle> {t("Add_New_Membership")} </PageTitle>
      <div
        className={`${styles.container} d-flex flex-row-reverse justify-content-end gap-4`}
      >
        {isMembershipLoading || isBenefitsLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <>
            <CustomCridetCardLetters
              isExternalImg={false}
              letterType={letters_type}
              bgImage={uploadedImage}
            />
            <div className={styles.innerContainer}>
              <form
                className={styles.companyDetailsForm}
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="d-flex flex-nowrap gap-4">
                  <div className="w-100 d-flex flex-column gap-1">
                    <ControlledTextInput
                      control={control}
                      name="name"
                      containerStyle="w-100"
                      label={`${t("Membership_Name")} *`}
                      placeholder={t("Membership_Name")}
                      errorMsg={errors?.name?.message}
                    />
                  </div>
                  <div className="w-100 d-flex flex-column gap-1">
                    <Controller
                      control={control}
                      name="card_code"
                      render={({ field }) => (
                        <TextInput
                          value={field.value}
                          onChange={(e) => {
                            if (/^(|\d{0,4})$/.test(e.target.value))
                              field.onChange(e);
                          }}
                          containerStyle="w-100"
                          label={`${t("Card_Code")} *`}
                          placeholder={t("Code_With_Four_digits")}
                          status={
                            errors.card_code && errors.card_code.message
                              ? "error"
                              : "default"
                          }
                          errorMsg={errors?.card_code?.message}
                        />
                      )}
                    />
                  </div>
                </div>

                <div className="d-flex gap-4">
                  <div className="w-100 d-flex flex-column gap-1">
                    <Controller
                      control={control}
                      name="price"
                      render={({ field }) => (
                        <TextInput
                          type="number"
                          onChange={field.onChange}
                          containerStyle="w-100"
                          label={`${t("Membership_Price")} *`}
                          placeholder={t("Membership_Price")}
                          value={String(field.value)}
                          status={
                            errors.name && errors.name.message
                              ? "error"
                              : "default"
                          }
                          suffix={
                            <Controller
                              control={control}
                              name="currency"
                              render={({ field: currencyField }) => (
                                <SelectionInput
                                  isLoading={isCurrenciesLoading}
                                  value={currencyField.value}
                                  onChange={currencyField.onChange}
                                  size="small"
                                  containerStyle={styles.textAndSelectDropdown}
                                  options={
                                    currenciesData
                                      ? Object.keys(currenciesData).map(
                                          (item) => ({
                                            label: item,
                                            value: item,
                                          })
                                        )
                                      : []
                                  }
                                  defaultValue="GBP"
                                />
                              )}
                            />
                          }
                        />
                      )}
                    />
                    {errors.price && errors.price.message && (
                      <div className="w-100">
                        <InstructionNote instructionType="danger">
                          <span className="Paragraph300Light">
                            {errors.price.message}
                          </span>
                        </InstructionNote>
                      </div>
                    )}
                    {errors.currency && errors.currency.message && (
                      <div className="w-100">
                        <InstructionNote instructionType="danger">
                          <span className="Paragraph300Light">
                            {errors.currency.message}
                          </span>
                        </InstructionNote>
                      </div>
                    )}
                  </div>
                  <div className="w-100" />
                </div>

                <div className={styles.uploadContainer}>
                  <span className="Label100"> {t("Template_Image")} </span>
                  {uploadedImage && uploadedFile ? (
                    <div className={styles.uploadedFile}>
                      <div>
                        <Icon
                          name="Image"
                          color="TextSecondaryBlack"
                          size={20}
                        />
                      </div>
                      <span className="Paragraph200Light">
                        {uploadedFile.name}
                      </span>
                      <span className="Paragraph300Light">
                        {(uploadedFile.size / 1024).toFixed(2)} KB
                      </span>
                      <Button
                        btnClassName={styles.uploadedFileRemoveBtn}
                        styleType="NoStyle"
                        onClick={() => {
                          setUploadedFile(null);
                          setUploadedImage("");
                        }}
                      >
                        <Icon
                          name="Close"
                          size={16}
                          color="TextSecondaryBlack"
                        />
                      </Button>
                    </div>
                  ) : (
                    <UploadDragger
                      containerStyle={styles.uploadDragger}
                      onChange={(e) => {
                        if (e.file.size <= 3145728) {
                          setUploadedFile(e.file);
                          setUploadedImage(e.file);
                        } else {
                          showToast({
                            toastType: "danger",
                            title: "File Size Exceeded",
                            msg: "Please upload an image smaller than 3MB.",
                            showCloseIcon: true,
                          });
                        }
                      }}
                    />
                  )}
                </div>

                <div className={styles.templateColors}>
                  <span className="Label100"> {t("Card_Template")} </span>
                  <Controller
                    control={control}
                    name="letters_type"
                    render={({ field }) => (
                      <SelectionInput
                        i18nPlaceholder="Choose_Letters_Type"
                        isLoading={isCurrenciesLoading}
                        value={field.value}
                        onChange={field.onChange}
                        containerStyle={`w-100 ${styles.benefitsList}`}
                        options={
                          CARD_LETTERS_TYPES
                            ? CARD_LETTERS_TYPES.map((item) => ({
                                label: item,
                                value: item,
                              }))
                            : []
                        }
                      />
                    )}
                  />
                </div>

                <div className={styles.benefits}>
                  <span className="Label100"> {t("Benefits")} </span>
                  <div className="d-flex justify-content-between flex-nowrap gap-3 gx-0">
                    <div
                      className={`${styles.benefitsDetailContainer} ${styles.dimmed} w-100 d-flex flex-column gap-2`}
                    >
                      {benefits.map((benefit, index) => (
                        <div
                          key={benefit.id}
                          className={`${styles.dimmed} w-100 d-flex flex-column gap-2`}
                        >
                          <div className="d-flex align-items-center gap-3">
                            <Controller
                              name={`benefits.${index}.general_benefit`}
                              control={control}
                              render={({ field }) => (
                                <SelectionInput
                                  size="small"
                                  value={
                                    field.value === 0
                                      ? undefined
                                      : String(field.value)
                                  }
                                  onChange={(value, option) => {
                                    field.onChange(value);
                                    setValue(
                                      `benefits.${index}.conditions`,
                                      []
                                    );
                                    setValue(
                                      `benefits.${index}.benefit`,
                                      (option as optionType)?.label
                                    );
                                  }}
                                  containerStyle={`w-100 ${styles.benefitsList}`}
                                  options={
                                    ConditionOptions?.map((condition) => ({
                                      value: String(condition.id),
                                      label: condition.name,
                                    })) ?? []
                                  }
                                  placeholder={t("Select_Benefit")}
                                  status={
                                    errors.benefits &&
                                    errors.benefits[index]?.general_benefit
                                      ?.message
                                      ? "error"
                                      : ""
                                  }
                                />
                              )}
                            />
                            <Controller
                              name={`benefits.${index}.is_active`}
                              control={control}
                              render={({ field }) => (
                                <Switch
                                  defaultChecked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e);
                                  }}
                                />
                              )}
                            />
                          </div>
                          {errors.benefits &&
                            errors.benefits[index]?.general_benefit
                              ?.message && (
                              <div className="w-100">
                                <InstructionNote instructionType="danger">
                                  <span className="Paragraph300Light">
                                    {
                                      errors.benefits[index]?.general_benefit
                                        ?.message
                                    }
                                  </span>
                                </InstructionNote>
                              </div>
                            )}
                          <BenefitConditions
                            nestIndex={index}
                            control={control}
                            errors={errors}
                          />
                        </div>
                      ))}
                      <Button styleType="Secondary" onClick={handleAddBenefit}>
                        <span className="Button100">+ {t("Add_Benefit")}</span>
                      </Button>
                      {errors.benefits && errors.benefits.message && (
                        <div className="w-100">
                          <InstructionNote instructionType="danger">
                            <span className="Paragraph300Light">
                              {errors.benefits.message}
                            </span>
                          </InstructionNote>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <Controller
                    control={control}
                    name="annual_fees"
                    render={({ field }) => (
                      <TextInput
                        type="number"
                        onChange={field.onChange}
                        containerStyle="w-100 mb-1"
                        placeholder={t("Annual_Fees")}
                        label={`${t("Annual_Fees")} *`}
                        value={String(field.value)}
                        status={
                          errors.name && errors.name.message
                            ? "error"
                            : "default"
                        }
                      />
                    )}
                  />
                  {errors.annual_fees && errors.annual_fees.message && (
                    <div className="w-100 mb-1">
                      <InstructionNote instructionType="danger">
                        <span className="Paragraph300Light">
                          {errors.annual_fees.message}
                        </span>
                      </InstructionNote>
                    </div>
                  )}
                  <InstructionNote instructionType="info">
                    <span className="Paragraph300Light">{t("Disclaimer")}</span>
                  </InstructionNote>
                </div>

                <ControlledTextInput
                  control={control}
                  name="notes"
                  type="textarea"
                  inputStyle={styles.formTextArea}
                  label={t("Additional_Notes")}
                  placeholder={t("Notes")}
                />

                <div className={styles.switchContainer}>
                  <span className="Paragraph100Light">
                    {isSwitchOpen ? t("Active") : t("Inactive")}
                  </span>
                  <Controller
                    control={control}
                    name="status"
                    render={({ field }) => (
                      <Switch
                        className={styles.switch}
                        defaultChecked={isSwitchOpen}
                        onChange={(e) => {
                          field.onChange(e);
                          onSwitchChange();
                        }}
                      />
                    )}
                  />
                </div>

                <Button btnClassName={styles.updateBtn} type="submit">
                  <span> {t("Save")} </span>
                </Button>
              </form>
            </div>
          </>
        )}
      </div>
    </section>
  );
}
