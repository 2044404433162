import { Control, FieldErrors } from "react-hook-form";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import { customAccommodationErrorsType } from ".";
import { generalQuotationTypes } from "../..";
import styles from "../../styles.module.scss";

export default function HotelRules({
  control,
  index,
  errors,
}: {
  control: Control<generalQuotationTypes>;
  index: number;
  errors: FieldErrors<customAccommodationErrorsType>;
}) {
  return (
    <div className={styles.spaceTop}>
      <ControlledTextInput
        control={control}
        name={`quotations.${index}.hotel_rules`}
        containerStyle={styles.textareaContainer}
        type="textarea"
        errorMsg={errors?.quotations?.[index]?.hotel_rules?.message}
        size="large"
      />
    </div>
  );
}
