import { Dropdown } from "antd";
import Button from "components/general/Button";
import { CustomMenuProps } from "./types";
import styles from "./styles.module.scss";

export default function Menu({ items, children }: CustomMenuProps) {
  const id = `menu${Math.random() * 2}`;
  return (
    <div id={id} className={styles.dropdownMenu}>
      <Dropdown
        menu={{ items }}
        trigger={["click"]}
        getPopupContainer={(trigger: HTMLElement | null) => {
          return (
            (trigger?.parentNode as HTMLElement) ?? document.getElementById(id)
          );
        }}
      >
        <Button styleType="NoStyle" btnClassName="h-auto">
          {children}
        </Button>
      </Dropdown>
    </div>
  );
}
