import DateInput from "components/inputs/DateInput";
import SelectionInput from "components/inputs/SelectionInput";
import TextInput from "components/inputs/TextInput";
import { useGetSuppliersQuery } from "apis/services/suppliers";
import { Control, Controller, FieldErrors } from "react-hook-form";
import InstructionNote from "components/general/InstructionNote";
import { Supplier } from "apis/types/suppliers";
import dayjs from "dayjs";
import { useGetCurrenciesQuery } from "apis/services/currency";
import { generalQuotationTypes } from "../..";
import { customAccommodationErrorsType } from ".";
import styles from "../../styles.module.scss";

export default function SupplierQuotationDetails({
  control,
  errors,
  index,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customAccommodationErrorsType>;
  index: number;
}) {
  const { data: suppliers, isLoading: isSuppliersLoading } =
    useGetSuppliersQuery({
      page_size: 60000,
      status: true,
    });

  const { data: currenciesData, isLoading: isCurrenciesLoading } =
    useGetCurrenciesQuery();
  return (
    <div className={styles.formGroup}>
      <div className="d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.supplier`}
          render={({ field }) => (
            <SelectionInput
              isLoading={isSuppliersLoading}
              size="large"
              i18nPlaceholder="Supplier_Name"
              options={
                suppliers?.results.map((item: Supplier) => ({
                  value: String(item.id),
                  label: item.name,
                })) ?? []
              }
              i18nLabelKey="Supplier_Name"
              onChange={(val) => {
                field.onChange(val);
              }}
              value={
                field.value === 0 || field.value === null
                  ? undefined
                  : String(field.value)
              }
              status={errors.quotations?.[index]?.supplier ? "error" : ""}
              showSearch
              onFilter={(search, item) =>
                (item?.label.toLowerCase() ?? "").includes(search.toLowerCase())
              }
            />
          )}
        />
        {errors.quotations?.[index]?.supplier &&
          errors.quotations?.[index]?.supplier?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.quotations?.[index]?.supplier?.message}
                </span>
              </InstructionNote>
            </div>
          )}
      </div>
      <div className="d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.supplier_currency`}
          render={({ field }) => (
            <SelectionInput
              isLoading={isSuppliersLoading || isCurrenciesLoading}
              size="large"
              i18nPlaceholder="Currency"
              i18nLabelKey="Currency"
              options={
                currenciesData
                  ? Object.keys(currenciesData).map((item) => ({
                      label: item,
                      value: item,
                    }))
                  : []
              }
              onChange={field.onChange}
              value={field.value === "" ? undefined : field.value}
              status={
                errors.quotations?.[index]?.supplier_currency ? "error" : ""
              }
            />
          )}
        />
        {errors.quotations?.[index]?.supplier_currency &&
          errors.quotations?.[index]?.supplier_currency?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.quotations?.[index]?.supplier_currency?.message}
                </span>
              </InstructionNote>
            </div>
          )}
      </div>
      <div className="d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.supplier_price`}
          render={({ field }) => (
            <TextInput
              type="number"
              onChange={field.onChange}
              containerStyle="w-100"
              inputStyle={styles.inputBg}
              i18nPlaceholder="Supplier_Price"
              i18nLabelKey="Supplier_Price"
              value={String(field.value)}
              status={
                errors.quotations?.[index]?.supplier_price?.message
                  ? "error"
                  : "default"
              }
              errorMsg={errors?.quotations?.[index]?.supplier_price?.message}
            />
          )}
        />
      </div>
      <div className="d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.payment_date_for_supplier`}
          render={({ field }) => (
            <DateInput
              defaultValue={
                field.value === null || field.value === "" ? "" : field.value
              }
              onChange={(e) =>
                e
                  ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                  : field.onChange("")
              }
              i18nLabelKey="Payment_Date"
              status={
                errors.quotations?.[index]?.payment_date_for_supplier
                  ? "error"
                  : ""
              }
            />
          )}
        />
        {errors.quotations?.[index]?.payment_date_for_supplier &&
          errors.quotations?.[index]?.payment_date_for_supplier?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {
                    errors.quotations?.[index]?.payment_date_for_supplier
                      ?.message
                  }
                </span>
              </InstructionNote>
            </div>
          )}
      </div>
    </div>
  );
}
