/* eslint-disable jsx-a11y/click-events-have-key-events */
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { Conversation } from "apis/types/chat";
import InfiniteScroll from "react-infinite-scroll-component";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import useScreenSize from "hooks/useScreenSize";
import styles from "./styles.module.scss";
import { SidebarTypes } from "./types";

export default function Sidebar({
  conversations,
  activeConversation,
  setActiveConversation,
  setMessagesPage,
  conversationsPage,
  setConversationsPage,
  setConversations,
  isLoading,
  setSearch,
  closeConversations,
  isConversationsOpen,
}: SidebarTypes) {
  const { t } = useAutoCompleteTranslation();
  const { width } = useScreenSize();

  const handleActiveChat = (conversation: Conversation) => {
    closeConversations();
    setActiveConversation(conversation);
    setConversations((prevState) => {
      const targetIndex = prevState?.results.findIndex(
        (item) => item.id === conversation?.id
      );

      let modifiedConversation = prevState?.results[targetIndex!];

      modifiedConversation = {
        ...modifiedConversation!,
        unread_count: 0,
      };

      const newResults = [
        ...prevState!.results.slice(0, targetIndex!),
        modifiedConversation!,
        ...prevState!.results.slice(targetIndex! + 1),
      ];

      return {
        ...prevState!,
        results: newResults,
      };
    });
  };

  return (
    <div
      className={`${styles.sideBar} ${
        // eslint-disable-next-line no-nested-ternary
        width < 800
          ? isConversationsOpen
            ? ""
            : styles.conversationsHidden
          : ""
      }`}
      id="chatSideBarScrollableDiv"
    >
      <div className={styles.headerText}>
        <span className="Paragraph100Heavy"> {t("Messages")} </span>
        <DebouncedSearch onSearchChange={(e) => setSearch(e)} size="small" />
      </div>
      {/* Chat */}
      {conversations?.results && (
        <InfiniteScroll
          scrollableTarget="chatSideBarScrollableDiv"
          dataLength={conversations?.results.length}
          next={() => {
            if (isLoading) return;
            if (!conversations?.next) return;
            setConversationsPage(conversationsPage + 1);
          }}
          hasMore={conversations?.next !== null}
          loader={
            <p className="text-center pt-4">
              <b>Loading...</b>
            </p>
          }
        >
          {conversations?.results?.map((data) => (
            <div
              className={`${styles.chatItemContainer} ${
                activeConversation?.id === data.id
                  ? styles.activechatItemContainer
                  : ""
              }`}
              key={data.id}
              onClick={() => {
                if (activeConversation?.id !== data.id) {
                  setMessagesPage(1);
                }
                handleActiveChat(data);
              }}
              role="button"
              tabIndex={0}
            >
              {/* left circle */}
              <div
                className={`${styles.circle} ${
                  activeConversation?.id === data.id ? styles.activeCircle : ""
                }`}
              >
                <span className="Paragraph200Heavy">
                  {data?.name?.split(" ")?.[0]?.[0]}
                </span>
              </div>
              {/* right side of chat  */}
              <div className={styles.chatText}>
                <span className="Paragraph200Heavy"> {data?.name} </span>
                <span className="Paragraph200Light">
                  {data?.customer_phone}
                </span>
                <span className="Paragraph200Light">
                  {data?.membership_name}
                </span>
                {/* <span className="Paragraph200Light"> {data.desc} </span> */}
              </div>
              {/* unread msgs count  */}
              {data.unread_count !== 0 && (
                <div className={styles.unread}>{data.unread_count}</div>
              )}
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}
