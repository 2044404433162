import { Controller, FieldValues } from "react-hook-form";
import TextInput from "../TextInput";
import { ControlledTextInputProps } from "./types";

/** @description Rhf: React Hook Form */
export default function ControlledTextInput<T extends FieldValues>({
  control,
  name,
  errorMsg,
  ...rest
}: ControlledTextInputProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <TextInput
            onChange={field.onChange}
            value={field.value}
            containerStyle="w-100"
            status={errorMsg ? "error" : "default"}
            errorMsg={errorMsg}
            {...rest}
          />
        );
      }}
    />
  );
}
