import { t } from "i18next";

export const ACCOMODATION_SAMPLE = {
  quotation_type: "accommodation",
  rooms: [
    {
      room_type: "",
      price: 0,
      discount: 0,
      adults: 0,
      children: 0,
      children_age: "",
      discount_credit: 0,
      taxes_fee: 0,
    },
  ],
  parameters: [{ name: "", value: "", order: "" }],
  accommodation_type: "",
  accommodation_service_id: 0,
  isAccomodationNew: true,
  name: "",
  rating: 0,
  address: "",
  board_type: "",
  currency: "",
  basic_price: 0,
  deposit: 0,
  taxes_fee: 0,
  city_tax: 0,
  tourism_fee: 0,
  discount: 0,
  discount_credit: 0,
  apply_discount_on_taxes_and_basic_price: false,
  check_in_date: "",
  check_out_date: "",
  cancelation_before: false,
  cancelation_before_date: "",
  supplier_currency: "",
  supplier_price: 0,
  payment_date_for_supplier: "",
  general_quotation: 0,
  city: null,
  city_name_new: "",
  supplier: 0,
  payment_gateway: "",
  hotel_rules: t("Hotel_Rules_Initial"),
  guest_list: "",
};

export const FLIGHT_SAMPLE = {
  quotation_type: "flight",
  parameters: [{ name: "", value: "", order: "" }],
  passengers: [
    {
      client_name: "",
      price: "",
      discount: "0",
      discount_credit: 0,
      is_child: false,
      age: "",
      no_of_adults: 0,
      no_of_children: 0,
      no_of_teens: 0,
      no_of_infants: 0,
      taxes_fee: 0,
      apply_discount_on_taxes_and_basic_price: false,
    },
  ],
  routes: [
    {
      serial: "",
      airline: "",
      airline_code: "",
      flight_number: "",
      departure_airport: "",
      departure_airport_code: "",
      departure_terminal: "",
      departure_datetime: "",
      duration: "",
      arrival_airport: "",
      arrival_airport_code: "",
      arrival_terminal: "",
      arrival_datetime: "",
      is_transit: false,
      transit_duration_days: 0,
      transit_duration_hours: 0,
      transit_duration_minutes: 0,
      cabin: "",
      baggage_allowance: "",
      cancelation_before: false,
      cancelation_before_datetime: "",
      departure_city: 0,
      departure_city_name_new: "",
      arrival_city: 0,
      arrival_city_name_new: "",
    },
  ],
  wallets: [],
  payment_gateway: "",
  general_quotation: 0,
  supplier_currency: "",
  supplier_price: "0",
  payment_date_for_supplier: "",
  supplier: 0,
  confirmation_number: "",
};

export const GENERAL_SAMPLE = {
  quotation_type: "general",
  parameters: [{ name: "", value: "", order: "" }],
  wallet_activation_date: "",
  general_quotation: 0,
  price: "",
  discount: "0",
  discount_credit: "0",
  taxes_fee: "0",
  apply_discount_on_taxes_and_basic_price: false,
  supplier_currency: "",
  supplier_price: "0",
  payment_date_for_supplier: "",
  supplier: 0,
};
