/* eslint-disable no-nested-ternary */
import { useState } from "react";
import Button from "components/general/Button";
import PageTitle from "components/general/PageTitle";
import Icon from "components/general/Icon";
import { useNavigate, useSearchParams } from "react-router-dom";
import Table from "components/general/Table";
import Spinner from "components/general/Spinner";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import {
  useDeleteAccommodationMutation,
  useDeleteAccommodationsBulkMutation,
  useGetAccommodationsQuery,
} from "apis/services/accommodations";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import { Accommodations, Country } from "apis/types/accommodations";
import { TableItemProps } from "components/general/Table/types";
import dayjs from "dayjs";
import styles from "./styles.module.scss";

const arrangeCompanyData = (item: Accommodations): TableItemProps => ({
  id: String(item.id),
  rowData: [
    { type: "text", dataItem: `${item?.name}` },
    {
      type: "text",
      dataItem: item?.service_type.split("_").join(" "),
    },
    { type: "text", dataItem: (item?.country as Country)?.name },
    { type: "rate", dataItem: item?.rating },
    {
      type: "text",
      dataItem: dayjs(item?.created_on).format("DD/MM/YYYY"),
    },
    {
      type: "more",
      dataItem: [item?.name, "/services/accommodation/edit-accommodation/"],
    },
  ],
});

export default function Accomodation() {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [searchParams] = useSearchParams();

  const tableHeaders = [
    t("Accommodation_name"),
    t("Accommodation_Type"),
    t("Country"),
    t("Rating"),
    t("Added_Date"),
    "", // Make the last one empty string to let the menu btn appear here
  ];
  const [deleteAccommodation, { isLoading: isDeleteLoading }] =
    useDeleteAccommodationMutation();
  const [deleteAccommodationsBulk, { isLoading: isDeleteBulkLoading }] =
    useDeleteAccommodationsBulkMutation();
  const {
    data: accommodations,
    isLoading,
    isFetching,
  } = useGetAccommodationsQuery({
    page_size: localStorage.getItem("AccommodationsTable")
      ? Number(localStorage.getItem("AccommodationsTable"))
      : 15,
    page: searchParams.get("page") ?? 1,
    search: searchParams.get("search") ?? "",
  });

  const handleDeleteAccommodation = (
    accommodationName: string,
    accommodationId: string
  ) => {
    if (accommodationId) {
      deleteAccommodation(accommodationId)
        .unwrap()
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/services");
          showToast({
            toastType: "success",
            title: `${accommodationName} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };

  const handleDeleteBulk = (accommodationsIds: string) => {
    deleteAccommodationsBulk(accommodationsIds)
      .unwrap()
      .then(() => {
        setIsDeleteModalVisible(false);
        showToast({
          toastType: "success",
          title: `Selected Agents have been deleted successfully!`,
          showCloseIcon: true,
        });
      })
      .catch(handleErrors);
  };

  return (
    <section>
      <div className="d-flex justify-content-between">
        <div>
          <PageTitle> {t("Accommodations")} </PageTitle>
        </div>
        <div className={styles.btnGroup}>
          <Button
            btnClassName={styles.importExportBtns}
            size="small"
            styleType="Secondary"
            prefix={<Icon name="import" size={16} color="Neutral1000" />}
          >
            <span className="Paragraph200Light">{t("import")}</span>
          </Button>
          <span className={styles.divider} />
          <Button
            btnClassName={styles.importExportBtns}
            size="small"
            styleType="Secondary"
            prefix={<Icon name="export" size={16} color="Neutral1000" />}
          >
            <span className="Paragraph200Light">{t("export")}</span>
          </Button>
          <span className={styles.divider} />
          <Button
            btnClassName={styles.addNewCompanyBtn}
            size="small"
            styleType="Primary"
            prefix={
              <Icon name="Add-Outline" size={12} color="TextPrimaryWhite" />
            }
            onClick={() => navigate("add-new-accommodation")}
          >
            <span className="Button200 text-nowrap text-white ">
              {t("Add_New_Accommodation")}
            </span>
          </Button>
        </div>
      </div>

      {isFetching || isLoading ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : accommodations?.results?.length ? (
        <Table
          tableName="AccommodationsTable"
          headers={tableHeaders}
          data={accommodations?.results?.map((item: Accommodations) =>
            arrangeCompanyData(item)
          )}
          count={accommodations?.count}
          pagesCount={accommodations?.pages_number}
          deleteOneRow={handleDeleteAccommodation}
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          isDeleteLoading={isDeleteLoading || isDeleteBulkLoading}
          deleteMultipleSelectedRows={handleDeleteBulk}
        />
      ) : (
        <div>No Data found!</div>
      )}
    </section>
  );
}
