import { useState } from "react";
import Button from "../Button";
import { TabsSectionProps } from "./types";
import styles from "./styles.module.scss";

export default function Tabs({ containerStyle, tabs }: TabsSectionProps) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <div className={styles.labelContainer}>
        {tabs.map((tab, index) => (
          <Button
            key={`tabItem${index + Math.random()}`}
            btnClassName={`w-auto ${styles.tabBtn} ${
              activeTab !== index ? styles.dimmed : ""
            }`}
            styleType={index === activeTab ? "Secondary" : "NoStyle"}
            onClick={() => setActiveTab(index)}
          >
            <span className="Button100">{tab.label}</span>
          </Button>
        ))}
      </div>
      <div>{tabs[activeTab].children}</div>
    </div>
  );
}
