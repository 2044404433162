import { useMemo, useState } from "react";
import Button from "components/general/Button";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import DateInput from "components/inputs/DateInput";
import TimeInput from "components/inputs/TimeInput";
import CheckBox from "components/general/CheckBox";
import { Switch } from "antd";
import {
  Control,
  Controller,
  FieldErrors,
  UseFieldArrayAppend,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useGetCitesQuery } from "apis/services/cities";
import dayjs from "dayjs";
import InstructionNote from "components/general/InstructionNote";
import SelectionSearchInput from "components/inputs/SelectionSearchInput";
import { City } from "apis/types/cities";
import { debounce } from "lodash-es";
import { generalQuotationTypes } from "../..";
import { customFlightErrorTypes } from ".";
import styles from "../../styles.module.scss";

export default function RoutItem({
  control,
  errors,
  addNewRoute,
  index,
  nestedIndex,
  setValue,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customFlightErrorTypes>;
  addNewRoute: UseFieldArrayAppend<
    generalQuotationTypes,
    `quotations.${number}.routes`
  >;
  index: number;
  nestedIndex: number;
  setValue: UseFormSetValue<generalQuotationTypes>;
}) {
  const { t } = useAutoCompleteTranslation();

  const [departureCitiesSearch, setDepartureCitiesSearch] = useState("");
  const [arrivalCitiesSearch, setArrivalCitiesSearch] = useState("");

  const isTransitWatcher = useWatch({
    control,
    name: `quotations.${index}.routes.${nestedIndex}.is_transit`,
  });
  const isCancelationBefore = useWatch({
    control,
    name: `quotations.${index}.routes.${nestedIndex}.cancelation_before`,
  });

  const {
    data: departureCities,
    isLoading: isDepartureCitiesLoading,
    isFetching: isDepartureCitiesFetching,
  } = useGetCitesQuery({
    search: departureCitiesSearch,
  });

  const {
    data: arrivalCities,
    isLoading: isArrivalCitiesLoading,
    isFetching: isArrivalCitiesFetching,
  } = useGetCitesQuery({
    search: arrivalCitiesSearch,
  });

  const departureCitiesSearchHandler = useMemo(
    () => debounce((e) => setDepartureCitiesSearch(e), 500),
    []
  );
  const arrivalCitiesSearchHandler = useMemo(
    () => debounce((e) => setArrivalCitiesSearch(e), 500),
    []
  );

  return (
    <div className={styles.spaceTop}>
      <div>
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.airline`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Airline"
            i18nPlaceholder="Airline"
            errorMsg={
              errors?.quotations?.[index]?.routes?.[nestedIndex]?.airline
                ?.message
            }
          />
        </div>
      </div>
      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.airline_code`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Airline_Code"
            placeholder="A-233u"
            errorMsg={
              errors?.quotations?.[index]?.routes?.[nestedIndex]?.airline_code
                ?.message
            }
          />
        </div>

        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.flight_number`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Flight_Number"
            i18nPlaceholder="Flight_Number"
            errorMsg={
              errors?.quotations?.[index]?.routes?.[nestedIndex]?.flight_number
                ?.message
            }
          />
        </div>
      </div>

      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.departure_airport_code`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Departure_Airport_Code"
            i18nPlaceholder="Departure_Airport_Code"
            errorMsg={
              errors?.quotations?.[index]?.routes?.[nestedIndex]
                ?.departure_airport_code?.message
            }
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.departure_airport`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Departure_Airport"
            i18nPlaceholder="Departure_Airport"
            errorMsg={
              errors?.quotations?.[index]?.routes?.[nestedIndex]
                ?.departure_airport?.message
            }
          />
        </div>
      </div>

      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.departure_terminal`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Departure_Terminal"
            i18nPlaceholder="Departure_Terminal"
            errorMsg={
              errors?.quotations?.[index]?.routes?.[nestedIndex]
                ?.departure_terminal?.message
            }
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.departure_city_name_new`}
            render={({ field }) => (
              <SelectionSearchInput
                isLoading={
                  isDepartureCitiesLoading || isDepartureCitiesFetching
                }
                size="large"
                options={
                  departureCities?.results?.map((item: City) => ({
                    id: item.id,
                    value: `${item.name}${item.country ? "," : ""} ${
                      item.country ? item?.country?.name : ""
                    }`,
                  })) ?? []
                }
                onSearch={(value) => {
                  departureCitiesSearchHandler(value);
                  field.onChange(value);
                }}
                onSelect={(value, option) => {
                  setValue(
                    `quotations.${index}.routes.${nestedIndex}.departure_city`,
                    Number(option.id)
                  );
                  field.onChange(value);
                }}
                i18nLabelKey="Departure_City"
                i18nPlaceholder="Departure_City"
                onChange={field.onChange}
                value={field.value}
                status={
                  errors.quotations?.[index]?.routes?.[nestedIndex]
                    ?.departure_city_name_new ||
                  errors.quotations?.[index]?.routes?.[nestedIndex]
                    ?.departure_city
                    ? "error"
                    : ""
                }
              />
            )}
          />
          {(errors.quotations?.[index]?.routes?.[nestedIndex]
            ?.departure_city_name_new?.message ||
            errors.quotations?.[index]?.routes?.[nestedIndex]?.departure_city
              ?.message) && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.quotations?.[index]?.routes?.[nestedIndex]
                    ?.departure_city_name_new?.message ||
                    errors.quotations?.[index]?.routes?.[nestedIndex]
                      ?.departure_city?.message}
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.departure_date`}
            render={({ field }) => (
              <DateInput
                defaultValue={field.value}
                onChange={(e) =>
                  e
                    ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                    : field.onChange("")
                }
                i18nLabelKey="Departure_Date"
                i18nPlaceholder="Departure_Date"
                status={
                  errors.quotations?.[index]?.routes?.[nestedIndex]
                    ?.departure_date
                    ? "error"
                    : ""
                }
              />
            )}
          />
          {errors.quotations?.[index]?.routes?.[nestedIndex]?.departure_date
            ?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {
                    errors.quotations?.[index]?.routes?.[nestedIndex]
                      ?.departure_date?.message
                  }
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.departure_time`}
            render={({ field }) => (
              <TimeInput
                defaultValue={field.value}
                onChange={(e, timeString) => field.onChange(timeString)}
                i18nLabelKey="Departure_Time"
                i18nPlaceholder="Departure_Time"
                status={
                  errors.quotations?.[index]?.routes?.[nestedIndex]
                    ?.departure_time
                    ? "error"
                    : ""
                }
              />
            )}
          />
          {errors.quotations?.[index]?.routes?.[nestedIndex]?.departure_time
            ?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {
                    errors.quotations?.[index]?.routes?.[nestedIndex]
                      ?.departure_time?.message
                  }
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
      </div>

      <div className="w-100 d-flex flex-column gap-1">
        <ControlledTextInput
          control={control}
          name={`quotations.${index}.routes.${nestedIndex}.duration`}
          containerStyle="w-100"
          inputStyle={styles.inputBg}
          i18nLabelKey="Duration"
          i18nPlaceholder="Hours_Minutes"
          errorMsg={
            errors?.quotations?.[index]?.routes?.[nestedIndex]?.duration
              ?.message
          }
        />
      </div>

      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.arrival_airport_code`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Arrival_Airport_Code"
            placeholder="A-233u"
            errorMsg={
              errors?.quotations?.[index]?.routes?.[nestedIndex]
                ?.arrival_airport_code?.message
            }
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.arrival_airport`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Arrival_Airport"
            placeholder="Cairo International Airport"
            errorMsg={
              errors?.quotations?.[index]?.routes?.[nestedIndex]
                ?.arrival_airport?.message
            }
          />
        </div>
      </div>

      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.arrival_terminal`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Arrival_Terminal"
            placeholder="Terminal 2"
            errorMsg={
              errors?.quotations?.[index]?.routes?.[nestedIndex]
                ?.arrival_terminal?.message
            }
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.arrival_city_name_new`}
            render={({ field }) => (
              <SelectionSearchInput
                isLoading={isArrivalCitiesLoading || isArrivalCitiesFetching}
                size="large"
                options={
                  arrivalCities?.results?.map((item: City) => ({
                    id: item.id,
                    value: `${item.name}${item.country ? "," : ""} ${
                      item.country ? item?.country?.name : ""
                    }`,
                  })) ?? []
                }
                onSearch={(value) => {
                  arrivalCitiesSearchHandler(value);
                  field.onChange(value);
                }}
                onSelect={(value, option) => {
                  setValue(
                    `quotations.${index}.routes.${nestedIndex}.arrival_city`,
                    Number(option.id)
                  );
                  field.onChange(value);
                }}
                i18nLabelKey="Arrival_City"
                i18nPlaceholder="Arrival_City"
                onChange={field.onChange}
                value={field.value}
                status={
                  errors.quotations?.[index]?.routes?.[nestedIndex]
                    ?.arrival_city_name_new ||
                  errors.quotations?.[index]?.routes?.[nestedIndex]
                    ?.arrival_city
                    ? "error"
                    : ""
                }
              />
            )}
          />
          {(errors?.quotations?.[index]?.routes?.[nestedIndex]
            ?.arrival_city_name_new?.message ||
            errors?.quotations?.[index]?.routes?.[nestedIndex]?.arrival_city
              ?.message) && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors?.quotations?.[index]?.routes?.[nestedIndex]
                    ?.arrival_city_name_new?.message ||
                    errors?.quotations?.[index]?.routes?.[nestedIndex]
                      ?.arrival_city?.message}
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.arrival_date`}
            render={({ field }) => (
              <DateInput
                defaultValue={field.value}
                i18nLabelKey="Arrival_Date"
                i18nPlaceholder="Arrival_Date"
                onChange={(e) =>
                  e
                    ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                    : field.onChange("")
                }
                status={
                  errors.quotations?.[index]?.routes?.[nestedIndex]
                    ?.arrival_date
                    ? "error"
                    : ""
                }
              />
            )}
          />
          {errors.quotations?.[index]?.routes?.[nestedIndex]?.arrival_date
            ?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {
                    errors.quotations?.[index]?.routes?.[nestedIndex]
                      ?.arrival_date?.message
                  }
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.arrival_time`}
            render={({ field }) => (
              <TimeInput
                i18nLabelKey="Arrival_Time"
                i18nPlaceholder="Arrival_Time"
                defaultValue={field.value}
                onChange={(e, timeString) => field.onChange(timeString)}
                status={
                  errors.quotations?.[index]?.routes?.[nestedIndex]
                    ?.arrival_time
                    ? "error"
                    : ""
                }
              />
            )}
          />
          {errors.quotations?.[index]?.routes?.[nestedIndex]?.arrival_time
            ?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {
                    errors.quotations?.[index]?.routes?.[nestedIndex]
                      ?.arrival_time?.message
                  }
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.cabin`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Cabin"
            placeholder="A-233u"
            errorMsg={
              errors.quotations?.[index]?.routes?.[nestedIndex]?.cabin?.message
            }
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.baggage_allowance`}
            containerStyle="w-100"
            inputStyle={styles.inputBg}
            i18nLabelKey="Baggage_Allowance"
            placeholder="2 Bags"
            errorMsg={
              errors.quotations?.[index]?.routes?.[nestedIndex]
                ?.baggage_allowance?.message
            }
          />
        </div>
      </div>
      <div className="d-flex justify-content-between ">
        <div className={styles.checkboxContainer}>
          <Controller
            control={control}
            name={`quotations.${index}.routes.${nestedIndex}.is_transit`}
            render={({ field }) => (
              <CheckBox
                containerStyle={`${styles.checkboxType_container} ${styles.customBg}`}
                text={t("Transit")}
                value={field.value}
                checked={field.value}
                onCheckChange={() => field.onChange(!field.value)}
              />
            )}
          />
        </div>
        {nestedIndex === 0 && (
          <Button
            styleType="NoStyle"
            btnClassName={styles.addAnotherRoomBtn}
            onClick={() =>
              addNewRoute({
                airline: "",
                airline_code: "",
                flight_number: "",
                departure_airport: "",
                departure_airport_code: "",
                departure_terminal: "",
                departure_date: "",
                departure_time: "",
                duration: "",
                arrival_airport: "",
                arrival_airport_code: "",
                arrival_terminal: "",
                arrival_date: "",
                arrival_time: "",
                is_transit: false,
                transit_duration_days: 0,
                transit_duration_hours: 0,
                transit_duration_minutes: 0,
                cabin: "",
                baggage_allowance: "",
                cancelation_before: false,
                cancelation_before_date: "",
                cancelation_before_time: "",
                departure_city: 0,
                arrival_city: 0,
              })
            }
          >
            <span className="Paragraph300Light">
              + {`${t("Add_One_More_Route")}`}
            </span>
          </Button>
        )}
      </div>

      {isTransitWatcher && (
        <div>
          <span className="Label100 mb-2"> {t("Transit_Duration")} </span>
          <div className="d-flex gap-4">
            <div className="d-flex align-items-center gap-2">
              <div className="w-100 d-flex flex-column gap-1">
                <Controller
                  control={control}
                  name={`quotations.${index}.routes.${nestedIndex}.transit_duration_days`}
                  render={({ field }) => (
                    <TextInput
                      onChange={field.onChange}
                      value={String(field.value)}
                      type="number"
                      containerStyle="w-100"
                      inputStyle={styles.inputBg}
                      placeholder="Days"
                      status={
                        errors.quotations?.[index]?.routes?.[nestedIndex]
                          ?.transit_duration_days
                          ? "error"
                          : "default"
                      }
                      errorMsg={
                        errors.quotations?.[index]?.routes?.[nestedIndex]
                          ?.transit_duration_days?.message
                      }
                    />
                  )}
                />
              </div>
              <span className="Paragraph100Light"> {t("Days")} </span>
            </div>

            <div className="d-flex align-items-center gap-2">
              <div className="w-100 d-flex flex-column gap-1">
                <Controller
                  control={control}
                  name={`quotations.${index}.routes.${nestedIndex}.transit_duration_hours`}
                  render={({ field }) => (
                    <TextInput
                      type="number"
                      containerStyle="w-100"
                      inputStyle={styles.inputBg}
                      placeholder="Hours"
                      onChange={field.onChange}
                      value={String(field.value)}
                      status={
                        errors.quotations?.[index]?.routes?.[nestedIndex]
                          ?.transit_duration_hours
                          ? "error"
                          : "default"
                      }
                      errorMsg={
                        errors.quotations?.[index]?.routes?.[nestedIndex]
                          ?.transit_duration_hours?.message
                      }
                    />
                  )}
                />
              </div>
              <span className="Paragraph100Light"> {t("Hours")} </span>
            </div>

            <div className="d-flex align-items-center gap-2">
              <div className="w-100 d-flex flex-column gap-1">
                <Controller
                  control={control}
                  name={`quotations.${index}.routes.${nestedIndex}.transit_duration_minutes`}
                  render={({ field }) => (
                    <TextInput
                      type="number"
                      containerStyle="w-100"
                      inputStyle={styles.inputBg}
                      placeholder="Min"
                      onChange={field.onChange}
                      value={String(field.value)}
                      status={
                        errors.quotations?.[index]?.routes?.[nestedIndex]
                          ?.transit_duration_minutes
                          ? "error"
                          : "default"
                      }
                      errorMsg={
                        errors.quotations?.[index]?.routes?.[nestedIndex]
                          ?.transit_duration_minutes?.message
                      }
                    />
                  )}
                />
              </div>
              <span className="Paragraph100Light"> {t("Min")} </span>
            </div>
          </div>
        </div>
      )}

      <div className={styles.switchContainer}>
        <span className="Paragraph100Light"> {t("Cancelation_Before")} </span>
        <Controller
          control={control}
          name={`quotations.${index}.routes.${nestedIndex}.cancelation_before`}
          render={({ field }) => (
            <Switch
              defaultChecked={field.value}
              onChange={(e) => {
                field.onChange(e);
              }}
              className={styles.switch}
            />
          )}
        />
      </div>

      {isCancelationBefore && (
        <div className="d-flex justify-content-between gap-4">
          <div className="w-100 d-flex flex-column gap-1">
            <Controller
              control={control}
              name={`quotations.${index}.routes.${nestedIndex}.cancelation_before_date`}
              render={({ field }) => (
                <DateInput
                  defaultValue={field.value}
                  onChange={(e) =>
                    e
                      ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                      : field.onChange("")
                  }
                  status={
                    errors.quotations?.[index]?.routes?.[nestedIndex]
                      ?.cancelation_before_date
                      ? "error"
                      : ""
                  }
                  i18nLabelKey="Cancelation_Date"
                  i18nPlaceholder="Cancelation_Date"
                />
              )}
            />
            {errors.quotations?.[index]?.routes?.[nestedIndex]
              ?.cancelation_before_date?.message && (
              <div className="w-100">
                <InstructionNote instructionType="danger">
                  <span className="Paragraph300Light">
                    {
                      errors.quotations?.[index]?.routes?.[nestedIndex]
                        ?.cancelation_before_date?.message
                    }
                  </span>
                </InstructionNote>
              </div>
            )}
          </div>
          <div className="w-100 d-flex flex-column gap-1">
            <Controller
              control={control}
              name={`quotations.${index}.routes.${nestedIndex}.cancelation_before_time`}
              render={({ field }) => (
                <TimeInput
                  defaultValue={field.value}
                  onChange={(e, timeString) => field.onChange(timeString)}
                  i18nLabelKey="Cancelation_Time"
                  i18nPlaceholder="Cancelation_Time"
                  status={
                    errors.quotations?.[index]?.routes?.[nestedIndex]
                      ?.cancelation_before_time
                      ? "error"
                      : ""
                  }
                />
              )}
            />
            {errors.quotations?.[index]?.routes?.[nestedIndex]
              ?.cancelation_before_time?.message && (
              <div className="w-100">
                <InstructionNote instructionType="danger">
                  <span className="Paragraph300Light">
                    {
                      errors.quotations?.[index]?.routes?.[nestedIndex]
                        ?.cancelation_before_time?.message
                    }
                  </span>
                </InstructionNote>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
