import ControlledTextInput from "components/inputs/ControlledTextInput";
import { Control, FieldErrors } from "react-hook-form";
import { generalQuotationTypes } from "../..";
import { customFlightErrorTypes } from ".";
import styles from "../../styles.module.scss";

export default function ConfirmationNumber({
  control,
  errors,
  index,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customFlightErrorTypes>;
  index: number;
}) {
  return (
    <div className={styles.spaceTop}>
      <ControlledTextInput
        control={control}
        name={`quotations.${index}.confirmation_number`}
        inputStyle={styles.inputBg}
        size="large"
        placeholder="775Ssdzqe2"
        status="default"
        errorMsg={errors?.quotations?.[index]?.confirmation_number?.message}
      />
    </div>
  );
}
