import Icon from "../Icon";
import styles from "./styles.module.scss";
import { StatusBadgeProps } from "./types";

export default function StatusBadge({
  containerStyle,
  children,
  size = "large",
  contrast = "low",
  type,
  showDot = true,
}: StatusBadgeProps) {
  return (
    <div
      className={`${styles.container} ${styles[size]} ${styles[contrast]} ${styles[type]} ${containerStyle}`}
    >
      {showDot && (
        <div className="d-flex align-items-center justify-content-center">
          <Icon name="dot" size={8} />
        </div>
      )}
      {children}
    </div>
  );
}
