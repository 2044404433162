/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import logo from "assets/images/logoPng.png";
import COLORS from "constants/Colors";
import { GeneralQuotationGetReq } from "apis/types/generalQuotations";
import SF_PRO_TEXT from "styles/Fonts/SF-Pro-Text-Regular.otf";
import SF_PRO_TEXT_LIGHT from "styles/Fonts/SF-Pro-Text-Light.otf";
import SF_PRO_TEXT_HEAVY from "styles/Fonts/SF-Pro-Text-Heavy.otf";
import IBMPlexSansArabic from "styles/Fonts/IBMPlexSansArabic-Bold.ttf";
import IBMPlexSansArabicLight from "styles/Fonts/IBMPlexSansArabic-Light.ttf";
import dayjs from "dayjs";
import AccommodationDetailsPDF from "./DetailsTypePDF/AccommodationDetailsPDF";
import FlightDetailsPDF from "./DetailsTypePDF/FlightDetailsPDF";
import GeneralDetailsPDF from "./DetailsTypePDF/GeneralDetailsPDF";

export default function PdfDocument({
  generalQuotation,
  total,
  companyLogo,
  currentLanguage,
  isConfirmation = false,
}: {
  generalQuotation?: GeneralQuotationGetReq;
  total?: number;
  companyLogo: string;
  currentLanguage: string;
  isConfirmation?: boolean;
}) {
  const { t } = useAutoCompleteTranslation();
  const CurrencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: generalQuotation?.currency ?? "GBP",
  });
  Font.register({ family: "SF-Pro-Text", src: SF_PRO_TEXT });
  Font.register({ family: "SF-Pro-Text-Light", src: SF_PRO_TEXT_LIGHT });
  Font.register({ family: "SF-Pro-Text-Heavy", src: SF_PRO_TEXT_HEAVY });
  Font.register({ family: "IBMPlexSansArabic", src: IBMPlexSansArabic });
  Font.register({
    family: "IBMPlexSansArabicLight",
    src: IBMPlexSansArabicLight,
  });

  const documentStyle = StyleSheet.create({
    Label100: {
      fontSize: "14px",
      textTransform: "capitalize",
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text",
      fontWeight: 700,
    },
    Paragraph100Heavy: {
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text-Heavy",
      fontSize: "14px",
    },
    Paragraph200Light: {
      fontFamily:
        currentLanguage === "ar"
          ? "IBMPlexSansArabicLight"
          : "SF-Pro-Text-Light",
      fontSize: "12px",
      fontWeight: 400,
      color: COLORS.TextSecondaryBlack,
    },
    Paragraph200Heavy: {
      color: COLORS.TextSecondaryBlack,
      fontSize: "12px",
      textTransform: "capitalize",
      fontFamily:
        currentLanguage === "ar"
          ? "IBMPlexSansArabicLight"
          : "SF-Pro-Text-Light",
      fontWeight: 400,
    },
    pageContainer: {
      backgroundColor: COLORS.Neutral0,
    },
    quotation_container: {
      display: "flex",
      flexDirection: "column",
      marginTop: "6px",
    },
    nonRefundableText: {
      fontSize: "12px",
      fontWeight: 700,
    },
    roomText: {
      fontSize: "12px",
      fontFamily:
        currentLanguage === "ar" ? "IBMPlexSansArabic" : "SF-Pro-Text",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    navbar: {
      backgroundColor: COLORS.Neutral1000,
      display: "flex",
      justifyContent: "space-between",
      paddingHorizontal: "20",
      height: "90px",
      alignItems: "center",
      flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
    },
    logoImg: {
      objectFit: "contain",
      height: "80%",
      marginVertical: "auto",
    },
    flightQuotation: {
      borderRadius: "10px",
      border: `1px solid ${COLORS.Neutral300}`,
      padding: "10px",
      marginTop: "10px",
    },
  });

  return (
    <Document>
      <Page size="A4" style={documentStyle.pageContainer}>
        <View style={documentStyle.navbar}>
          <Image style={documentStyle.logoImg} src={logo} />
          <Image
            style={documentStyle.logoImg}
            src={{
              uri: companyLogo,
              method: "GET",
              headers: {
                Pragma: "no-cache",
                "Cache-Control": "no-cache",
                crossOrigin: "anonymous",
              },
              body: "",
            }}
          />
        </View>
        <View>
          <View
            style={{
              width: "85%",
              marginHorizontal: "auto",
              border: `1px solid ${COLORS.Neutral300}`,
              borderRadius: "10px",
              marginTop: "15px",
              flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
              display: "flex",
            }}
          >
            <Text
              style={{
                ...documentStyle.Paragraph100Heavy,
                padding: "15px",
                color: COLORS.TextPrimaryBlack,
                fontWeight: 400,
              }}
            >
              {!isConfirmation
                ? t("Quotation_Details")
                : t("Confirmation_Details_Title")}
            </Text>
          </View>
          <View
            style={{
              ...documentStyle.quotation_container,
              width: "85%",
              marginHorizontal: "auto",
            }}
            wrap={false}
          >
            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
                marginTop: "8px",
              }}
            >
              <Text style={documentStyle.Paragraph200Heavy}>
                {generalQuotation?.customer?.first_name}{" "}
                {generalQuotation?.customer?.last_name}
              </Text>
              <Text style={documentStyle.Paragraph200Light}>
                {dayjs(generalQuotation?.created_on).format("DD/MM/YYYY")}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
                marginTop: "8px",
              }}
            >
              <Text style={documentStyle.Paragraph200Heavy}>
                {t("Reference")}
              </Text>
              <Text style={documentStyle.Paragraph200Light}>
                {generalQuotation?.quotation_id}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: currentLanguage === "ar" ? "row-reverse" : "row",
                marginTop: "8px",
              }}
            >
              <Text style={documentStyle.Paragraph200Heavy}>
                {t("Membership")}
              </Text>
              <Text style={documentStyle.Paragraph200Light}>
                {generalQuotation?.membership_name}
              </Text>
            </View>
          </View>

          {generalQuotation?.accomodation_quotations
            ? generalQuotation?.accomodation_quotations?.map((data, index) => (
                <AccommodationDetailsPDF
                  key={`accommodation${data.name}${+index}`}
                  currentLanguage={currentLanguage}
                  data={data}
                  isConfirmation={isConfirmation}
                />
              ))
            : null}

          {generalQuotation?.flight_quotations
            ? generalQuotation?.flight_quotations.map((flight, index) => (
                <FlightDetailsPDF
                  key={`flight${flight.confirmation_number}${+index}`}
                  generalQuotation={generalQuotation}
                  flight={flight}
                  currentLanguage={currentLanguage}
                  isConfirmation={isConfirmation}
                />
              ))
            : null}

          {generalQuotation?.quotations
            ? generalQuotation?.quotations.map((generalData, index) => (
                <GeneralDetailsPDF
                  key={`generalTypeOfQoutations${
                    generalData.quotation_type_name
                  }${+index}`}
                  generalQuotation={generalQuotation}
                  currentLanguage={currentLanguage}
                  generalData={generalData}
                  isConfirmation={isConfirmation}
                />
              ))
            : null}

          {!isConfirmation && (
            <View
              wrap={false}
              style={{
                border: `1px solid ${COLORS.Neutral300}`,
                borderRadius: "10px",
                padding: "10px",
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
                width: "85%",
                marginHorizontal: "auto",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection:
                    currentLanguage === "ar" ? "row-reverse" : "row",
                }}
              >
                <Text
                  style={{
                    ...documentStyle.Paragraph100Heavy,
                    fontSize: "12px",
                  }}
                >
                  {t("Quotation_Total")}
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection:
                    currentLanguage === "ar" ? "row-reverse" : "row",
                  marginTop: "8px",
                }}
              >
                <Text style={documentStyle.Paragraph200Light}>
                  {t("Total")}
                </Text>

                {total ? (
                  <Text
                    style={{
                      ...documentStyle.Paragraph100Heavy,
                      color: COLORS.Success900,
                      fontSize: "12px",
                    }}
                  >
                    {CurrencyFormatter.format(Number(total))}
                  </Text>
                ) : null}
              </View>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
}
