import { useEffect } from "react";
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  useFieldArray,
} from "react-hook-form";
import Collapse from "components/wrappers/Collapse";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import useToggleState from "hooks/useToggleState";
import RoutItem from "./RoutItem";
import styles from "../../styles.module.scss";
import { generalQuotationTypes } from "../..";
import { customFlightErrorTypes } from ".";

export default function RouteDetails({
  control,
  errors,
  index,
  setValue,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customFlightErrorTypes>;
  index: number;
  setValue: UseFormSetValue<generalQuotationTypes>;
}) {
  const { t } = useAutoCompleteTranslation();
  const {
    fields: routes,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `quotations.${index}.routes`,
  });

  const {
    isSelected: isCollapseSelected,
    addToSelected: addToSelectedCollapse,
    replaceState,
  } = useToggleState<string>([]);

  useEffect(() => {
    if (errors.quotations?.[index]) {
      const temp: string[] = [];

      temp.push("flight");

      routes.forEach((_, nestedIndex) => {
        if (errors.quotations?.[index]?.routes?.[nestedIndex]) {
          temp.push(`Route${nestedIndex + 1}`);
        }
      });

      if (temp.length > 0) replaceState(temp);
    }
  }, [errors.quotations]);

  return (
    <>
      {routes.map((route, nestedIndex) => (
        <Collapse
          key={`Routes${route.id}`}
          isGeneral
          title={`${t("Route")} ${nestedIndex + 1}`}
          isOpen={isCollapseSelected(`Route${nestedIndex + 1}`)}
          setIsOpen={() => addToSelectedCollapse(`Route${nestedIndex + 1}`)}
          innerCollapesContainerStyle={styles.passengerContainer_collapse}
          containerStyle={styles.collapseCustom}
          showRemoveBtn={routes.length > 1}
          removeCollapse={() => remove(nestedIndex)}
        >
          <RoutItem
            control={control}
            setValue={setValue}
            errors={errors}
            addNewRoute={append}
            index={index}
            nestedIndex={nestedIndex}
          />
        </Collapse>
      ))}
    </>
  );
}
