import { useEffect, useState } from "react";
import Icon from "../Icon";
import styles from "./styles.module.scss";

export default function CriteriaTitle({
  title,
  value,
}: {
  title: string;
  value: string;
}) {
  const [status, setStatus] = useState("");

  const targetTest = new Map<string, RegExp>([
    ["Capital letter", /[A-Z]/],
    ["Small letter", /[a-z]/],
    ["Number", /[0-9]/],
    ["Special symbol", /[~!@#%^&*()+_=/-]/],
  ]);

  useEffect(() => {
    if (value) {
      if (targetTest.get(title)?.test(value)) {
        setStatus("success");
      } else {
        setStatus("error");
      }
    } else {
      setStatus("");
    }
  }, [value]);

  return (
    <div className={`${styles.criteriaContainer} ${styles[status]}`}>
      <div className="d-flex align-items-center justify-content-center">
        <Icon
          name={status !== "success" ? "dot" : "sucessOutside"}
          size={12}
          color="Neutral300"
        />
      </div>
      <span className="Label100">{title}</span>
    </div>
  );
}
