import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Breadcrumb } from "antd";
import styles from "./styles.module.scss";
import { BreadcumbsProps } from "./types";
import Icon from "../Icon";
import Button from "../Button";

export default function Breadcrumbs({ containerStyle }: BreadcumbsProps) {
  const location = useLocation();
  const navigate = useNavigate();

  let currentLink = "";
  const crumbs: any = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "");

  const [searchParams] = useSearchParams();
  const idName = searchParams.get("idName"); // Get idName from URL search parameters

  const crumbsMap: any = crumbs.map((crumb: any, index: number) => {
    currentLink += `/${crumb}`;

    const enhancedCrumb = crumb.includes("-")
      ? crumb.replace(/-/g, " ")
      : crumb;

    if (index === crumbs.length - 1) {
      return {
        title: idName || enhancedCrumb,
        href: undefined,
      };
    }

    return {
      title: index === crumbs.length - 1 ? idName : enhancedCrumb,
      href: index === crumbs.length - 1 ? undefined : currentLink,
    };
  });

  return (
    <div className={`${styles.container} ${containerStyle}`}>
      <Breadcrumb
        items={crumbsMap}
        separator={
          <Icon name="ChevronRight" size={20} color="TextDisabledBlack" />
        }
        // eslint-disable-next-line react/no-unstable-nested-components
        itemRender={(item) => (
          <Button
            styleType="NoStyle"
            btnClassName={`${styles.breadcrumbBtn} ${
              item.href === undefined && styles.disableBtn
            }`}
            onClick={() => item.href && navigate(item.href)}
          >
            <span className="Paragraph300Light text-capitalize">
              {item.title}
            </span>
          </Button>
        )}
      />
    </div>
  );
}
