import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import showToast from "./showToast";

function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value !== null;
}

export default function handleErrors(err: FetchBaseQueryError) {
  if (err.status === "FETCH_ERROR" || err.status === "TIMEOUT_ERROR") {
    showToast({
      toastType: "danger",
      title: "Connection Error",
      showCloseIcon: true,
    });
  } else if (err.status === "PARSING_ERROR") {
    showToast({
      toastType: "danger",
      title: "Unknown Error",
      showCloseIcon: true,
    });
  } else if (Array.isArray(err.data)) {
    showToast({
      toastType: "danger",
      title: JSON.stringify(err.data),
      showCloseIcon: true,
    });
  } else if (isObject(err.data)) {
    const dataObject = err.data as Record<string, unknown>;

    Object.keys(err.data).forEach((key) => {
      if (Array.isArray(dataObject[key])) {
        const errResult = dataObject[key] as string[];
        showToast({
          toastType: "danger",
          title: key,
          msg: JSON.stringify(errResult),
          showCloseIcon: true,
        });
      } else {
        const errResult = dataObject[key] as string;
        showToast({
          toastType: "danger",
          title: key,
          msg: JSON.stringify(errResult),
          showCloseIcon: true,
        });
      }
    });
  }
}
