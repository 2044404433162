import { useEffect, useRef, useState } from "react";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Icon from "components/general/Icon";
import Button from "components/general/Button";
import InstructionNote from "components/general/InstructionNote";
import { TextInputProps } from "./types";
import styles from "./styles.module.scss";

export default function TextInput({
  containerStyle,
  inputStyle,
  prefix,
  suffix,
  name,
  value,
  onChange,
  placeholder,
  i18nPlaceholder,
  label,
  i18nLabelKey,
  type = "text",
  size = "large",
  status = "default",
  errorMsg,
  reactHookFormProps,
}: TextInputProps) {
  const { t } = useAutoCompleteTranslation();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const showPassword = () => {
    if (type !== "password") return type;

    return isPasswordVisible ? "text" : "password";
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const cancelWheel = (e: WheelEvent) =>
      inputRef.current && e.preventDefault();
    inputRef?.current?.addEventListener("wheel", cancelWheel, {
      passive: false,
    });
    return () => inputRef?.current?.removeEventListener("wheel", cancelWheel);
  }, []);

  return (
    <>
      <div className={`${styles.container} ${containerStyle}`}>
        {(!!label || !!i18nLabelKey) && (
          <span className="d-block mb-2 Label100">
            {i18nLabelKey ? t(i18nLabelKey) : label}
          </span>
        )}

        <div
          className={`${styles.inputContent} ${styles[status]} ${styles[size]} ${inputStyle}`}
        >
          {prefix && <div>{prefix}</div>}

          {type === "textarea" ? (
            <textarea
              name={name}
              className={`${
                size === "small" ? "Paragraph200Light" : "Paragraph100Light"
              } TextPrimaryBlack`}
              placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
              value={value}
              onChange={onChange}
              {...reactHookFormProps}
            />
          ) : (
            <input
              ref={type === "number" ? inputRef : undefined}
              onKeyDown={
                type === "number"
                  ? (evt) =>
                      (["e", "E", "+", "-", "ArrowDown", "ArrowUp"].includes(
                        evt.key
                      ) ||
                        (value &&
                          value?.split(".")?.[1]?.length > 1 &&
                          [
                            "0",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                          ].includes(evt.key))) &&
                      evt.preventDefault()
                  : undefined
              }
              disabled={status === "disabled"}
              type={showPassword()}
              name={name}
              className={`${
                size === "small" ? "Paragraph200Light" : "Paragraph100Light"
              } TextPrimaryBlack`}
              placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
              value={value}
              onChange={onChange}
              {...reactHookFormProps}
            />
          )}
          {type === "password" && !suffix && (
            <Button
              tabIndex={-1}
              styleType="NoStyle"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              <Icon
                name={isPasswordVisible ? "VisibilityOn" : "VisibilityOff"}
                size={16}
              />
            </Button>
          )}
          {suffix && <div>{suffix}</div>}
        </div>
      </div>
      {errorMsg && (
        <div className="w-100">
          <InstructionNote instructionType="danger">
            <span className="Paragraph300Light">{errorMsg}</span>
          </InstructionNote>
        </div>
      )}
    </>
  );
}
