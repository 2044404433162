import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import i18n from "i18n";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import logoutHandler from "utils/logoutHandler";
import Menu from "components/Menu";
import { RootState } from "reducers";
import Breadcrumbs from "../Breadcumbs";
import Icon from "../Icon";
import styles from "./styles.module.scss";
import Spinner from "../Spinner";

export default function Navbar({ isSideBarOpen }: { isSideBarOpen: boolean }) {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();

  const onLogout = () => {
    logoutHandler();
  };

  const onChangeLang = () => {
    const isEn = localStorage.getItem("gtoLang") === "en";
    i18n.changeLanguage(isEn ? "ar" : "en");
    window.location.reload();
  };

  const user = useSelector((state: RootState) => state.auth.userData);

  return (
    <nav
      className={`${styles.navbar} ${
        isSideBarOpen ? "" : styles.widthWithSidebarClosed
      }`}
    >
      <Breadcrumbs />
      <Menu
        items={[
          {
            key: 1,
            label: (
              <div className="d-flex align-items-center gap-1">
                <div>
                  <Icon name="key" size={20} />
                </div>
                <span className="Paragraph200Light">
                  {" "}
                  {t("Change_Password")}{" "}
                </span>
              </div>
            ),
            onClick: () => navigate("/forgot-password"),
          },
          {
            key: 2,
            label: (
              <div className="d-flex align-items-center gap-1">
                <div>
                  <Icon name="language" size={20} />
                </div>
                <span className="Paragraph200Light">
                  {" "}
                  {t("Change_Language")}{" "}
                </span>
              </div>
            ),
            onClick: onChangeLang,
          },
          {
            key: 3,
            label: (
              <div className="d-flex align-items-center gap-1">
                <div>
                  <Icon name="logout" size={20} />
                </div>
                <span className="Paragraph200Light"> {t("Logout")} </span>
              </div>
            ),
            onClick: onLogout,
          },
        ]}
      >
        <div className="d-flex gap-1 align-items-center">
          <div>
            <Icon name="Person" size={32} color="Neutral1000" />
          </div>
          {!user ? (
            <Spinner size="Small" />
          ) : (
            <div className="d-flex flex-column align-items-start">
              <span className="Paragraph200Heavy text-uppercase">
                {user?.first_name
                  ? `${user.first_name} ${user.last_name}`
                  : user?.username}
              </span>
              <span className="Paragraph300Light text-capitalize">
                {user?.role.split("_").join(" ")}
              </span>
            </div>
          )}
          <div className={styles.menuArrowDown}>
            <Icon name="ChevronDown" size={16} color="TextSecondaryBlack" />
          </div>
        </div>
      </Menu>
    </nav>
  );
}
