import { DatePicker } from "antd";
import dayjs from "dayjs";
import Icon from "components/general/Icon";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { DateInputProps } from "./types";
import styles from "./styles.module.scss";

export default function DateInput({
  containerStyle,
  onChange,
  value,
  placeholder = "DD/MM/YYYY",
  size = "large",
  label,
  i18nPlaceholder,
  i18nLabelKey,
  status = "",
  isDisabled = false,
  defaultValue = undefined,
  dateFormat = "DD/MM/YYYY",
  picker = "date",
  disabledDate,
}: DateInputProps) {
  const { t } = useAutoCompleteTranslation();
  const id = `selectInput${Math.random()}`;

  return (
    <div className={`${styles.container} ${containerStyle}`} id={id}>
      {(!!label || !!i18nLabelKey) && (
        <span className="d-block mb-2 Label100">
          {i18nLabelKey ? t(i18nLabelKey) : label}
        </span>
      )}
      <DatePicker
        status={status}
        getPopupContainer={(trigger: HTMLElement | null) => {
          return (
            (trigger?.parentNode as HTMLElement) ?? document.getElementById(id)
          );
        }}
        className={`${styles.selectInput} ${styles[size]} w-100`}
        value={value}
        placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
        onChange={onChange}
        disabled={isDisabled}
        defaultValue={
          defaultValue ? dayjs(defaultValue, dateFormat) : undefined
        }
        format={dateFormat}
        suffixIcon={<Icon name="calendar_month" size={16} />}
        picker={picker}
        disabledDate={disabledDate}
      />
    </div>
  );
}
