import api from "apis";
import { PaginatedResponse } from "apis/types/general";
import { GetWalletsParams, WalletItem } from "apis/types/wallet";

export const walletApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWalletTransactions: build.query<
      PaginatedResponse<WalletItem>,
      GetWalletsParams
    >({
      query: (params) => ({
        url: "/wallet-transactions/",
        params: { ordering: "-id", ...params },
      }),
      forceRefetch: () => true,
    }),

    addWalletTransactions: build.mutation<WalletItem, Omit<WalletItem, "id">[]>(
      {
        query: (body) => ({
          url: "/wallet-transactions/",
          method: "POST",
          body,
        }),
      }
    ),

    updateWalletTransactionsBulk: build.mutation<any, Partial<WalletItem>[]>({
      query: (body) => ({
        url: "/wallet-transactions/update_bulk/",
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  useGetWalletTransactionsQuery,
  useAddWalletTransactionsMutation,
  useUpdateWalletTransactionsBulkMutation,
} = walletApi;
