import { toast } from "react-toastify";
import CustomToast from "components/general/CustomToast";
import { CustomToastProps } from "components/general/CustomToast/types";

export default function showToast({
  toastType,
  title,
  msg,
  showCloseIcon,
}: CustomToastProps) {
  const options = {
    className: `${toastType}Toast`,
  };
  toast(CustomToast({ toastType, title, msg, showCloseIcon }), options);
}
