import { useState } from "react";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import PageTitle from "components/general/PageTitle";
import InstructionNote from "components/general/InstructionNote";
import Button from "components/general/Button";
import { useForm, Controller } from "react-hook-form";
import { Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SelectionInput from "components/inputs/SelectionInput";
import { useAddAgentMutation } from "apis/services/agents";
import showToast from "utils/showToast";
import Spinner from "components/general/Spinner";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "./styles.module.scss";

const schema = yup.object({
  first_name: yup
    .string()
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name must be at most 50 characters")
    .required("First name is required!"),
  last_name: yup
    .string()
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name must be at most 50 characters")
    .required("Last Name is required!"),
  email: yup
    .string()
    .email()
    .min(5, "Email name must be at least 5 characters")
    .max(100, "Email must be at most 100 characters")
    .required("Email is required!"),
  password: yup
    .string()
    .min(4, "Password must be at least 4 characters")
    .max(10, "Password must be at most 10 characters")
    .required("Password field is required")
    .test(
      "isValidPass",
      "Password must contain at least one uppercase character",
      (value) => {
        return /[A-Z]/.test(value!);
      }
    )
    .test(
      "isValidPass",
      "Password must contain at least one lowercase character",
      (value) => {
        return /[a-z]/.test(value!);
      }
    )
    .test(
      "isValidPass",
      "Password must contain at least one number",
      (value) => {
        return /[0-9]/.test(value!);
      }
    )
    .test(
      "isValidPass",
      "Password must contain at least one special character",
      (value) => {
        return /[~!@#%^&*()+_=/-]/.test(value!);
      }
    ),
  role: yup.string().required("Role field is required"),
  is_active: yup.boolean().required(),
});

type formData = yup.InferType<typeof schema>;

export default function AddNewAgent() {
  const { t } = useAutoCompleteTranslation();

  const roleOptions = [
    { value: "admin", label: t("Admin") },
    { value: "booking_team", label: t("Booking_Team") },
    { value: "finance", label: t("Finance") },
    { value: "company_admin", label: t("Company_Admin") },
  ];
  const navigate = useNavigate();
  const [isSwitchOpen, setIsSwitchOpen] = useState(true);
  const [addAgent, { isLoading }] = useAddAgentMutation();

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
    control,
  } = useForm<formData>({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      role: "",
      is_active: true,
    },
    mode: "onSubmit",
  });

  const onSwitchChange = () => {
    setIsSwitchOpen(!isSwitchOpen);
  };

  // Generating random password
  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789~!@#%^&*()+_=-";
    const passwordLength = 10; // You can adjust the length as needed
    let password = "";

    const getRandomChar = (chars = characters) => {
      const randomIndex = Math.floor(Math.random() * chars.length);
      return chars[randomIndex];
    };

    // Ensure at least one uppercase, one lowercase, one number, and one symbol
    password += getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ"); // Uppercase
    password += getRandomChar("abcdefghijklmnopqrstuvwxyz"); // Lowercase
    password += getRandomChar("0123456789"); // Number
    password += getRandomChar("~!@#%^&*()+_=-"); // Symbol

    // Fill the remaining characters randomly
    for (let i = 4; i < passwordLength; i += 1) {
      password += getRandomChar();
    }

    // Shuffle the password characters
    password = password
      .split("")
      .sort(() => Math.random() - 0.5)
      .join("");

    return password;
  };

  const onSubmit = (data: formData) => {
    addAgent(data)
      .unwrap()
      .then(() => {
        navigate("/user-management");
        showToast({
          toastType: "success",
          title: `New agent has been added successfully!`,
          msg: "Congratulations! ",
          showCloseIcon: true,
        });
      })
      .catch((err) => {
        handleErrors(err);
        navigate("/user-management");
      });
  };

  return (
    <section>
      <div className={styles.container}>
        <PageTitle> {t("Add_New_Agent")} </PageTitle>
        <div className={styles.newAgent_container}>
          {isLoading ? (
            <div className="w-100 d-flex align-items-center justify-content-center">
              <Spinner size="Large" />
            </div>
          ) : (
            <form
              className={styles.form_container}
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* First and Last Name Input */}
              <div className="d-flex gap-4">
                <div className="d-flex justify-content-between gap-2 flex-column ">
                  <ControlledTextInput
                    control={control}
                    name="first_name"
                    containerStyle="w-100"
                    label={`${t("First_Name")} *`}
                    placeholder={t("First_Name")}
                    errorMsg={errors?.first_name?.message}
                  />
                </div>
                <div className="d-flex justify-content-between gap-2 flex-column ">
                  <ControlledTextInput
                    control={control}
                    name="last_name"
                    containerStyle="w-100"
                    label={`${t("Last_Name")} *`}
                    placeholder={t("Last_Name")}
                    errorMsg={errors?.last_name?.message}
                  />
                </div>
              </div>
              {/* Email Input */}
              <div className="w-100 d-flex flex-column gap-2">
                <ControlledTextInput
                  control={control}
                  name="email"
                  containerStyle="w-100"
                  label={`${t("Email")} *`}
                  placeholder="example@gmail.com"
                  errorMsg={errors?.email?.message}
                />
                <InstructionNote instructionType="info">
                  <span className="Paragraph300Light">{t("Follow_OTP")}</span>
                </InstructionNote>
              </div>

              {/* Password Container */}
              <div className="w-100 d-flex gap-2 align-items-center flex-wrap ">
                <div className="d-flex flex-column gap-2">
                  <ControlledTextInput
                    control={control}
                    name="password"
                    containerStyle={styles.passwordInput}
                    label={`${t("Password")} *`}
                    placeholder="**************"
                    type="password"
                    errorMsg={errors?.password?.message}
                  />
                </div>
                <Button
                  styleType="Secondary"
                  btnClassName={styles.passwordButton}
                  onClick={() => {
                    const newPassword = generatePassword();
                    setValue("password", newPassword);
                  }}
                >
                  {t("Generate_Automatically")}
                </Button>
                {errors.password && errors.password.message && (
                  <div className="w-100">
                    <InstructionNote instructionType="danger">
                      <span className="Paragraph300Light">
                        {errors.password.message}
                      </span>
                    </InstructionNote>
                  </div>
                )}
              </div>

              {/* Role Container */}
              <div className={styles.roleContainer}>
                <div className="d-flex justify-content-between ">
                  <span className="Label100">{t("Role")} *</span>
                </div>
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => {
                    return (
                      <SelectionInput
                        size="large"
                        placeholder={t("Select")}
                        options={roleOptions}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        value={field.value === "" ? undefined : field.value}
                        status={errors.role ? "error" : ""}
                      />
                    );
                  }}
                />
                {errors.role && errors.role.message && (
                  <div className="w-100">
                    <InstructionNote instructionType="danger">
                      <span className="Paragraph300Light">
                        {errors.role.message}
                      </span>
                    </InstructionNote>
                  </div>
                )}

                {/* Switch */}
                <div className={styles.switchContainer}>
                  <span className="Paragraph100Light">
                    {isSwitchOpen ? t("Active") : t("Inactive")}
                  </span>
                  <Controller
                    control={control}
                    {...register("is_active")}
                    render={({ field }) => (
                      <Switch
                        className={styles.switch}
                        defaultChecked={isSwitchOpen}
                        onChange={(e) => {
                          field.onChange(e);
                          onSwitchChange();
                        }}
                      />
                    )}
                  />
                </div>

                {/* Save Button */}
                <div className="mt-3">
                  <Button
                    styleType="Primary"
                    btnClassName={`${styles.saveContainer}  Button100`}
                    type="submit"
                  >
                    {t("Save")}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </section>
  );
}
