import React from "react";
import { ToastContainer } from "react-toastify";
import { ConfigProvider, ThemeConfig } from "antd";
import COLORS from "constants/Colors";
import useAuth from "hooks/useAuth";
import styles from "./styles.module.scss";

const config: ThemeConfig = {
  token: {
    colorPrimary: COLORS.Primary700,
    colorErrorBorder: COLORS.Danger700,
    colorError: COLORS.Danger700,
    colorErrorActive: COLORS.Danger700,
    colorErrorBg: COLORS.Danger700,
    colorErrorBgHover: COLORS.Danger700,
    colorErrorBorderHover: COLORS.Danger700,
    colorErrorHover: COLORS.Danger700,
    colorErrorOutline: COLORS.Danger700,
    colorErrorText: COLORS.Danger700,
    colorErrorTextActive: COLORS.Danger700,
    colorErrorTextHover: COLORS.Danger700,
    colorTextDisabled: COLORS.Neutral700,
    colorBgContainerDisabled: COLORS.Neutral300,
  },
};

export default function MainLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  useAuth();

  return (
    <ConfigProvider theme={config}>
      <div className={styles.mainContainer}>
        {children}

        <ToastContainer
          closeButton={false}
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </ConfigProvider>
  );
}
