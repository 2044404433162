import { Navigate, Outlet, useRoutes } from "react-router-dom";
import SidebarLayout from "components/wrappers/SidebarLayout";

// Main Screens Imports
import Login from "pages/Auth/Login";
import AuthLayout from "components/wrappers/AuthLayout";
import ForgotPassword from "pages/Auth/ForgotPassword";
import FristLogin from "pages/Auth/FristLogin";
import ProtectedOutlet from "components/wrappers/ProtectedOutlet";
import Companies from "pages/Companies";
import CompanyDetails from "pages/Companies/CompanyDetails";
import AddNewCompany from "pages/Companies/AddNewCompany";
import EditCompany from "pages/Companies/EditCompany";
import Memberships from "pages/Memberships";
import MembershipDetails from "pages/Memberships/MembershipDetails";
import UserManagement from "pages/UserManagement";
import AddNewAgent from "pages/UserManagement/Add-new-agent";
import AgentDetails from "pages/UserManagement/AgentDetails";
import EditAgent from "pages/UserManagement/Edit-agent";
import AddNewSupplier from "pages/Suppliers/AddNewSupplier";
import SupplierDetails from "pages/Suppliers/SupplierDetails";
import Suppliers from "pages/Suppliers";
import EditSupplier from "pages/Suppliers/EditSupplier";
import Accomodation from "pages/Services";
import AddNewAccommodation from "pages/Services/AddNewAccommodation";
import EditAccomodation from "pages/Services/EditAccomodation";
import AccommodationDetails from "pages/Services/AccommodationDetails";
import AddNewMembership from "pages/Memberships/AddNewMembership";
import EditMembership from "pages/Memberships/EditMembership";
import Customers from "pages/Customers";
import CreateCustomerProfile from "pages/Customers/CreateCustomerProfile";
import EditCustomerProfile from "pages/Customers/EditCustomerProfile";
import CustomerDetails from "pages/Customers/CustomerDetails";
import Quotations from "pages/Quotations";
import AddNewQuotation from "pages/Quotations/AddNewQuotation";
import EditQuotation from "pages/Quotations/EditQuotation";
import QuotationDetails from "pages/Quotations/QuotationDetails";
import MembershipBenefitsSettings from "pages/Memberships/MembershipBenefitsSettings";
import ChatPage from "pages/Chat";
// End Main Screens Imports

export default function useGenerateRoutes() {
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      ),
      children: [
        {
          index: true,
          element: <Login />,
        },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "forgot-password",
          element: <ForgotPassword />,
        },
        {
          path: "first-login",
          element: <FristLogin />,
        },
      ],
    },
    {
      path: "/companies",
      element: (
        <SidebarLayout>
          <ProtectedOutlet />
        </SidebarLayout>
      ),
      children: [
        {
          index: true,
          element: <Companies />,
        },
        {
          path: "add-new-company",
          element: <AddNewCompany />,
        },
        {
          path: ":companyId",
          element: <CompanyDetails />,
        },
        {
          path: "edit-company",
          element: <Navigate to="/companies" />,
        },
        {
          path: "edit-company/:companyId",
          element: <EditCompany />,
        },
      ],
    },
    {
      path: "/memberships",
      element: (
        <SidebarLayout>
          <ProtectedOutlet />
        </SidebarLayout>
      ),
      children: [
        {
          index: true,
          element: <Memberships />,
        },
        {
          path: ":membershipId",
          element: <MembershipDetails />,
        },
        {
          path: "membership-benefits-settings",
          element: <MembershipBenefitsSettings />,
        },
        {
          path: "add-new-membership",
          element: <AddNewMembership />,
        },
        {
          path: "edit-membership",
          element: <Navigate to="/memberships" />,
        },
        {
          path: "edit-membership/:membershipId",
          element: <EditMembership />,
        },
      ],
    },
    {
      path: "/customers",
      element: (
        <SidebarLayout>
          <ProtectedOutlet />
        </SidebarLayout>
      ),
      children: [
        {
          index: true,
          element: <Customers />,
        },
        {
          path: ":customerId",
          element: <CustomerDetails />,
        },
        {
          path: "create-customer-profile",
          element: <CreateCustomerProfile />,
        },
        {
          path: "edit-customer",
          element: <Navigate to="/cutomers" />,
        },
        {
          path: "edit-customer/:customerId",
          element: <EditCustomerProfile />,
        },
        // End Main Screens
      ],
    },
    {
      path: "/user-management",
      element: (
        <SidebarLayout>
          <ProtectedOutlet />
        </SidebarLayout>
      ),
      children: [
        {
          index: true,
          element: <UserManagement />,
        },
        {
          path: "add-new-agent",
          element: <AddNewAgent />,
        },
        {
          path: ":agentId",
          element: <AgentDetails />,
        },
        {
          path: "edit-user",
          element: <Navigate to="/user-management" />,
        },
        {
          path: "edit-user/:agentId",
          element: <EditAgent />,
        },
      ],
    },
    {
      path: "/suppliers",
      element: (
        <SidebarLayout>
          <ProtectedOutlet />
        </SidebarLayout>
      ),
      children: [
        {
          index: true,
          element: <Suppliers />,
        },
        {
          path: "add-new-supplier",
          element: <AddNewSupplier />,
        },
        {
          path: "edit-supplier",
          element: <Navigate to="/suppliers" />,
        },
        {
          path: "edit-supplier/:supplierId",
          element: <EditSupplier />,
        },
        {
          path: ":supplierId",
          element: <SupplierDetails />,
        },
      ],
    },
    {
      path: "/services",
      element: (
        <SidebarLayout>
          <ProtectedOutlet />
        </SidebarLayout>
      ),
      children: [
        {
          index: true,
          element: <Navigate to="/services/accommodation" />,
        },
        {
          path: "/services/accommodation",
          element: <Accomodation />,
        },
        {
          path: "accommodation/add-new-accommodation",
          element: <AddNewAccommodation />,
        },
        {
          path: "accommodation/edit-accomodation",
          element: <Navigate to="/services" />,
        },
        {
          path: "accommodation/edit-accommodation/:accomodationId",
          element: <EditAccomodation />,
        },
        {
          path: "accommodation/:accommodationId",
          element: <AccommodationDetails />,
        },
      ],
    },
    {
      path: "quotations",
      element: (
        <SidebarLayout>
          <ProtectedOutlet />
        </SidebarLayout>
      ),
      children: [
        {
          index: true,
          element: <Quotations />,
        },
        {
          path: "add-new-quotation",
          element: <AddNewQuotation />,
        },
        {
          path: "edit-quotation",
          element: <Navigate to="/quotations" />,
        },
        {
          path: "edit-quotation/:quotationId",
          element: <EditQuotation />,
        },
        {
          path: ":quotationId",
          element: <QuotationDetails />,
        },
      ],
    },
    {
      path: "/chat",
      element: (
        <SidebarLayout>
          <ProtectedOutlet />
        </SidebarLayout>
      ),
      children: [
        {
          index: true,
          element: <ChatPage />,
        },
      ],
    },
  ]);

  return routes;
}
