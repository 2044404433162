import { useEffect } from "react";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import useToggleState from "hooks/useToggleState";
import Collapse from "components/wrappers/Collapse";
import * as yup from "yup";
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useGetCustomerQuery } from "apis/services/customers";
import PassengerDetails from "./PassengerDetails";
import SupplierFlightQuotationDetails from "./SupplierFlightQuotationDetails";
import { generalQuotationSchema, generalQuotationTypes } from "../..";
import RouteDetails from "./RouteDetails";
import FlightPayment from "./FlightPayment";
import ConfirmationNumber from "./ConfirmationNumber";
import ParameterDetails from "../General/ParameterDetails";

export const flightSchema = yup.object().shape({
  parameters: yup.array().of(
    yup.object().shape({
      name: yup.string(),
      value: yup.string().when("name", {
        is: (val: string) => !!val,
        then: (schema) => schema.required("Value is required"),
      }),
      order: yup.string().when("name", {
        is: (val: string) => !!val,
        then: (schema) => schema.required("Order is required"),
      }),
    })
  ),
  quotation_type: yup
    .string()
    .required("Quotation type is required")
    .equals(["flight"], "Invalid quotation type"),
  passengers: yup.array().of(
    yup.object().shape({
      client_name: yup.string().required("Client Name is required"),
      price: yup.string().required("Price is required"),
      discount_credit: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .max(100, "Credit percentage can't exceed 100"),
      discount: yup
        .string()
        .test(
          "maxDiscount",
          "Discount must be less than or equal to 100",
          (value) => {
            if (!value) return true; // Allow empty string
            const discountValue = parseFloat(value);
            return discountValue <= 100;
          }
        ),
      is_child: yup.boolean(),
      age: yup.string().when("is_child", {
        is: true,
        then: (schema) => schema.required("Age is required"),
      }),
      no_of_adults: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      no_of_children: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      no_of_teens: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      no_of_infants: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .nullable(),
      taxes_fee: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
      apply_discount_on_taxes_and_basic_price: yup.boolean(),
    })
  ),
  routes: yup.array().of(
    yup.object().shape({
      airline: yup.string().required("Airline is required"),
      airline_code: yup.string().required("Airline code is required"),
      flight_number: yup.string().required("Flight number is required"),
      departure_airport: yup.string().required("Departure airport is required"),
      departure_airport_code: yup
        .string()
        .required("Departure airport code is required"),
      departure_terminal: yup.string(),
      departure_date: yup.string().required("Departure date is required"),
      departure_time: yup.string().required("Departure time is required"),
      duration: yup.string().required("Duration is required"),
      arrival_airport: yup.string().required("Arrival airport is required"),
      arrival_airport_code: yup
        .string()
        .required("Arrival airport code is required"),
      arrival_terminal: yup.string(),
      arrival_date: yup.string().required("Arrival date is required"),
      arrival_time: yup.string().required("Arrival time is required"),
      is_transit: yup.boolean(),
      transit_duration_days: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .when("is_transit", {
          is: true,
          then: (schema) =>
            schema.required("Transit duration days is required"),
        }),
      transit_duration_hours: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .when("is_transit", {
          is: true,
          then: (schema) =>
            schema.required("Transit duration hours is required"),
        }),
      transit_duration_minutes: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .when("is_transit", {
          is: true,
          then: (schema) =>
            schema.required("Transit duration minutes is required"),
        }),
      cabin: yup.string().required("Cabin is required"),
      baggage_allowance: yup.string().required("Baggage allowance is required"),
      cancelation_before: yup.boolean(),
      cancelation_before_date: yup.string().when("cancelation_before", {
        is: true,
        then: (schema) =>
          schema.required("Cancelation before date is required"),
      }),
      cancelation_before_time: yup.string().when("cancelation_before", {
        is: true,
        then: (schema) =>
          schema.required("Cancelation before time is required"),
      }),
      departure_city_name_new: yup.string(),
      arrival_city_name_new: yup.string(),
      departure_city: yup
        .number()
        .required("Departure city is required")
        .when("departure_city_name_new", {
          is: (value: string) => value.trim() === "",
          then: (schema) => schema.notOneOf([0], "Departure city is required"),
        })
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
      arrival_city: yup
        .number()
        .required("Arrival city is required")
        .when("arrival_city_name_new", {
          is: (value: string) => value.trim() === "",
          then: (schema) => schema.notOneOf([0], "Arrival city is required"),
        })
        .transform((value) => (Number.isNaN(value) ? undefined : value)),
    })
  ),
  wallets: yup
    .array()
    .of(yup.number().required())
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  payment_gateway: yup
    .string()
    .oneOf(["stripe", "paytabs", "kashier"], "Payment gateway is required!")
    .required("Payment gateway is required!"),
  supplier: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  supplier_currency: yup.string(),
  supplier_price: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  payment_date_for_supplier: yup.string(),
  general_quotation: yup
    .number()
    .required("General Quotation is required")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  city: yup
    .number()
    .nullable()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  city_name_new: yup.string().when("city", {
    is: (val: number) => val === 0 || val === null,
    then: (schema) => schema.required("City is required"),
  }),
  confirmation_number: yup.string(),
});

export type flightTypes = yup.InferType<typeof flightSchema>;

export type customFlightErrorTypes = Omit<
  yup.InferType<typeof generalQuotationSchema>,
  "quotations"
> & {
  quotations: yup.InferType<typeof flightSchema>[];
};

export default function FlightFields({
  control,
  errors,
  index,
  setValue,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customFlightErrorTypes>;
  index: number;
  setValue: UseFormSetValue<generalQuotationTypes>;
}) {
  const { t } = useAutoCompleteTranslation();
  const {
    isSelected: isCollapseSelected,
    addToSelected: addToSelectedCollapse,
    replaceState,
  } = useToggleState<string>([]);
  const clientWatcher = useWatch({ control, name: "customerId" });

  const { data: clientData } = useGetCustomerQuery(clientWatcher, {
    skip: clientWatcher === 0,
  });

  useEffect(() => {
    if (clientData) {
      setValue(
        `quotations.${index}.passengers.${0}.client_name`,
        `${clientData.first_name} ${clientData.last_name}`
      );
    }
  }, [clientData]);

  useEffect(() => {
    if (errors.quotations?.[index]) {
      const temp: string[] = [];

      temp.push("flight");

      if (errors.quotations?.[index]?.passengers) {
        temp.push("PassengerDetails");
      }

      if (errors.quotations?.[index]?.routes) {
        temp.push("Route");
      }
      if (errors.quotations?.[index]?.payment_gateway) {
        temp.push("Payment");
      }
      if (errors.quotations?.[index]?.confirmation_number) {
        temp.push("Confirmation_Number");
      }
      if (errors.quotations?.[index]?.parameters) {
        temp.push("Parameter");
      }

      if (temp.length > 0) replaceState(temp);
    }
  }, [errors.quotations]);

  return (
    <Collapse
      isGeneral
      title={t("Flight")}
      isOpen={isCollapseSelected("flight")}
      setIsOpen={() => addToSelectedCollapse("flight")}
    >
      <Collapse
        order={1}
        title={t("Passenger_Details")}
        isOpen={isCollapseSelected("PassengerDetails")}
        setIsOpen={() => addToSelectedCollapse("PassengerDetails")}
      >
        <PassengerDetails
          control={control}
          errors={errors}
          index={index}
          // setValue={setValue}
        />
      </Collapse>
      <Collapse
        order={2}
        title={t("Route")}
        isOpen={isCollapseSelected("Route")}
        setIsOpen={() => addToSelectedCollapse("Route")}
      >
        <RouteDetails
          control={control}
          errors={errors}
          index={index}
          setValue={setValue}
        />
      </Collapse>

      <Collapse
        order={3}
        title={t("Payment")}
        isOpen={isCollapseSelected("Payment")}
        setIsOpen={() => addToSelectedCollapse("Payment")}
      >
        <FlightPayment
          control={control}
          errors={errors}
          index={index}
          // setValue={setValue}
        />
      </Collapse>

      <Collapse
        order={4}
        title={t("Supplier")}
        isOpen={isCollapseSelected("supplier")}
        setIsOpen={() => addToSelectedCollapse("supplier")}
      >
        <SupplierFlightQuotationDetails
          control={control}
          errors={errors}
          index={index}
        />
      </Collapse>
      <Collapse
        order={5}
        title={t("Confirmation_Number")}
        isOpen={isCollapseSelected("Confirmation_Number")}
        setIsOpen={() => addToSelectedCollapse("Confirmation_Number")}
      >
        <ConfirmationNumber control={control} errors={errors} index={index} />
      </Collapse>

      <Collapse
        order={6}
        title={t("Parameter")}
        isOpen={isCollapseSelected("Parameter")}
        setIsOpen={() => addToSelectedCollapse("Parameter")}
      >
        <ParameterDetails control={control} errors={errors} index={index} />
      </Collapse>
    </Collapse>
  );
}
