import Button from "components/general/Button";
import Icon from "components/general/Icon";
import { CollapseProps } from "./types";
import styles from "./styles.module.scss";

export default function Collapse({
  isGeneral = false,
  isSuccess = false,
  order,
  title,
  setIsOpen,
  isOpen,
  children,
  containerStyle,
  innerCollapesContainerStyle,
  showRemoveBtn = false,
  removeCollapse = undefined,
}: CollapseProps) {
  return (
    <div
      className={`${styles.container} ${
        isGeneral ? styles.general : ""
      } ${containerStyle}`}
    >
      <Button
        styleType="NoStyle"
        btnClassName={`${styles.collapse} ${
          isSuccess ? styles.success : ""
        } w-100`}
        onClick={setIsOpen}
      >
        <div className={styles.subject}>
          {showRemoveBtn && (
            <Button styleType="NoStyle" onClick={removeCollapse}>
              <Icon name="minus-cirlce" size={24} color="TextPrimaryBlack" />
            </Button>
          )}
          {isGeneral && (
            <span className={`Paragraph100Heavy ${styles.generalTitle}`}>
              {title}
            </span>
          )}
          {!isGeneral && order && (
            <span className="Paragraph200Heavy">{order}</span>
          )}
          {!isGeneral && <span className="Paragraph100Heavy">{title}</span>}
        </div>
        {isOpen ? (
          <Icon
            name="ChevronDown"
            size={24}
            color={isGeneral ? "TextPrimaryBlack" : "Neutral100"}
          />
        ) : (
          <Icon
            name="ChevronRight"
            size={24}
            color={isGeneral ? "TextPrimaryBlack" : "Neutral100"}
          />
        )}
      </Button>
      {isOpen && (
        <div
          className={`${
            isGeneral ? styles.innerCollapesContainer : ""
          } ${innerCollapesContainerStyle}`}
        >
          {children}
        </div>
      )}
    </div>
  );
}
