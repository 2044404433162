import i18n from "i18n";
import moment from "moment";
import useToggleState from "hooks/useToggleState";
import Collapse from "components/wrappers/Collapse";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { FlightQuotation } from "apis/types/flightQuotation";
import { GeneralQuotationGetReq } from "apis/types/generalQuotations";
import dayjs from "dayjs";
import styles from "../styles.module.scss";

export default function FlightDetails({
  flightData: flight,
  generalQuotation,
}: {
  generalQuotation: GeneralQuotationGetReq;
  flightData: FlightQuotation;
}) {
  const { t } = useAutoCompleteTranslation();

  const {
    isSelected: isCollapseSelected,
    addToSelected: addToSelectedCollapse,
  } = useToggleState<string>([]);

  const currentLanguage = i18n.language ?? "en";

  const CurrencyFormatter = new Intl.NumberFormat(
    currentLanguage === "ar" ? "ar-EG" : "en-US",
    {
      style: "currency",
      currency: generalQuotation?.currency ?? "GBP",
    }
  );

  const sortedParams = flight?.parameters?.length
    ? // eslint-disable-next-line no-unsafe-optional-chaining
      [...flight?.parameters].sort((a: any, b: any) => a.order - b.order)
    : [];

  return (
    <div>
      {/* Passengers */}
      <div className={`${styles.customBorder} ${styles.passengerContainer}`}>
        <div className="d-flex justify-content-between">
          <span className="Paragraph200Light">{t("Confirmation_Number")}</span>
          <span className="Paragraph200Light">
            {flight?.confirmation_number}
          </span>
        </div>
      </div>
      <div className={`${styles.customBorder} ${styles.passengerContainer}`}>
        <span className="Label100"> {t("Passenger")} </span>
        {flight?.passengers.length > 0 && (
          <div className={styles.passengerDetails_container}>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">
                {flight?.passengers[0]?.client_name}
              </span>
              <span className="Paragraph200Light">
                {flight?.passengers[0]?.is_child ? "Child" : "Adult"}
              </span>
            </div>

            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Number_Of_Adults")}</span>
              <span className="Paragraph200Light">
                {flight?.passengers[0]?.no_of_adults}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Number_Of_Teens")}</span>
              <span className="Paragraph200Light">
                {flight?.passengers[0]?.no_of_teens}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">
                {t("Number_Of_Childern")}
              </span>
              <span className="Paragraph200Light">
                {flight?.passengers[0]?.no_of_children}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">
                {t("Number_Of_Infants")}
              </span>
              <span className="Paragraph200Light">
                {flight?.passengers[0]?.no_of_infants}
              </span>
            </div>

            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Price")}</span>
              <span className="Paragraph200Light">
                {CurrencyFormatter.format(Number(flight?.passengers[0]?.price))}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Taxes_Fees")}</span>
              <span className="Paragraph200Light">
                {CurrencyFormatter.format(
                  Number(flight?.passengers[0]?.taxes_fee)
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Kudos_Point")}</span>
              <span className="Paragraph200Light">
                {flight?.passengers[0]?.discount_credit} %
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("discount")}</span>
              <span className="Paragraph200Light">
                {flight?.passengers[0]?.discount} %
              </span>
            </div>
          </div>
        )}

        {flight?.passengers?.length > 1 &&
          flight?.passengers?.slice(1).map((passenger, index) => {
            return (
              <Collapse
                title={`${t("Passenger")} ${index + 2}`}
                isOpen={isCollapseSelected(`Passenger ${index + 2}`)}
                setIsOpen={() =>
                  addToSelectedCollapse(`Passenger ${index + 2}`)
                }
                containerStyle={styles.bgCollapse}
              >
                <div
                  className={`${styles.passengerDetails_container} ${styles.secondPassenger} ${styles.customBorder}`}
                >
                  <div className="d-flex justify-content-between">
                    <span className="Paragraph200Light">
                      {passenger.client_name}
                    </span>
                    <span className="Paragraph200Light">
                      {passenger.is_child ? t("Child") : t("Adult")}
                    </span>
                  </div>

                  <div className="d-flex justify-content-between">
                    <span className="Paragraph200Light">{t("Age")}</span>
                    <span className="Paragraph200Light">{passenger?.age}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="Paragraph200Light">{t("Price")}</span>
                    <span className="Paragraph200Light">
                      {CurrencyFormatter.format(Number(passenger?.price))}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="Paragraph200Light">{t("Taxes_Fees")}</span>
                    <span className="Paragraph200Light">
                      {CurrencyFormatter.format(Number(passenger?.taxes_fee))}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="Paragraph200Light">
                      {t("Kudos_Point")}
                    </span>
                    <span className="Paragraph200Light">
                      {passenger?.discount_credit} %
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="Paragraph200Light">{t("Discount")}</span>
                    <span className="Paragraph200Light">
                      {passenger?.discount} %
                    </span>
                  </div>
                </div>
              </Collapse>
            );
          })}
      </div>
      {/* Routes */}

      <div className={`${styles.customBorder} ${styles.routeContainer}`}>
        <span className="Label100"> {t("Route")} </span>
        {flight.routes.length > 0 && (
          <div className={styles.passengerDetails_container}>
            {flight.routes[0].is_transit && (
              <div className="d-flex justify-content-between">
                {flight?.routes[0]?.is_transit && (
                  <span className="Paragraph200Light">{t("Transit")}</span>
                )}
                <span className="Paragraph200Light">
                  {flight?.routes[0]?.transit_duration_days
                    ? `${flight?.routes[0]?.transit_duration_days} ${t("Days")}`
                    : ""}{" "}
                  {flight?.routes[0]?.transit_duration_hours
                    ? `${flight?.routes[0]?.transit_duration_hours} ${t(
                        "Hours"
                      )}`
                    : ""}{" "}
                  {flight?.routes[0]?.transit_duration_minutes
                    ? `${flight?.routes[0]?.transit_duration_minutes} ${t(
                        "Min"
                      )}`
                    : ""}
                </span>
              </div>
            )}

            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Airline")}</span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.airline}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Airline_Code")}</span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.airline_code}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Flight_Number")}</span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.flight_number}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Departure_City")}</span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.departure_city_name},{" "}
                {flight?.routes[0]?.departure_country_name &&
                  flight?.routes[0]?.departure_country_name}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Arrival_City")}</span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.arrival_city_name},
                {flight?.routes[0]?.arrival_country_name}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Departure_Date")}</span>
              <span className="Paragraph200Light">
                {dayjs(flight?.routes[0]?.departure_datetime).format(
                  "DD/MM/YYYY"
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Arrival_Date")}</span>
              <span className="Paragraph200Light">
                {dayjs(flight?.routes[0]?.arrival_datetime).format(
                  "DD/MM/YYYY"
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Departure_Time")}</span>
              <span className="Paragraph200Light">
                {moment(flight?.routes[0]?.departure_datetime).format("HH:mm")}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Arrival_Time")}</span>
              <span className="Paragraph200Light">
                {moment(flight?.routes[0]?.arrival_datetime).format("HH:mm")}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">
                {t("Departure_Airport")}
              </span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.departure_airport}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Arrival_Airport")}</span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.arrival_airport}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">
                {t("Departure_Airport_Code")}
              </span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.departure_airport_code}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">
                {t("Arrival_Airport_Code")}
              </span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.arrival_airport_code}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">
                {t("Departure_Terminal")}
              </span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.departure_terminal}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Arrival_Terminal")}</span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.arrival_terminal}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Duration")}</span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.duration}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Cabin")}</span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.cabin}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">{t("Baggage")}</span>
              <span className="Paragraph200Light">
                {flight?.routes[0]?.baggage_allowance}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">
                {t("Cancelation_Before")}
              </span>

              <span
                className={`Paragraph200Heavy  ${
                  flight?.routes[0]?.cancelation_before
                    ? styles.refund
                    : styles.nonRefund
                }`}
              >
                {flight?.routes[0]?.cancelation_before
                  ? t("Refundable")
                  : t("Non_Refundable")}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="Paragraph200Light">
                {t("Cancelation_Before_Date")}{" "}
              </span>
              <span className="Paragraph200Light">
                {dayjs(flight?.routes[0]?.cancelation_before_datetime).format(
                  "DD/MM/YYYY"
                )}
              </span>
            </div>

            {flight?.routes[0]?.cancelation_before && (
              <>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Cancelation_Date")}
                  </span>
                  <span className="Paragraph200Light">
                    {dayjs(
                      flight?.routes[0]?.cancelation_before_datetime
                    ).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Cancelation_Time")}
                  </span>
                  <span className="Paragraph200Light">
                    {moment(
                      flight?.routes[0]?.cancelation_before_datetime
                    ).format("HH:mm")}
                  </span>
                </div>
              </>
            )}
          </div>
        )}
        {flight?.routes?.length > 1 &&
          flight?.routes?.slice(1).map((route, routeIndex) => (
            <Collapse
              title={`${t("Route")} ${routeIndex + 2}`}
              isOpen={isCollapseSelected(`Route ${routeIndex + 2}`)}
              setIsOpen={() => addToSelectedCollapse(`Route ${routeIndex + 2}`)}
              containerStyle={styles.bgCollapse}
            >
              <div
                className={`${styles.passengerDetails_container} ${styles.secondPassenger} ${styles.customBorder}`}
              >
                <div className="d-flex justify-content-between">
                  {route?.is_transit && (
                    <span className="Paragraph200Light">{t("Transit")}</span>
                  )}
                  <span className="Paragraph200Light">
                    {route?.transit_duration_days
                      ? `${route?.transit_duration_days} ${t("Days")}`
                      : ""}{" "}
                    {route?.transit_duration_hours
                      ? `${route?.transit_duration_hours} ${t("Hours")}`
                      : ""}{" "}
                    {route?.transit_duration_minutes
                      ? `${route?.transit_duration_minutes} ${t("Min")}`
                      : ""}
                  </span>
                </div>

                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">{t("Airline")}</span>
                  <span className="Paragraph200Light">{route.airline}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">{t("Airline_Code")}</span>
                  <span className="Paragraph200Light">
                    {route.airline_code}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Flight_Number")}
                  </span>
                  <span className="Paragraph200Light">
                    {route.flight_number}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Departure_City")}
                  </span>
                  <span className="Paragraph200Light">
                    {route.departure_city_name},{route.departure_country_name}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">{t("Arrival_City")}</span>
                  <span className="Paragraph200Light">
                    {route.arrival_city_name},{route.arrival_country_name}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Departure_Date")}
                  </span>
                  <span className="Paragraph200Light">
                    {dayjs(route.departure_datetime).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">{t("Arrival_Date")}</span>
                  <span className="Paragraph200Light">
                    {dayjs(route.arrival_datetime).format("DD/MM/YYYY")}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Departure_Time")}
                  </span>
                  <span className="Paragraph200Light">
                    {moment(route.departure_datetime).format("HH:mm")}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">{t("Arrival_Time")}</span>
                  <span className="Paragraph200Light">
                    {moment(route.arrival_datetime).format("HH:mm")}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Departure_Airport")}
                  </span>
                  <span className="Paragraph200Light">
                    {route.departure_airport}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Arrival_Airport")}
                  </span>
                  <span className="Paragraph200Light">
                    {route.arrival_airport}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Departure_Airport_Code")}
                  </span>
                  <span className="Paragraph200Light">
                    {route.departure_airport_code}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Arrival_Airport_Code")}
                  </span>
                  <span className="Paragraph200Light">
                    {route.arrival_airport_code}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Departure_Terminal")}
                  </span>
                  <span className="Paragraph200Light">
                    {route.departure_terminal}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Arrival_Terminal")}
                  </span>
                  <span className="Paragraph200Light">
                    {route.arrival_terminal}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">{t("Cabin")}</span>
                  <span className="Paragraph200Light">{route.cabin}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">{t("Baggage")}</span>
                  <span className="Paragraph200Light">
                    {route.baggage_allowance}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Cancelation_Before")}
                  </span>
                  <span
                    className={`Paragraph200Heavy ${
                      route.cancelation_before
                        ? styles.refund
                        : styles.nonRefund
                    }`}
                  >
                    {route.cancelation_before
                      ? t("Refundable")
                      : t("Non_Refundable")}
                  </span>
                </div>
                {route.cancelation_before && (
                  <>
                    <div className="d-flex justify-content-between">
                      <span className="Paragraph200Light">
                        {t("Cancelation_Date")}
                      </span>
                      <span className="Paragraph200Light">
                        {dayjs(route.cancelation_before_datetime).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span className="Paragraph200Light">
                        {t("Cancelation_Time")}
                      </span>
                      <span className="Paragraph200Light">
                        {dayjs(route.cancelation_before_datetime).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </Collapse>
          ))}
      </div>
      {/* Payment */}

      <div className={`${styles.customBorder} ${styles.passengerContainer}`}>
        <span className="Label100"> {t("Payment")} </span>
        <div className={styles.passengerDetails_container}>
          {/* <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Wallet_Amount")}</span>
            <span className="Paragraph200Light">{flight?.wallets}</span>
          </div> */}
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Payment_gateway")}</span>
            <span className="Paragraph200Light">
              {flight?.payment_gateway &&
                flight.payment_gateway.charAt(0).toUpperCase() +
                  flight.payment_gateway.slice(1)}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("payment_link")}</span>
            {flight?.payment_link !== null && (
              <a
                rel="noreferrer"
                target="_blank"
                className="Paragraph200Light"
                href={flight?.payment_link}
              >
                Click here to open
              </a>
            )}
          </div>
        </div>
      </div>

      <div className={`${styles.customBorder} ${styles.passengerContainer}`}>
        <span className="Label100"> {t("Parameter")} </span>
        {sortedParams.map((item: any) => (
          <div key={item.order} className="d-flex justify-content-between">
            <span className="Paragraph200Light">{item.name}</span>
            <span
              className={`Paragraph200Light w-50 ${
                currentLanguage === "en" ? "text-end" : "text-start"
              } text-break`}
            >
              {item.value}
            </span>
          </div>
        ))}
      </div>

      {/* Supplier */}

      <div className={`${styles.customBorder} ${styles.passengerContainer}`}>
        <span className="Label100"> {t("Supplier_Name")} </span>
        <div className={styles.passengerDetails_container}>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Supplier_Name")}</span>
            <span className="Paragraph200Light">{flight?.supplier_name}</span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Supplier_Price")}</span>
            <span className="Paragraph200Light">
              {new Intl.NumberFormat(
                currentLanguage === "ar" ? "ar-EG" : "en-US",
                {
                  style: "currency",
                  currency: flight?.supplier_currency
                    ? flight?.supplier_currency
                    : "GBP",
                }
              ).format(Number(flight?.supplier_price ?? 0))}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Payment_Date")}</span>
            <span className="Paragraph200Light">
              {flight?.payment_date_for_supplier
                ? dayjs(flight?.payment_date_for_supplier).format("DD/MM/YYYY")
                : ""}
            </span>
          </div>
        </div>
      </div>

      <div className={`${styles.customBorder} ${styles.passengerContainer}`}>
        <span className="Label200">{t("Price")}</span>
        <div className={styles.passengerDetails_container}>
          {flight.total_price_before_discount !== flight.total && (
            <div className="d-flex justify-content-between ">
              <span className="Paragraph200Light">
                {t("Total_Price_Before_Discount")}{" "}
              </span>
              <span className="Paragraph200Light">
                {CurrencyFormatter.format(
                  Number(flight.total_price_before_discount)
                )}
              </span>
            </div>
          )}

          {Number(flight.total_discount_amount) !== 0 && (
            <div className="d-flex justify-content-between ">
              <span className="Paragraph200Light">
                {t("Total_Discount_Amount")}{" "}
              </span>
              <span className="Paragraph200Light">
                -{" "}
                {CurrencyFormatter.format(Number(flight.total_discount_amount))}
              </span>
            </div>
          )}

          {Number(flight.amount_deducted_from_wallet) !== 0 && (
            <div className="d-flex justify-content-between ">
              <span className="Paragraph200Light">
                {t("Amount_Deducted_From_Wallet")}{" "}
              </span>
              <span className="Paragraph200Light">
                -{" "}
                {CurrencyFormatter.format(
                  Number(flight.amount_deducted_from_wallet)
                )}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Total")}</span>
            <span className={`Paragraph200Heavy ${styles.totalPrice}`}>
              {CurrencyFormatter.format(Number(flight.total))}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
