import { useEffect, useState } from "react";
import PageTitle from "components/general/PageTitle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteSupplierMutation,
  useGetSupplierQuery,
} from "apis/services/suppliers";
import Spinner from "components/general/Spinner";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import ModalWrapper from "components/modals/ModalWrapper";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "./styles.module.scss";

export default function SupplierDetails() {
  const { t } = useAutoCompleteTranslation();
  const { supplierId } = useParams();
  const navigate = useNavigate();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { pathname } = useLocation();
  const { data: supplier, isLoading } = useGetSupplierQuery(supplierId || "");
  const handleEditBtn = () => {
    navigate(`/suppliers/edit-supplier/${supplierId}`);
  };

  const [deleteSupllier, { isLoading: isDeleteLoading }] =
    useDeleteSupplierMutation();

  const handleDeleteAgent = () => {
    if (supplierId) {
      deleteSupllier(supplierId)
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/user-management");
          showToast({
            toastType: "success",
            title: `${supplier?.name}  has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };

  useEffect(() => {
    if (supplier) {
      const updatedUrl = `${pathname}?idName=${supplier?.name}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [supplier]);

  return (
    <section>
      <PageTitle> {supplier?.name} </PageTitle>
      <div className={styles.detailsContainer}>
        {isLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <div className="d-flex justify-content-between w-100">
            <div className={styles.info_container}>
              <div className="d-flex flex-column gap-2">
                <span className="Label100"> {t("name")} </span>
                <span className="Paragraph200Light"> {supplier?.name} </span>
              </div>
              <div className="d-flex flex-column gap-2">
                <span className="Label100"> {t("Service_type")}</span>
                <span className="Paragraph200Light"> {supplier?.service} </span>
              </div>
              <div className="d-flex flex-column gap-2">
                <span className="Label100"> {t("Currency")} </span>
                <span className="Paragraph200Light">
                  {" "}
                  {supplier?.currency}{" "}
                </span>
              </div>
              <div className="d-flex flex-column gap-2">
                <span className="Label100"> {t("GTO_Current_Amount")} </span>
                <span className="Paragraph200Light"> {supplier?.credit} </span>
              </div>
            </div>
            <div className="d-flex gap-2">
              <Button
                styleType="Primary"
                prefix={<Icon name="Edit" size={16} color="TextPrimaryWhite" />}
                btnClassName={styles.editBtn}
                onClick={handleEditBtn}
              >
                <span className="Button100 text-white"> {t("Edit")} </span>
              </Button>
              <Button
                styleType="Secondary"
                prefix={
                  <Icon name="Delete" size={16} color="TextPrimaryBlack" />
                }
                btnClassName={styles.editBtn}
                onClick={() => setIsDeleteModalVisible(true)}
              >
                <span className="Button100">{t("Delete")}</span>
              </Button>
            </div>
          </div>
        )}
      </div>
      <ModalWrapper
        size="large"
        headerTitle="Delete"
        modalType="error"
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        isFooterHidden
      >
        {isDeleteLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Medium" />
          </div>
        ) : (
          <>
            <span className="Paragraph100Light">
              {t("Delete_Modal")} ({supplier?.name})
            </span>
            <div className={styles.deleteModalBtns}>
              <Button
                styleType="Secondary"
                btnClassName={styles.cancelBtn}
                onClick={() => setIsDeleteModalVisible(false)}
              >
                <span className="Button100">{t("Cancel")}</span>
              </Button>
              <Button
                btnClassName={styles.deleteBtn}
                onClick={handleDeleteAgent}
              >
                <span className="Button100">{t("Delete")}</span>
              </Button>
            </div>
          </>
        )}
      </ModalWrapper>
    </section>
  );
}
