/* eslint-disable no-nested-ternary */
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { AutoComplete } from "antd";
import Spinner from "components/general/Spinner";
import { SelectionSearchInputProps } from "./types";
import styles from "./styles.module.scss";

export default function SelectionSearchInput({
  containerStyle,
  options,
  value,
  placeholder = "",
  size = "large",
  label,
  i18nPlaceholder,
  i18nLabelKey,
  status = "",
  isDisabled = false,
  defaultValue = undefined,
  isLoading = false,
  onSearch, // use onSearch instead of onChange because in AutoComplete component onChange get triggered when input change or option is selected, but onSearch get triggered when input changes
  onSelect,
}: SelectionSearchInputProps) {
  const { t } = useAutoCompleteTranslation();
  const id = `selectInput${Math.random()}`;

  return (
    <div className={`${containerStyle}`} id={id}>
      {(!!label || !!i18nLabelKey) && (
        <span className="d-block mb-2 Label100">
          {i18nLabelKey ? t(i18nLabelKey) : label}
        </span>
      )}
      <AutoComplete
        className={`${styles.selectInput} ${styles[size]} w-100`}
        getPopupContainer={(trigger: HTMLElement | null) => {
          return (
            (trigger?.parentNode as HTMLElement) ?? document.getElementById(id)
          );
        }}
        suffixIcon={
          isLoading && (
            <Spinner containerStyle={styles.loadingSpinner} size="Small" />
          )
        }
        placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
        value={value}
        options={options}
        status={status}
        disabled={isDisabled}
        defaultValue={defaultValue}
        onSearch={onSearch}
        onSelect={onSelect}
        filterOption={(inputValue, option) =>
          option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
      />
    </div>
  );
}
