import api from "apis";
import { ChatMessage, Conversation } from "apis/types/chat";
import { PaginatedResponse } from "apis/types/general";
import infintyPaginationMergeHandler from "utils/infintyPaginationMergeHandler";

export const chatApi = api.injectEndpoints({
  endpoints: (build) => ({
    getConversations: build.query<
      PaginatedResponse<Conversation>,
      { page: number; search?: string }
    >({
      query: (params) => ({
        url: "/chat/?page_size=50",
        params: { ...params },
      }),
      serializeQueryArgs: ({
        endpointName,
        queryArgs,
      }: {
        endpointName: string;
        queryArgs: any;
      }) => {
        let result = endpointName;

        Object.keys(queryArgs || {}).forEach((key) => {
          if (key === "page") return;
          result = `${result}-(${key}=${queryArgs[key]})`;
        });

        return result;
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
      merge: infintyPaginationMergeHandler,
    }),

    // this endpoint initialized from customers side only until now
    initConversation: build.mutation<void, void>({
      query: () => ({
        url: "/chat/",
        method: "POST",
      }),
    }),

    sendMessage: build.mutation<void, FormData>({
      query: (body) => ({
        url: "/chat/send_message/",
        method: "POST",
        body,
      }),
    }),

    getConversationMessages: build.query<
      PaginatedResponse<ChatMessage>,
      { id: number | null; page: number }
    >({
      query: (params) => ({
        url: `/chat/${params.id}/chat_messages/?page_size=200`,
        params: { page: params.page },
      }),
      serializeQueryArgs: ({
        endpointName,
        queryArgs,
      }: {
        endpointName: string;
        queryArgs: any;
      }) => {
        let result = endpointName;

        Object.keys(queryArgs || {}).forEach((key) => {
          if (key === "page") return;
          result = `${result}-(${key}=${queryArgs[key]})`;
        });

        return result;
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.page !== previousArg?.page ||
          currentArg?.id !== previousArg?.id
        );
      },
      merge: infintyPaginationMergeHandler,
    }),
  }),
});

export const {
  useGetConversationsQuery,
  useInitConversationMutation,
  useSendMessageMutation,
  useGetConversationMessagesQuery,
} = chatApi;
