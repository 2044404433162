import Icon from "components/general/Icon";
import Button from "components/general/Button";
import { ModalWrapperProps } from "./types";
import styles from "./styles.module.scss";

export default function ModalWrapper({
  children,
  size,
  headerTitle,
  headerTitleJustify = "start",
  isFooterHidden = false,
  onConfirm,
  isVisible = false,
  setIsVisible,
  modalType,
  onClose = () => {},
}: ModalWrapperProps) {
  if (!isVisible) return null;

  const iconTypes = {
    warning: "Warning",
    error: "Error",
    info: "Info",
    success: "Done",
  };

  return (
    <div className={styles.modal}>
      <div className={`${styles.modalBox} ${styles[size]}`}>
        {/* Modal Header */}
        <div className={styles.headerTitleContainer}>
          {modalType && (
            <div>
              <div
                className={`${styles.modalIconContainer} ${styles[modalType]}`}
              >
                <Icon name={iconTypes[modalType]} size={20} />
              </div>
            </div>
          )}
          <div
            className={`${styles.headerTitle} ${styles[headerTitleJustify]}`}
          >
            <span className="Headline200Heavy">{headerTitle}</span>
          </div>
          <Button
            styleType="White"
            btnClassName={styles.modalCloseBtn}
            onClick={() => {
              setIsVisible?.(false);
              onClose();
            }}
          >
            <Icon name="Close" size={20} color="TextSecondaryBlack" />
          </Button>
        </div>
        {/* Modal Body */}
        <div className={styles.body}>{children}</div>
        {/* Modal Footer */}
        {!isFooterHidden && (
          <div className={styles.footer}>
            <Button styleType="Primary" onClick={onConfirm}>
              Confirm
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
