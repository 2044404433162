import i18n from "i18n";
import dayjs from "dayjs";
import { GeneralQuotationGetReq } from "apis/types/generalQuotations";
import { GeneralTypeOfQuotations } from "apis/types/generalTypeOfQuotaions";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import styles from "../styles.module.scss";

export default function GeneralDetails({
  generalData,
  generalQuotation,
}: {
  generalData: GeneralTypeOfQuotations;
  generalQuotation: GeneralQuotationGetReq;
}) {
  const { t } = useAutoCompleteTranslation();

  const sortedParams = [...generalData.parameters].sort(
    (a: any, b: any) => a.order - b.order
  );

  const currentLanguage = i18n.language ?? "en";

  const CurrencyFormatter = new Intl.NumberFormat(
    currentLanguage === "ar" ? "ar-EG" : "en-US",
    {
      style: "currency",
      currency: generalQuotation?.currency ?? "GBP",
    }
  );

  return (
    <div className={`${styles.customBorder} ${styles.passengerContainer}`}>
      <span className="Label100">{generalData?.quotation_type_name}</span>

      <div className={styles.passengerDetails_container}>
        {sortedParams.map((item: any) => (
          <div key={item.order} className="d-flex justify-content-between">
            <span className="Paragraph200Light">{item.name}</span>
            <span
              className={`Paragraph200Light w-50 ${
                currentLanguage === "en" ? "text-end" : "text-start"
              } text-break`}
            >
              {item.value}
            </span>
          </div>
        ))}
        <div className="d-flex justify-content-between">
          <span className="Paragraph200Light">{t("Cancelation_Before")} </span>
          <span
            className={`${styles.nonRefundableText} ${
              generalData.cancelation_before
                ? styles.Refundable
                : styles.nonRefundable
            }`}
          >
            {generalData.cancelation_before
              ? t("Refundable")
              : t("Non_Refundable")}
          </span>
        </div>
        <div className="d-flex justify-content-between">
          <span className="Paragraph200Light">
            {t("Cancelation_Before_Date")}{" "}
          </span>
          <span className="Paragraph200Light">
            {dayjs(generalData.cancelation_before_date).format("DD/MM/YYYY")}
          </span>
        </div>
      </div>

      <div className={`${styles.customBorder} ${styles.passengerContainer}`}>
        <span className="Label200"> {t("Price")} </span>
        <div
          className={`${styles.passengerDetails_container} ${styles.spaceStart}`}
        >
          <div className="d-flex justify-content-between ">
            <span className="Paragraph200Light">{t("Price")} </span>
            <span className="Paragraph200Light">
              {CurrencyFormatter.format(Number(generalData?.price))}
            </span>
          </div>

          <div className="d-flex justify-content-between ">
            <span className="Paragraph200Light">{t("Taxes_Fees")} </span>
            <span className="Paragraph200Light">
              {CurrencyFormatter.format(Number(generalData.taxes_fee))}
            </span>
          </div>

          <div className="d-flex justify-content-between ">
            <span className="Paragraph200Light">{t("Kudos_Point")} </span>
            <span className="Paragraph200Light">
              {generalData.discount_credit} %
            </span>
          </div>

          <div className="d-flex justify-content-between ">
            <span className="Paragraph200Light">{t("discount")}</span>
            <span className={`Paragraph200Light `}>
              {generalData.discount} %
            </span>
          </div>
          {generalData.total_price_before_discount !== generalData.total && (
            <div className="d-flex justify-content-between ">
              <span className="Paragraph200Light">
                {t("Total_Price_Before_Discount")}
              </span>
              <span className="Paragraph200Light">
                {CurrencyFormatter.format(
                  Number(generalData.total_price_before_discount)
                )}
              </span>
            </div>
          )}

          {Number(generalData.total_discount_amount) !== 0 && (
            <div className="d-flex justify-content-between ">
              <span className="Paragraph200Light">
                {t("Total_Discount_Amount")}{" "}
              </span>
              <span className="Paragraph200Light">
                -{" "}
                {CurrencyFormatter.format(
                  Number(generalData.total_discount_amount)
                )}
              </span>
            </div>
          )}

          {Number(generalData.amount_deducted_from_wallet) !== 0 && (
            <div className="d-flex justify-content-between ">
              <span className="Paragraph200Light">
                {t("Amount_Deducted_From_Wallet")}{" "}
              </span>
              <span className="Paragraph200Light">
                -{" "}
                {CurrencyFormatter.format(
                  Number(generalData.amount_deducted_from_wallet)
                )}
              </span>
            </div>
          )}

          <div className="d-flex justify-content-between ">
            <span className="Paragraph200Light">{t("Total")}</span>
            <span className={`Paragraph200Heavy ${styles.totalPrice}`}>
              {CurrencyFormatter.format(Number(generalData.total))}
            </span>
          </div>
        </div>
      </div>

      <div className={`${styles.customBorder} ${styles.passengerContainer}`}>
        <span className="Label100"> {t("Payment")} </span>
        <div className="d-flex justify-content-between">
          <span className="Paragraph200Light">{t("payment_link")}</span>
          {generalData?.payment_link !== null && (
            <a
              rel="noreferrer"
              target="_blank"
              className="Paragraph200Light"
              href={generalData.payment_link}
            >
              Click here to open
            </a>
          )}
        </div>
      </div>

      {/* Supplier */}

      <div className={`${styles.customBorder} ${styles.passengerContainer}`}>
        <span className="Label100"> {t("Supplier_Name")} </span>
        <div className={styles.passengerDetails_container}>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Supplier_Name")}</span>
            <span className="Paragraph200Light">
              {generalData?.supplier_name}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Supplier_Price")}</span>
            <span className="Paragraph200Light">
              {new Intl.NumberFormat(
                currentLanguage === "ar" ? "ar-EG" : "en-US",
                {
                  style: "currency",
                  currency: generalData?.supplier_currency
                    ? generalData?.supplier_currency
                    : "GBP",
                }
              ).format(Number(generalData?.supplier_price ?? 0))}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Payment_Date")}</span>
            <span className="Paragraph200Light">
              {generalData?.payment_date_for_supplier
                ? dayjs(generalData?.payment_date_for_supplier).format(
                    "DD/MM/YYYY"
                  )
                : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
