import getClassNames from "utils/getClassName";
import styles from "./styles.module.scss";
import { ButtonProps } from "./types";
import Spinner from "../Spinner";

export default function Button({
  styleType = "Primary",
  size = "large",
  children,
  prefix,
  suffix,
  onClick = () => {},
  type = "button",
  disabled = false,
  btnClassName = "",
  isLoading = false,
  spinnerSize = "Small",
  tabIndex,
}: ButtonProps) {
  const buttonClassName = getClassNames([
    styles.customBtn,
    styles[styleType],
    styles[size],
    btnClassName,
  ]);

  return (
    <button
      className={buttonClassName}
      type={type}
      onClick={onClick}
      disabled={disabled}
      tabIndex={tabIndex}
    >
      {isLoading ? (
        <Spinner size={spinnerSize} />
      ) : (
        <>
          {!!prefix && (
            <div className="d-flex justify-content-center align-items-center">
              {prefix}
            </div>
          )}
          {children}
          {!!suffix && (
            <div className="d-flex justify-content-center align-items-center">
              {suffix}
            </div>
          )}
        </>
      )}
    </button>
  );
}
