import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useDeleteMembershipMutation,
  useGetMembershipQuery,
} from "apis/services/memberships";
import PageTitle from "components/general/PageTitle";
import Spinner from "components/general/Spinner";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import StatusBadge from "components/general/StatusBadge";
import ModalWrapper from "components/modals/ModalWrapper";
import { Benefit } from "apis/types/memberships";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import CustomCridetCardLetters from "components/general/CustomCridetCardLetters";
import styles from "./styles.module.scss";

function BenefitsDetailRow({ benefit }: { benefit: Benefit }) {
  return (
    <div className={styles.benefitItemContainer}>
      <span className={`${styles.benefitTitle} Paragraph100Light`}>
        {benefit.general_benefit_name || benefit.benefit}
      </span>
      {benefit.conditions.map((condition) => (
        <div className="d-flex justify-content-between">
          <div className={`col-8 ${styles.benefitItemContainer}`}>
            <span className="Paragraph100Light">
              {condition.general_condition_name || condition.condition}
            </span>
          </div>
          <div className={`col-3 ${styles.benefitItemContainer}`}>
            <div className="d-flex justify-content-between">
              <span className="Paragraph100Light">{condition.discount}</span>
              <span className="Paragraph100Light">%</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default function MembershipDetails() {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();
  const { membershipId } = useParams();
  const { pathname } = useLocation();
  const { data: membership, isLoading } = useGetMembershipQuery(
    membershipId || ""
  );

  const [deleteMembership, { isLoading: isDeleteLoading }] =
    useDeleteMembershipMutation();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const handleDeleteMembership = () => {
    if (membershipId) {
      deleteMembership(membershipId)
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/memberships");
          showToast({
            toastType: "success",
            title: `${membership?.name} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };
  useEffect(() => {
    if (membership) {
      const updatedUrl = `${pathname}?idName=${membership?.name}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [membership]);

  return (
    <section>
      <PageTitle> {t("Membership_Details")} </PageTitle>

      <div className={styles.mainContentContainer}>
        {isLoading || isDeleteLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <div className="d-flex flex-row-reverse justify-content-between">
            <div className={styles.card}>
              <CustomCridetCardLetters
                letterType={membership?.letters_type}
                bgColor={membership?.template_color}
                bgImage={membership?.template_image}
                textColor1={membership?.template_text_color_1}
                textColor2={membership?.template_text_color_2}
                shadowColor={membership?.template_shadow_color}
                shadowWidth={Number(membership?.template_shadow_width)}
              />
            </div>
            <div className={styles.deleteAndEditBtns}>
              <Button
                size="small"
                prefix={<Icon name="Edit" size={16} />}
                btnClassName={styles.edit}
                onClick={() =>
                  navigate(`/memberships/edit-membership/${membershipId}`)
                }
              >
                <span className="Button100"> {t("Edit")} </span>
              </Button>
              <Button
                size="small"
                styleType="Secondary"
                prefix={<Icon name="delete_outline" size={16} />}
                btnClassName={styles.delete}
                onClick={() => setIsDeleteModalVisible(true)}
              >
                <span className="Button100">{t("Delete")}</span>
              </Button>
            </div>

            <div className={styles.innerContainer}>
              <div className="d-flex flex-column gap-2 col-6">
                <span className="Label100"> {t("Card_Name")} </span>
                <span className={`Paragraph200Light ${styles.dimmed}`}>
                  {membership?.name}
                </span>
              </div>
              <div className="d-flex flex-column gap-2 col-6">
                <span className="Label100"> {t("Annual_Fees")} </span>
                <span className={`Paragraph200Light ${styles.dimmed}`}>
                  {membership?.annual_fees} {membership?.currency.toUpperCase()}
                </span>
              </div>
              <div className="w-100 d-flex flex-column gap-2">
                <span className="Label100">{t("Status")}</span>
                <StatusBadge type={membership?.status ? "success" : "error"}>
                  <span className="Label200">
                    {membership?.status ? t("Active") : t("Inactive")}
                  </span>
                </StatusBadge>
              </div>

              <div className={styles.benefits}>
                <span className="Label100"> {t("Benefits")} </span>
                {membership?.benefits.map((item) => {
                  if (!item.is_active) return;
                  // eslint-disable-next-line consistent-return
                  return (
                    <BenefitsDetailRow
                      key={item.id! * Math.random()}
                      benefit={item}
                    />
                  );
                })}
              </div>

              <div className="w-100 d-flex flex-column gap-2">
                <span className="Label100"> {t("Additional_Notes")} </span>
                <p className="Paragraph200Light">{membership?.notes}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <ModalWrapper
        size="large"
        headerTitle={t("Delete")}
        modalType="error"
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        isFooterHidden
      >
        {isDeleteLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Medium" />
          </div>
        ) : (
          <>
            <span className="Paragraph100Light">
              {t("Delete_Modal")} ({membership?.name})?
            </span>
            <div className={styles.deleteModalBtns}>
              <Button
                styleType="Secondary"
                btnClassName={styles.cancelBtn}
                onClick={() => setIsDeleteModalVisible(false)}
              >
                <span className="Button100">{t("Cancel")}</span>
              </Button>
              <Button
                btnClassName={styles.deleteBtn}
                onClick={handleDeleteMembership}
              >
                <span className="Button100">{t("Delete")}</span>
              </Button>
            </div>
          </>
        )}
      </ModalWrapper>
    </section>
  );
}
