/* eslint-disable no-nested-ternary */
import { useState } from "react";
import { useGetWalletTransactionsQuery } from "apis/services/wallet";
import { WalletItem } from "apis/types/wallet";
import Button from "components/general/Button";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Spinner from "components/general/Spinner";
import Table from "components/general/Table";
import ModalWrapper from "components/modals/ModalWrapper";
import { TableItemProps } from "components/general/Table/types";
import { useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";

export default function WalletTable({
  id,
  wallet_total_inactive,
  wallet_total_active,
}: {
  id: number;
  wallet_total_inactive: number;
  wallet_total_active: number;
}) {
  const { t } = useAutoCompleteTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isActiveWallet, setIsActiveWallet] = useState<boolean | undefined>(
    true
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data: walletTransactions, isLoading } = useGetWalletTransactionsQuery(
    {
      customer_id: id,
      page_size: 5,
      is_active: isActiveWallet,
      page: searchParams.get("page") ?? 1,
    }
  );

  const headers = [
    " ",
    t("Wallet_Amount"),
    t("Status"),
    <Button
      styleType="NoStyle"
      btnClassName={styles.linkBtn}
      onClick={() => {
        setIsActiveWallet(undefined);
        setIsModalVisible(true);
      }}
    >
      <span className="Button100">{t("View_All")}</span>
    </Button>,
    " ",
  ];

  const arrangeWalletData = (item: WalletItem): TableItemProps => ({
    id: String(item.id),
    rowData: [
      { type: "pureText", dataItem: `${item.amount} ${item.currency}` },
      {
        type: "pureText",
        dataItem: `${item.amount - Number(item.amount_used)} ${item.currency}`,
      },
      {
        type: "status",
        dataItem: item.is_active ? t("Active") : t("Inactive"),
      },
      {
        type: "pureText",
        dataItem: (
          <span className="text-capitalize">
            {item.amount_type.split("_").join(" ")}
          </span>
        ),
      },
      {
        type: "pureText",
        dataItem: item.date,
      },
    ],
  });

  return (
    <div>
      {isLoading ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : (
        <Table
          containerStyle="mt-4"
          isCheckBoxVisible={false}
          tableName="walletTable"
          headers={headers}
          data={[
            {
              id: "1",
              rowData: [
                { type: "pureText", dataItem: "" },
                {
                  type: "pureText",
                  dataItem: `${Number(wallet_total_active).toFixed(2)} GBP`,
                },
                { type: "status", dataItem: t("Active") },
                {
                  type: "link",
                  dataItem: [
                    t("View_More_Info"),
                    () => {
                      setIsActiveWallet(true);
                      setIsModalVisible(true);
                    },
                  ],
                },
                { type: "pureText", dataItem: "" },
              ],
            },
            {
              id: "2",
              rowData: [
                { type: "pureText", dataItem: "" },
                {
                  type: "pureText",
                  dataItem: `${Number(wallet_total_inactive).toFixed(2)} GBP`,
                },
                { type: "status", dataItem: t("Inactive") },
                {
                  type: "link",
                  dataItem: [
                    t("View_More_Info"),
                    () => {
                      setIsActiveWallet(false);
                      setIsModalVisible(true);
                    },
                  ],
                },
                { type: "pureText", dataItem: "" },
              ],
            },
          ]}
        />
      )}

      <ModalWrapper
        headerTitle={
          typeof isActiveWallet === "undefined"
            ? t("Total_Balance")
            : isActiveWallet
              ? t("Active_Balance")
              : t("Inctive_Balance")
        }
        isVisible={isModalVisible}
        setIsVisible={setIsModalVisible}
        size="large"
        isFooterHidden
        onClose={() => {
          searchParams.delete("page");
          setSearchParams(searchParams);
        }}
      >
        {isLoading ? (
          <div className="w-100 d-flex align-items-center justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : walletTransactions?.results?.length ? (
          <Table
            containerStyle="mt-4"
            isCheckBoxVisible={false}
            tableName="walletTable"
            headers={[
              t("Wallet_Amount"),
              t("Remaining_Amount"),
              t("Status"),
              t("Type"),
              !isActiveWallet ? t("date") : "",
              "",
            ]}
            data={walletTransactions?.results.map((item) =>
              arrangeWalletData(item)
            )}
            count={walletTransactions?.count}
            pagesCount={walletTransactions?.pages_number}
          />
        ) : (
          <div> {t("No_Wallet_Data")} </div>
        )}
      </ModalWrapper>
    </div>
  );
}
