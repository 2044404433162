/* eslint-disable no-nested-ternary */
import { useState } from "react";
import DebouncedSearch from "components/inputs/DebouncedSearch";
import { useNavigate, useSearchParams } from "react-router-dom";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import {
  useDeleteCustomerMutation,
  useDeleteCustomersBulkMutation,
  useGetCustomersQuery,
} from "apis/services/customers";
import { Customer } from "apis/types/customers";
import { TableItemProps } from "components/general/Table/types";
import Spinner from "components/general/Spinner";
import Table from "components/general/Table";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import dayjs from "dayjs";
import styles from "./styles.module.scss";

export default function Customers() {
  const { t } = useAutoCompleteTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [deleteAgent, { isLoading: isDeleteLoading }] =
    useDeleteCustomerMutation();

  const [deleteCustomersBulk, { isLoading: isDeleteBulkLoading }] =
    useDeleteCustomersBulkMutation();

  const {
    data: customers,
    isLoading,
    isFetching,
  } = useGetCustomersQuery({
    page_size: localStorage.getItem("CompaniesTable")
      ? Number(localStorage.getItem("CompaniesTable"))
      : 15,
    page: searchParams.get("page") ?? 1,
    search: searchParams.get("search") ?? "",
    customer: searchParams.get("customer") ?? "",
  });

  const tableHeaders = [
    t("Customer_Name"),
    t("Mobile_Number"),
    t("Membership_Price"),
    t("Currency"),
    t("Card_Type"),
    t("Expiry_Date"),
    "", // Make the last one empty string to let the menu btn appear here
  ];

  const arrangeCompanyData = (item: Customer): TableItemProps => ({
    id: String(item.id),
    rowData: [
      { type: "text", dataItem: `${item.first_name} ${item.last_name}` },
      {
        type: "text",
        dataItem: `${item.mobile_code} ${
          item.mobile.split(item.mobile_code)[1] ||
          item.mobile.split(item.mobile_code)
        }`,
      },
      {
        type: "text",
        dataItem: item?.cards[0]?.price_after_discount,
      },
      { type: "text", dataItem: item?.cards[0]?.currency },
      {
        type: "membership",
        dataItem:
          item?.cards?.map((card) => ({
            membership: card?.membership?.name,
          })) ?? [],
      },
      {
        type: "text",
        dataItem: dayjs(item?.cards[0]?.expiry_date).format("DD/MM/YYYY"),
      },
      {
        type: "more",
        dataItem: [
          `${item.first_name} ${item.last_name}`,
          "/customers/edit-customer/",
        ],
      },
    ],
  });

  const handleDeleteCustomer = (customerName: string, customerId: string) => {
    if (customerId) {
      deleteAgent(customerId)
        .unwrap()
        .then(() => {
          setIsDeleteModalVisible(false);
          navigate("/customers");
          showToast({
            toastType: "success",
            title: `${customerName} has been deleted successfully!`,
            showCloseIcon: true,
          });
        })
        .catch(handleErrors);
    }
  };

  const handleDeleteBulk = (customersIds: string) => {
    deleteCustomersBulk(customersIds)
      .unwrap()
      .then(() => {
        setIsDeleteModalVisible(false);
        showToast({
          toastType: "success",
          title: `Selected Customers have been deleted successfully!`,
          showCloseIcon: true,
        });
      })
      .catch(handleErrors);
  };
  return (
    <section>
      <div className={styles.filtersContainer}>
        <div className={styles.filtersGroup}>
          <span className={`Headline300Heavy ${styles.headline}`}>
            {t("Customer_Profiles")}
          </span>
          <DebouncedSearch
            size="small"
            onSearchChange={(val) => {
              searchParams.set("search", val);
              setSearchParams(searchParams);
            }}
          />
        </div>
        <div className={styles.btnGroup}>
          <Button
            btnClassName={styles.importExportBtns}
            size="small"
            styleType="Secondary"
            prefix={<Icon name="import" size={16} color="Neutral1000" />}
          >
            <span className="Paragraph200Light">{t("import")}</span>
          </Button>
          <Button
            btnClassName={styles.importExportBtns}
            size="small"
            styleType="Secondary"
            prefix={<Icon name="export" size={16} color="Neutral1000" />}
          >
            <span className="Paragraph200Light">{t("export")}</span>
          </Button>
          <span className={styles.divider} />
          <Button
            btnClassName={styles.addNewCompanyBtn}
            size="small"
            styleType="Primary"
            prefix={
              <Icon name="Add-Outline" size={12} color="TextPrimaryWhite" />
            }
            onClick={() => navigate("create-customer-profile")}
          >
            <span className="Button200 text-nowrap">
              {t("Add_New_Customer")}
            </span>
          </Button>
        </div>
      </div>

      {isFetching || isLoading ? (
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : customers?.results?.length ? (
        <Table
          tableName="CompaniesTable"
          headers={tableHeaders}
          data={customers?.results?.map((item: Customer) =>
            arrangeCompanyData(item)
          )}
          count={customers?.count}
          pagesCount={customers?.pages_number}
          deleteOneRow={handleDeleteCustomer}
          isDeleteModalVisible={isDeleteModalVisible}
          setIsDeleteModalVisible={setIsDeleteModalVisible}
          isDeleteLoading={isDeleteLoading || isDeleteBulkLoading}
          deleteMultipleSelectedRows={handleDeleteBulk}
        />
      ) : (
        <div> {t("No_Data_Found")}!</div>
      )}
    </section>
  );
}
