import api from "apis";
import { Country, GetCountriesParams } from "apis/types/countries";
import { PaginatedResponse } from "apis/types/general";

export const countriesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCountries: build.query<PaginatedResponse<Country>, GetCountriesParams>({
      query: (params) => ({
        url: "/countries/",
        params: { ...params },
      }),
      forceRefetch: () => true,
    }),

    getCountry: build.query<Country, string>({
      query: (id) => ({
        url: `/countries/${id}`,
      }),
      forceRefetch: () => true,
    }),
  }),
});

export const {
  useGetCountriesQuery,
  useLazyGetCountriesQuery,
  useGetCountryQuery,
  useLazyGetCountryQuery,
} = countriesApi;
