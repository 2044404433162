/* eslint-disable no-unsafe-optional-chaining */
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import CheckBox from "components/general/CheckBox";
import WalletSelectionInput from "components/inputs/WalletSelectionInput";
import { Control, Controller, FieldErrors, useWatch } from "react-hook-form";
import InstructionNote from "components/general/InstructionNote";
import { useGetCustomerQuery } from "apis/services/customers";
import { generalQuotationTypes } from "../..";
import { customgeneralErrorTypes } from ".";
import styles from "../../styles.module.scss";

export default function GeneralPayment({
  control,
  errors,
  index,
}: {
  control: Control<generalQuotationTypes>;
  errors: FieldErrors<customgeneralErrorTypes>;
  index: number;
}) {
  const { t } = useAutoCompleteTranslation();

  const customerWatcher = useWatch({
    control,
    name: "customerId",
  });

  const {
    data: customerData,
    isLoading: isWalletLoading,
    isFetching: isWalletFetching,
  } = useGetCustomerQuery(customerWatcher, {
    skip: customerWatcher === 0,
  });

  return (
    <div className="d-flex flex-column gap-4">
      <div className="mt-4">
        <span className="Label100"> {t("Payment_Gateway")} </span>
      </div>
      <div className="w-100 d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.payment_gateway`}
          render={({ field }) => (
            <div className="d-flex gap-4">
              <CheckBox
                containerStyle={`${styles.checkboxType_container} ${styles.customBg}`}
                checked={field.value === "stripe"}
                onCheckChange={() => field.onChange("stripe")}
                value="stripe"
                text="Stripe"
              />
              <CheckBox
                containerStyle={`${styles.checkboxType_container} ${styles.customBg}`}
                checked={field.value === "paytabs"}
                onCheckChange={() => field.onChange("paytabs")}
                value="paytabs"
                text="PayTabs"
              />
              <CheckBox
                containerStyle={`${styles.checkboxType_container} ${styles.customBg}`}
                checked={field.value === "kashier"}
                onCheckChange={() => field.onChange("kashier")}
                value="kashier"
                text="Kashier"
              />
            </div>
          )}
        />
        {errors?.quotations?.[index]?.payment_gateway?.message && (
          <div className="w-100">
            <InstructionNote instructionType="danger">
              <span className="Paragraph300Light">
                {errors?.quotations?.[index]?.payment_gateway?.message}
              </span>
            </InstructionNote>
          </div>
        )}
      </div>
      <div className="w-100 d-flex flex-column gap-1">
        <Controller
          control={control}
          name={`quotations.${index}.wallets`}
          render={({ field }) => (
            <WalletSelectionInput
              value={field.value?.map((item) => String(item))}
              onChange={(val) =>
                field.onChange((val as string[]).map((item) => Number(item)))
              }
              isLoading={isWalletLoading || isWalletFetching}
              options={
                customerData?.wallet_transactions?.map((wallet) => ({
                  label: String(wallet?.amount),
                  remainingAmount: String(
                    wallet?.amount - Number(wallet?.amount_used)
                  ),
                  value: String(wallet?.id),
                  isActive: wallet?.is_active,
                  walletType: wallet?.amount_type,
                  disabled: !wallet?.is_active,
                  walletCurrency: wallet?.currency,
                })) ?? []
              }
              containerStyle="w-100"
              i18nLabelKey="Wallet_Amount"
              i18nPlaceholder="Wallet_Amount"
              status={errors?.quotations?.[index]?.wallets ? "error" : ""}
            />
          )}
        />

        {errors?.quotations?.[index]?.wallets?.message && (
          <div className="w-100">
            <InstructionNote instructionType="danger">
              <span className="Paragraph300Light">
                {errors?.quotations?.[index]?.wallets?.message}
              </span>
            </InstructionNote>
          </div>
        )}
      </div>
    </div>
  );
}
