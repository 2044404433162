import api from "apis";
import {
  Card,
  CardPostRequest,
  Customer,
  CustomerPostRerquest,
  GetCustomersParams,
} from "apis/types/customers";
import { PaginatedResponse } from "apis/types/general";

export const customersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCustomers: build.query<PaginatedResponse<Customer>, GetCustomersParams>({
      query: (params) => ({
        url: "/customers/",
        params: { ordering: "-id", ...params },
      }),
      forceRefetch: () => true,
    }),

    getCustomer: build.query<Customer, string>({
      query: (id) => ({
        url: `/customers/${id}/`,
      }),
      forceRefetch: () => true,
    }),

    addCustomer: build.mutation<Customer, CustomerPostRerquest>({
      query: (body) => ({
        url: "/customers/",
        method: "POST",
        body,
      }),
    }),

    updateCustomer: build.mutation<
      Customer,
      [id: string, body: Partial<Customer>]
    >({
      query: ([id, body]) => ({
        url: `/customers/${id}/`,
        method: "PATCH",
        body,
      }),
    }),

    updateCard: build.mutation<Card, [id: string, body: CardPostRequest]>({
      query: ([id, body]) => ({
        url: `/cards/${id}/`,
        method: "PATCH",
        body,
      }),
    }),

    deleteCustomer: build.mutation<Customer, string>({
      query: (id) => ({
        url: `/customers/${id}/`,
        method: "DELETE",
      }),
    }),

    deleteCustomersBulk: build.mutation<any, string>({
      query: (ids) => ({
        url: `/customers/delete_bulk/?ids=${ids}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useDeleteCustomersBulkMutation,
  useUpdateCardMutation,
} = customersApi;
