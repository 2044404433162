import { useEffect, useState } from "react";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Icon from "components/general/Icon";
import SelectionInput from "components/inputs/SelectionInput";
import TextInput from "components/inputs/TextInput";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import InstructionNote from "components/general/InstructionNote";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useGetCompaniesQuery } from "apis/services/companies";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { FormTypes } from ".";
import styles from "./styles.module.scss";

const countriesNamesShort = getCountries();

export default function EditCustomerInfoInputs({
  control,
  errors,
  setValue,
}: {
  control: Control<FormTypes>;
  errors: FieldErrors<FormTypes>;
  setValue: UseFormSetValue<FormTypes>;
}) {
  const { t } = useAutoCompleteTranslation();
  const [companiesPage, setCompaniesPage] = useState(1);

  const {
    data: companies,
    isLoading: isCompaniesLoading,
    isFetching,
  } = useGetCompaniesQuery({
    page_size: 60000,
    page: companiesPage,
  });

  const [companiesList, setCompaniesList] = useState<
    | {
        value: string;
        label: string;
      }[]
    | []
  >([]);

  useEffect(() => {
    if (companies)
      setCompaniesList([
        ...companiesList,
        ...companies.results.map((company) => ({
          value: String(company.id),
          label: company.name,
        })),
      ]);
  }, [companies]);

  const onScroll = (event: any) => {
    const { target } = event;
    if (
      !isCompaniesLoading &&
      companies?.next &&
      target.offsetHeight > 200 &&
      target.scrollTop + target.offsetHeight === target.scrollHeight
    ) {
      setCompaniesPage(companiesPage + 1);
    }
  };

  const countryShortNameWatcher = useWatch({
    control,
    name: "mobile_code_char",
  });

  const mobileCodeWatcher = useWatch({
    control,
    name: "mobileCode",
  });

  return (
    <>
      <div className="d-flex gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name="firstName"
            containerStyle="w-100"
            label={`${t("First_Name")} *`}
            i18nPlaceholder="First_Name"
            errorMsg={errors?.firstName?.message}
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <ControlledTextInput
            control={control}
            name="lastName"
            containerStyle="w-100"
            label={`${t("Last_Name")} *`}
            i18nPlaceholder="Last_Name"
            errorMsg={errors?.lastName?.message}
          />
        </div>
      </div>

      <div className="w-100 d-flex flex-column gap-1">
        <ControlledTextInput
          control={control}
          name="email"
          containerStyle="w-100"
          label={`${t("Email")} *`}
          i18nPlaceholder="Email"
          errorMsg={errors?.email?.message}
        />
      </div>

      <div className="w-100 d-flex flex-column gap-1">
        <Controller
          control={control}
          name="company"
          render={({ field }) => (
            <SelectionInput
              value={field.value === 0 ? undefined : String(field.value)}
              onChange={field.onChange}
              showSearch
              isLoading={isCompaniesLoading || isFetching}
              options={companiesList}
              onFilter={(search, item) =>
                (item?.label.toLowerCase() ?? "").includes(search.toLowerCase())
              }
              onScroll={onScroll}
              containerStyle="w-100"
              label={`${t("Company")} *`}
              placeholder={t("Company")}
              status={errors.company && errors.company.message ? "error" : ""}
            />
          )}
        />
        {errors.company && errors.company.message && (
          <div className="w-100">
            <InstructionNote instructionType="danger">
              <span className="Paragraph300Light">
                {errors.company.message}
              </span>
            </InstructionNote>
          </div>
        )}
      </div>

      <div className="w-100 d-flex flex-column gap-1">
        <Controller
          control={control}
          name="mobile"
          render={({ field }) => (
            <TextInput
              value={
                field.value.includes(mobileCodeWatcher)
                  ? field.value.split(mobileCodeWatcher)[1]
                  : field.value
              }
              onChange={(e) => {
                if (/^\d{0,15}$/.test(e.target.value)) field.onChange(e);
              }}
              prefix={
                <div className="d-flex align-items-center gap-2">
                  <Icon name={countryShortNameWatcher ?? ""} size={30} />
                  <Controller
                    control={control}
                    name="mobile_code_char"
                    render={({ field: fields }) => (
                      <SelectionInput
                        value={fields.value}
                        onChange={(e) => {
                          setValue(
                            "mobileCode",
                            // @ts-ignore
                            `+${getCountryCallingCode(e)}`
                          );
                          fields.onChange(e);
                        }}
                        size="small"
                        containerStyle={styles.textAndSelectDropdown}
                        options={countriesNamesShort.map((item) => ({
                          value: item,
                          label: `${item} +${getCountryCallingCode(item)}`,
                        }))}
                        showSearch
                        onFilter={(search, item) =>
                          (item?.label.toLowerCase() ?? "").includes(
                            search.toLowerCase()
                          )
                        }
                      />
                    )}
                  />
                </div>
              }
              containerStyle="w-100"
              label={`${t("Mobile_Number")} *`}
              placeholder={t("Mobile_Number")}
              status={
                errors.mobile && errors.mobile.message ? "error" : "default"
              }
            />
          )}
        />
        {errors.mobile && errors.mobile.message && (
          <div className="w-100">
            <InstructionNote instructionType="danger">
              <span className="Paragraph300Light">{errors.mobile.message}</span>
            </InstructionNote>
          </div>
        )}
      </div>
    </>
  );
}
