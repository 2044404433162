import { useEffect } from "react";
import i18n from "i18n";
import PageTitle from "components/general/PageTitle";
import { useGetCompanyQuery } from "apis/services/companies";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useConfirmQuotationMutation,
  useGetGeneralQuotationQuery,
  useConfirmGeneralQuotationMutation,
} from "apis/services/generalQuotations";
import Spinner from "components/general/Spinner";
import Button from "components/general/Button";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import PdfDocument from "./PdfDocument";
import AccommodationDetails from "./DetailsType/AccommodationDetails";
import FlightDetails from "./DetailsType/FlightDetails";
import GeneralDetails from "./DetailsType/GeneralDetails";
import styles from "./styles.module.scss";

export default function QoutationDetails() {
  const { t } = useAutoCompleteTranslation();
  const { quotationId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: generalQuotation, isLoading } = useGetGeneralQuotationQuery(
    quotationId || ""
  );

  const { data: company } = useGetCompanyQuery(
    String(generalQuotation?.customer.company) || "",
    { skip: !generalQuotation }
  );

  const [confirmQuotation, { isLoading: isConfirmationLoading }] =
    useConfirmQuotationMutation();

  const [confirmGeneralQuotation, { isLoading: isGeneralConfirmationLoading }] =
    useConfirmGeneralQuotationMutation();

  const accommodationTotals: number = generalQuotation
    ? generalQuotation?.accomodation_quotations?.reduce((acc, cur) => {
        return acc + Number(cur.total);
      }, 0)
    : 0;
  const flightTotals: number = generalQuotation
    ? generalQuotation?.flight_quotations?.reduce((acc, cur) => {
        return acc + Number(cur.total);
      }, 0)
    : 0;
  const generalTotals: number = generalQuotation
    ? generalQuotation?.quotations?.reduce((acc, cur) => {
        return acc + Number(cur.total);
      }, 0)
    : 0;
  const total = accommodationTotals + flightTotals + generalTotals;

  const currentLanguage = i18n.language ?? "en";

  const CurrencyFormatter = new Intl.NumberFormat(
    currentLanguage === "ar" ? "ar-EG" : "en-US",
    {
      style: "currency",
      currency: generalQuotation?.currency ?? "GBP",
    }
  );

  const generatePdfDocument = async (fileName: string) => {
    const FILE_NAME = [];

    FILE_NAME.push(
      `${generalQuotation?.customer.first_name} ${generalQuotation?.customer.last_name}`
    );

    if (generalQuotation?.quotations) {
      // eslint-disable-next-line no-unused-expressions
      generalQuotation?.quotations?.length > 0 && FILE_NAME.push("General");
    }
    if (generalQuotation?.accomodation_quotations) {
      // eslint-disable-next-line no-unused-expressions
      generalQuotation?.accomodation_quotations?.length > 0 &&
        FILE_NAME.push("Accommodation");
    }
    if (generalQuotation?.flight_quotations) {
      // eslint-disable-next-line no-unused-expressions
      generalQuotation?.flight_quotations?.length > 0 &&
        FILE_NAME.push("Flight");
    }

    const blob = await pdf(
      <PdfDocument
        currentLanguage={currentLanguage}
        generalQuotation={generalQuotation}
        total={total}
        companyLogo={(company?.logo as string) || ""}
      />
    ).toBlob();
    saveAs(
      blob,
      `${FILE_NAME.join(" ")} ${fileName} ${currentLanguage.toUpperCase()}`
    );
  };

  const generateConfirmationPDF = async (fileName: string) => {
    const FILE_NAME = [];

    FILE_NAME.push(
      `${generalQuotation?.customer.first_name} ${generalQuotation?.customer.last_name}`
    );

    if (generalQuotation?.quotations) {
      // eslint-disable-next-line no-unused-expressions
      generalQuotation?.quotations?.length > 0 && FILE_NAME.push("General");
    }
    if (generalQuotation?.accomodation_quotations) {
      // eslint-disable-next-line no-unused-expressions
      generalQuotation?.accomodation_quotations?.length > 0 &&
        FILE_NAME.push("Accommodation");
    }
    if (generalQuotation?.flight_quotations) {
      // eslint-disable-next-line no-unused-expressions
      generalQuotation?.flight_quotations?.length > 0 &&
        FILE_NAME.push("Flight");
    }

    const blob = await pdf(
      <PdfDocument
        currentLanguage={currentLanguage}
        generalQuotation={generalQuotation}
        total={total}
        companyLogo={(company?.logo as string) || ""}
        isConfirmation
      />
    ).toBlob();
    saveAs(
      blob,
      `${FILE_NAME.join(" ")} ${fileName} ${currentLanguage.toUpperCase()}`
    );
  };

  useEffect(() => {
    if (generalQuotation) {
      const updatedUrl = `${pathname}?idName=${generalQuotation?.quotation_id}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [generalQuotation]);
  return (
    <section>
      <PageTitle> {t("Quotation")} </PageTitle>
      {isLoading ? (
        <div className="w-100 d-flex justify-content-center">
          <Spinner size="Large" />
        </div>
      ) : (
        <div className={styles.detailsContainer}>
          <div className={styles.details_Width}>
            <div className={`${styles.customBorder} ${styles.spaceBottom}`}>
              <span className="Label100 text-Capitalize">General</span>
              <div className={styles.general_column}>
                <div className="d-flex justify-content-between ">
                  <span className="Paragraph200Light">{t("Reference")} </span>
                  <span className="Paragraph200Light">
                    {generalQuotation?.quotation_id}
                  </span>
                </div>
                <div className="d-flex justify-content-between ">
                  <span className="Paragraph200Light"> {t("Agent_name")} </span>
                  <span className="Paragraph200Light">
                    {generalQuotation?.agent.first_name &&
                    generalQuotation?.agent.last_name
                      ? `${generalQuotation?.agent.first_name} ${generalQuotation?.agent.last_name}`
                      : generalQuotation?.agent.username}
                  </span>
                </div>
                <div className="d-flex justify-content-between ">
                  <span className="Paragraph200Light">{t("Customer_Id")} </span>
                  <span className="Paragraph200Light">
                    {generalQuotation?.customer.mobile}
                  </span>
                </div>
                <div className="d-flex justify-content-between ">
                  <span className="Paragraph200Light">{t("Membership")} </span>
                  <span className="Paragraph200Light">
                    {generalQuotation?.membership_name}
                  </span>
                </div>
                <div className="d-flex justify-content-between ">
                  <span className="Paragraph200Light"> {t("country")} </span>
                  <span className="Paragraph200Light">
                    {generalQuotation?.country?.name}
                  </span>
                </div>
                <div className="d-flex justify-content-between ">
                  <span className="Paragraph200Light"> {t("Currency")} </span>
                  <span className="Paragraph200Light">
                    {generalQuotation?.currency}
                  </span>
                </div>
                <div className="d-flex justify-content-between ">
                  <span className="Paragraph200Light text-capitalize">
                    {t("Creation_Date")}{" "}
                  </span>
                  <span className="Paragraph200Light">
                    {dayjs(generalQuotation?.created_on).format("DD/MM/YYYY")}
                  </span>
                </div>
              </div>
            </div>

            <div id="quotationDownloadTarget">
              {generalQuotation?.accomodation_quotations?.map(
                (accommodationData, index) => {
                  return (
                    <AccommodationDetails
                      key={`accommodation_quotation${accommodationData.id}`}
                      index={index}
                      generalQuotation={generalQuotation}
                      accommodationData={accommodationData}
                    />
                  );
                }
              )}

              {generalQuotation?.flight_quotations?.map((flightData) => {
                return (
                  <FlightDetails
                    key={`flight_quotation${flightData.id}`}
                    generalQuotation={generalQuotation}
                    flightData={flightData}
                  />
                );
              })}

              {generalQuotation?.quotations?.map((generalData) => (
                <GeneralDetails
                  key={`quotation${generalData.id}`}
                  generalQuotation={generalQuotation}
                  generalData={generalData}
                />
              ))}

              <div className={styles.spaceTop}>
                <span className="Label100"> {t("Quotation_Total")} </span>
                <div className="mt-4 d-flex justify-content-between">
                  <span className="Paragraph200Light">{t("Total")}</span>
                  <span className={`Paragraph200Heavy ${styles.totalPrice}`}>
                    {CurrencyFormatter.format(Number(total))}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex flex-column gap-3 mt-3">
              <div className="mt-2 d-flex gap-2">
                <Button
                  styleType="Primary"
                  btnClassName="text-white"
                  onClick={() => generatePdfDocument("Quotation")}
                >
                  {t("Download_Quotation")}
                </Button>
                <Button
                  styleType="Primary"
                  btnClassName="text-white"
                  onClick={() => generateConfirmationPDF("Confirmation")}
                >
                  {t("Download_Confirmation")}
                </Button>
              </div>
              {!generalQuotation?.is_confirmed && (
                <div className="mt-2 d-flex gap-2">
                  <Button
                    isLoading={isConfirmationLoading}
                    styleType="Primary"
                    btnClassName="text-white"
                    onClick={() =>
                      confirmQuotation([
                        String(generalQuotation?.id),
                        { is_confirmed: true },
                      ])
                    }
                  >
                    {t("Show_Confirmation_Tab")}
                  </Button>
                </div>
              )}
              {!generalQuotation?.is_visible_in_website && (
                <div className="mt-2 d-flex gap-2">
                  <Button
                    isLoading={isGeneralConfirmationLoading}
                    styleType="Primary"
                    btnClassName="text-white"
                    onClick={() =>
                      confirmGeneralQuotation([
                        String(generalQuotation?.id),
                        { is_visible_in_website: true },
                      ])
                    }
                  >
                    {t("Show_Quotation_Tab")}
                  </Button>
                </div>
              )}
              <div className="d-flex gap-2">
                {/* <Button styleType="Primary" btnClassName="text-white" disabled>
                  Send Quotation
                </Button>
                <Button styleType="Primary" btnClassName="text-white" disabled>
                  Send Invoice
                </Button> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
