import { NavLink, useLocation } from "react-router-dom";
import Icon from "../Icon";
import { NavLinkItemProps } from "./types";
import styles from "./styles.module.scss";

function SubNavLinkItem({ item }: Omit<NavLinkItemProps, "isSideBarOpen">) {
  return (
    <NavLink
      key={item?.title}
      to={item?.href}
      className={({ isActive }) =>
        isActive
          ? `${styles.navigationListItemActive} ${styles.subNavListItem}`
          : styles.subNavListItem
      }
    >
      <div className="d-flex align-items-center justify-content-center">
        <Icon name="dot" size={7} color="TextPrimaryWhite" />
      </div>
      <span className="w-100 Paragraph200Heavy">{item?.title}</span>
    </NavLink>
  );
}

export default function NavLinkItem({ item, isSideBarOpen }: NavLinkItemProps) {
  const location = useLocation();
  const match = location.pathname.includes(item.href);

  return (
    <>
      <NavLink
        key={item?.title}
        to={item?.href}
        className={({ isActive }) =>
          isActive
            ? `${styles.navigationListItemActive} ${styles.navigationListItem}`
            : styles.navigationListItem
        }
      >
        {({ isActive }) => (
          <>
            <div className="d-flex align-items-center justify-content-center">
              <Icon name={item?.iconName} size={20} color="TextPrimaryWhite" />
            </div>
            {isSideBarOpen && (
              <>
                <span className="w-100 Paragraph200Heavy">{item?.title}</span>
                {item.subList && (
                  <div className="d-flex align-items-center justify-content-center">
                    <Icon
                      name={isActive ? "ChevronDown" : "ChevronRight"}
                      size={20}
                      color="TextPrimaryWhite"
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </NavLink>
      {item.subList &&
        isSideBarOpen &&
        match &&
        item.subList.map((subItem) => {
          return <SubNavLinkItem key={subItem.title} item={subItem} />;
        })}
    </>
  );
}
