import { useEffect, useRef, useState } from "react";
import Icon from "components/general/Icon";
import Button from "components/general/Button";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import showToast from "utils/showToast";
import moment from "moment";
import { useSendMessageMutation } from "apis/services/chat";
import handleErrors from "utils/handleErrors";
import ModalWrapper from "components/modals/ModalWrapper";
import Image from "components/general/Image";
import useScreenSize from "hooks/useScreenSize";
import { ChatMessage } from "apis/types/chat";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import Message from "../Message";
import styles from "./styles.module.scss";
import { ChatBodyTypes } from "./Types";

export default function ChatBody({
  messages,
  activeConversation,
  isLoading,
  page,
  setPage,
  socket,
  isConversationsOpen,
}: ChatBodyTypes) {
  const { t } = useAutoCompleteTranslation();
  const { width } = useScreenSize();
  const user = useSelector((state: RootState) => state.auth.userData);
  const [value, setValue] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [eidtValue, setEditValue] = useState("");
  const editTextAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [sendMessage, { isLoading: UploadingMsgLoading }] =
    useSendMessageMutation();
  const [modalImg, setModalImg] = useState<string | null>(null);
  const [replyTarget, setReplyTarget] = useState<ChatMessage | null>(null);
  const [editTarget, setEditTarget] = useState<ChatMessage | null>(null);
  const [deleteTarget, setDeleteTarget] = useState<ChatMessage | null>(null);

  const handleSendMessage = () => {
    if (!uploadedFile) {
      socket?.send(
        JSON.stringify({
          text: value,
        })
      );
      setValue("");
    } else {
      const formData = new FormData();
      formData.append("chat", String(activeConversation?.id));
      if (value) {
        formData.append("text", value);
      }
      if (uploadedFile?.type === "application/pdf") {
        formData.append("file", uploadedFile);
      } else if (uploadedFile) {
        formData.append("picture", uploadedFile);
      }
      sendMessage(formData).catch((err) => handleErrors(err));
      setUploadedFile(null);
      setValue("");
    }
  };

  const handleEditMessage = () => {};

  const handleDeleteMessage = () => {};

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "0px";
      const { scrollHeight } = textAreaRef.current;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);

  useEffect(() => {
    if (editTextAreaRef.current) {
      editTextAreaRef.current.style.height = "0px";
      const { scrollHeight } = editTextAreaRef.current;
      editTextAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }, [editTextAreaRef, eidtValue]);

  const uploadProps: UploadProps = {
    beforeUpload: (file) => {
      const isSupportedFile = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "application/pdf",
      ].includes(file.type);
      if (!isSupportedFile) {
        showToast({
          toastType: "danger",
          title: "The file you'r trying to upload is not supported!",
        });
        return Upload.LIST_IGNORE;
      }
      setUploadedFile(file);
      return false;
    },
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  if (!activeConversation) return null;

  return (
    <div
      className={`${styles.container} ${
        width < 800 && isConversationsOpen ? styles.msgsHidden : ""
      }`}
    >
      {/* Header */}
      <div className={styles.headerContainer}>
        <div className={styles.textContainer}>
          <span className="Paragraph100Heavy">{activeConversation?.name}</span>
          <span className="Paragraph200Heavy">
            {activeConversation?.agents.length &&
              `${activeConversation?.agents.length} agents`}
          </span>
        </div>
      </div>

      <div
        className={styles.chatsContainer}
        id="scrollableDiv"
        ref={chatContainerRef}
      >
        {messages?.results && (
          <InfiniteScroll
            scrollableTarget="scrollableDiv"
            dataLength={messages?.results.length}
            next={() => {
              if (isLoading) return;
              if (!messages?.next) return;
              setPage(page + 1);
            }}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            inverse
            hasMore={messages?.next !== null}
            loader={
              <p className="text-center pt-4">
                <b>Loading...</b>
              </p>
            }
            endMessage={
              <p className="text-center">
                <b>No more Messages!</b>
              </p>
            }
          >
            {messages?.results?.map((msg) => (
              <Message
                key={`${msg.user_name}${msg.id}`}
                isUser={msg.user === user?.id}
                iconLetters={msg.user_name?.split(" ")[0][0]}
                messageName={msg.user_name}
                messageDescription={msg.text}
                messageImage={msg.picture}
                messageFile={msg.file}
                messageTime={moment(msg.created_at).format(
                  "YYYY-MM-DD : hh:mm A"
                )}
                msgImageModal={(img) => setModalImg(img)}
                msgReplyTarget={() => setReplyTarget(msg)}
                msgEditTarget={() => {
                  setEditTarget(msg);
                  setEditValue(msg?.text || "");
                }}
                msgDeleteTarget={() => setDeleteTarget(msg)}
              />
            ))}
          </InfiniteScroll>
        )}
      </div>
      <div className={styles.inputContainer}>
        {replyTarget?.text && (
          <div className={styles.uploadedFileRemoveBtn}>
            <span className="Paragraph200Heavy">
              {`${replyTarget?.text?.substring(0, 100)}${
                replyTarget?.text?.length > 100 ? "..." : ""
              }`}
            </span>
            <Button
              styleType="NoStyle"
              onClick={() => setReplyTarget(null)}
              suffix={<Icon name="Close" size={24} />}
            />
          </div>
        )}
        {uploadedFile?.name && (
          <div className={styles.uploadedFileRemoveBtn}>
            <span className="Paragraph200Heavy">{uploadedFile?.name}</span>
            <Button
              styleType="NoStyle"
              onClick={() => setUploadedFile(null)}
              suffix={<Icon name="Close" size={24} />}
            />
          </div>
        )}
        {UploadingMsgLoading && (
          <div className={styles.uploadedFileRemoveBtn}>Uploading...</div>
        )}
        <Upload {...uploadProps} showUploadList={false} className="py-2">
          <Button
            styleType="NoStyle"
            btnClassName={styles.actionBtn}
            prefix={<Icon name="attatchment" size={20} color="Primary700" />}
          />
        </Upload>
        <div className={styles.inputAndSendContainer}>
          <textarea
            placeholder="Type a message"
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            ref={textAreaRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className={styles.textareaInput}
          />
          <Button
            onClick={handleSendMessage}
            styleType="NoStyle"
            btnClassName={styles.actionBtn}
            prefix={<Icon name="send" size={20} color="Primary700" />}
          />
        </div>
      </div>
      <ModalWrapper
        size="xLarge"
        headerTitle="View Image"
        isVisible={!!modalImg}
        setIsVisible={() => setModalImg(null)}
        isFooterHidden
      >
        {modalImg && (
          <Image src={modalImg} alt="image" className={styles.modalImg} />
        )}
      </ModalWrapper>
      <ModalWrapper
        size="large"
        headerTitle="Edit Message"
        isVisible={!!editTarget}
        setIsVisible={() => {
          setEditTarget(null);
          setEditValue("");
        }}
        isFooterHidden
      >
        <div className={styles.inputContainer}>
          <div className={styles.inputAndSendContainer}>
            <textarea
              placeholder="Type a message"
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handleEditMessage();
                }
              }}
              ref={editTextAreaRef}
              value={eidtValue}
              onChange={(e) => setEditValue(e.target.value)}
              className={styles.textareaInput}
            />
            <Button
              onClick={handleEditMessage}
              styleType="NoStyle"
              btnClassName={styles.actionBtn}
              prefix={<Icon name="send" size={20} color="Primary700" />}
            />
          </div>
        </div>
      </ModalWrapper>
      <ModalWrapper
        size="large"
        headerTitle="Delete Message"
        isVisible={!!deleteTarget}
        setIsVisible={() => {
          setDeleteTarget(null);
        }}
        isFooterHidden
      >
        <span className="Paragraph100Light">{t("Delete_Msg")}?</span>
        <div className={styles.deleteModalBtns}>
          <Button
            styleType="Secondary"
            btnClassName={styles.cancelBtn}
            onClick={() => setDeleteTarget(null)}
          >
            <span className="Button100">{t("Cancel")}</span>
          </Button>
          <Button btnClassName={styles.deleteBtn} onClick={handleDeleteMessage}>
            <span className="Button100">{t("Delete")}</span>
          </Button>
        </div>
      </ModalWrapper>
    </div>
  );
}
