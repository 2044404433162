import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { TimeInputProps } from "./types";
import styles from "./styles.module.scss";

export default function TimeInput({
  containerStyle,
  onChange,
  value,
  timeFormat = "HH:mm",
  placeholder = "H:MM",
  size = "large",
  label,
  i18nLabelKey,
  i18nPlaceholder,
  status = "",
  isDisabled = false,
  defaultValue = undefined,
}: TimeInputProps) {
  const { t } = useAutoCompleteTranslation();
  const id = `selectInput${Math.random()}`;

  return (
    <div className={`${styles.container} ${containerStyle}`} id={id}>
      {(!!label || !!i18nLabelKey) && (
        <span className="d-block mb-2 Label100">
          {i18nLabelKey ? t(i18nLabelKey) : label}
        </span>
      )}
      <TimePicker
        placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
        status={status}
        getPopupContainer={(trigger: HTMLElement | null) => {
          return (
            (trigger?.parentNode as HTMLElement) ?? document.getElementById(id)
          );
        }}
        value={value}
        use12Hours={false}
        format={timeFormat}
        onChange={onChange}
        disabled={isDisabled}
        className={`${styles[size]} w-100`}
        defaultValue={
          defaultValue ? dayjs(defaultValue, timeFormat) : undefined
        }
      />
    </div>
  );
}
