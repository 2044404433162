import { useState } from "react";
import PageTitle from "components/general/PageTitle";
import InstructionNote from "components/general/InstructionNote";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import {
  useAddGeneralBenefitMutation,
  useAddGeneralConditionMutation,
  useEditGeneralBenefitMutation,
  useEditGeneralConditionMutation,
  useGetGeneralBenefitsQuery,
  useToggleGeneralBenefitVisibilityMutation,
  useToggleGeneralConditionVisibilityMutation,
} from "apis/services/memberships";
import Spinner from "components/general/Spinner";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import TextInput from "components/inputs/TextInput";
import { Switch } from "antd";
import ModalWrapper from "components/modals/ModalWrapper";
import styles from "./styles.module.scss";

export default function MembershipBenefitsSettings() {
  const { t } = useAutoCompleteTranslation();

  const { data, isLoading } = useGetGeneralBenefitsQuery();

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [newTargetVal, setNewTargetVal] = useState("");
  const [newTargetOrder, setNewTargetOrder] = useState<undefined | number>(0);
  const [targetId, setTargetId] = useState<undefined | number>(undefined);
  const [targetConditionParentId, setTargetConditionParentId] = useState<
    undefined | number
  >(undefined);
  const [modalMode, setModalMode] = useState<
    "Add_Benefit" | "Edit_Benefit" | "Add_Condition" | "Edit_Condition" | null
  >(null);

  const [addGeneralBenefit] = useAddGeneralBenefitMutation();
  const [editGeneralBenefit] = useEditGeneralBenefitMutation();
  const [toggleGeneralBenefitVisibility] =
    useToggleGeneralBenefitVisibilityMutation();

  const [addGeneralCondition] = useAddGeneralConditionMutation();
  const [editGeneralCondition] = useEditGeneralConditionMutation();
  const [toggleGeneralConditionVisibility] =
    useToggleGeneralConditionVisibilityMutation();

  const MODAL_MODES = new Map([
    [
      "Add_Benefit",
      () => addGeneralBenefit({ name: newTargetVal, order: newTargetOrder }),
    ],
    [
      "Edit_Benefit",
      () =>
        editGeneralBenefit({
          id: targetId,
          name: newTargetVal,
          order: newTargetOrder,
        }),
    ],
    [
      "Add_Condition",
      () =>
        addGeneralCondition({
          name: newTargetVal,
          general_benefit: targetConditionParentId,
          order: newTargetOrder,
        }),
    ],
    [
      "Edit_Condition",
      () =>
        editGeneralCondition({
          id: targetId,
          name: newTargetVal,
          order: newTargetOrder,
        }),
    ],
  ]);

  const onModalClose = () => {
    setNewTargetVal("");
    setNewTargetOrder(0);
    setModalMode(null);
    setTargetId(undefined);
    setTargetConditionParentId(undefined);
    setIsAddModalVisible(false);
  };

  const onModalAction = () => {
    if (modalMode) {
      MODAL_MODES?.get(modalMode)?.().unwrap().then(onModalClose);
    }
  };

  if (isLoading)
    return (
      <div className="w-100 d-flex justify-content-center">
        <Spinner size="Large" />
      </div>
    );

  return (
    <section>
      <PageTitle> {t("Membership_Benefits_Settings")} </PageTitle>
      <InstructionNote instructionType="danger">
        <span className="Paragraph200Heavy">
          Caution: Any action is taken in this page will reflect directly on
          GTO&apos;s public site in membership types page
        </span>
      </InstructionNote>
      <div className={`${styles.container} d-flex flex-column gap-4`}>
        <div className={styles.innerContainer}>
          {data?.map((generalBenefitItem) => (
            <div
              className={styles.benefitItemContainer}
              key={`${generalBenefitItem.id}${generalBenefitItem.name}`}
            >
              <div className="d-flex w-100 justify-content-between">
                <span
                  className={`${styles.benefitTitle} Paragraph100Heavy py-2 col-7`}
                >
                  {generalBenefitItem.name}
                </span>
                <div className="col-4 d-flex gap-1 d-flex align-items-center">
                  <div className="d-flex px-2 border rounded justify-content-center align-items-center">
                    {generalBenefitItem.order}
                  </div>
                  <Button
                    btnClassName="w-75"
                    styleType="Secondary"
                    prefix={
                      <div>
                        <Icon name="Edit" size={14} />
                      </div>
                    }
                    onClick={() => {
                      setModalMode("Edit_Benefit");
                      setNewTargetVal(generalBenefitItem.name);
                      setNewTargetOrder(generalBenefitItem.order);
                      setTargetId(generalBenefitItem.id);
                      setIsAddModalVisible(true);
                    }}
                  >
                    Edit Benefit
                  </Button>
                  <div className="w-25 d-flex justify-content-center align-items-center">
                    <Switch
                      defaultChecked={generalBenefitItem.is_visible}
                      onChange={(e) =>
                        toggleGeneralBenefitVisibility({
                          id: generalBenefitItem.id!,
                          is_visible: e,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              {generalBenefitItem?.general_conditions?.map((conditionItem) => (
                <div
                  className="d-flex justify-content-between"
                  key={`${conditionItem.id}${conditionItem.name}`}
                >
                  <div className={`col-9 ${styles.benefitItemContainer}`}>
                    <span className="Paragraph100Light">
                      {conditionItem?.name}
                    </span>
                  </div>
                  <div className="col-3 d-flex gap-1 d-flex align-items-center justify-content-end gap-2">
                    <div className="w-50 d-flex justify-content-center align-items-center gap-1">
                      <div className="d-flex px-2 border rounded justify-content-center align-items-center">
                        {conditionItem.order}
                      </div>
                      <Button
                        styleType="Secondary"
                        onClick={() => {
                          setModalMode("Edit_Condition");
                          setNewTargetVal(conditionItem.name);
                          setNewTargetOrder(conditionItem.order);
                          setTargetId(conditionItem.id);
                          setIsAddModalVisible(true);
                        }}
                      >
                        <div>
                          <Icon name="Edit" size={20} />
                        </div>
                      </Button>
                    </div>
                    <div className="w-25 d-flex justify-content-center align-items-center">
                      <Switch
                        defaultChecked={conditionItem.is_visible}
                        onChange={(e) =>
                          toggleGeneralConditionVisibility({
                            id: conditionItem.id!,
                            is_visible: e,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
              <Button
                styleType="NoStyle"
                btnClassName={styles.addCondtionBtn}
                onClick={() => {
                  setModalMode("Add_Condition");
                  setTargetConditionParentId(generalBenefitItem.id);
                  setIsAddModalVisible(true);
                }}
              >
                <span className="Button100">+ {t("Add_Condition")} </span>
              </Button>
            </div>
          ))}
        </div>
        <div className={styles.innerContainer}>
          <Button
            styleType="Primary"
            btnClassName="w-100"
            onClick={() => {
              setModalMode("Add_Benefit");
              setIsAddModalVisible(true);
            }}
          >
            <span className="Button100">+ {t("Add_Benefit")} </span>
          </Button>
        </div>
      </div>
      <ModalWrapper
        size="large"
        isVisible={isAddModalVisible}
        headerTitle={t(modalMode ?? "Add")}
        onClose={onModalClose}
        isFooterHidden
      >
        <TextInput
          label={t("name")}
          value={newTargetVal}
          onChange={(e) => setNewTargetVal(e.target.value)}
        />

        <TextInput
          type="number"
          label={t("order")}
          value={String(newTargetOrder)}
          onChange={(e) => setNewTargetOrder(Number(e.target.value))}
        />

        <div className={styles.addModalBtns}>
          <Button
            styleType="Secondary"
            btnClassName={styles.cancelBtn}
            onClick={onModalClose}
          >
            <span className="Button100"> {t("Cancel")} </span>
          </Button>
          <Button
            btnClassName={styles.addBtn}
            disabled={newTargetVal === ""}
            onClick={onModalAction}
          >
            <span className="Button100"> {t(modalMode ?? "Add")} </span>
          </Button>
        </div>
      </ModalWrapper>
    </section>
  );
}
