import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Controller,
  ResolverOptions,
  useForm,
  useWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PageTitle from "components/general/PageTitle";
import Button from "components/general/Button";
import {
  useGetCompanyMembershipsQuery,
  useLazyGetMembershipQuery,
} from "apis/services/memberships";
import handleErrors from "utils/handleErrors";
import { CardPostRequest, Customer } from "apis/types/customers";
import { useLazyGetCountryQuery } from "apis/services/countries";
import { Membership } from "apis/types/memberships";
import {
  useGetCustomerQuery,
  useUpdateCardMutation,
  useUpdateCustomerMutation,
} from "apis/services/customers";
import {
  useGetWalletTransactionsQuery,
  useUpdateWalletTransactionsBulkMutation,
} from "apis/services/wallet";
import Spinner from "components/general/Spinner";
import dayjs from "dayjs";
import showToast from "utils/showToast";
import { Switch } from "antd";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import { TabItem } from "components/general/Tabs/types";
import Tabs from "components/general/Tabs";
import { WalletItem } from "apis/types/wallet";
import ControlledTextInput from "components/inputs/ControlledTextInput";
import { useGetCurrenciesQuery } from "apis/services/currency";
import CustomCridetCardLetters from "components/general/CustomCridetCardLetters";
import EditCustomerInfoInputs from "./EditCustomerInfoInputs";
import EditMembershipCardDetails from "./EditMembershipCardDetails";
import EditWallet from "./EditWallet";
import styles from "./styles.module.scss";

const schema = yup.object({
  firstName: yup.string().required("First name is required!"),
  lastName: yup.string().required("Last name is required!"),
  email: yup.string().email().trim().required("Email address is required!"),
  company: yup
    .number()
    .moreThan(0, "Company is required!")
    .required()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  mobileCode: yup.string().required(),
  mobile_code_char: yup.string().required(),

  mobile: yup.string().required("Mobile number is required!"),
  membership: yup
    .number()
    .moreThan(0, "Memebership is required!")
    .required()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  companyMembership: yup
    .number()
    .moreThan(0, "Memebership is required!")
    .required()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  country: yup
    .number()
    .moreThan(0, "Country is required!")
    .required()
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  currency: yup.string().required(),
  membershipPrice: yup.string().required("Memberhsip price is required!"),
  afterDiscount: yup
    .number()
    .required("After discount is required!")
    .transform((value) => (Number.isNaN(value) ? undefined : value)),
  cardCode: yup.string().length(4, "Card code is required!"),
  countryCardCode: yup.string().required("Country code is required!"),
  cardNumber: yup.string().length(8, "Card Number is required!"),
  expiryDate: yup.string().required("Expiray Date is required!"),
  renewalPrice: yup.string().required("Renewal Price is required!"),
  status: yup.boolean().required(),
  notes: yup.string(),
  wallet: yup
    .array()
    .of(
      yup.object({
        amount: yup
          .number()
          .moreThan(0, "Amount is required!")
          .required("Amount is required!")
          .transform((value) => (Number.isNaN(value) ? undefined : value)),
        currency: yup.string().required("Currency is required!"),
        amount_type: yup
          .string()
          .oneOf(["refund", "travel_pound", "kudos", ""])
          .required("Amount Type is required!"),
        date: yup
          .string()
          .nullable()
          .when("is_active", {
            is: false,
            then: (dateSchema) => dateSchema.required("Date is required"),
          }),
        amount_used_before_confirmation: yup.string(),
        amount_used: yup.string(),
        is_active: yup.boolean().required("Status is required!"),
      })
    )
    .required(),
});

export type FormTypes = yup.InferType<typeof schema>;

export default function EditCustomerProfile() {
  const { t } = useAutoCompleteTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const user = useSelector((state: RootState) => state.auth.userData);

  const { customerId } = useParams();

  const { data: customer, isLoading: isCustomerLoading } = useGetCustomerQuery(
    customerId ?? ""
  );

  const { data: WalletTransactions, isLoading: isWalletLoading } =
    useGetWalletTransactionsQuery({
      customer_id: Number(customerId),
      page_size: 10,
    });

  const { data: currenciesData } = useGetCurrenciesQuery();

  const [targetMembershipObj, setTargetMembershipObj] =
    useState<Membership | null>(null);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormTypes>({
    resolver:
      user?.role !== "admin"
        ? yupResolver(schema.omit(["wallet"]))
        : yupResolver(schema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      company: customer?.company,
      mobileCode: customer?.mobile_code,
      mobile: "",
      mobile_code_char: customer?.mobile_code_char,
      membership: 0,
      companyMembership: customer?.cards?.[0]?.company_membership?.id,
      country: customer?.country,
      currency: customer?.cards?.[0]?.currency,
      membershipPrice: "",
      afterDiscount: targetMembershipObj?.price,
      cardCode: "",
      countryCardCode: "",
      cardNumber: "",
      expiryDate: "",
      renewalPrice: "",
      status: true,
      notes: "",
      wallet:
        user?.role !== "admin"
          ? undefined
          : [
              {
                amount: 0,
                currency: "GBP",
                amount_type: "",
                date: "",
                is_active: false,
              },
            ],
    },
    mode: "onSubmit",
  } as unknown as ResolverOptions<FormTypes>);

  const TabsData: TabItem[] = [
    {
      label: t("Wallet"),
      children: <EditWallet control={control} errors={errors} />,
    },
    {
      label: t("Notes"),
      children: (
        <ControlledTextInput
          control={control}
          name="notes"
          type="textarea"
          containerStyle="mt-2"
          inputStyle={styles.formTextArea}
          i18nLabelKey="Notes"
          i18nPlaceholder="Notes"
        />
      ),
    },
  ];

  useEffect(() => {
    if (customer) {
      setValue("firstName", customer.first_name);
      setValue("mobile_code_char", customer?.mobile_code_char);

      setValue("lastName", customer.last_name);
      setValue("email", customer.email);
      setValue("company", customer.company);
      setValue("mobileCode", customer.mobile_code);
      setValue("mobile", customer.mobile);
      setValue("country", customer.country);
      setValue("membership", customer.cards?.[0]?.membership.id);
      setValue("currency", customer.cards?.[0]?.currency);
      setValue("companyMembership", customer.cards?.[0]?.company_membership.id);
      setValue("cardCode", customer.cards?.[0]?.card_number.slice(0, 4));
      setValue("cardNumber", customer.cards?.[0]?.card_number.slice(-8));
      setValue(
        "expiryDate",
        dayjs(customer.cards?.[0]?.expiry_date, "YYYY-MM-DD").format("MM/YY")
      );
      setValue(
        "renewalPrice",
        String(customer.cards?.[0]?.membership?.annual_fees)
      );
      setValue(
        "membershipPrice",
        String(customer.cards?.[0]?.membership?.price)
      );
      setValue("status", customer.status);
      setValue("notes", customer.notes);
      const updatedUrl = `${pathname}?idName=${customer?.first_name} ${customer?.last_name}`;
      navigate(updatedUrl, { replace: true });
    }
  }, [customer]);

  useEffect(() => {
    if (user?.role === "admin" && WalletTransactions) {
      setValue(
        "wallet",
        WalletTransactions.results.map((wallet) => ({
          ...wallet,
          date: wallet.date
            ? dayjs(wallet.date).format("DD/MM/YYYY")
            : wallet.date,
        }))
      );
    }
  }, [WalletTransactions?.results]);

  const companyId = useWatch({ control, name: "company" });

  const { data: comapnyMemberhsips } = useGetCompanyMembershipsQuery(
    {
      company_id: companyId,
    },
    { skip: !companyId }
  );

  const companyMembershipIdWatcher = useWatch({
    control,
    name: "companyMembership",
  });

  const [getMemberhsip] = useLazyGetMembershipQuery();

  useEffect(() => {
    if (!comapnyMemberhsips?.results?.length) return;

    const targetMembershipId = comapnyMemberhsips?.results.find(
      (item) => item.id === Number(companyMembershipIdWatcher)
    );

    if (!currenciesData) return;

    if (!targetMembershipId) return;

    getMemberhsip(String(targetMembershipId.membership))
      .unwrap()
      .then((res) => {
        setTargetMembershipObj(res);
        if (res.price) {
          setValue("membership", res.id);
          setValue(
            "membershipPrice",
            String(
              (
                Number(res.price) *
                // @ts-ignore
                currenciesData[res.currency][customer?.cards?.[0]?.currency]
              ).toFixed(2)
            )
          );
          setValue(
            "afterDiscount",
            Number(customer?.cards?.[0]?.price_after_discount)
          );
        }
        if (res.annual_fees) setValue("renewalPrice", String(res.annual_fees));
      })
      .catch(handleErrors);
  }, [companyMembershipIdWatcher, comapnyMemberhsips, currenciesData]);

  const [getCountry] = useLazyGetCountryQuery();

  const countryWatcher = useWatch({ control, name: "country" });

  useEffect(() => {
    if (!countryWatcher) return;

    getCountry(String(countryWatcher))
      .unwrap()
      .then((res) => {
        if (!res) return;

        setValue("countryCardCode", res.country_card_code);
        setValue("cardCode", customer?.cards?.[0]?.card_number.slice(0, 4));
        setValue("cardNumber", customer?.cards?.[0]?.card_number.slice(-8));
      });
  }, [countryWatcher]);

  const countryCodeWatcher = useWatch({ control, name: "cardCode" });
  const countryCardCodeWatcher = useWatch({ control, name: "countryCardCode" });
  const cardNumberWatcher = useWatch({ control, name: "cardNumber" });
  const nameWatcher = useWatch({ control, name: ["firstName", "lastName"] });
  const expiryDateWatcher = useWatch({ control, name: "expiryDate" });
  const statusWatcher = useWatch({ control, name: "status" });

  const [updateCustomer, { isLoading: isUpdateCustomerLoading }] =
    useUpdateCustomerMutation();

  const [updateCard, { isLoading: isUpdateCardLoading }] =
    useUpdateCardMutation();

  const [updateWallet, { isLoading: isUpdateWalletLoading }] =
    useUpdateWalletTransactionsBulkMutation();

  const onSubmit = (data: FormTypes) => {
    if (customerId) {
      const customerData: Partial<
        Omit<Customer, "created_on" | "modified_on" | "id">
      > = {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        company: data.company,
        mobile_code: data.mobileCode,
        mobile_code_char: data.mobile_code_char,
        mobile: data.mobile,
        country: data.country,
        status: data.status,
        notes: data.notes,
      };

      const cardData: CardPostRequest = {
        card_number: `${data.cardCode}${data.countryCardCode}${data.cardNumber}`,
        company_membership: data.companyMembership,
        currency: data.currency,
        expiry_date: dayjs(data.expiryDate, "MM/YY").format("YYYY-MM-01"),
        membership: data.membership,
        price_after_discount: data.afterDiscount,
      };

      if (customer) {
        updateCard([String(customer.cards?.[0]?.id), cardData])
          .unwrap()
          .then(() => {
            updateCustomer([customerId, customerData])
              .then(() => {
                if (user && user.role === "admin") {
                  const transactions: Partial<WalletItem>[] = data.wallet?.map(
                    (item) => ({
                      ...item,
                      date:
                        item.date === "" || item.date === null
                          ? undefined
                          : dayjs(item.date, "DD/MM/YYYY").format("YYYY-MM-DD"),
                      customer: Number(customerId),
                    })
                  );
                  updateWallet(transactions)
                    .unwrap()
                    .then(() => {
                      navigate("/customers");
                      showToast({
                        toastType: "success",
                        title: `${data.firstName} ${data.lastName} has been updated successfully!`,
                        showCloseIcon: true,
                      });
                    });
                } else {
                  navigate("/customers");
                  showToast({
                    toastType: "success",
                    title: `${data.firstName} ${data.lastName} has been updated successfully!`,
                    showCloseIcon: true,
                  });
                }
              })
              .catch(handleErrors);
          });
      }
    }
  };

  return (
    <section>
      <PageTitle>
        {nameWatcher[0]} {nameWatcher[1]}
      </PageTitle>
      <div className={styles.container}>
        {isCustomerLoading ||
        isUpdateCustomerLoading ||
        isUpdateCardLoading ||
        isUpdateWalletLoading ||
        isWalletLoading ? (
          <div className="w-100 d-flex justify-content-center">
            <Spinner size="Large" />
          </div>
        ) : (
          <div className={styles.innerContainer}>
            <form
              className={styles.form}
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <EditCustomerInfoInputs
                control={control}
                errors={errors}
                setValue={setValue}
              />

              <div className={styles.cardContainer}>
                <div className={styles.card}>
                  <CustomCridetCardLetters
                    letterType={targetMembershipObj?.letters_type}
                    bgColor={targetMembershipObj?.template_color}
                    bgImage={targetMembershipObj?.template_image}
                    textColor1={targetMembershipObj?.template_text_color_1}
                    textColor2={targetMembershipObj?.template_text_color_2}
                    shadowColor={targetMembershipObj?.template_shadow_color}
                    shadowWidth={Number(
                      targetMembershipObj?.template_shadow_width
                    )}
                    firstFourNums={
                      countryCodeWatcher === "" ? undefined : countryCodeWatcher
                    }
                    secondFourNums={
                      countryCardCodeWatcher === ""
                        ? undefined
                        : countryCardCodeWatcher
                    }
                    thirdFourNums={
                      cardNumberWatcher === ""
                        ? undefined
                        : cardNumberWatcher?.slice(0, 4)
                    }
                    fourthFourNums={
                      cardNumberWatcher === ""
                        ? undefined
                        : cardNumberWatcher?.slice(4)
                    }
                    cardHolder={
                      nameWatcher[0] === ""
                        ? undefined
                        : `${nameWatcher[0]} ${nameWatcher[1]}`
                    }
                    expiaryDate={
                      expiryDateWatcher === "" ? undefined : expiryDateWatcher
                    }
                  />
                </div>
              </div>

              <EditMembershipCardDetails
                control={control}
                errors={errors}
                setValue={setValue}
              />

              <Tabs tabs={TabsData} />

              <div className={styles.switchContainer}>
                <span className="Paragraph100Light">
                  {statusWatcher ? t("Active") : t("Inactive")}
                </span>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Switch
                      defaultChecked={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </div>

              <Button btnClassName={styles.saveBtn} type="submit">
                <span>{t("Save")}</span>
              </Button>
            </form>
          </div>
        )}
      </div>
    </section>
  );
}
