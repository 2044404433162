import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input } from "antd";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextInput from "components/inputs/TextInput";
import Button from "components/general/Button";
import FormLogo from "components/general/FormLogo";
import COLORS from "constants/Colors";
import ModalWrapper from "components/modals/ModalWrapper";
import CriteriaTitle from "components/general/CriteriaTitle";
import {
  useCheckResetCodeMutation,
  useGetResetCodeMutation,
  useResetPasswordMutation,
} from "apis/services/auth";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import showToast from "utils/showToast";
import handleErrors from "utils/handleErrors";
import styles from "./styles.module.scss";

interface formData {
  email?: string;
  code?: string;
}

interface stepProps {
  handleNextStep?: (data: formData) => void;
  formData?: formData;
}

const firstStepSchema = yup
  .object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
  })
  .required();

type firstStepData = yup.InferType<typeof firstStepSchema>;

function FirstStep({ handleNextStep }: stepProps) {
  const { t } = useAutoCompleteTranslation();
  const [getResetCode, { isLoading }] = useGetResetCodeMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<firstStepData>({
    resolver: yupResolver(firstStepSchema),
    mode: "onSubmit",
  });

  const handleOnSubmit = (data: firstStepData) => {
    getResetCode(data)
      .unwrap()
      .then((res) => {
        showToast({ toastType: "success", title: res.details });
        handleNextStep!({ email: data.email });
      })
      .catch(handleErrors);
  };

  return (
    <>
      <FormLogo />
      <h3 className="Headline300Heavy"> {t("Reset_Your_Password")} </h3>
      <span className="Paragraph200Light">
        {t("Enter_Your_Email_For_The_Verification_Process")}
      </span>
      <form onSubmit={handleSubmit(handleOnSubmit)} className={styles.form}>
        <div className="d-flex flex-column gap-1">
          <TextInput
            status={errors.email?.message ? "error" : "default"}
            placeholder={t("EMAIL")}
            i18nLabelKey="EMAIL"
            reactHookFormProps={register("email")}
            errorMsg={errors?.email?.message}
          />
        </div>

        <Button
          btnClassName={styles.submitBtn}
          styleType="Primary"
          type="submit"
          isLoading={isLoading}
        >
          <span
            className="Button100"
            style={{ color: COLORS.TextPrimaryWhite }}
          >
            {t("Submit")}
          </span>
        </Button>
      </form>
    </>
  );
}

function SecondStep({ handleNextStep, formData }: stepProps) {
  const { t } = useAutoCompleteTranslation();
  const [checkResetCode, { isLoading }] = useCheckResetCodeMutation();
  const [getResetCode, { isLoading: isResetLoading }] =
    useGetResetCodeMutation();

  const [timer, setTimer] = useState(30);
  const [isResetVisible, setIsResetVisible] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const verificationInputOneRef = useRef(null);
  const verificationInputTwoRef = useRef(null);
  const verificationInputThreeRef = useRef(null);
  const verificationInputFourRef = useRef(null);

  const refs = [
    verificationInputOneRef,
    verificationInputTwoRef,
    verificationInputThreeRef,
    verificationInputFourRef,
  ];

  const handleInputFocus = (ref: any, loseFocus = false) => {
    if (loseFocus) ref?.current?.blur();
    else ref?.current?.focus();
  };

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  useEffect(() => {
    let timeout: any;
    if (timer === 0) {
      setIsResetVisible(true);
      timeout = null;
    } else {
      timeout = setTimeout(() => setTimer(timer - 1), 1000);
    }

    return () => clearTimeout(timeout);
  }, [timer]);

  useEffect(() => {
    if (verificationInputOneRef?.current)
      handleInputFocus(verificationInputOneRef);
  }, [verificationInputOneRef]);

  const handleOnResend = () => {
    getResetCode({ email: formData!.email! })
      .unwrap()
      .then((res) => {
        showToast({ toastType: "success", title: res.details });
      })
      .catch(handleErrors);
  };

  const handleOnFinish = (data: any) => {
    const code = Object.keys(data)
      ?.map((codeItem) => data[codeItem])
      .join("");

    const formBody = {
      email: formData!.email!,
      code,
    };

    checkResetCode(formBody)
      .unwrap()
      .then(() => handleNextStep!({ code }))
      .catch(handleErrors);
  };
  return (
    <>
      <h3 className="Headline300Heavy">Verification</h3>
      <span className="Paragraph200Light">{t("Check_Verification_Code")}</span>
      <Form
        form={form}
        name="validateOnly"
        className={styles.form}
        autoComplete="off"
        autoCorrect="off"
        onFinish={handleOnFinish}
      >
        <span className="Label100"> {t("Code")} </span>
        <div className="d-flex gap-1">
          {refs.map((item, index) => {
            return (
              <Form.Item
                key={`inputNum${index + 1}`}
                name={`inputNum${index}`}
                rules={[{ required: true, message: " ", max: 1 }]}
              >
                <Input
                  maxLength={1}
                  ref={item}
                  type="number"
                  className={styles.codeInput}
                  placeholder=""
                  onChange={(e) => {
                    if (e.target.value)
                      handleInputFocus(
                        refs[index + 1] !== undefined ? refs[index + 1] : item,
                        index === refs.length - 1
                      );
                  }}
                  onKeyDown={(e) => {
                    switch (e.key) {
                      case "Backspace":
                        if (e.currentTarget.value) break;
                        else
                          handleInputFocus(
                            refs[index - 1] !== undefined
                              ? refs[index - 1]
                              : item
                          );
                        break;
                      case "ArrowRight":
                        e.preventDefault();
                        handleInputFocus(
                          refs[index + 1] !== undefined ? refs[index + 1] : item
                        );
                        break;
                      case "ArrowLeft":
                        e.preventDefault();
                        handleInputFocus(
                          refs[index - 1] !== undefined ? refs[index - 1] : item
                        );
                        break;
                      case "ArrowUp":
                        e.preventDefault();
                        break;
                      case "ArrowDown":
                        e.preventDefault();
                        break;
                      default:
                        break;
                    }
                  }}
                />
              </Form.Item>
            );
          })}
        </div>

        <div className={styles.timer}>
          <span className="Paragraph200Light">
            00:{`${timer < 10 ? "0" : ""}${timer}`}
          </span>
        </div>

        <Button
          btnClassName={styles.submitBtn}
          styleType="Primary"
          type="submit"
          disabled={!submittable}
          isLoading={isLoading || isResetLoading}
        >
          <span
            className="Button100"
            style={{ color: COLORS.TextPrimaryWhite }}
          >
            {t("Submit")}
          </span>
        </Button>

        <div className="d-flex justify-content-center">
          <span className={`Paragraph200Light ${styles.resendCode}`}>
            If you didn’t receive a code?{" "}
            <Button
              styleType="NoStyle"
              btnClassName={`Label200 ${styles.timerOut}`}
              onClick={handleOnResend}
              disabled={!isResetVisible}
            >
              {t("Resend")}
            </Button>
          </span>
        </div>
      </Form>
    </>
  );
}

const thirdStepSchema = yup
  .object({
    password: yup
      .string()
      .required("Password field is required")
      .test("isValidPass", "Password criteria error", (value) => {
        const hasUpperCase = /[A-Z]/.test(value!);
        const hasLowerCase = /[a-z]/.test(value!);
        const hasNumber = /[0-9]/.test(value!);
        const hasSymbole = /[~!@#%^&*()+_=/-]/.test(value!);
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbole];
        return conditions.find((item) => item === false) === undefined;
      }),
    confirmPassword: yup
      .string()
      .required("Confirm password field is required")
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .required();

type thirdStepData = yup.InferType<typeof thirdStepSchema>;

function ThirdStep({ formData }: stepProps) {
  const { t } = useAutoCompleteTranslation();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<thirdStepData>({
    resolver: yupResolver(thirdStepSchema),
    mode: "onSubmit",
  });

  const passwordWatch = watch("password");

  const [isVisible, setIsVisible] = useState(false);
  const submitForm = (data: thirdStepData) => {
    const finalStepData = {
      email: formData!.email!,
      code: formData!.code!,
      password: data.password,
    };

    resetPassword(finalStepData)
      .unwrap()
      .then(() => setIsVisible(true))
      .catch(handleErrors);
  };

  return (
    <>
      <FormLogo />
      <h3 className="Headline300Heavy">New Password</h3>
      <span className="Paragraph200Light">{t("Set_New_Password")}</span>
      <form className={styles.form} onSubmit={handleSubmit(submitForm)}>
        <div className={styles.inputsContainer}>
          <div className="d-flex flex-column gap-1">
            <TextInput
              type="password"
              status={errors.password?.message ? "error" : "default"}
              placeholder={t("PASSWORD")}
              i18nLabelKey="NEW_PASSWORD"
              reactHookFormProps={register("password")}
              errorMsg={errors?.password?.message}
            />
          </div>
          <div className="d-flex flex-column gap-1">
            <TextInput
              type="password"
              status={errors.confirmPassword?.message ? "error" : "default"}
              placeholder={t("PASSWORD")}
              i18nLabelKey="CONFIRM_PASSWORD"
              reactHookFormProps={register("confirmPassword")}
              errorMsg={errors?.confirmPassword?.message}
            />
          </div>
          <CriteriaTitle value={passwordWatch} title={t("Capital_Letter")} />
          <CriteriaTitle value={passwordWatch} title={t("Small_Letter")} />
          <CriteriaTitle value={passwordWatch} title={t("Number")} />
          <CriteriaTitle value={passwordWatch} title={t("Special_Symbol")} />
        </div>

        <Button
          btnClassName={styles.updatePassword}
          styleType="Primary"
          type="submit"
          isLoading={isLoading}
        >
          <span
            className="Button100"
            style={{ color: COLORS.TextPrimaryWhite }}
          >
            {t("Update_Password")}
          </span>
        </Button>
      </form>

      <ModalWrapper
        headerTitle={t("Congratulations")}
        size="large"
        isFooterHidden
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        modalType="success"
        onClose={() => navigate("/login")}
      >
        <span className="Paragraph100Light">{t("Updated_Password")}</span>
      </ModalWrapper>
    </>
  );
}

export default function ForgotPassword() {
  const [currSetp, setCurrStep] = useState(0);
  const [formData, setFormData] = useState({});

  const handleNextStep = (data: any) => {
    setFormData({ ...formData, ...data });
    setCurrStep(currSetp + 1);
  };

  const setps = [
    <FirstStep handleNextStep={handleNextStep} />,
    <SecondStep handleNextStep={handleNextStep} formData={formData} />,
    <ThirdStep formData={formData} />,
  ];

  return setps[currSetp];
}
