import api from "apis";
import { Company, GetCompaniesParams } from "apis/types/companies";
import { PaginatedResponse } from "apis/types/general";

export const companiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<PaginatedResponse<Company>, GetCompaniesParams>({
      query: (params) => ({
        url: "/company/",
        params: { ordering: "-id", ...params },
      }),
      forceRefetch: () => true,
    }),

    getCompany: build.query<Company, string>({
      query: (id) => ({
        url: `/company/${id}/`,
      }),
      forceRefetch: () => true,
    }),

    addCompany: build.mutation<Omit<Company, "company_memberships">, FormData>({
      query: (body) => ({
        url: "/company/",
        method: "POST",
        body,
      }),
    }),

    deleteCompany: build.mutation<any, string>({
      query: (id) => ({
        url: `/company/${id}/`,
        method: "DELETE",
      }),
    }),

    deleteCompaniesBulk: build.mutation<any, string>({
      query: (ids) => ({
        url: `/company/delete_bulk/?ids=${ids}`,
        method: "DELETE",
      }),
    }),

    updateCompany: build.mutation<Company, [id: string, body: FormData]>({
      query: ([id, body]) => ({
        url: `/company/${id}/`,
        method: "PATCH",
        body,
      }),
    }),
  }),
});

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useAddCompanyMutation,
  useDeleteCompanyMutation,
  useDeleteCompaniesBulkMutation,
  useUpdateCompanyMutation,
} = companiesApi;
