import api from "apis";
import { City, GetCitiesParams } from "apis/types/cities";
import { PaginatedResponse } from "apis/types/general";

export const citiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCites: build.query<PaginatedResponse<City>, GetCitiesParams>({
      query: (params) => ({
        url: "/cities/",
        params: { ...params },
      }),
      forceRefetch: () => true,
    }),

    getCity: build.query<City, string>({
      query: (id) => ({
        url: `/cities/${id}`,
      }),
      forceRefetch: () => true,
    }),
  }),
});

export const { useGetCitesQuery, useGetCityQuery } = citiesApi;
