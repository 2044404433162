import i18n from "i18n";
import { GeneralQuotationGetReq } from "apis/types/generalQuotations";
import { AccommodationQuotation } from "apis/types/accommodationQuotation";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import useToggleState from "hooks/useToggleState";
import Collapse from "components/wrappers/Collapse";
import dayjs from "dayjs";
import styles from "../styles.module.scss";

export default function AccommodationDetails({
  index,
  generalQuotation,
  accommodationData,
}: {
  index: number;
  generalQuotation: GeneralQuotationGetReq;
  accommodationData: AccommodationQuotation;
}) {
  const { t } = useAutoCompleteTranslation();

  const currentLanguage = i18n.language ?? "en";

  const CurrencyFormatter = new Intl.NumberFormat(
    currentLanguage === "ar" ? "ar-EG" : "en-US",
    {
      style: "currency",
      currency: accommodationData?.currency ?? "GBP",
    }
  );

  const {
    isSelected: isCollapseSelected,
    addToSelected: addToSelectedCollapse,
  } = useToggleState<string>([]);

  const sortedParams = accommodationData?.parameters?.length
    ? // eslint-disable-next-line no-unsafe-optional-chaining
      [...accommodationData?.parameters].sort(
        (a: any, b: any) => a.order - b.order
      )
    : [];

  return (
    <div>
      <div
        className={`${styles.customBorder} ${index !== 0 && styles.spaceTop}`}
      >
        <span className="Label100 text-Capitalize">{t("Quotation")}</span>
        <div className={styles.quotation_container}>
          <div className="d-flex justify-content-between ">
            <span className="Paragraph200Light">
              {generalQuotation?.customer?.first_name}{" "}
              {generalQuotation?.customer?.last_name}
            </span>
            <span className="Paragraph200Light">
              {dayjs(generalQuotation?.created_on).format("DD/MM/YYYY")}
            </span>
          </div>
          <div className="d-flex justify-content-between ">
            <span className="Paragraph200Light"> {t("Reference")} </span>
            <span className="Paragraph200Light">
              {generalQuotation.quotation_id}
            </span>
          </div>
        </div>
      </div>
      <div className={`${styles.customBorder} ${styles.spaceTop}`}>
        <div className={styles.hotel_container}>
          <span className="Label100"> {accommodationData.type} </span>
          <span className="Label100">{accommodationData.name}</span>
        </div>
        <div className={styles.data_container}>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("city")} </span>
            <span className="Paragraph200Light">
              {accommodationData.city_name}, {accommodationData.country_name}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Check_In_date")} </span>
            <span className="Paragraph200Light">
              {dayjs(accommodationData.check_in_date).format("DD/MM/YYYY")}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Check_Out_date")} </span>
            <span className="Paragraph200Light">
              {dayjs(accommodationData.check_out_date).format("DD/MM/YYYY")}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("rating")} </span>
            <span className="Paragraph200Light">
              {accommodationData.rating} {t("Stars")}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("Board_type")}</span>
            <span className="Paragraph200Light">
              {accommodationData.board_type}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">{t("address")} </span>
            <span
              className={`Paragraph200Light w-75 ${
                currentLanguage === "en" ? "text-end" : "text-start"
              } text-break`}
            >
              {accommodationData.address}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">
              {t("Cancelation_Before")}{" "}
            </span>
            <span
              className={`${styles.nonRefundableText} ${
                accommodationData.cancelation_before
                  ? styles.Refundable
                  : styles.nonRefundable
              }`}
            >
              {accommodationData.cancelation_before
                ? t("Refundable")
                : t("Non_Refundable")}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span className="Paragraph200Light">
              {t("Cancelation_Before_Date")}{" "}
            </span>
            <span className="Paragraph200Light">
              {dayjs(accommodationData.cancelation_before_date).format(
                "DD/MM/YYYY"
              )}
            </span>
          </div>
          <div className={styles.rooms_container}>
            <div className={styles.customBorder}>
              <span className={styles.roomText}>
                {accommodationData.rooms?.length} {t("Room")}
                {(accommodationData.rooms?.length ?? 0) > 1 && t("all")}
              </span>
              {accommodationData.rooms?.map((room, idx) => {
                return (
                  <div
                    className={styles.customPadding}
                    key={`room${+index}${+idx}`}
                  >
                    <span className={`${styles.roomText}`} key={room.id}>
                      {room?.type}
                    </span>
                    <div className={styles.roomsData}>
                      <div className="d-flex justify-content-between ">
                        <span className="Paragraph200Light">{t("Adults")}</span>
                        <span className="Paragraph200Light">
                          {room?.adults} {t("Adultss")}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between ">
                        <span className="Paragraph200Light">
                          {t("Children")}
                        </span>
                        <span className="Paragraph200Light">
                          {room?.children} {t("Childs")}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between ">
                        <span className="Paragraph200Light">
                          {t("Children_Age")}
                        </span>
                        <span className="Paragraph200Light">
                          {room?.children_age} {t("Years")}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between ">
                        <span className="Paragraph200Light">
                          {t("Room_price")}
                        </span>
                        <span className="Paragraph200Light">
                          {CurrencyFormatter.format(Number(room?.price))}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between ">
                        <span className="Paragraph200Light">
                          {t("Room_Discount")}
                        </span>
                        <span className="Paragraph200Light">
                          {Number(room?.discount)} %
                        </span>
                      </div>
                      <div className="d-flex justify-content-between ">
                        <span className="Paragraph200Light">
                          {t("Kudos_Point")}
                        </span>
                        <span className="Paragraph200Light">
                          {Number(room.discount_credit)} %
                        </span>
                      </div>

                      <div className="d-flex justify-content-between ">
                        <span className="Paragraph200Light">
                          {t("Taxes_Fee")}
                        </span>
                        <span className="Paragraph200Light">
                          {CurrencyFormatter.format(Number(room?.taxes_fee))}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Collapse
              title={t("Hotel_Rules")}
              isOpen={isCollapseSelected(`Hotel_Rules`)}
              setIsOpen={() => addToSelectedCollapse(`Hotel_Rules`)}
              containerStyle="bg-white"
            >
              <div
                className={`${styles.customBorder} d-flex flex-column mt-2 bg-white`}
              >
                <div className={styles.passengerDetails_container}>
                  {accommodationData?.hotel_rules?.split(/\r\n|\n|\r/)?.map(
                    (ruleText) =>
                      ruleText && (
                        <div key={ruleText} className="mb-3">
                          <span className="Paragraph200Light">{ruleText}</span>
                        </div>
                      )
                  )}
                </div>
              </div>
            </Collapse>
            <div
              className={`${styles.customBorder} ${styles.passengerContainer}`}
            >
              <span className="Label100"> {t("Parameter")} </span>
              {sortedParams.map((item: any) => (
                <div
                  key={item.order}
                  className="d-flex justify-content-between"
                >
                  <span className="Paragraph200Light">{item.name}</span>
                  <span
                    className={`Paragraph200Light w-50 ${
                      currentLanguage === "en" ? "text-end" : "text-start"
                    } text-break`}
                  >
                    {item.value}
                  </span>
                </div>
              ))}
            </div>

            {/* Supplier */}

            <div
              className={`${styles.customBorder} ${styles.passengerContainer}`}
            >
              <span className="Label100"> {t("Supplier_Name")} </span>
              <div className={styles.passengerDetails_container}>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Supplier_Name")}
                  </span>
                  <span className="Paragraph200Light">
                    {accommodationData?.supplier_name}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">
                    {t("Supplier_Price")}
                  </span>
                  <span className="Paragraph200Light">
                    {new Intl.NumberFormat(
                      currentLanguage === "ar" ? "ar-EG" : "en-US",
                      {
                        style: "currency",
                        currency: accommodationData?.supplier_currency
                          ? accommodationData?.supplier_currency
                          : "GBP",
                      }
                    ).format(Number(accommodationData?.supplier_price ?? 0))}
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">{t("Payment_Date")}</span>
                  <span className="Paragraph200Light">
                    {accommodationData?.payment_date_for_supplier
                      ? dayjs(
                          accommodationData?.payment_date_for_supplier
                        ).format("DD/MM/YYYY")
                      : ""}
                  </span>
                </div>
              </div>
            </div>

            <div className={styles.roomType_container}>
              <div className={styles.customBorder}>
                <div className={styles.priceText}>
                  <span className="Label200"> {t("Price")} </span>
                </div>
                <div className={styles.priceDetails}>
                  <div className="d-flex justify-content-between ">
                    <span className="Paragraph200Light">{t("City_Tax")} </span>
                    <span className="Paragraph200Light">
                      {CurrencyFormatter.format(
                        Number(accommodationData.city_tax)
                      )}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <span className="Paragraph200Light">
                      {t("Tourism_Fee")}{" "}
                    </span>
                    <span className="Paragraph200Light">
                      {CurrencyFormatter.format(
                        Number(accommodationData.tourism_fee)
                      )}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <span className="Paragraph200Light">
                      {t("Damage_Deposit")}{" "}
                    </span>
                    <span className="Paragraph200Light">
                      {CurrencyFormatter.format(
                        Number(accommodationData.damage_deposit ?? 0)
                      )}
                    </span>
                  </div>
                  {accommodationData.total_price_before_discount !==
                    accommodationData.total && (
                    <div className="d-flex justify-content-between ">
                      <span className="Paragraph200Light">
                        {t("Total_Price_Before_Discount")}{" "}
                      </span>
                      <span className="Paragraph200Light">
                        {CurrencyFormatter.format(
                          Number(accommodationData.total_price_before_discount)
                        )}
                      </span>
                    </div>
                  )}

                  {Number(accommodationData.total_discount_amount) !== 0 && (
                    <div className="d-flex justify-content-between ">
                      <span className="Paragraph200Light">
                        {t("Total_Discount_Amount")}{" "}
                      </span>
                      <span className="Paragraph200Light">
                        -{" "}
                        {CurrencyFormatter.format(
                          Number(accommodationData.total_discount_amount)
                        )}
                      </span>
                    </div>
                  )}

                  {Number(accommodationData.amount_deducted_from_wallet) !==
                    0 && (
                    <div className="d-flex justify-content-between ">
                      <span className="Paragraph200Light">
                        {t("Amount_Deducted_From_Wallet")}{" "}
                      </span>
                      <span className="Paragraph200Light">
                        -{" "}
                        {CurrencyFormatter.format(
                          Number(accommodationData.amount_deducted_from_wallet)
                        )}
                      </span>
                    </div>
                  )}

                  <div className="d-flex justify-content-between ">
                    <span className="Paragraph200Light">{t("Total")} </span>
                    <span className={`Paragraph200Heavy ${styles.totalPrice}`}>
                      {CurrencyFormatter.format(
                        Number(accommodationData.total)
                      )}
                    </span>
                  </div>
                </div>
              </div>

              <div
                className={`${styles.customBorder} ${styles.passengerContainer}`}
              >
                <span className="Label100"> {t("Payment")} </span>
                <div className="d-flex justify-content-between">
                  <span className="Paragraph200Light">{t("payment_link")}</span>
                  {accommodationData?.payment_link !== null && (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className="Paragraph200Light"
                      href={accommodationData?.payment_link}
                    >
                      Click here to open
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
