import {
  Control,
  Controller,
  FieldErrors,
  UseFieldArrayRemove,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import Button from "components/general/Button";
import Icon from "components/general/Icon";
import useAutoCompleteTranslation from "hooks/useAutoCompleteTranslation";
import TextInput from "components/inputs/TextInput";
import InstructionNote from "components/general/InstructionNote";
import SelectionInput from "components/inputs/SelectionInput";
import DateInput from "components/inputs/DateInput";
import { Switch } from "antd";
import dayjs from "dayjs";
import { useGetCurrenciesQuery } from "apis/services/currency";
import { Currencies } from "apis/types/currency";
import { FormTypes } from ".";
import styles from "./styles.module.scss";

const amountTypesOptions = [
  { value: "refund", label: "Refund" },
  { value: "travel_pound", label: "Travel Pound" },
  { value: "kudos", label: "Kudos" },
];

function WalletItem({
  control,
  errors,
  index,
  currenciesData,
  isCurrenciesLoading,
  remove,
}: {
  control: Control<FormTypes>;
  errors: FieldErrors<FormTypes>;
  index: number;
  currenciesData?: Currencies;
  isCurrenciesLoading?: boolean;
  remove: UseFieldArrayRemove;
}) {
  const { t } = useAutoCompleteTranslation();

  const CancelBeforeWatcher = useWatch({
    control,
    name: `wallet.${index}.is_active`,
  });

  const WalletItemData = useWatch({
    control,
    name: `wallet.${index}`,
  });

  return (
    <div
      key={`card${index * Math.random()}`}
      className={styles.transactionCard}
    >
      <Button
        styleType="NoStyle"
        btnClassName={styles.closeBtn}
        onClick={() => remove(index)}
      >
        <Icon name="Close" size={20} />
      </Button>

      <div className="d-flex gap-4">
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`wallet.${index}.amount`}
            render={({ field }) => (
              <TextInput
                type="number"
                containerStyle="w-100"
                inputStyle={styles.textAndSelectDropdown}
                label={t("Wallet_Amount")}
                placeholder={t("Wallet_Amount")}
                value={field.value === 0 ? undefined : String(field.value)}
                onChange={field.onChange}
                status={
                  errors.wallet && errors.wallet[index]?.amount?.message
                    ? "error"
                    : "default"
                }
                suffix={
                  <Controller
                    control={control}
                    name={`wallet.${index}.currency`}
                    render={({ field: currencyField }) => (
                      <SelectionInput
                        isLoading={isCurrenciesLoading}
                        value={currencyField.value}
                        onChange={currencyField.onChange}
                        size="small"
                        containerStyle={styles.textAndSelectDropdown}
                        options={
                          currenciesData
                            ? Object.keys(currenciesData).map((item) => ({
                                label: item,
                                value: item,
                              }))
                            : []
                        }
                        defaultValue="GBP"
                      />
                    )}
                  />
                }
                errorMsg={errors?.wallet?.[index]?.amount?.message}
              />
            )}
          />
          {errors.wallet && errors.wallet[index]?.currency?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.wallet[index]?.currency?.message}
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`wallet.${index}.amount_type`}
            render={({ field }) => (
              <SelectionInput
                label={t("Amount_Type")}
                placeholder={t("Amount_Type")}
                value={field.value === "" ? undefined : field.value}
                onChange={field.onChange}
                options={amountTypesOptions}
              />
            )}
          />
          {errors.wallet && errors.wallet[index]?.amount_type?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.wallet[index]?.amount_type?.message}
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex gap-4">
        <div className={`w-100 d-flex flex-column gap-1 ${styles.cardSwitch}`}>
          <span className="Label100"> {t("Active")} </span>
          <Controller
            control={control}
            name={`wallet.${index}.is_active`}
            render={({ field }) => (
              <Switch
                defaultChecked={field.value}
                onChange={(e) => {
                  field.onChange(e);
                }}
                checked={field.value}
              />
            )}
          />
        </div>
        <div className="w-100 d-flex flex-column gap-1">
          <Controller
            control={control}
            name={`wallet.${index}.date`}
            render={({ field }) => (
              <DateInput
                isDisabled={CancelBeforeWatcher}
                defaultValue={
                  field.value === "" || field.value === null ? "" : field.value
                }
                onChange={(e) =>
                  e
                    ? field.onChange(dayjs(e).format("DD/MM/YYYY"))
                    : field.onChange("")
                }
                i18nLabelKey="date"
                i18nPlaceholder="date"
                status={
                  errors.wallet && errors.wallet[index]?.date?.message
                    ? "error"
                    : ""
                }
              />
            )}
          />
          {errors.wallet && errors.wallet[index]?.date?.message && (
            <div className="w-100">
              <InstructionNote instructionType="danger">
                <span className="Paragraph300Light">
                  {errors.wallet[index]?.date?.message}
                </span>
              </InstructionNote>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex gap-4">
        <div className={`w-100 d-flex flex-column gap-1 ${styles.cardSwitch}`}>
          <span className="Label100"> {t("Remaining_Amount")} </span>
          <span className="Label100">
            {Number(WalletItemData.amount) - Number(WalletItemData.amount_used)}{" "}
            {WalletItemData.currency}
          </span>
        </div>
        <div className={`w-100 d-flex flex-column gap-1 ${styles.cardSwitch}`}>
          <span className="Label100"> {t("Amount_Used")} </span>
          <span className="Label100">
            {WalletItemData.amount_used} {WalletItemData.currency}
          </span>
        </div>
      </div>
    </div>
  );
}

export default function EditWallet({
  control,
  errors,
}: {
  control: Control<FormTypes>;
  errors: FieldErrors<FormTypes>;
}) {
  const { t } = useAutoCompleteTranslation();
  const user = useSelector((state: RootState) => state.auth.userData);
  const { fields, append, remove } = useFieldArray({ control, name: "wallet" });

  const { data: currenciesData, isLoading: isCurrenciesLoading } =
    useGetCurrenciesQuery();

  if (!user || (user && user.role !== "admin"))
    return (
      <div className="mt-1">
        <InstructionNote instructionType="info">
          <span className="Paragraph200Light">{t("Admin_Access")}!</span>
        </InstructionNote>
      </div>
    );

  const handleAdd = () => {
    append({
      amount: 0,
      currency: "GBP",
      amount_type: "",
      date: "",
      is_active: false,
    });
  };

  return (
    <div>
      {fields.map((transaction, index) => (
        <WalletItem
          key={`editWallet${transaction.id}`}
          index={index}
          control={control}
          errors={errors}
          currenciesData={currenciesData}
          isCurrenciesLoading={isCurrenciesLoading}
          remove={remove}
        />
      ))}
      <Button styleType="Secondary" btnClassName="mt-4" onClick={handleAdd}>
        <span className="Button100">+ {t("Add_Another_Amount")}</span>
      </Button>
    </div>
  );
}
